// import logoImage from './assets/img/logo.svg';
// import logoWhiteImage from './assets/img/logo-white.png';

// import logoImage from './assets/img/worldline-mint-horizontal.png';
// import logoWhiteImage from './assets/img/worldline-coconut-horizontal.png';

import logoImage from './assets/img/worldline-mint-horizontal.png';
import logoWhiteImage from './assets/img/worldline-coconut-horizontal.png';

export const guideReferenceURL = '/GuideReference';
export const apiReferenceURL = '/ApiReference';
export const logo = logoImage;
export const logoWhite = logoWhiteImage;
export const supportMail = 'merchantservices@worldline.com';
export const implementationtMail = 'implementation@worldline.com';
export const supportPhone = '';
export const merchantName = 'Worldline';
export const merchantNameFooter = 'Worldline';
export const cookieExpDays = 1;
export const googleAnalitics = {
    active: true,
    code: 'UA-118892260-1'
};