import React from 'react';

import DocBuilder from '../../../components/DocBuilder';
import Breadcrumbs from '../../../components/Breadcrumbs';

import request from '../../../documentation/documentation/GetIINDetails-request';
import response from '../../../documentation/documentation/GetIINDetails-response';

class IinLookup extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            By using this method and given a IIN (BIN), you can query information like financial institution, country, etc.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} examples={true} endpoint='GetIINDetails' />
                    </div>
                </div>
            </div>
        );
    }
}

export default IinLookup;