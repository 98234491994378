import React from 'react';
import T from "i18react/dist/i18react";
import visaCheckoutIcon from '../../../../assets/img/wallets/visacheckout.png';
import Sale from '../../../ApiReference/Payments/CardPayment/Sale';

class Visacheckout extends React.Component {

    render() {
        return (
            <div className="container-fluid wallets">
                <div className="row">
                    <div className="col-12">
                        <h3>
                            {T.translate("guideReference.services.wallets.visacheckout.title")}
                        </h3>
                        <img src={visaCheckoutIcon} alt="VisaCheckout" className="watermark-image"/>
                        <p>
                            {T.translate("guideReference.services.wallets.visacheckout.text")}
                        </p>
                        <h4>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.text")}
                        </p>
                        <ul>
                            <li>{T.translate("guideReference.services.wallets.visacheckout.pci.list.1")}</li>
                            <li>{T.translate("guideReference.services.wallets.visacheckout.pci.list.2")}</li>
                            <li>{T.translate("guideReference.services.wallets.visacheckout.pci.list.3")}</li>
                            <li>{T.translate("guideReference.services.wallets.visacheckout.pci.list.4")}</li>
                        </ul>
                        <h5>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.how.title")}
                        </h5>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.1.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.1.text",
                                {"link": <T.a href="https://developer.visa.com/capabilities/visa_checkout/docs" target="_blank" text="VisaCheckout Integration"/>})}
                        </p>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.2.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.2.text")}
                        </p>
                        <Sale showDocumentation={false} onlyFiles="visacheckout_pci"/>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.3.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.3.text",
                                {"link": <T.a href="https://developer.visa.com/capabilities/visa_checkout/docs#availability" target="_blank" text="VisaCheckout Testing Documentation"/>})}
                        </p>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.4.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.visacheckout.pci.how.steps.4.text",
                                {"link": <T.a href="mailto:merchantservices@worldline.com" text="Support Team"/>})}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Visacheckout;