import React from 'react';
import Select from 'react-select';

import 'react-select/dist/react-select.css';

import ExampleSelector from '../../components/ExampleSelector';
import DocumentationTable from '../../helpers/DocumentationTable';

import SharedComplex from '../../documentation/documentation/SharedStruct';
import axios from "axios/index";

class Complex extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedComplex: null,
            options: [],
            examples: [],
            documentationHelper: new DocumentationTable()
        };

        this.handleChange = this.handleChange.bind(this);
        this.getFileName = this.getFileName.bind(this);
    }

    componentDidMount(){
        let selectOptions = [];
        Object.keys(SharedComplex).sort().map(type =>
            selectOptions.push({value: type, label: type})
        );
        this.setState({options: selectOptions});
    }

    attachClick(){
      let self = this;
      let stateCheck = setInterval(() => {
          if (document.readyState === 'complete' && self.tableDocumentation) {
            clearInterval(stateCheck);
              let tableDocumentation = document.querySelector('.table-documentation');
              tableDocumentation.addEventListener('click', (event) => {
                  if(event.target.parentNode.classList.contains('have-sub-row')){
                      self.state.documentationHelper.showChildren(self.tableDocumentation,event)
                  }
              });
          }
      }, 1000);
    }

    handleChange(event){
        let examples = {};
        let promises = [];

        if(event){
            this.setState({selectedComplex: event});
            let self = this;

            let languages = ['dotnet','go','java','lua','node','php','python','ruby'];

            languages.forEach((language) => {
                promises.push(axios({
                    method: 'get',
                    url: `/api/complexTypes/${language}/${self.getFileName(event)}Type.md`,
                }));
            });
            axios.all(promises)
                .then((result) =>{
                    result.forEach((response) =>{
                        if(response.data !== ''){
                            languages.forEach((language) => {
                                if(response.config.url.indexOf(`/api/complexTypes/${language}/${self.getFileName(event)}Type.md`) === 0){
                                    if(!examples[language]){
                                        examples[language] = {};
                                    }
                                    examples[language] = response.data;
                                    self.setState({
                                        examples: examples
                                    });
                                }
                            });
                        }
                    });
                });
        }
        this.attachClick();
    }

    getFileName(file){
        let tmp = file.value;
        let index = tmp.indexOf('Struct');
        return tmp.substring(0,index);
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h4>Complex Types</h4>
                        <div className="form-group">
                            <Select
                                value={this.state.selectedComplex}
                                onChange={this.handleChange}
                                options={this.state.options}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        {this.state.selectedComplex !== null &&
                            <table className="table table-documentation table-responsive" ref={(node) => this.tableDocumentation = node}>
                                <thead>
                                <tr>
                                    <th scope="col" className="table-field">Field</th>
                                    <th scope="col" className="table-required"/>
                                    <th scope="col" className="table-type">Type</th>
                                    <th scope="col" className="table-description">Description</th>
                                </tr>
                                </thead>
                                <tbody className="table-body" dangerouslySetInnerHTML={this.state.documentationHelper.iterateThroughItems(SharedComplex[this.state.selectedComplex.value])}>

                                </tbody>
                            </table>
                        }
                        {(Object.getOwnPropertyNames(this.state.examples).length > 0) && this.state.selectedComplex !== null &&
                            <div className="row">
                                <div className="col-12">
                                    <h4>Example</h4>
                                    <ExampleSelector examples={this.state.examples} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default Complex;