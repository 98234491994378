import React from 'react';
import {merchantNameFooter} from '../env';

const Footer = () => {

    const year = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="copyright pull-right">
                    &copy; {year}, {merchantNameFooter}
                </div>
            </div>
        </footer>

    );
};

export default Footer;