import React from 'react';
import { Link } from 'react-router-dom';

import DocBuilder from '../../../../components/DocBuilder';
import guideReferenceIcon from '../../../../assets/img/guide_reference.svg';
import request from '../../../../documentation/documentation/Authorize_2p-request';
import response from '../../../../documentation/documentation/Authorize_2p-response';
import {guideReferenceURL} from '../../../../env';
import Breadcrumbs from "../../../../components/Breadcrumbs";

class Authorization extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {this.state.showDocumentation &&
                            <div>
                                <Breadcrumbs/>
                                <br/>
                                <br/>
                                <p>
                                    Authorization also know as Payment without auto capture: Authorize_2p <br/>
                                    <br/>
                                    When a delayed settlement is preferred, 2 transactions are needed:
                                </p>
                                <ol>
                                    <li>Authorization</li>
                                    <li>Capture</li>
                                </ol>
                                <p>
                                    Authorizations have an expiration date, that depends on the acquirer.
                                    Once the authorization has expired, the capture is no longer valid, though
                                    some acquirers may approved it online, the settlement isn’t done
                                    The request and response structures used in this method are the same used in PayOnline_2p
                                </p>
                                <div className="alert alert-info">
                                    <div className="row">
                                        <div className="col-12">
                                            <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                            <span>
                                            For more information please go to&nbsp;
                                                <Link to={`${guideReferenceURL}/reference/directPayment`}>
                                              Guide Reference: Direct Payment
                                            </Link>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='Authorize_2p' showDocumentation={this.state.showDocumentation} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Authorization;