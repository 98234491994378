import React from 'react';
import {default as env, merchantNameFooter} from '../env';

import worldlineLogo from '../assets/img/worldline-coconut-horizontal.png';

const FooterLanding = () => {

  const year = new Date().getFullYear();

  return (<>
    <footer className="footer-landing">
      <div className="row">
        <div className="footer-content">
          <div className="column">
            <div className="mainLink">
              <a target="_blank" rel="noopener noreferrer" href="https://worldline.com/" className="mainLink">
                <img
                    // src="//cdn.ingenico.com/binaries/content/gallery/corporate/logos/footer-new-logo-ingenico.png"
                    src={worldlineLogo}  alt="Worldline" />
              </a>
            </div>
          </div>
          <div className="column">
            <div className="fmenu">
              <p>Worldline</p>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/about-us.html">About us</a></li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/careers.html">Careers</a></li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/pressroom.html">Media contacts</a> </li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/contact/office-locations.html">Our offices</a> </li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/contact.html">Contact us</a> </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="fmenu sites">
              <p>Our solutions</p>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/solutions/pos-and-terminals.html">POS solutions</a> </li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/solutions/omnichannel-commerce.html">Omnichannel</a> </li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/solutions/online-payments/wl-online-payments.html">SMB Payments</a> </li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://worldline.com/en/home/solutions/online-payments/large-global-businesses.html">Global eCommerce</a> </li>
              </ul>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://rethink-transactions.worldline.com/en/home/solutions/mobile-services.html">Mobile Solutions</a> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="sub-footer">
          <div className="column-social">
            <div className="social-media">
              <ul>
                <li className="follow">Follow us :</li>
                <li><a target="_blank" rel="noopener noreferrer" className="linkedin" href="https://worldline.com/linkedin"></a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="twitter" href="https://worldline.com/twitter"></a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="youtube" href="https://worldline.com/youtube"></a></li>
              </ul>
            </div>
          </div>
          <div className="column-copy">
            <div className="copyright">
              <ul>
                <li> &copy; {year}, {merchantNameFooter}. All rights reserved.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>

  );
};

export default FooterLanding;