import React from 'react';
import T from 'i18react';

// import lockIcon from '../../../assets/img/lock-content.svg';

class Aggregators extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.openLogin = this.openLogin.bind(this);
    }

    openLogin(){
        document.getElementById('loginClickOpen').click();
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.aggregators.title">
                            { T.translate("guideReference.services.aggregators.title") }
                        </h3>
                        <div id="guideReference.services.aggregators.text">
                            { T.translate("guideReference.services.aggregators.text") }
                        </div>
                        <ul>
                            <li>{ T.translate("guideReference.services.aggregators.list.1") }</li>
                            <li>{ T.translate("guideReference.services.aggregators.list.2") }</li>
                        </ul>
                    </div>
                    {/*<div className="col-12">*/}
                        {/*<h3 id="guideReference.services.aggregators.availability">*/}
                            {/*{ T.translate("guideReference.services.aggregators.availability") }*/}
                        {/*</h3>*/}
                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            </div>

        );
    }
}

export default Aggregators;