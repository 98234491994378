import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

class Python extends React.Component {

    render() {
        return (
            <div className="col-12">
                <div className="python-library"/>
                <div className="row">
                    <div className="col-12 col-md-2 text-center download">
                        <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-python" className="get-started-in"
                           target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-github"/>
                            Download
                        </a>
                        <br/>
                        <br/>
                        <span className="badge badge-success">
                            2.6 or above
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr/>
                        <h4 id="libraries.server.python.install">
                            {T.translate("libraries.server.python.install")}
                        </h4>
                        <CodeHighlight languague="Bash">
                            {
                                'pip install nps_sdk'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.python.configuration.title">
                            {T.translate("libraries.server.python.configuration.title")}
                        </h4>
                        <Accordion id="libraries.server.python.configuration.basic" open
                            title={T.translate("libraries.server.python.configuration.basic")}>
                            <CodeHighlight languague="Python">
                                {
                                    'import nps_sdk\n' +
                                    'from nps_sdk.constants import SANDBOX_ENV\n' +
                                    '\n' +
                                    'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\')'
                                }
                            </CodeHighlight>
                        </Accordion>
                        <Accordion id="libraries.server.python.configuration.advanced.title" open
                            title={T.translate("libraries.server.python.configuration.advanced.title")}>
                            <div className="row">
                                <div className="col-12">
                                    <h4 id="libraries.server.python.configuration.advanced.log.title">
                                        {T.translate("libraries.server.python.configuration.advanced.log.title")}
                                    </h4>
                                    <p id="libraries.server.python.configuration.advanced.log.text">
                                        {T.translate("libraries.server.python.configuration.advanced.log.text")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk\n' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\', debug=True)'
                                        }
                                    </CodeHighlight>
                                    <p id="libraries.server.python.configuration.advanced.log.file">
                                        {T.translate("libraries.server.python.configuration.advanced.log.file")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk\n' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\', debug=True, log_file="path/to/your/file.log")'
                                        }
                                    </CodeHighlight>
                                    <p id="libraries.server.python.configuration.advanced.log.level">
                                        {T.translate("libraries.server.python.configuration.advanced.log.level")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk\n' +
                                            'import logging\n' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\', debug=True, log_level=logging.DEBUG)'
                                        }
                                    </CodeHighlight>
                                    <hr/>
                                </div>
                                <div className="col-12">
                                    <h4 id="libraries.server.python.configuration.advanced.sanitize.title">
                                        {T.translate("libraries.server.python.configuration.advanced.sanitize.title")}
                                    </h4>
                                    <p id="libraries.server.python.configuration.advanced.sanitize.title">
                                        {T.translate("libraries.server.python.configuration.advanced.sanitize.text")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\', sanitize=True)'
                                        }
                                    </CodeHighlight>
                                    <hr/>
                                </div>
                                <div className="col-12">
                                    <h4 id="libraries.server.python.configuration.advanced.timeout.title">
                                        {T.translate("libraries.server.python.configuration.advanced.timeout.title")}
                                    </h4>
                                    <p id="libraries.server.python.configuration.advanced.timeout.text">
                                        {T.translate("libraries.server.python.configuration.advanced.timeout.text")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk\n' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\', timeout=60)'
                                        }
                                    </CodeHighlight>
                                    <hr/>
                                </div>
                                <div className="col-12">
                                    <h4 id="libraries.server.python.configuration.advanced.proxy.title">
                                        {T.translate("libraries.server.python.configuration.advanced.proxy.title")}
                                    </h4>
                                    <p id="libraries.server.python.configuration.advanced.proxy.text">
                                        {T.translate("libraries.server.python.configuration.advanced.proxy.text")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk\n' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\', \n' +
                                            '                                proxy_url = \'http://__YOUR_PROXY_URL\', proxy_port = \'3128\', proxy_pass = \'_YOUR_PASSWORD\', proxy_user = \'_YOUR_USER_\')'
                                        }
                                    </CodeHighlight>
                                    <hr/>
                                </div>
                                <div className="col-12">
                                    <h4 id="libraries.server.python.configuration.advanced.cache.title">
                                        {T.translate("libraries.server.python.configuration.advanced.cache.title")}
                                    </h4>
                                    <p id="libraries.server.python.configuration.advanced.cache.text">
                                        {T.translate("libraries.server.python.configuration.advanced.cache.text")}
                                    </p>
                                    <CodeHighlight languague="Python">
                                        {
                                            'import nps_sdk\n' +
                                            'from nps_sdk.constants import SANDBOX_ENV\n' +
                                            '\n' +
                                            'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key="_YOUR_SECRET_KEY_", cache=True, cache_location=\'/tmp\', cache_ttl=86400) #seconds'
                                        }
                                    </CodeHighlight>
                                    <hr/>
                                </div>
                            </div>
                        </Accordion>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.python.environments.title">
                            {T.translate("libraries.server.python.environments.title")}
                        </h4>
                        <p id="libraries.server.python.environments.text">
                            {T.translate("libraries.server.python.environments.text")}
                        </p>
                        <CodeHighlight languague="Python">
                            {
                                'import nps_sdk\n' +
                                'from nps_sdk.constants import PRODUCTION_ENV, STAGING_ENV, SANDBOX_ENV'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.python.error.title">
                            {T.translate("libraries.server.python.error.title")}
                        </h4>
                        <p id="libraries.server.python.error.text">
                            {T.translate("libraries.server.python.error.text")}
                        </p>
                        <div  id="libraries.server.python.error.information"
                            className="alert alert-info"
                              role="alert">
                            {T.translate("libraries.server.python.error.information")}
                        </div>
                        <CodeHighlight languague="Python">
                            {
                                'from nps_sdk.errors import ApiException\n' +
                                '\n' +
                                '#Code\n' +
                                'try:\n' +
                                '    #code or sdk call\n' +
                                'except ApiException as e:\n' +
                                '    #Code to handle error'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.python.example.title">
                            {T.translate("libraries.server.python.example.title")}
                        </h4>
                        <p id="libraries.server.python.example.text">
                            {T.translate("libraries.server.python.example.text")}
                        </p>
                        <CodeHighlight languague="Python">
                            {
                                'import nps_sdk\n' +
                                'from nps_sdk.errors import ApiException\n' +
                                'from nps_sdk.constants import SANDBOX_ENV\n' +
                                '\n' +
                                'nps_sdk.Configuration.configure(environment=SANDBOX_ENV, secret_key=\'_YOUR_SECRET_KEY_\')\n' +
                                'sdk = nps_sdk.Nps()\n' +
                                '\n' +
                                'params = {\n' +
                                '    "psp_Version": \'2.2\',\n' +
                                '    "psp_MerchantId": \'psp_test\',\n' +
                                '    "psp_TxSource": \'WEB\',\n' +
                                '    "psp_MerchTxRef": \'ORDER69461-3\',\n' +
                                '    "psp_MerchOrderId": \'ORDER69461\',\n' +
                                '    "psp_Amount": \'15050\',\n' +
                                '    "psp_NumPayments": \'1\',\n' +
                                '    "psp_Currency": \'032\',\n' +
                                '    "psp_Country": \'ARG\',\n' +
                                '    "psp_Product": \'14\',\n' +
                                '    "psp_CardNumber": \'4507990000000010\',\n' +
                                '    "psp_CardExpDate": \'1612\',\n' +
                                '    "psp_PosDateTime": \'2016-12-01 12:00:00\',\n' +
                                '    "psp_CardSecurityCode": \'123\'\n' +
                                '}\n' +
                                'try:\n' +
                                '    resp = sdk.pay_online_2p(params)\n' +
                                'except ApiException as e:\n' +
                                '    #Code to handle error'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </div>
        );
    }
}

export default Python;