import React from 'react';
import SimpleQueryTx from './RetrieveTransactions/SimpleQueryTx';
import {apiReferenceURL} from "../../../env";

class RetrieveTransactions extends React.Component {

    componentDidMount(){
        this.props.history.push(`${apiReferenceURL}/payments/RetrieveTransactions/SimpleQueryTx`);
    }

    componentDidUpdate(){
        this.props.history.push(`${apiReferenceURL}/payments/RetrieveTransactions/SimpleQueryTx`);
    }

    render() {
        return (
            <SimpleQueryTx/>
        );
    }
}

export default RetrieveTransactions;