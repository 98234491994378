import React from 'react';
import ReactDOM from 'react-dom';
import {Tabs, Tab} from 'react-bootstrap-tabs';

import ExampleBuilder from './ExampleBuilder';
import DocumentationTable from '../helpers/DocumentationTable';
import Emmiter from '../helpers/EventEmmiter';

class DocBuilder extends React.Component {

    constructor(props, context) {
        super(props, context);
        let self = this;

        this.state = {
            indexTab: 0,
            showDocumentation: true,
            updateExample: false,
            documentationHelper: new DocumentationTable(),
            examples: false
        };
        this.emitter = new Emmiter();
        this.emitter.add('updateExample', (update) => {
            self.setState({
                updateExample: update
            })
        });
        this.attachClick = this.attachClick.bind(this);
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }

        this.attachClick();
        this.setState({
            examples: this.props.examples
        });

        let session = sessionStorage.getItem('apiData');
        if(session){
            session = session.split('.');
            if(session[1] === 'api'){
                if(session[3] === 'response'){
                    this.setState({
                        indexTab: 1
                    });
                }else{
                    this.setState({
                        indexTab: 0
                    });
                }
            }
            sessionStorage.removeItem('apiData');
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.showDocumentation === false){
            this.setState({
                showDocumentation: nextProps.showDocumentation
            })
        }
    }

    componentDidUpdate(){
        let session = sessionStorage.getItem('apiData');
        if(session){
            session = session.split('.');
            if(session[1] === 'api'){
                if(session[3] === 'response'){
                    this.setState({
                        indexTab: 1
                    });
                }else{
                    this.setState({
                        indexTab: 0
                    });
                }
            }
            sessionStorage.removeItem('apiData');
        }
    }

    componentWillUnmount(){
        let self = this;
        if(self.tableDocumentation)
        {
            let parents = ReactDOM.findDOMNode(self.tableDocumentation).getElementsByClassName('have-sub-row');
            Array.from(parents).map(function(parent,index){
                    parents[index].removeEventListener('click',self.state.documentationHelper.showChildren.bind(null,self.tableDocumentation));
                    return false;
                }
            );
        }
    }

    attachClick(){
        let self = this;
        let stateCheck = setInterval(() => {
            if (document.readyState === 'complete' && self.tableDocumentation) {
                if(document.getElementById("request-table-documentation")){
                    document.getElementById("request-table-documentation").click();
                }
                if(document.getElementById("response-table-documentation")){
                    document.getElementById("response-table-documentation").click();
                }
                clearInterval(stateCheck);

                let tableDocumentation = document.querySelector('.table-documentation');
                tableDocumentation.addEventListener('click', (event) => {
                    if(event.target.parentNode.classList.contains('have-sub-row')){
                        self.state.documentationHelper.showChildren(self.tableDocumentation,event)
                    }

                });
                /* OLD METHOD
                    // let parents = ReactDOM.findDOMNode(self.tableDocumentation).getElementsByClassName('have-sub-row');
                    // Array.from(parents).forEach(function(parent,index){
                    //         parents[index].addEventListener('click',self.state.documentationHelper.showChildren.bind(null,self.tableDocumentation));
                    //         // return false;
                    //     }
                    // );
                */
            }
        }, 1000);
    }

    scrollToElement(){
        setTimeout(() => {
            let element = document.getElementById('exampleTitle');
            let uniqueMainPanelId = document.getElementById('uniqueMainPanelId');
            uniqueMainPanelId.scrollTop = element.offsetTop;
        },500);
    }

    render() {
        return (
                <div>
                    <hr/>
                    {
                        this.props.showEndpoint !== undefined &&
                        <h4 className="text-center no-underline">
                            Endpoint: <code>{this.props.endpoint}</code>
                        </h4>
                    }
                    <hr/>
                    <Tabs className="docBuilder" selected={this.state.indexTab} onSelect={()=> this.attachClick()}>
                        <Tab label={[<i className="fa fa-cloud-upload" key={1}/>, <br key={2}/>,  "Request"]}>
                            {this.state.showDocumentation &&
                                <table id="request-table-documentation" className="table table-documentation table-responsive"
                                       ref={(node) => this.tableDocumentation = node}>
                                    <thead>
                                    <tr>
                                        <th scope="col" className="table-field">Field</th>
                                        <th scope="col" className="table-required"/>
                                        <th scope="col" className="table-type">Type</th>
                                        <th scope="col" className="table-description">Description</th>
                                    </tr>
                                    </thead>
                                    <tbody className="table-body"
                                           dangerouslySetInnerHTML={this.state.documentationHelper.iterateThroughItems(this.props.data.request, 'api.' + this.props.endpoint + '.request')}>

                                    </tbody>
                                </table>
                            }

                            <div className="row">
                                <div className="col-6" id="exampleTitle">
                                    <h4>Example</h4>
                                </div>
                                {this.state.showDocumentation &&
                                    <div className="col-6">
                                        <button className="btn btn-success  float-right"
                                                onClick={() => {this.setState({indexTab: 1}); this.scrollToElement(); }}>
                                            Go to response
                                        </button>
                                    </div>
                                }
                                <div className="col-12" id="exampleBuilder">
                                    <ExampleBuilder
                                        endpoint={this.props.endpoint}
                                        type="Request"
                                        clientExamples={this.props.examples}
                                        onlyClientExamples={this.props.onlyClientExamples}
                                        onlyServerExamples={this.props.onlyServerExamples}
                                        onlyFiles={this.props.onlyFiles}
                                    />
                                </div>

                            </div>
                        </Tab>
                        <Tab label={[<i className="fa fa-cloud-download" key={1}/>, <br key={2}/>,  "Response"]}>
                            <hr/>
                            {this.state.showDocumentation &&
                                <table id="response-table-documentation" className="table table-documentation  table-responsive"
                                       ref={(node) => this.tableDocumentation = node}>
                                    <thead>
                                    <tr>
                                        <th scope="col" className="table-field">Field</th>
                                        <th scope="col" className="table-required"/>
                                        <th scope="col" className="table-type">Type</th>
                                        <th scope="col" className="table-description">Description</th>
                                    </tr>
                                    </thead>
                                    <tbody className="table-body"
                                           dangerouslySetInnerHTML={this.state.documentationHelper.iterateThroughItems(this.props.data.response, 'api.' + this.props.endpoint + '.response')}>

                                    </tbody>
                                </table>
                            }
                            <div className="row">
                                <div className="col-6" id="exampleTitle">
                                    <h4>Example</h4>
                                </div>
                                {this.state.showDocumentation &&
                                    <div className="col-6">
                                        <button className="btn btn-success float-right"
                                                onClick={() => { this.setState({indexTab: 0}); this.scrollToElement(); }}>
                                            Go to request
                                        </button>
                                    </div>
                                }
                                <div className="col-12"  id="exampleBuilder">
                                    <ExampleBuilder
                                        endpoint={this.props.endpoint}
                                        type="Response"
                                        clientExamples={this.props.examples}
                                        onlyClientExamples={this.props.onlyClientExamples}
                                        onlyServerExamples={this.props.onlyServerExamples}
                                        onlyFiles={this.props.onlyFiles}
                                    />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
        );
    }
}

export default DocBuilder;