import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/CreatePaymentMethodFromPayment-request';
import response from '../../../documentation/documentation/CreatePaymentMethodFromPayment-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class CreateFromPayment extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            This method allows you to convert the payment details into a re-usable Payment Method.
                            This call is especially useful if you don’t have access to sensitive data like card data,
                            but you have processed transaction successfully based on card data that has been collected
                            either using the Simple Checkout integration method.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='CreatePaymentMethodFromPayment' />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateFromPayment;