import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import T from 'i18react';
import en from './i18n/en.json';
import es from './i18n/es.json';
import pt from './i18n/pt.json';

import {guideReferenceURL, apiReferenceURL} from "./env";
import Landing from './pages/Landing';

/* Guide Reference */

import GuideReferenceGettingStarted from './pages/GuideReference/GettingStarted';
import GuideReferenceGuideReference from './pages/GuideReference/GuideReference';
import GuideReferenceAdvancedCheckout from './pages/GuideReference/AdvancedCheckout';
import GuideReferenceServer from './pages/GuideReference/Libraries/Server';
import GuideReferenceClient from './pages/GuideReference/Libraries/Client';
import GuideReferencePlugins from './pages/GuideReference/Libraries/Plugins';
import GuideReferenceSimpleCheckout from './pages/GuideReference/SimpleCheckout';
import GuideReferenceSimpleCheckoutSplit from './pages/GuideReference/SimpleCheckout/Split';
import GuideReferenceSimpleCheckoutVoucher from './pages/GuideReference/SimpleCheckout/Voucher';
import GuideReferenceSimpleCheckoutBank from './pages/GuideReference/SimpleCheckout/Bank';
import GuideReferenceSimpleCheckoutMerchantPackage from './pages/GuideReference/SimpleCheckout/MerchantPackage';
import GuideReferenceDirectPayment from './pages/GuideReference/DirectPayment';
import GuideReferenceCommonInterface from './pages/GuideReference/CommonInterface';
import GuideReferenceServices from './pages/GuideReference/Services';
import GuideReferenceRiskAssessment from './pages/GuideReference/Services/RiskAssessment';
import GuideReference3DSecure from './pages/GuideReference/Services/Secure';
import GuideReferenceVerification from './pages/GuideReference/Services/Verification';
import GuideReferenceAggregators from './pages/GuideReference/Services/Aggregators';
import GuideReferenceTaxes from './pages/GuideReference/Services/Taxes';
import GuideReferenceInstallments from './pages/GuideReference/Services/Installments';
import GuideReferenceRecurring from './pages/GuideReference/Services/Recurring';
import GuideReferencePromotions from './pages/GuideReference/Services/Promotions';
import GuideReferenceWallets from './pages/GuideReference/Services/Wallets';
import GuideReferenceWalletsMaster from './pages/GuideReference/Services/Wallets/Masterpass';
import GuideReferenceWalletsMasterPCI from './pages/GuideReference/Services/Wallets/Masterpass/pci';
import GuideReferenceWalletsMasterNonPCI from './pages/GuideReference/Services/Wallets/Masterpass/nonPci';
import GuideReferenceWalletsMasterFull from './pages/GuideReference/Services/Wallets/Masterpass/fullIntegration';
import GuideReferenceWalletsVisa from './pages/GuideReference/Services/Wallets/Visacheckout';
import GuideReferenceTokenization from './pages/GuideReference/Services/Tokenization';
import GuideReferenceTesting from './pages/GuideReference/Testing';
import GuideReferenceThirdParty from './pages/GuideReference/ThirdParty';
import GuideReferencePaymentMethods from './pages/GuideReference/PaymentMethods2';

/* Api Reference */
import ApiReferenceLanding from './pages/ApiReference/Landing';

import ApiReferenceSimpleCheckout from './pages/ApiReference/SimpleCheckout';
import ApiReferenceSimpleCheckoutCardPayment from './pages/ApiReference/SimpleCheckout/CardPayment';
import ApiReferenceSimpleCheckoutCardPaymentSale from './pages/ApiReference/SimpleCheckout/CardPayment/Sale';
import ApiReferenceSimpleCheckoutCardPaymentAuthorization from './pages/ApiReference/SimpleCheckout/CardPayment/Authorization';

import ApiReferenceSimpleCheckoutCardSplitPayment from './pages/ApiReference/SimpleCheckout/CardSplitPayment';
import ApiReferenceSimpleCheckoutCardSplitPaymentSale from './pages/ApiReference/SimpleCheckout/CardSplitPayment/Sale';
import ApiReferenceSimpleCheckoutCardSplitPaymentAuthorization from './pages/ApiReference/SimpleCheckout/CardSplitPayment/Authorization';

import ApiReferenceSimpleCheckoutCashPayment from './pages/ApiReference/SimpleCheckout/CashPayment';
import ApiReferenceSimpleCheckoutBankPayment from './pages/ApiReference/SimpleCheckout/BankPayment';

import ApiReferencePayments from './pages/ApiReference/Payments';
import ApiReferencePaymentsCardPayments from './pages/ApiReference/Payments/CardPayment';
import ApiReferencePaymentsCardPaymentsSale from './pages/ApiReference/Payments/CardPayment/Sale';
import ApiReferencePaymentsCardPaymentsAuthorization from './pages/ApiReference/Payments/CardPayment/Authorization';
import ApiReferencePaymentsCapture from './pages/ApiReference/Payments/Capture';
import ApiReferencePaymentsRefund from './pages/ApiReference/Payments/Refund';
import ApiReferencePaymentsRetrieveTransactions from './pages/ApiReference/Payments/RetrieveTransactions';
import ApiReferencePaymentsRetrieveTransactionsSimpleQueryTx from './pages/ApiReference/Payments/RetrieveTransactions/SimpleQueryTx';
import ApiReferencePaymentsRetrieveTransactionsQueryTxs from './pages/ApiReference/Payments/RetrieveTransactions/QueryTxs';
import ApiReferencePaymentsRetrieveInstallments from './pages/ApiReference/Payments/RetrieveInstallments';

import ApiReferenceServices from './pages/ApiReference/Services';
import ApiReferenceServicesCardNumber from './pages/ApiReference/Services/CardNumber';
import ApiReferenceServicesIinLookup from './pages/ApiReference/Services/IinLookup';
import ApiReferenceServicesClientSession from './pages/ApiReference/Services/ClientSession';
import ApiReferenceServicesQueryCardDetails from './pages/ApiReference/Services/QueryCardDetails';
import ApiReferenceServicesChangeSecretKey from './pages/ApiReference/Services/ChangeSecretKey';

import ApiReferencePaymentMethodToken from './pages/ApiReference/PaymentMethodToken';
import ApiReferencePaymentMethodTokenCreate from './pages/ApiReference/PaymentMethodToken/Create';
import ApiReferencePaymentMethodTokenRecache from './pages/ApiReference/PaymentMethodToken/Recache';
import ApiReferencePaymentMethodTokenRetrieve from './pages/ApiReference/PaymentMethodToken/Retrieve';

import ApiReferencePaymentMethod from './pages/ApiReference/PaymentMethod';
import ApiReferencePaymentMethodCreate from './pages/ApiReference/PaymentMethod/Create';
import ApiReferencePaymentMethodCreateFomPayment from './pages/ApiReference/PaymentMethod/CreateFromPayment';
import ApiReferencePaymentMethodUpdate from './pages/ApiReference/PaymentMethod/Update';
import ApiReferencePaymentMethodRetrieve from './pages/ApiReference/PaymentMethod/Retrieve';
import ApiReferencePaymentMethodDelete from './pages/ApiReference/PaymentMethod/Delete';

import ApiReferenceCustomer from './pages/ApiReference/Customer';
import ApiReferenceCustomerCreate from './pages/ApiReference/Customer/Create';
import ApiReferenceCustomerRetrieve from './pages/ApiReference/Customer/Retrieve';
import ApiReferenceCustomerUpdate from './pages/ApiReference/Customer/Update';
import ApiReferenceCustomerDelete from './pages/ApiReference/Customer/Delete';

import ApiReferenceComplex from './pages/ApiReference/Complex';
import ApiReferenceReference from './pages/ApiReference/Reference';

import ApiReferenceFraud from './pages/ApiReference/Fraud';
import ApiReferenceFraudScreening from './pages/ApiReference/Fraud/Screening';
import ApiReferenceFraudManual from './pages/ApiReference/Fraud/Manual';

import ApiReferenceOverview from './pages/ApiReference/Overview';


/* Commons */
import Sidebar from './components/Sidebar'
import Navigation from './components/Navigation'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Error404 from './pages/Errors/Error404'
import Emmiter from './helpers/EventEmmiter';
import SearchPage from './components/SearchPage';
import YoutubeModal from "./components/YoutubeModal";

import './assets/css/main.css';

class bootstrap extends React.Component {

    constructor(props, context) {
        super(props, context);
        let self = this;
        this.state = {
            openSidebar: true,
            intervalId: 0,
            language: 'en',
            contactModal: false,
            openYoutubeModal: false,
            youtubeVideo: ''
        };

        this.emitter = new Emmiter();

        this.emitter.add('openSidebar', (opened) => {
            self.setState({
                openSidebar: opened
            })
        });

        this.changeTranslations = this.changeTranslations.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.openContactForm = this.openContactForm.bind(this);
        this.setVideoAndOpen = this.setVideoAndOpen.bind(this);
        this.addCommonComponents = this.addCommonComponents.bind(this);
        T.setTexts(en);
        this.darkMode = (localStorage.getItem('darkModeEnabled')) ? JSON.parse(localStorage.getItem('darkModeEnabled')) : false ;
        (this.darkMode === true) ? document.body.classList.add("dark-mode") : document.body.classList.remove("dark-mode");

    }

    changeTranslations(language){
        switch (language){
            case 'es':
                T.setTexts(es);
                break;
            case 'en':
                T.setTexts(en);
                break;
            case 'pt':
                T.setTexts(pt);
                break;
            default:
                T.setTexts(en);
                break;
        }
        this.setState({
            language: language
        })
    }

    openContactForm(){
        this.setState({
            contactModal: !this.state.contactModal
        });
    }

    setVideoAndOpen(video){
        let thisState = {};
        if(video){
            thisState = {
                openYoutubeModal: true,
                youtubeVideo: video
            };
        }else{
            thisState = {
                openYoutubeModal: false,
                youtubeVideo: ''
            };
        }
        this.setState({
            openYoutubeModal: thisState.openYoutubeModal,
            youtubeVideo: thisState.youtubeVideo
        })
    }

    scrollToTop() {
        this._div.scrollTop = 0;
    }

    scrollToElement(){
        let scrollTo = sessionStorage.getItem('scrollTo');
        if(scrollTo){
            scrollTo = scrollTo.substr(1);
            let element = document.getElementById(scrollTo);
            if(element){
                element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }
            sessionStorage.removeItem('scrollTo')
        }
    }

    addCommonComponents(routeComponent,props){
        return  <div className="wrapper">
                    <Sidebar event={this.emitter}
                             open={this.state.openSidebar}
                             changeLanguage={this.changeTranslations}
                             openContactForm={this.openContactForm}
                             scrollToTop={this.scrollToTop}
                    />
                    <div className="main-panel" ref={(ref) => this._div = ref} id="uniqueMainPanelId">
                        <Navigation event={this.emitter} language={this.state.language} sidebarState={this.state.openSidebar} darkMode={this.darkMode}/>
                        <div className="content">
                            {routeComponent === 'SearchPage' && <SearchPage scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceGettingStarted' && <GuideReferenceGettingStarted scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceGuideReference' && <GuideReferenceGuideReference scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceAdvancedCheckout' && <GuideReferenceAdvancedCheckout scrollToElement={this.scrollToElement} mainPanel={this._div} youtubeHandler={this.setVideoAndOpen} {...props}/>}
                            {routeComponent === 'GuideReferenceServer' && <GuideReferenceServer scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceClient' && <GuideReferenceClient scrollToElement={this.scrollToElement} mainPanel={this._div} youtubeHandler={this.setVideoAndOpen} {...props}/>}
                            {routeComponent === 'GuideReferencePlugins' && <GuideReferencePlugins scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceSimpleCheckout' && <GuideReferenceSimpleCheckout scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceSimpleCheckoutSplit' && <GuideReferenceSimpleCheckoutSplit scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceSimpleCheckoutVoucher' && <GuideReferenceSimpleCheckoutVoucher scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceSimpleCheckoutBank' && <GuideReferenceSimpleCheckoutBank scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceSimpleCheckoutMerchantPackage' && <GuideReferenceSimpleCheckoutMerchantPackage scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceDirectPayment' && <GuideReferenceDirectPayment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceCommonInterface' && <GuideReferenceCommonInterface scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceServices' && <GuideReferenceServices scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceRiskAssessment' && <GuideReferenceRiskAssessment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReference3DSecure' && <GuideReference3DSecure scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceVerification' && <GuideReferenceVerification scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceAggregators' && <GuideReferenceAggregators scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceTaxes' && <GuideReferenceTaxes scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceInstallments' && <GuideReferenceInstallments scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceRecurring' && <GuideReferenceRecurring scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferencePromotions' && <GuideReferencePromotions scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceWallets' && <GuideReferenceWallets scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceWalletsMaster' && <GuideReferenceWalletsMaster scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceWalletsMasterPCI' && <GuideReferenceWalletsMasterPCI scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceWalletsMasterNonPCI' && <GuideReferenceWalletsMasterNonPCI scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceWalletsMasterFull' && <GuideReferenceWalletsMasterFull scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceWalletsVisa' && <GuideReferenceWalletsVisa scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceTokenization' && <GuideReferenceTokenization scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceTesting' && <GuideReferenceTesting scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferenceThirdParty' && <GuideReferenceThirdParty scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'GuideReferencePaymentMethods' && <GuideReferencePaymentMethods scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}

                            {routeComponent === 'ApiReferenceLanding' && <ApiReferenceLanding scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckout' && <ApiReferenceSimpleCheckout scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCardPayment' && <ApiReferenceSimpleCheckoutCardPayment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCardPaymentSale' && <ApiReferenceSimpleCheckoutCardPaymentSale scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCardPaymentAuthorization' && <ApiReferenceSimpleCheckoutCardPaymentAuthorization scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCardSplitPayment' && <ApiReferenceSimpleCheckoutCardSplitPayment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCardSplitPaymentSale' && <ApiReferenceSimpleCheckoutCardSplitPaymentSale scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCardSplitPaymentAuthorization' && <ApiReferenceSimpleCheckoutCardSplitPaymentAuthorization scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutCashPayment' && <ApiReferenceSimpleCheckoutCashPayment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceSimpleCheckoutBankPayment' && <ApiReferenceSimpleCheckoutBankPayment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePayments' && <ApiReferencePayments scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsCardPayments' && <ApiReferencePaymentsCardPayments scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsCardPaymentsSale' && <ApiReferencePaymentsCardPaymentsSale scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsCardPaymentsAuthorization' && <ApiReferencePaymentsCardPaymentsAuthorization scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsCapture' && <ApiReferencePaymentsCapture scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsRefund' && <ApiReferencePaymentsRefund scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsRetrieveTransactions' && <ApiReferencePaymentsRetrieveTransactions scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsRetrieveTransactionsSimpleQueryTx' && <ApiReferencePaymentsRetrieveTransactionsSimpleQueryTx scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsRetrieveTransactionsQueryTxs' && <ApiReferencePaymentsRetrieveTransactionsQueryTxs scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentsRetrieveInstallments' && <ApiReferencePaymentsRetrieveInstallments scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceServices' && <ApiReferenceServices scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceServicesCardNumber' && <ApiReferenceServicesCardNumber scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceServicesIinLookup' && <ApiReferenceServicesIinLookup scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceServicesClientSession' && <ApiReferenceServicesClientSession scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceServicesQueryCardDetails' && <ApiReferenceServicesQueryCardDetails scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceServicesChangeSecretKey' && <ApiReferenceServicesChangeSecretKey scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodToken' && <ApiReferencePaymentMethodToken scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodTokenCreate' && <ApiReferencePaymentMethodTokenCreate scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodTokenRecache' && <ApiReferencePaymentMethodTokenRecache scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodTokenRetrieve' && <ApiReferencePaymentMethodTokenRetrieve scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethod' && <ApiReferencePaymentMethod scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodCreate' && <ApiReferencePaymentMethodCreate scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodCreateFomPayment' && <ApiReferencePaymentMethodCreateFomPayment scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodUpdate' && <ApiReferencePaymentMethodUpdate scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodRetrieve' && <ApiReferencePaymentMethodRetrieve scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferencePaymentMethodDelete' && <ApiReferencePaymentMethodDelete scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceCustomer' && <ApiReferenceCustomer scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceCustomerCreate' && <ApiReferenceCustomerCreate scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceCustomerUpdate' && <ApiReferenceCustomerUpdate scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceCustomerRetrieve' && <ApiReferenceCustomerRetrieve scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceCustomerDelete' && <ApiReferenceCustomerDelete scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceFraud' && <ApiReferenceFraud scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceFraudScreening' && <ApiReferenceFraudScreening scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceFraudManual' && <ApiReferenceFraudManual scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceComplex' && <ApiReferenceComplex scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceReference' && <ApiReferenceReference scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                            {routeComponent === 'ApiReferenceOverview' && <ApiReferenceOverview scrollToElement={this.scrollToElement} mainPanel={this._div} {...props}/>}
                        </div>
                        <Footer/>
                    </div>
                    <div className="scrollTop" onClick={() => this.scrollToTop()}>
                        <i className="fa fa-angle-up" aria-hidden="true"/>
                    </div>
                    {
                        this.state.openYoutubeModal &&
                        <YoutubeModal open={true}
                                      youtubeHandler={this.setVideoAndOpen}
                                      youtubeUrl="ha9XpgOzgOU"/>
                    }
                    <Contact
                        openContactForm={this.openContactForm}
                        openContact={this.state.contactModal}
                    />
                </div>
    }

    render(){
        return(
            <Router>
                <Switch>
                    <Route exact path={`/`}
                           component={Landing}/>
                    <Route exact path={`/search/:text?`}
                           render={props => this.addCommonComponents('SearchPage',props)}/>
                    <Route exact path={`${guideReferenceURL}/`}
                           render={props => this.addCommonComponents('GuideReferenceGettingStarted',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference`}
                           render={props => this.addCommonComponents('GuideReferenceGuideReference',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/advanced/:tab?`}
                           render={props => this.addCommonComponents('GuideReferenceAdvancedCheckout',props)}/>
                    <Route exact path={`${guideReferenceURL}/libraries`}
                           render={props => this.addCommonComponents('GuideReferenceServer',props)}/>
                    <Route exact path={`${guideReferenceURL}/libraries/server/:tab?`}
                           render={props => this.addCommonComponents('GuideReferenceServer',props)}/>
                    <Route exact path={`${guideReferenceURL}/libraries/client/:tab?`}
                           render={props => this.addCommonComponents('GuideReferenceClient',props)}/>
                    <Route exact path={`${guideReferenceURL}/libraries/plugins/:tab?`}
                           render={props => this.addCommonComponents('GuideReferencePlugins',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/simple`}
                           render={props => this.addCommonComponents('GuideReferenceSimpleCheckout',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/simple/card`}
                           render={props => this.addCommonComponents('GuideReferenceSimpleCheckout',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/simple/split`}
                           render={props => this.addCommonComponents('GuideReferenceSimpleCheckoutSplit',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/simple/voucher`}
                           render={props => this.addCommonComponents('GuideReferenceSimpleCheckoutVoucher',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/simple/bank`}
                           render={props => this.addCommonComponents('GuideReferenceSimpleCheckoutBank',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/simple/merchantPackage`}
                           render={props => this.addCommonComponents('GuideReferenceSimpleCheckoutMerchantPackage',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/directPayment`}
                           render={props => this.addCommonComponents('GuideReferenceDirectPayment',props)}/>
                    <Route exact path={`${guideReferenceURL}/reference/commonInterface`}
                           render={props => this.addCommonComponents('GuideReferenceCommonInterface',props)}/>
                    <Route exact path={`${guideReferenceURL}/services`}
                           render={props => this.addCommonComponents('GuideReferenceServices',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/risk`}
                           render={props => this.addCommonComponents('GuideReferenceRiskAssessment',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/3DSecure`}
                           render={props => this.addCommonComponents('GuideReference3DSecure',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/verificationServices`}
                           render={props => this.addCommonComponents('GuideReferenceVerification',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/aggregators`}
                           render={props => this.addCommonComponents('GuideReferenceAggregators',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/taxes`}
                           render={props => this.addCommonComponents('GuideReferenceTaxes',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/installments`}
                           render={props => this.addCommonComponents('GuideReferenceInstallments',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/recurring`}
                           render={props => this.addCommonComponents('GuideReferenceRecurring',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/promotions`}
                           render={props => this.addCommonComponents('GuideReferencePromotions',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/wallets`}
                           render={props => this.addCommonComponents('GuideReferenceWallets',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/wallets/masterpass`}
                           render={props => this.addCommonComponents('GuideReferenceWalletsMaster',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/wallets/masterpass/pci`}
                           render={props => this.addCommonComponents('GuideReferenceWalletsMasterPCI',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/wallets/masterpass/nonPci`}
                           render={props => this.addCommonComponents('GuideReferenceWalletsMasterNonPCI',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/wallets/masterpass/full`}
                           render={props => this.addCommonComponents('GuideReferenceWalletsMasterFull',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/wallets/visacheckout`}
                           render={props => this.addCommonComponents('GuideReferenceWalletsVisa',props)}/>
                    <Route exact path={`${guideReferenceURL}/services/tokenization`}
                           render={props => this.addCommonComponents('GuideReferenceTokenization',props)}/>
                    <Route exact path={`${guideReferenceURL}/testing`}
                           render={props => this.addCommonComponents('GuideReferenceTesting',props)}/>
                    <Route exact path={`${guideReferenceURL}/thirdParty`}
                           render={props => this.addCommonComponents('GuideReferenceThirdParty',props)}/>
                    <Route exact path={`${guideReferenceURL}/paymentMethods`}
                           render={props => this.addCommonComponents('GuideReferencePaymentMethods',props)}/>


                    <Route exact path={`${apiReferenceURL}/`}
                           render={props => this.addCommonComponents('ApiReferenceLanding',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckout',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CardPayment`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCardPayment',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CardPayment/Sale`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCardPaymentSale',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CardPayment/Authorization`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCardPaymentAuthorization',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CardSplitPayment`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCardSplitPayment',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Sale`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCardSplitPaymentSale',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Authorization`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCardSplitPaymentAuthorization',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/CashPayment`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutCashPayment',props)}/>
                    <Route exact path={`${apiReferenceURL}/simpleCheckout/BankPayment`}
                           render={props => this.addCommonComponents('ApiReferenceSimpleCheckoutBankPayment',props)}/>

                    <Route exact path={`${apiReferenceURL}/payments`}
                           render={props => this.addCommonComponents('ApiReferencePayments',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/CardPayment`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsCardPayments',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/CardPayment/Sale`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsCardPaymentsSale',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/CardPayment/Authorization`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsCardPaymentsAuthorization',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/Capture`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsCapture',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/Refund`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsRefund',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/RetrieveTransactions`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsRetrieveTransactions',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/RetrieveTransactions/SimpleQueryTx`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsRetrieveTransactionsSimpleQueryTx',props)}/>
                    <Route exact path={`${apiReferenceURL}/payments/RetrieveTransactions/QueryTxs`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsRetrieveTransactionsQueryTxs',props)}/>

                    <Route exact path={`${apiReferenceURL}/payments/RetrieveInstallments`}
                           render={props => this.addCommonComponents('ApiReferencePaymentsRetrieveInstallments',props)}/>

                    <Route exact path={`${apiReferenceURL}/services`}
                           render={props => this.addCommonComponents('ApiReferenceServices',props)}/>
                    <Route exact path={`${apiReferenceURL}/services/CardNumber`}
                           render={props => this.addCommonComponents('ApiReferenceServicesCardNumber',props)}/>
                    <Route exact path={`${apiReferenceURL}/services/IINLookup`}
                           render={props => this.addCommonComponents('ApiReferenceServicesIinLookup',props)}/>
                    <Route exact path={`${apiReferenceURL}/services/ClientSession`}
                           render={props => this.addCommonComponents('ApiReferenceServicesClientSession',props)}/>
                    <Route exact path={`${apiReferenceURL}/services/QueryCardDetails`}
                           render={props => this.addCommonComponents('ApiReferenceServicesQueryCardDetails',props)}/>
                    <Route exact path={`${apiReferenceURL}/services/ChangeSecretKey`}
                           render={props => this.addCommonComponents('ApiReferenceServicesChangeSecretKey',props)}/>

                    <Route exact path={`${apiReferenceURL}/paymentMethodToken`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodToken',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethodToken/Create`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodTokenCreate',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethodToken/Recache`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodTokenRecache',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethodToken/Retrieve`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodTokenRetrieve',props)}/>

                    <Route exact path={`${apiReferenceURL}/paymentMethod`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethod',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethod/Create`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodCreate',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethod/CreateFromPayment`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodCreateFomPayment',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethod/Update`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodUpdate',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethod/Retrieve`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodRetrieve',props)}/>
                    <Route exact path={`${apiReferenceURL}/paymentMethod/Delete`}
                           render={props => this.addCommonComponents('ApiReferencePaymentMethodDelete',props)}/>

                    <Route exact path={`${apiReferenceURL}/customer`}
                           render={props => this.addCommonComponents('ApiReferenceCustomer',props)}/>
                    <Route exact path={`${apiReferenceURL}/customer/Create`}
                           render={props => this.addCommonComponents('ApiReferenceCustomerCreate',props)}/>
                    <Route exact path={`${apiReferenceURL}/customer/Update`}
                           render={props => this.addCommonComponents('ApiReferenceCustomerUpdate',props)}/>
                    <Route exact path={`${apiReferenceURL}/customer/Retrieve`}
                           render={props => this.addCommonComponents('ApiReferenceCustomerRetrieve',props)}/>
                    <Route exact path={`${apiReferenceURL}/customer/Delete`}
                           render={props => this.addCommonComponents('ApiReferenceCustomerDelete',props)}/>

                    <Route exact path={`${apiReferenceURL}/fraud`}
                           render={props => this.addCommonComponents('ApiReferenceFraud',props)}/>
                    <Route exact path={`${apiReferenceURL}/fraud/Screening`}
                           render={props => this.addCommonComponents('ApiReferenceFraudScreening',props)}/>
                    <Route exact path={`${apiReferenceURL}/fraud/Manual`}
                           render={props => this.addCommonComponents('ApiReferenceFraudManual',props)}/>

                    <Route exact path={`${apiReferenceURL}/complex`}
                           render={props => this.addCommonComponents('ApiReferenceComplex',props)}/>
                    <Route exact path={`${apiReferenceURL}/reference`}
                           render={props => this.addCommonComponents('ApiReferenceReference',props)}/>
                    <Route exact path={`${apiReferenceURL}/overview`}
                           render={props => this.addCommonComponents('ApiReferenceOverview',props)}/>
                    <Route component={Error404} />
                </Switch>
            </Router>
        )
    }

}
export default bootstrap;