import React from 'react';
import T from "i18react/dist/i18react";

// import lockIcon from '../../../assets/img/lock-content.svg';

class Verification extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            intervalCheck: {}
        };
        this.checkSession = this.checkSession.bind(this);
        this.openLogin = this.openLogin.bind(this);
    }


    componentDidMount(){
        let self = this;
        let interval = setInterval(()=> self.checkSession(), 1);
        this.setState({
            intervalCheck: interval
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.intervalCheck);
        this.setState({
            intervalCheck: {}
        });
    }

    openLogin(){
        document.getElementById('loginClickOpen').click();
    }

    checkSession(){
        let session = sessionStorage.getItem('hashGuideVerification');
        if(session){
            let element = document.getElementById(session);
            if(element){
                element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }
            sessionStorage.removeItem('hashGuideVerification');
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.verification.securityCode.title">
                            { T.translate("guideReference.services.verification.securityCode.title") }
                        </h3>
                        { T.translate("guideReference.services.verification.securityCode.text") }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.verification.avs.title">
                            {T.translate("guideReference.services.verification.avs.title")}
                        </h3>
                        {T.translate("guideReference.services.verification.avs.text")}
                    </div>
                    <div className="col-12">
                        <h3 id="guideReference.services.verification.extended.title">
                            { T.translate("guideReference.services.verification.extended.title") }
                        </h3>
                        { T.translate("guideReference.services.verification.extended.text") }
                    </div>
                </div>
                {/*<div className="row">*/}
                    {/*<div className="col-12">*/}
                        {/*<br/><br/>*/}
                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default Verification;