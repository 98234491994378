import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/DeletePaymentMethod-request';
import response from '../../../documentation/documentation/DeletePaymentMethod-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Delete extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            Deletes a re-usable Payment Method stored in your vault. If you delete the Payment Method
                            will not be able to undo the operation and will not be able to use it anymore.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='DeletePaymentMethod' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Delete;