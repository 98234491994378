import React from 'react';
import DocBuilder from '../../../components/DocBuilder';
import Breadcrumbs from "../../../components/Breadcrumbs";

import request from '../../../documentation/documentation/DeleteCustomer-request';
import response from '../../../documentation/documentation/DeleteCustomer-response';

class Delete extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            Deletes a Customer stored in your vault
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='DeleteCustomer' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Delete;