import React from 'react';
import T from 'i18react';
import { Link } from 'react-router-dom';
import {guideReferenceURL} from "../../env";
import * as env from "../../env";


class Services extends React.Component {

    render() {
        return (
            <div className="container-fluid services">
                <div className="row">
                    <div className="col-12">
                        <h3>{ T.translate("guideReference.services.title") }</h3>
                        { T.translate("guideReference.services.text") }
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/risk`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Risk Assessment
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/3DSecure`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                3D Secure
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/verificationServices`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Verification Services
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/recurring`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Recurring Payments
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${env.guideReferenceURL}/services/installments`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Installment Plans
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/taxes`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Taxes
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/promotions`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Promotions
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/wallets`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Wallets
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/tokenization`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Tokenization
                            </button>
                        </Link>
                    </div>
                    <div className="col col-lg-4">
                        <Link to={`${guideReferenceURL}/services/aggregators`}>
                            <button type="button" className="btn btn-light btn-block cursor-pointer">
                                Aggregators
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;