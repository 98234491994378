import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/ChangeSecretKey-request';
import response from '../../../documentation/documentation/ChangeSecretKey-response';
import Breadcrumbs from "../../../components/Breadcrumbs";
import T from "i18react/dist/i18react";

class ChangeSecretKey extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <div className="row">
                            <div className="col-12">
                                {T.translate("apiReference.changeSecretKey.text")}
                            </div>
                        </div>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='ChangeSecretKey' />
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeSecretKey;