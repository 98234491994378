import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import {apiReferenceURL} from "../../../../env";

const Configure = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.javascript.configure.title">
                Step 2 - {T.translate("advancedCheckout.javascript.configure.title")}
            </h4>
            <Accordion id="advancedCheckout.javascript.configure.clientSession.title" open
                title={ "2.1 - " + T.translate("advancedCheckout.javascript.configure.clientSession.title")}>
                <p id="advancedCheckout.javascript.configure.clientSession.text">
                    {T.translate("advancedCheckout.javascript.configure.clientSession.text")}
                </p>
                <div id="advancedCheckout.javascript.configure.clientSession.create"
                    className="alert alert-danger"
                     role="alert">
                    {T.translate("advancedCheckout.javascript.configure.clientSession.create",{"apiLink": <T.a href={ `${apiReferenceURL}/services/ClientSession` } target="_blank" text="CreateClientSession"/>})}
                </div>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.setClientSession("YOUR_CLIENT_SESSION")\n'+
                    '</script>'
                    }
                </CodeHighlight>
                <p id="advancedCheckout.javascript.configure.clientSession.get">
                    {T.translate("advancedCheckout.javascript.configure.clientSession.get")}
                </p>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.getClientSession()\n'+
                    '</script>'
                    }
                </CodeHighlight>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.configure.merchant.title" open
                title={ "2.2 - " + T.translate("advancedCheckout.javascript.configure.merchant.title")}>
                <p id="advancedCheckout.javascript.configure.merchant.text">
                    {T.translate("advancedCheckout.javascript.configure.merchant.text")}
                </p>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.setMerchantID("YOUR_MERCHANT_ID")\n'+
                    '</script>'
                    }
                </CodeHighlight>
                <div id="advancedCheckout.javascript.configure.merchant.information"
                    className="alert alert-info"
                     role="alert">
                    {T.translate("advancedCheckout.javascript.configure.merchant.information")}
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.configure.fingerprint.title" open
                title={ "2.3 - " + T.translate("advancedCheckout.javascript.configure.fingerprint.title")}>
                <p id="advancedCheckout.javascript.configure.fingerprint.text">
                    {T.translate("advancedCheckout.javascript.configure.fingerprint.text")}
                </p>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.setUseDeviceFingerPrint(true);\n'+
                    '</script>'
                    }
                </CodeHighlight>
                <p id="advancedCheckout.javascript.configure.fingerprint.get">
                    {T.translate("advancedCheckout.javascript.configure.fingerprint.get")}
                </p>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.getUseDeviceFingerPrint();\n'+
                    '</script>'
                    }
                </CodeHighlight>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.configure.date.title" open
                title={ "2.4 - " + T.translate("advancedCheckout.javascript.configure.date.title")}>
                <p id="advancedCheckout.javascript.configure.date.text">
                    {T.translate("advancedCheckout.javascript.configure.date.text")}
                </p>
                <ul>
                    <li id="advancedCheckout.javascript.configure.date.list.1">
                        {T.translate("advancedCheckout.javascript.configure.date.list.1")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.2">
                        {T.translate("advancedCheckout.javascript.configure.date.list.2")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.3">
                        {T.translate("advancedCheckout.javascript.configure.date.list.3")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.4">
                        {T.translate("advancedCheckout.javascript.configure.date.list.4")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.5">
                        {T.translate("advancedCheckout.javascript.configure.date.list.5")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.6">
                        {T.translate("advancedCheckout.javascript.configure.date.list.6")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.7">
                        {T.translate("advancedCheckout.javascript.configure.date.list.7")}
                    </li>
                    <li id="advancedCheckout.javascript.configure.date.list.8">
                        {T.translate("advancedCheckout.javascript.configure.date.list.8")}
                    </li>
                </ul>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.setExpDateFormat(\'MMYY\');\n'+
                    '</script>'
                    }
                </CodeHighlight>
                <p id="advancedCheckout.javascript.configure.date.get">
                    {T.translate("advancedCheckout.javascript.configure.date.get")}
                </p>
                <CodeHighlight language="HTML">
                    { '<script>\n' +
                    'NPS.getExpDateFormat();\n'+
                    '</script>'
                    }
                </CodeHighlight>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Configure;