import {EventEmitter} from 'fbemitter';

export default class Emmiter{

    constructor(){
        this.eventEmmiter = new EventEmitter()
    }

    add(channel,message){
        this.eventEmmiter.addListener(channel, message);
    }

    sendMessage(channel,message){
        this.eventEmmiter.emit(channel, message);
    }

}