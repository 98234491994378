import React from 'react';
import Sale from './CardPayment/Sale';
import {apiReferenceURL} from "../../../env";

class CardPayment extends React.Component {

    componentDidMount(){
        this.props.history.push(`${apiReferenceURL}/simpleCheckout/CardPayment/Sale`);
    }

    componentDidUpdate(){
        this.props.history.push(`${apiReferenceURL}/simpleCheckout/CardPayment/Sale`);
    }

    render() {
        return (
            <Sale/>
        );
    }
}

export default CardPayment;