import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Tokenization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.dart.tokenization.title">
                 Step 4 - {T.translate("advancedCheckout.dart.tokenization.title")}
            </h4>
            <p id="advancedCheckout.dart.tokenization.text">
                {T.translate("advancedCheckout.dart.tokenization.text")}
            </p>
            <Accordion id="advancedCheckout.dart.tokenization.create.title" open
                title={ "4.1 - " + T.translate("advancedCheckout.dart.tokenization.create.title")}>
                <div className="row">
                    <div className="col-12">
                        <div id="advancedCheckout.dart.tokenization.create.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.dart.tokenization.create.information")}
                        </div>
                        <CodeHighlight language="dart">
                        {
                            'import \'dart:async\';\n' +
                            '\n' +
                            'import \'package:nps_sdk/nps_sdk.dart\';\n'+
                            '\n' +
                            'main(List<String> arguments) async {\n' +
                            '  Nps nps = new Nps(sandbox);\n' +
                            '\n' +
                            '  Map createPaymentMethodTokenParams = {\n' +
                            '    "psp_Version": "2.2",\n' +
                            '    "psp_MerchantId": "sdk_test",\n' +
                            '    "psp_CardInputDetails": {\n' +
                            '      "Number": "4507990000000010",\n' +
                            '      "ExpirationDate": "2501",\n' +
                            '      "SecurityCode": "123",\n' +
                            '      "HolderName": "JOHN DOE"\n' +
                            '    },\n' +
                            '    "psp_ClientSession": "YOUR_CLIENT_SESSION_HERE"\n' +
                            '  };\n' +
                            '\n'+
                            '  Future response = nps.createPaymentMethodToken(nps, createPaymentMethodTokenParams);\n' +
                            '  response.then((resp) => print(resp["psp_ResponseCod"]))\n' +
                            '          .catchError((error) => print);\n' +
                            '}'
                        }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.dart.tokenization.create.response.text">
                            {T.translate("advancedCheckout.dart.tokenization.create.response.text")}
                        </p>
                        <p id="advancedCheckout.dart.tokenization.create.error.text">
                            {T.translate("advancedCheckout.dart.tokenization.create.error.text")}
                        </p>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.dart.tokenization.paymentMethod.title" open
                title={ "4.2 - " + T.translate("advancedCheckout.dart.tokenization.paymentMethod.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.dart.tokenization.paymentMethod.text">
                            {T.translate("advancedCheckout.dart.tokenization.paymentMethod.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="java">
                            {
                                'import \'dart:async\';\n' +
                                '\n' +
                                'import \'package:nps_sdk/nps_sdk.dart\';\n'+
                                '\n' +
                                'main(List<String> arguments) async {\n' +
                                '  Nps nps = new Nps(sandbox);\n' +
                                '\n' +
                                '  Map recachePaymentMethodTokenParams = {\n' +
                                '    "psp_Version": "2.2",\n' +
                                '    "psp_MerchantId": "sdk_test",\n' +
                                '    "psp_PaymentMethodId": "YOUR_PAYMENT_METHOD_ID",\n' +
                                '    "psp_CardSecurityCode": "123"\n' +
                                '    "psp_ClientSession": "YOUR_CLIENT_SESSION_HERE"\n' +
                                '  };\n' +
                                '\n'+
                                '  Future response = nps.recachePaymentMethodToken(nps, recachePaymentMethodTokenParams);\n' +
                                '  response.then((resp) => print(resp["psp_ResponseCod"]))\n' +
                                '          .catchError((error) => print);\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.dart.tokenization.paymentMethod.information"
                            className="alert alert-info"
                             role="alert">
                            {T.translate("advancedCheckout.dart.tokenization.paymentMethod.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Tokenization;