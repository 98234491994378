import React from 'react';

class Cookies extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: "Cookies Component"
        };
        this.setCookie = this.setCookie.bind(this);
        this.getCookie = this.getCookie.bind(this);
    }

    setCookie(name, value, expiration) {
        let d = new Date();
        d.setTime(d.getTime() + (expiration*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    getCookie(cookie) {
        let name = cookie + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    render() {
        return (
            <div>
                {this.state.name}
            </div>
        );
    }
}

export default Cookies;