import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/UpdateCustomer-request';
import response from '../../../documentation/documentation/UpdateCustomer-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Update extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            By sending a CustomerId, you will Update customer details . You can add a new PaymentMethod
                            to the customer. Using this functionality you will add a new PaymentMethod to the customer
                            making it the default PaymentMethod for the customer. The previous default PaymentMethod
                            will not be deleted. <br/>
                            If you want to add additional PaymentMethod instead of replacing the existing default you
                            should send in the request field psp_DefaultPaymentMethodId the previous default
                            PaymentMethodId or if you want to delete the previous default, use the CreatePaymentMethod API.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='UpdateCustomer' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Update;