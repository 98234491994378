import React from 'react';
import T from 'i18react';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import ReactSVG from 'react-svg';

import Javascript from './AdvancedCheckout/Javascript';
import Android from './AdvancedCheckout/Android';
import ObjectiveC from './AdvancedCheckout/ObjectiveC';
import Swift from './AdvancedCheckout/Swift';
import Dart from './AdvancedCheckout/Dart';
import ReactPage from './AdvancedCheckout/ReactPage';

import checkout from '../../assets/img/checkout.svg';
import * as env from '../../env';

class AdvancedCheckout extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            indexTab: 0,
            canvasWidth: 0,
            test: {},
            showImage: false
        };
        this.loadImagesAndPick = this.loadImagesAndPick.bind(this);
        this.loadYoutubeVideo = this.loadYoutubeVideo.bind(this);
    }

    componentWillMount(){
        if(this.props.match.params.tab){
            if(this.props.match.params.tab === 'javascript'){
                this.setState( {indexTab: 0} );
            }
            if(this.props.match.params.tab === 'android'){
                this.setState( {indexTab: 1} );
            }
            if(this.props.match.params.tab === 'objectiveC'){
                this.setState( {indexTab: 2} );
            }
            if(this.props.match.params.tab === 'swift'){
                this.setState( {indexTab: 3} );
            }
            if(this.props.match.params.tab === 'dart'){
                this.setState( {indexTab: 4} );
            }
            if(this.props.match.params.tab === 'react'){
                this.setState( {indexTab: 5} );
            }
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState( {indexTab: this.state.indexTab} );
        if(this.props.match.params.tab !== nextProps.match.params.tab){
            if(nextProps.match.params.tab === 'javascript'){
                this.setState( {indexTab: 0} );
            }
            if(nextProps.match.params.tab === 'android'){
                this.setState( {indexTab: 1} );
            }
            if(nextProps.match.params.tab === 'objectiveC'){
                this.setState( {indexTab: 2} );
            }
            if(nextProps.match.params.tab === 'swift'){
                this.setState( {indexTab: 3} );
            }
            if(nextProps.match.params.tab === 'dart'){
                this.setState( {indexTab: 4} );
            }
            if(nextProps.match.params.tab === 'react'){
                this.setState( {indexTab: 5} );
            }
        }
    }

    componentDidMount(){
        this.props.scrollToElement();
    }

    componentDidUpdate(){
        this.props.scrollToElement();
    }

    loadImagesAndPick(){
        let images = {};
        let folder = require.context('../../assets/img/users/', false, /\.(png|jpe?g|svg)$/)
        folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

        let result;
        let count = 0;
        for (let prop in images)
            if (Math.random() < 1/++count)
                result = prop;

        return images[result];
    }

    loadYoutubeVideo(video){
        this.props.youtubeHandler(video);
    }

    render() {

        return (
            <div className="container-fluid advanced-checkout">
                <div className="row ">
                    <div className="col-12">
                        <h3>
                            {T.translate("advancedCheckout.title")}
                        </h3>
                        <p>
                            {T.translate("advancedCheckout.text")}
                        </p>
                        <div className="alert alert-info" role="alert">
                            <p>
                                <strong>{T.translate("advancedCheckout.features.title")}</strong>
                            </p>
                            <ul>
                                <li>{T.translate("advancedCheckout.features.list.1")}</li>
                                <li>{T.translate("advancedCheckout.features.list.2")}</li>
                                <li>{T.translate("advancedCheckout.features.list.3")}</li>
                                <li>{T.translate("advancedCheckout.features.list.4")}</li>
                                <li>{T.translate("advancedCheckout.features.list.5")}</li>
                                <li>{T.translate("advancedCheckout.features.list.6")}</li>
                            </ul>
                        </div>
                        <div className="alert alert-success" role="alert">
                            <p>
                                {T.translate("advancedCheckout.information.1")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4>
                            {T.translate("advancedCheckout.flow.title")}
                        </h4>
                        <ul>
                            <li>{T.translate("advancedCheckout.flow.list.1")}</li>
                            <li>{T.translate("advancedCheckout.flow.list.2")}</li>
                            <li>{T.translate("advancedCheckout.flow.list.3")}</li>
                            <li>{T.translate("advancedCheckout.flow.list.4")}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 checkout-container"
                         ref={(container) => this.container = container}>
                        <ReactSVG src={checkout}
                             alt="1"
                             className="img-fluid checkout-image"
                        />
                        <img src={this.loadImagesAndPick()} alt="1" className="img-fluid checkout-user"/>
                        <img src={env.logo} alt="1" className="img-fluid checkout-logo"/>

                        <span className="checkout-texts client-session-ask">
                            <span className="badge badge-success">1</span>
                            Ask for Client Session
                        </span>
                        <span className="checkout-texts client-session-create">
                            <span className="badge badge-success">2</span>
                            Create Client Session
                        </span>
                        <span className="checkout-texts client-session-response">
                            <span className="badge badge-success">3</span>
                            Client Session = "aJHyahj9182"
                        </span>
                        <span className="checkout-texts client-session-response-device">
                            <span className="badge badge-success">4</span>
                            Client Session = "aJHyahj9182"
                        </span>
                        <span className="checkout-texts create-payment-token">
                            <span className="badge badge-success">5</span>
                            CreatePaymentMethodToken()
                        </span>
                        <span className="checkout-texts create-payment-token-response">
                            <span className="badge badge-success">6</span>
                            PaymentMethodToken
                        </span>
                        <span className="checkout-texts payment-token-request">
                            <span className="badge badge-success">7</span>
                            PaymentMethodToken
                        </span>
                        <span className="checkout-texts request-payment">
                            <span className="badge badge-success">8</span>
                            Request Payment()
                        </span>
                        <span className="checkout-texts response-payment">
                            <span className="badge badge-success">9</span>
                            Response Payment()
                        </span>
                    </div>
                </div>
                <hr/>
                <Tabs selected={this.state.indexTab} onSelect={(index, label) => this.setState({ indexTab: index }) }>
                    <Tab label={T.translate("advancedCheckout.javascript.title")}>
                        <Javascript/>
                    </Tab>
                    <Tab label={T.translate("advancedCheckout.android.title")}>
                        <Android youtubeHandler={this.loadYoutubeVideo}/>
                    </Tab>
                    <Tab label={T.translate("advancedCheckout.objectiveC.title")}>
                        <ObjectiveC youtubeHandler={this.loadYoutubeVideo}/>
                    </Tab>
                    <Tab label={T.translate("advancedCheckout.swift.title")}>
                        <Swift youtubeHandler={this.loadYoutubeVideo}/>
                    </Tab>
                    <Tab label={T.translate("advancedCheckout.dart.title")}>
                        <Dart youtubeHandler={this.loadYoutubeVideo}/>
                    </Tab>
                    <Tab label={T.translate("advancedCheckout.react.title")}>
                        <ReactPage/>
                    </Tab>
                </Tabs>

            </div>
        );
    }
}

export default AdvancedCheckout;