import React from 'react';
import T from 'i18react';
import ReactSVG from 'react-svg';

import * as env from "../../env";
import apiIcon from '../../assets/img/api.svg';
import CodeHighlight from '../../components/CodeHighlight';
import diagram from '../../assets/img/Simple-Checkout-Bank.svg';
import Sale from '../ApiReference/SimpleCheckout/CardPayment/Sale';
import iframeImg from '../../assets/img/simple_checkout_iframe.PNG'

class SimpleCheckout extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            showImage: false,
            title: ''
        };
        this.loadImagesAndPick = this.loadImagesAndPick.bind(this);
    }

    componentDidMount(){
        if(this.props.title){
            this.setState({
               title: this.props.title
            });
        }else{
            this.setState({
                title: T.translate("simpleCheckout.title")
            });
        }
    }

    loadImagesAndPick(){
        let images = {};
        let folder = require.context('../../assets/img/users/', false, /\.(png|jpe?g|svg)$/);
        folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

        let result;
        let count = 0;
        for (let prop in images)
            if (Math.random() < 1/++count)
                result = prop;

        return images[result];
    }

    render() {
        return (
            <div className="container-fluid simple-checkout">
                <div className="row">
                    <div className="col-12">
                        <h3 id="simpleCheckout.title">
                            { this.state.title }
                        </h3>
                        <p id="simpleCheckout.text">
                            {T.translate("simpleCheckout.text")}
                        </p>
                        <div id="simpleCheckout.important" className="alert alert-warning">
                            {T.translate("simpleCheckout.important",{"link": <T.a href={ `${env.apiReferenceURL}/overview` } text="Connection environment"/>})}

                        </div>
                        <div id="simpleCheckout.information.text" className="alert alert-info">
                            <b id="simpleCheckout.information.text">
                                {T.translate("simpleCheckout.information.text")}
                            </b>
                            <ul>
                                <li id="simpleCheckout.information.list.1">
                                    {T.translate("simpleCheckout.information.list.1")}
                                </li>
                                <li id="simpleCheckout.information.list.2">
                                    {T.translate("simpleCheckout.information.list.2")}</li>
                                <li id="simpleCheckout.information.list.3">
                                    {T.translate("simpleCheckout.information.list.3")}</li>
                                <li id="simpleCheckout.information.list.4">
                                    {T.translate("simpleCheckout.information.list.4")}</li>
                                <li id="simpleCheckout.information.list.5">
                                    {T.translate("simpleCheckout.information.list.5")}</li>
                                <li id="simpleCheckout.information.list.7">
                                    {T.translate("simpleCheckout.information.list.7")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"
                         id="simpleCheckout.diagram.title">
                        {T.translate("simpleCheckout.diagram.title")}
                        <div className="col-12 checkout-container">
                            <ReactSVG src={diagram}
                                 alt="bank diagram"
                                 className="checkout-image"/>
                            <img src={this.loadImagesAndPick()} alt="1" className="img-fluid checkout-user"/>
                            <img src={env.logo} alt="1" className="img-fluid checkout-logo"/>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr/>
                        <ol>
                            <li id="simpleCheckout.diagram.list.1">
                                {T.translate("simpleCheckout.diagram.list.1")}</li>
                            <li id="simpleCheckout.diagram.list.2">
                                {T.translate("simpleCheckout.diagram.list.2")}</li>
                            <li id="simpleCheckout.diagram.list.3">
                                {T.translate("simpleCheckout.diagram.list.3")}</li>
                            <li id="simpleCheckout.diagram.list.4">
                                {T.translate("simpleCheckout.diagram.list.4")}</li>
                            <li id="simpleCheckout.diagram.list.5">
                                {T.translate("simpleCheckout.diagram.list.5")}</li>
                            <li id="simpleCheckout.diagram.list.6">
                                {T.translate("simpleCheckout.diagram.list.6")}</li>
                            <li id="simpleCheckout.diagram.list.7">
                                {T.translate("simpleCheckout.diagram.list.7")}</li>
                            <li id="simpleCheckout.diagram.list.8">
                                {T.translate("simpleCheckout.diagram.list.8")}</li>
                            <li id="simpleCheckout.diagram.list.9">
                                {T.translate("simpleCheckout.diagram.list.9")}</li>
                            <li id="simpleCheckout.diagram.list.10">
                                {T.translate("simpleCheckout.diagram.list.10")}</li>
                            <li id="simpleCheckout.diagram.list.11">
                                {T.translate("simpleCheckout.diagram.list.11")}</li>
                            <li id="simpleCheckout.diagram.list.12">
                                {T.translate("simpleCheckout.diagram.list.12")}</li>
                            <li id="simpleCheckout.diagram.list.13">
                                {T.translate("simpleCheckout.diagram.list.13")}</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>
                            {T.translate("simpleCheckout.redirection.title")}
                        </h3>
                        <div>
                            {T.translate("simpleCheckout.redirection.text")}
                        </div>
                        <span>
                            {T.translate("simpleCheckout.redirection.listText")}
                        </span>
                        <ul>
                            <li>{T.translate("simpleCheckout.redirection.list.1")}</li>
                            <li>{T.translate("simpleCheckout.redirection.list.2")}</li>
                            <li>{T.translate("simpleCheckout.redirection.list.3")}</li>
                            <li>{T.translate("simpleCheckout.redirection.list.4")}</li>
                            <li>{T.translate("simpleCheckout.redirection.list.5")}</li>
                        </ul>
                        <p>
                            {T.translate("simpleCheckout.redirection.example")}
                        </p>
                        <CodeHighlight language='HTML'>
                            {
                                '<html>\n' +
                                '   <body>\n' +
                                '      <form action="<?php echo {$result[\'psp_FrontPSP_URL\']}?>" method="POST">\n' +
                                '         <input type="text" name="psp_MerchantId" value="<?php echo {$result[\'psp_MerchantId\']}?>">\n' +
                                '         <input type="text" name="psp_TransactionId" value="<?php echo {$result[\'psp_TransactionId\']}?>">\n' +
                                '         <input type="text" name="psp_MerchTxRef" value="<?php echo {$result[\'psp_MerchTxRef\']}?>">\n' +
                                '         <input type="text" name="psp_Session3p" value="<?php echo {$result[\'psp_Session3p\']}?>">\n' +
                                '         <input type="submit">\n' +
                                '      </form>\n' +
                                '   </body>\n' +
                                '</html>'
                            }
                        </CodeHighlight>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12"
                         id="simpleCheckout.iframe.title">
                        <h3 id="simpleCheckout.iframe.title">
                            {T.translate("simpleCheckout.iframe.title")}
                        </h3>
                        <p id="simpleCheckout.iframe.text">
                            {T.translate("simpleCheckout.iframe.text")}
                        </p>
                        <img src={iframeImg} alt="Iframe" className="img-fluid"/>
                        <br/><br/>
                        <div className="alert alert-info">
                            <b id="simpleCheckout.iframe.information.1.text">
                                {T.translate("simpleCheckout.iframe.information.1.text")}
                            </b>
                            <ul>
                                <li id="simpleCheckout.iframe.information.1.list.1">
                                    {T.translate("simpleCheckout.iframe.information.1.list.1")}</li>
                                <li id="simpleCheckout.iframe.information.1.list.2">
                                    {T.translate("simpleCheckout.iframe.information.1.list.2")}</li>
                            </ul>
                        </div>
                        <div className="alert alert-info">
                            <b id="simpleCheckout.iframe.information.2.text">
                                {T.translate("simpleCheckout.iframe.information.2.text")}
                            </b>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h3 id="simpleCheckout.languages.title">
                            {T.translate("simpleCheckout.languages.title")}</h3>
                        <p id="simpleCheckout.languages.text">
                            {T.translate("simpleCheckout.languages.text")}
                        </p>
                        <table className="table table-documentation table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Language</th>
                                    <th scope="col">Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>English (Australia)</td>
                                    <td>en_AU</td>
                                </tr>
                                <tr>
                                    <td>English (Canada)</td>
                                    <td>en_CA</td>
                                </tr>
                                <tr>
                                    <td>English (Ireland)</td>
                                    <td>en_IE</td>
                                </tr>
                                <tr>
                                    <td>English (New Zeland)</td>
                                    <td>en_NZ</td>
                                </tr>
                                <tr>
                                    <td>English (United States)</td>
                                    <td>en_US</td>
                                </tr>
                                <tr>
                                    <td>Spanish (Argentina)</td>
                                    <td>es_AR</td>
                                </tr>
                                <tr>
                                    <td>Spanish (Colombia)</td>
                                    <td>es_CO</td>
                                </tr>
                                <tr>
                                    <td>Spanish (Spain)</td>
                                    <td>es_ES</td>
                                </tr>
                                <tr>
                                    <td>Spanish (Mexico)</td>
                                    <td>es_MX</td>
                                </tr>
                                <tr>
                                    <td>Portuguese (Brazil)</td>
                                    <td>pt_BR</td>
                                </tr>
                                <tr>
                                    <td>Portuguese (Portugal)</td>
                                    <td>pt_PT</td>
                                </tr>
                                <tr>
                                    <td>French (Belgium)</td>
                                    <td>fr_BE</td>
                                </tr>
                                <tr>
                                    <td>French (Canada)</td>
                                    <td>fr_CA</td>
                                </tr>
                                <tr>
                                    <td>French (France)</td>
                                    <td>fr_FR</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("guideReference.apiHelp.split.detail",
                                            {
                                                "auth": <T.a href={`${env.apiReferenceURL}/simpleCheckout/CardPayment/Authorization` } text="Authorization"/>,
                                                "sale": <T.a href={`${env.apiReferenceURL}/simpleCheckout/CardPayment/Sale` } text="Sale"/>
                                            }
                                        )}
                                        <br/>
                                        {T.translate("guideReference.apiHelp.all",
                                            {
                                                "api": <T.a href={`${env.apiReferenceURL}/`} text="Api Reference"/>
                                            }
                                        )}
                                        <br/><br/>
                                        {T.translate("guideReference.apiHelp.example",
                                            {
                                                "method": <T.text text="PayOnline_3p"/>
                                            }
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <Sale showDocumentation={false}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default SimpleCheckout;