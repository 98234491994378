import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";

class Fraud extends React.Component {

    render() {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Fraud</h3>
                    </div>
                    <div className="col-12">
                        <p>
                            In this section you will see methods available for Fraud screening.
                        </p>
                        <hr/>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 25}}>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/fraud/Screening`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Fraud Screening
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/fraud/Manual`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Notify manual review
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Fraud;