import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const Java = () => {
    return (
        <div className="col-12">
            <div className="java-library"/>
            <div className="row">
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-java" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        1.6 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.java.install">
                        {T.translate("libraries.server.java.install")}
                    </h4>
                    <p id="libraries.server.java.install.text">
                        {T.translate("libraries.server.java.install.text")}
                    </p>
                    <CodeHighlight languague="XML">
                        {
                            '<dependency>\n' +
                            '    <groupId>com.github.ingenico-nps-latam</groupId>\n' +
                            '    <artifactId>nps-sdk-java</artifactId>\n' +
                            '    <version>1.0.34</version>\n' +
                            '</dependency>'
                        }
                    </CodeHighlight>
                    <p id="libraries.server.java.install.command">
                        {T.translate("libraries.server.java.install.command")}
                    </p>
                    <CodeHighlight languague="BASH">
                        {
                            'compile \'com.github.ingenico-nps-latam:nps-sdk-java:1.0.34\''
                        }
                    </CodeHighlight>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.java.configuration.title">
                        {T.translate("libraries.server.java.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.java.configuration.basic" open
                        title={T.translate("libraries.server.java.configuration.basic")}>
                        <CodeHighlight languague="Java">
                            {
                                'import NpsSDK.ConsoleLogger;\n' +
                                'import NpsSDK.ILogger;\n' +
                                'import NpsSDK.NpsSdk;\n' +
                                'import NpsSDK.WsdlHandlerConfiguration;\n' +
                                'import NpsSDK.ILogger.LogLevel;\n' +
                                'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                                'import NpsSDK.WsdlHandlerException;\n' +
                                '\n' +
                                'ILogger consoleLogger = new ConsoleLogger();\n' +
                                'WsdlHandlerConfiguration wsdlHandlerConfiguration = new WsdlHandlerConfiguration(LogLevel.DEBUG, NpsEnvironment.sandbox, "_YOUR_SECRET_KEY_", consoleLogger);      \n' +
                                'NpsSdk npsSdk = null;\n' +
                                'try {\n' +
                                '    npsSdk = new NpsSdk(wsdlHandlerConfiguration);\n' +
                                '} \n' +
                                'catch (WsdlHandlerException ex) {   \n' +
                                '    //Code to handle error\n' +
                                '}  '
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.java.configuration.advanced.title" open
                        title={T.translate("libraries.server.java.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <h4 id="libraries.server.java.configuration.advanced.log.title">
                                    {T.translate("libraries.server.java.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.java.configuration.advanced.log.text">
                                    {T.translate("libraries.server.java.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="Java">
                                    {
                                        'import NpsSDK.ConsoleLogger;\n' +
                                        'import NpsSDK.ILogger;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration;\n' +
                                        'import NpsSDK.ILogger.LogLevel;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                                        '\n' +
                                        'ILogger consoleLogger = new ConsoleLogger();\n' +
                                        'WsdlHandlerConfiguration wsdlHandlerConfiguration = new WsdlHandlerConfiguration(LogLevel.DEBUG, NpsEnvironment.sandbox, "_YOUR_SECRET_KEY_", consoleLogger);'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.java.configuration.advanced.log.file">
                                    {T.translate("libraries.server.java.configuration.advanced.log.file")}
                                </p>
                                <CodeHighlight languague="Java">
                                    {
                                        'import NpsSDK.FileLogger;\n' +
                                        'import NpsSDK.ILogger;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration;\n' +
                                        'import NpsSDK.ILogger.LogLevel;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                                        '\n' +
                                        'ILogger fileLogger = new FileLogger("path/to/your/file.log");\n' +
                                        'WsdlHandlerConfiguration wsdlHandlerConfiguration = new WsdlHandlerConfiguration(LogLevel.DEBUG, NpsEnvironment.sandbox, "_YOUR_SECRET_KEY_", fileLogger);  '
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.java.configuration.advanced.log.level">
                                    {T.translate("libraries.server.java.configuration.advanced.log.level")}
                                </p>
                                <CodeHighlight languague="Java">
                                    {
                                        'import NpsSDK.ConsoleLogger;\n' +
                                        'import NpsSDK.ILogger;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration;\n' +
                                        'import NpsSDK.ILogger.LogLevel;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                                        '\n' +
                                        'ILogger consoleLogger = new ConsoleLogger();\n' +
                                        '\n' +
                                        'WsdlHandlerConfiguration wsdlHandlerConfiguration = new WsdlHandlerConfiguration(LogLevel.DEBUG, NpsEnvironment.sandbox, "_YOUR_SECRET_KEY_", consoleLogger);'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.java.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.java.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.java.configuration.advanced.timeout.text">
                                    {T.translate("libraries.server.java.configuration.advanced.timeout.text")}
                                </p>
                                <CodeHighlight languague="Java">
                                    {
                                        'import NpsSDK.ConsoleLogger;\n' +
                                        'import NpsSDK.ILogger;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration;\n' +
                                        'import NpsSDK.ILogger.LogLevel;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                                        '\n' +
                                        'ILogger consoleLogger = new ConsoleLogger();\n' +
                                        '\n' +
                                        'int openTimeOut = 10;\n' +
                                        'int readTimeOut = 60;\n' +
                                        'WsdlHandlerConfiguration wsdlHandlerConfiguration = new WsdlHandlerConfiguration(LogLevel.DEBUG, NpsEnvironment.sandbox, "_YOUR_SECRET_KEY_", consoleLogger,openTimeOut,readTimeOut);'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.java.configuration.advanced.proxy.title">
                                    {T.translate("libraries.server.java.configuration.advanced.proxy.title")}
                                </h4>
                                <p id="libraries.server.java.configuration.advanced.proxy.text">
                                    {T.translate("libraries.server.java.configuration.advanced.proxy.text")}
                                </p>
                                <CodeHighlight languague="Java">
                                    {
                                        'import NpsSDK.ConsoleLogger;\n' +
                                        'import NpsSDK.ILogger;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration;\n' +
                                        'import NpsSDK.ILogger.LogLevel;\n' +
                                        'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                                        '\n' +
                                        'ILogger consoleLogger = new ConsoleLogger();\n' +
                                        '\n' +
                                        'int openTimeOut = 10;\n' +
                                        'int readTimeOut = 60;\n' +
                                        'HttpHost proxy = new HttpHost("PROXY HOST", 8080);\n' +
                                        'WsdlHandlerConfiguration wsdlHandlerConfiguration = new WsdlHandlerConfiguration(LogLevel.DEBUG, NpsEnvironment.sandbox, "_YOUR_SECRET_KEY_", consoleLogger,openTimeOut,readTimeOut,proxy);'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.java.environments.title">
                        {T.translate("libraries.server.java.environments.title")}
                    </h4>
                    <p id="libraries.server.java.environments.text">
                        {T.translate("libraries.server.java.environments.text")}
                    </p>
                    <CodeHighlight languague="Java">
                        {
                            'import NpsSDK.WsdlHandlerConfiguration.NpsEnvironment;\n' +
                            '\n' +
                            'NpsEnvironment.sandbox\n' +
                            'NpsEnvironment.staging\n' +
                            'NpsEnvironment.production'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.java.error.title">
                        {T.translate("libraries.server.java.error.title")}
                    </h4>
                    <p id="libraries.server.java.error.text">
                        {T.translate("libraries.server.java.error.text")}
                    </p>
                    <CodeHighlight languague="Java">
                        {
                            'import NpsSDK.WsdlHandlerException;\n' +
                            '\n' +
                            '//Code\n' +
                            'try {\n' +
                            '    //code or sdk call\n' +
                            '} \n' +
                            'catch (WsdlHandlerException ex) {   \n' +
                            '    //Code to handle error\n' +
                            '}  '
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.java.example.title">
                        {T.translate("libraries.server.java.example.title")}
                    </h4>
                    <p id="libraries.server.java.example.text">
                        {T.translate("libraries.server.java.example.text")}
                    </p>
                    <CodeHighlight languague="Java">
                        {
                            'import NpsSDK.ComplexElement;\n' +
                            'import NpsSDK.ComplexElementArray;\n' +
                            'import NpsSDK.ComplexElementArrayItem;\n' +
                            'import NpsSDK.WsdlHandlerException;\n' +
                            '\n' +
                            'RootElement data = null;\n' +
                            'RootElement response = null;\n' +
                            '\n' +
                            'try {\n' +
                            '    data = new RootElement();\n' +
                            '    data.add("psp_Version", "2.2" );\n' +
                            '    data.add("psp_MerchantId", PSPMERCHANTID );\n' +
                            '    data.add("psp_TxSource", "WEB" );\n' +
                            '    data.add("psp_MerchTxRef", "ORDER69461-3" );\n' +
                            '    data.add("psp_MerchOrderId", "ORDER69461" );\n' +
                            '    data.add("psp_Amount", "15050" );\n' +
                            '    data.add("psp_NumPayments", "1" );\n' +
                            '    data.add("psp_Currency", "032" );\n' +
                            '    data.add("psp_Country", "ARG" );\n' +
                            '    data.add("psp_Product", "14" );\n' +
                            '    data.add("psp_CardNumber", "4507990000000010" );\n' +
                            '    data.add("psp_CardExpDate", "1612" );\n' +
                            '    data.add("psp_PosDateTime", "2016-12-01 12:00:00");\n' +
                            '    data.add("psp_CardSecurityCode", "325" );   \n' +
                            '\n' +
                            '    response = npsSdk.payOnLine_2p(data);\n' +
                            '} \n' +
                            'catch (WsdlHandlerException ex) {   \n' +
                            '    //Code to handle error\n' +
                            '}  '
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default Java;