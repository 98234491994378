import React from 'react';
import T from 'i18react';
import {Tabs, Tab} from 'react-bootstrap-tabs';

import Javascript from './Client/Javascript';
import Android from './Client/Android';
import ObjectiveC from './Client/ObjectiveC';
import Swift from './Client/Swift';
import Dart from './Client/Dart';
import ReactPage from './Client/ReactPage';

/*ICONS*/
import javascriptIcon from '../../../assets/img/dev/javascript.svg';
import androidIcon from '../../../assets/img/dev/android.svg';
import appleIcon from '../../../assets/img/dev/apple.svg';
import swiftIcon from '../../../assets/img/dev/swift.svg';
import dartIcon from '../../../assets/img/dev/dart.svg';
import reactIcon from '../../../assets/img/dev/react.svg';


class Client extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            tabContainer: ['row', 'tabs-container'],
            iconsContainer: ['row', 'icons-container'],
            showHelp: true,
            indexTab: 0
        };
        this.showElements = this.showElements.bind(this);
    }

    componentWillMount(){
        if(this.props.match.params.tab){
            if(this.props.match.params.tab === 'javascript'){
                this.showElements(0);
            }
            if(this.props.match.params.tab === 'android'){
                this.showElements(1);
            }
            if(this.props.match.params.tab === 'objectiveC'){
                this.showElements(2);
            }
            if(this.props.match.params.tab === 'swift'){
                this.showElements(3);
            }
            if(this.props.match.params.tab === 'dart'){
                this.showElements(4);
            }
            if(this.props.match.params.tab === 'react'){
                this.showElements(5);
            }
        }
    }

    componentDidMount(){
        this.props.scrollToElement();
    }

    componentDidUpdate(){
        this.props.scrollToElement();
    }

    showElements(indexTab){
        let tabsClass = this.state.tabContainer;
        tabsClass.push('show');

        let iconsClass = this.state.iconsContainer;
        iconsClass.push('hide');

        this.setState({
            tabContainer: tabsClass,
            iconsContainer: iconsClass,
            showHelp: false,
            indexTab: indexTab
        });
    }

    render() {
        return (
            <div className="container-fluid libraries">
                <div className="row">
                    <div className="col-12  text-center">
                        <p id="libraries.client.text">
                            {T.translate("libraries.client.text")}
                        </p>
                    </div>
                </div>
                <div className={this.state.iconsContainer.join(' ')}>
                    <div className="col-12 icons">
                        <img src={javascriptIcon}
                             alt="Javascript"
                             onClick={() => this.showElements(0)}/>
                        <img src={androidIcon}
                             alt="Android"
                             onClick={() => this.showElements(1)}/>
                        <img src={appleIcon}
                             alt="Apple"
                             onClick={() => this.showElements(2)}/>
                        <img src={swiftIcon}
                             alt="Apple"
                             onClick={() => this.showElements(3)}/>
                        <img src={dartIcon}
                             alt="Dart"
                             onClick={() => this.showElements(4)}/>
                        <img src={reactIcon}
                             alt="React"
                             onClick={() => this.showElements(5)}/>
                    </div>
                </div>
                <div className={this.state.tabContainer.join(' ')}>
                    <div className="col-12">
                        <Tabs selected={this.state.indexTab}>
                            <Tab label="Javascript">
                                <Javascript/>
                            </Tab>
                            <Tab label="Android">
                                <Android  youtubeHandler={this.props.youtubeHandler}/>
                            </Tab>
                            <Tab label="Objective-C">
                                <ObjectiveC  youtubeHandler={this.props.youtubeHandler}/>
                            </Tab>
                            <Tab label="Swift">
                                <Swift youtubeHandler={this.props.youtubeHandler}/>
                            </Tab>
                            <Tab label="Dart">
                                <Dart/>
                            </Tab>
                            <Tab label="React">
                                <ReactPage/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default Client;