import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import {apiReferenceURL} from "../../../../env";

const Configure = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.swift.configure.title">
               Step 3 - {T.translate("advancedCheckout.swift.configure.title")}
            </h4>
            <p id="advancedCheckout.swift.configure.text">
                {T.translate("advancedCheckout.swift.configure.text")}
            </p>
            <CodeHighlight language="Swift">
                {
                    'let nps = Nps(environment: NPSSANDBOX)!\n' +
                    'nps.merchantId = "__YOUR_NPS_MERCHANT_ID__"\n' +
                    'nps.clientSession = "__YOUR_NPS_CLIENT_SESSION__"'
                }
            </CodeHighlight>
            <Accordion id="advancedCheckout.swift.configure.client.title" open
                title={ "3.1 - " + T.translate("advancedCheckout.swift.configure.client.title")}>
                <p id="advancedCheckout.swift.configure.client.text">
                    {T.translate("advancedCheckout.swift.configure.client.text",{"apiLink": <T.a href={ `${apiReferenceURL}/services/ClientSession` } target="_blank" text="CreateClientSession"/>})}
                </p>
            </Accordion>
            <Accordion id="advancedCheckout.swift.configure.merchant.title" open
                title={ "3.2 - " + T.translate("advancedCheckout.swift.configure.merchant.title")}>
                <p id="advancedCheckout.swift.configure.merchant.text">
                    {T.translate("advancedCheckout.swift.configure.merchant.text")}
                </p>
            </Accordion>
            <Accordion id="advancedCheckout.swift.configure.fingerprint.title" 
                title={ "3.3 - " + T.translate("advancedCheckout.swift.configure.fingerprint.title")}>
                <p id="advancedCheckout.swift.configure.fingerprint.text">
                    {T.translate("advancedCheckout.swift.configure.fingerprint.text")}
                </p>
                <CodeHighlight language="Swift">
                    {
                       'Nps.getDeviceFingerprint()'
                    }
                </CodeHighlight>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Configure;