import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Validators = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.javascript.validators.title">
                Step 6 - {T.translate("advancedCheckout.javascript.validators.title")}
            </h4>
            <div id="advancedCheckout.javascript.validators.information"
                className="alert alert-info"
                 role="alert">
                {T.translate("advancedCheckout.javascript.validators.information")}
            </div>
            <Accordion id="advancedCheckout.javascript.validators.methods.title" open
                title={ "6.1 - " + T.translate("advancedCheckout.javascript.validators.methods.title")}>
                <div className="row">
                    <div className="col-12">
                        <h6 id="advancedCheckout.javascript.validators.methods.name.title">
                            {T.translate("advancedCheckout.javascript.validators.methods.name.title")}
                        </h6>
                        <p id="advancedCheckout.javascript.validators.methods.name.text">
                            {T.translate("advancedCheckout.javascript.validators.methods.name.text")}
                        </p>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.javascript.validators.methods.number.title">
                            {T.translate("advancedCheckout.javascript.validators.methods.number.title")}
                        </h6>
                        <p id="advancedCheckout.javascript.validators.methods.number.text">
                            {T.translate("advancedCheckout.javascript.validators.methods.number.text")}
                        </p>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.javascript.validators.methods.expiration.title">
                            {T.translate("advancedCheckout.javascript.validators.methods.expiration.title")}
                        </h6>
                        <p id="advancedCheckout.javascript.validators.methods.expiration.text">
                            {T.translate("advancedCheckout.javascript.validators.methods.expiration.text")}
                        </p>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.javascript.validators.methods.security.title">
                            {T.translate("advancedCheckout.javascript.validators.methods.security.title")}
                        </h6>
                        <p id="advancedCheckout.javascript.validators.methods.security.text">
                            {T.translate("advancedCheckout.javascript.validators.methods.security.text")}
                        </p>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.javascript.validators.methods.inn.title">
                            {T.translate("advancedCheckout.javascript.validators.methods.inn.title")}
                        </h6>
                        <p id="advancedCheckout.javascript.validators.methods.inn.text">
                            {T.translate("advancedCheckout.javascript.validators.methods.inn.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                '// NPS Client Session\n' +
                                'NPS.setClientSession(\'__YOUR_NPS_CLIENT_SESSION__\');\n' +
                                '\n' +
                                '// NPS Merchant ID\n' +
                                'NPS.setMerchantId(\'__YOUR_NPS_MERCHANT_ID__\');\n' +
                                '\n' +
                                'NPS.getIINDetails(\'XXXXXX\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Validators;