import React from 'react';
import Highlight from 'react-highlight.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class CodeHighlight extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            copied: false
        };
        this.handleCopy = this.handleCopy.bind(this);
    }

    handleCopy(){
        let self = this;
        this.setState({copied: true});
        setTimeout(function () {
            self.setState({copied: false});
        },5000)
    }

    render() {
        return (
            <div>
                <CopyToClipboard text={this.props.children}
                                 onCopy={() => this.handleCopy()}>
                        <span className={ this.state.copied ? 'button-copy copied': 'button-copy' }>
                            { this.state.copied ? 'Copied': 'Copy' }
                        </span>
                </CopyToClipboard>
                <Highlight language={this.props.language}>
                    { this.props.children }
                </Highlight>
            </div>

        );
    }
}

export default CodeHighlight;