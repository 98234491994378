import React from 'react';

import logo from '../../../../assets/img/dev/woocommerce.svg';


const Woocommerce = () => {
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md-2 text-center">
                    <img src={logo} alt="Woocommerce" className="img-fluid language-logo"/>
                    <br/>
                    <span className="badge badge-success">
                        3.x.x
                    </span>
                </div>
                <div className="col-12 col-md-10 download text-center text-md-right">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-plugin-woocommerce" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Woocommerce;