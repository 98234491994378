import React from 'react';
import { Link } from 'react-router-dom';
import T from 'i18react';
import {guideReferenceURL, apiReferenceURL} from "../../env";

import android from '../../assets/img/dev/android.svg';
import apple from '../../assets/img/dev/apple.svg';
import javascript from '../../assets/img/dev/javascript.svg';
import swift from '../../assets/img/dev/swift.svg';
import dart from '../../assets/img/dev/dart.svg';
import reactIcon from '../../assets/img/dev/react.svg';

import net from '../../assets/img/dev/dot-net.svg';
import java from '../../assets/img/dev/java.svg';
import nodejs from '../../assets/img/dev/nodejs.svg';
import php from '../../assets/img/dev/php.svg';
import python from '../../assets/img/dev/python.svg';
import ruby from '../../assets/img/dev/ruby.svg';
import lua from '../../assets/img/dev/lua.svg';
import go from '../../assets/img/dev/go.svg';
import perl from '../../assets/img/dev/perl.svg';
import c from '../../assets/img/dev/c.svg'

import prestashop from '../../assets/img/dev/prestashop.svg';
import magento from '../../assets/img/dev/magento.svg';
import woocommerce from '../../assets/img/dev/woocommerce.svg';

import simpleCheckout from '../../assets/img/gettingStarted/simpleCheckout.svg';
import advancedCheckout from '../../assets/img/gettingStarted/advancedCheckout.svg';
import directPayment from '../../assets/img/gettingStarted/directPayment.svg';
import apiReference from '../../assets/img/gettingStarted/apiReference.svg';
import sdk from '../../assets/img/gettingStarted/sdk.svg';
import plugins from '../../assets/img/gettingStarted/plugins.svg';

class GettingStarted extends React.Component {
    render() {
        return (
            <div className="container-fluid getting-started">
                <div className="row card-container">
                    <div className="col-12">
                        <div className="card">
                            <Link to={`${guideReferenceURL}/reference/simple`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 img-card-icon">
                                            <h5 className="card-title">
                                                <img src={simpleCheckout} alt="" style={{maxHeight: '100px'}}/>
                                                <br/>
                                                {T.translate("gettingStarted.simpleCheckout.title")}
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-6 card-content">

                                            <ul>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.hosted")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.card")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.tailoring")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.effort")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.checkout")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.responsive")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.simpleCheckout.list.available")}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card">
                            <Link to={`${guideReferenceURL}/reference/advanced`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 img-card-icon">
                                            <h5 className="card-title">
                                                <img src={advancedCheckout} alt="" style={{maxHeight: '100px'}}/>
                                                <br/>
                                                {T.translate("gettingStarted.advancedCheckout.title")}
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-6 card-content">
                                            <ul>
                                                <li>
                                                    {T.translate("gettingStarted.advancedCheckout.list.hosted")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.advancedCheckout.list.card")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.advancedCheckout.list.tailoring")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.advancedCheckout.list.effort")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.advancedCheckout.list.checkout")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.advancedCheckout.list.available")}
                                                </li>
                                            </ul>
                                            <div className="images text-center advanced">
                                                <img src={javascript} className="img-fluid" alt="js" />
                                                <img src={android} className="img-fluid" alt="android" />
                                                <img src={apple} className="img-fluid" alt="apple" />
                                                <img src={swift} className="img-fluid" alt="swift" />
                                                <img src={dart} className="img-fluid" alt="dart" />
                                                <img src={reactIcon} className="img-fluid" alt="dart" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card">
                            <Link to={`${guideReferenceURL}/reference/directPayment`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 img-card-icon">
                                            <h5 className="card-title">
                                                <img src={directPayment} alt="" style={{maxHeight: '100px'}}/>
                                                <br/>
                                                {T.translate("gettingStarted.directPayment.title")}
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-6 card-content">
                                            <ul>
                                                <li>
                                                    {T.translate("gettingStarted.directPayment.list.hosted")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.directPayment.list.card")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.directPayment.list.tailoring")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.directPayment.list.effort")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.directPayment.list.checkout")}
                                                </li>
                                                <li>
                                                    {T.translate("gettingStarted.directPayment.list.available")}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card">
                            <Link to={`${apiReferenceURL}/`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6 img-card-icon">
                                            <h5 className="card-title">
                                                <img src={apiReference} alt="" style={{maxHeight: '100px'}}/>
                                                <br/>
                                                {T.translate("gettingStarted.apiReference.title")}
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-6 card-content">
                                            <p>
                                                {T.translate("gettingStarted.apiReference.text")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="card">
                            <Link to={`${guideReferenceURL}/libraries`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4 img-card-icon">
                                            <img src={sdk} alt="" style={{maxHeight: '100px'}}/>
                                        </div>
                                        <div className="col-12 col-md-8 card-content">
                                            <h5 className="card-title">
                                                {T.translate("gettingStarted.sdk.title")}
                                            </h5>
                                            <p>
                                                {T.translate("gettingStarted.sdk.text")}
                                            </p>
                                            <div className="images text-center">
                                                <img src={php} className="img-fluid" alt="php" />
                                                <img src={nodejs} className="img-fluid" alt="nodejs" />
                                                <img src={net} className="img-fluid" alt="net" />
                                                <img src={java} className="img-fluid" alt="java" />
                                                <img src={python} className="img-fluid" alt="python" />
                                                <img src={ruby} className="img-fluid" alt="ruby" />
                                                <img src={lua} className="img-fluid" alt="lua" />
                                                <img src={go} className="img-fluid" alt="go" />
                                                <img src={perl} className="img-fluid" alt="perl" />
                                                <img src={c} className="img-fluid" alt="c" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="card">
                            <Link to={`${guideReferenceURL}/libraries/plugins`}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4 img-card-icon">
                                            <img src={plugins} alt="" style={{maxHeight: '100px'}}/>
                                        </div>
                                        <div className="col-12 col-md-8 card-content">
                                            <h5 className="card-title">
                                                {T.translate("gettingStarted.cartPlugins.title")}
                                            </h5>
                                            <p>
                                                {T.translate("gettingStarted.cartPlugins.text")}
                                            </p>
                                            <div className="images text-center">
                                                <img src={prestashop} className="img-fluid" alt="Prestashop" />
                                                <img src={magento} className="img-fluid" alt="Magento" />
                                                <img src={woocommerce} className="img-fluid" alt="Woocommerce" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GettingStarted;