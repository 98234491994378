import React from 'react';
import T from 'i18react';
import {apiReferenceURL, guideReferenceURL} from "../../env";
import Install from './AdvancedCheckout/Javascript/Install';
import CodeHighlight from "../../components/CodeHighlight";

class DeviceFingerprint extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="advancedCheckout.javascript.title">
                            How to implement the Device Fingerprint
                        </h3>
                    </div>
                    <Install showDocumentation={false}/>
                </div>
                <div className="row">
                    <div className="col-12">  
                        <h4 id="deviceFingerprint.title">Step 2 - {T.translate("deviceFingerprint.title")}</h4>
                        <div id="deviceFingerprint.text">
                            {T.translate("deviceFingerprint.text",{"link": <T.a href={ `${apiReferenceURL}/overview` } text="Connection environment"/>})}
                        </div>
                    </div>
                    <div className="col-12">
                        <br/>
                        <CodeHighlight language="javascript">
                            {
                                'NPS.getDeviceFingerprint(npsSuccessResponseHandler, npsErrorResponseHandler);'
                            }
                        </CodeHighlight>                    
                    </div>
                    <div className="col-12">
                        <p id="deviceFingerprint.javascript.get.response.text">
                            {T.translate("deviceFingerprint.javascript.get.response.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'var npsSuccessResponseHandler;\n' +
                                'npsSuccessResponseHandler = function(deviceFingerprint) {\n' +
                                '  // To retrieve the device fingerprint\n' +
                                '  console.log(deviceFingerprint.id);\n' +
                                '  document.getElementById("form").innerHTML = response.message_to_purchaser;\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>                    
                    <div className="col-12">
                        <p id="deviceFingerprint.javascript.get.error.text">
                            {T.translate("deviceFingerprint.javascript.get.error.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'var npsErrorResponseHandler;\n' +
                                'npsErrorResponseHandler = function(response) {\n' +
                                '  // To retrieve errors on get device fingerprint\n' +
                                '  console.log(response.message);\n' +
                                '  document.getElementById("form").innerHTML = response.message_to_purchaser;\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>   
                    <div className="alert alert-info">
                        <b id="deviceFingerprint.information.title">
                            {T.translate("deviceFingerprint.information.title")}</b>
                        <div id="deviceFingerprint.information.text">
                            {T.translate("deviceFingerprint.information.text",{"advanced": <T.a href={`${guideReferenceURL}/reference/advanced`} text="Advanced Checkout"/>})}
                        </div>
                    </div>   
                </div>
            </div>

        );
    }
}

export default DeviceFingerprint;