import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Installments = () => {
    return (
        <div className="col-12">
            <h4>
                (Optional) Step 5 - {T.translate("advancedCheckout.android.installments.title")}
            </h4>
            <p>
                {T.translate("advancedCheckout.android.installments.text")}
            </p>
            <CodeHighlight language="java">
                {
                    'Nps nps = new Nps(Nps.SANDBOX, "__YOUR_NPS_CLIENT_SESSION__", "__YOUR_NPS_MERCHANT_ID__");\n' +
                    'nps.setAmount(\'120000\');\n' +
                    'nps.setCountry(\'CHL\');\n' +
                    'nps.setCurrency(\'152\');\n' +
                    '\n' +
                    'nps.getInstallmentsOptions(\'8T3BOsXaLMxVsvtHiSuWKL1DEOUUDq3N\', \'14\', \'3\');'
                }
            </CodeHighlight>
            <Accordion id="advancedCheckout.android.installments.amount.title" open
                title={ "5.1 - " + T.translate("advancedCheckout.android.installments.amount.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.android.installments.amount.text">
                            {T.translate("advancedCheckout.android.installments.amount.text")}
                        </p>
                        <CodeHighlight language="java">
                            {
                                'Nps nps = new Nps(Nps.SANDBOX, "__YOUR_NPS_CLIENT_SESSION__", "__YOUR_NPS_MERCHANT_ID__");\n' +
                                'nps.setAmount(\'120000\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.android.installments.country.title" open
                title={ "5.2 - " + T.translate("advancedCheckout.android.installments.country.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.android.installments.country.text">
                            {T.translate("advancedCheckout.android.installments.country.text")}
                        </p>
                        <CodeHighlight language="java">
                            {
                                'Nps nps = new Nps(Nps.SANDBOX, "__YOUR_NPS_CLIENT_SESSION__", "__YOUR_NPS_MERCHANT_ID__");\n' +
                                'nps.setCountry(\'CHL\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.android.installments.currency.title" open
                title={ "5.3 - " + T.translate("advancedCheckout.android.installments.currency.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.android.installments.currency.text">
                            {T.translate("advancedCheckout.android.installments.currency.text")}
                        </p>
                        <CodeHighlight language="java">
                            {
                                'Nps nps = new Nps(Nps.SANDBOX, "__YOUR_NPS_CLIENT_SESSION__", "__YOUR_NPS_MERCHANT_ID__");\n' +
                                'nps.setCurrency(\'152\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

export default Installments;