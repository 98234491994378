import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const NET = () => {
    return (
        <div className="col-12">
            <div className="net-library"/>
            <div className="row">
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-dotnet" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        4 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.net.install">
                        {T.translate("libraries.server.net.install")}
                    </h4>
                    <CodeHighlight languague="BASH">
                        {
                            'nuget install nps_sdk'
                        }
                    </CodeHighlight>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.net.configuration.title">
                        {T.translate("libraries.server.net.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.net.configuration.basic" open
                        title={T.translate("libraries.server.net.configuration.basic")}>
                        <CodeHighlight languague="VB.NET">
                            {
                                'using NpsSdk;\n' +
                                '\n' +
                                'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Debug, NpsSdk.NpsEnvironment.SandBox, "_YOUR_SECRET_KEY_", new DebugLogger()));'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.net.configuration.advanced.title" open
                        title={T.translate("libraries.server.net.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <h4 id="libraries.server.net.configuration.advanced.log.title">
                                    {T.translate("libraries.server.net.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.net.configuration.advanced.log.text">
                                    {T.translate("libraries.server.net.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        '\n' +
                                        'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Debug, NpsSdk.NpsEnvironment.SandBox, "_YOUR_SECRET_KEY_", new DebugLogger()));'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.net.configuration.advanced.log.file">
                                    {T.translate("libraries.server.net.configuration.advanced.log.file")}
                                </p>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        '\n' +
                                        'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Debug, NpsSdk.NpsEnvironment.SandBox, "_YOUR_SECRET_KEY_", new FileLogger("path/to/your/file.log")));'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.net.configuration.advanced.log.level">
                                    {T.translate("libraries.server.net.configuration.advanced.log.level")}
                                </p>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        '\n' +
                                        'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Debug, NpsSdk.NpsEnvironment.SandBox, "_YOUR_SECRET_KEY_", new DebugLogger()));'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.net.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.net.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.net.configuration.advanced.timeout.text">
                                    {T.translate("libraries.server.net.configuration.advanced.timeout.text")}
                                </p>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        '\n' +
                                        'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Debug, NpsSdk.NpsEnvironment.SandBox, "_YOUR_SECRET_KEY_", new DebugLogger(), 60));'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.net.configuration.advanced.proxy.title">
                                    {T.translate("libraries.server.net.configuration.advanced.proxy.title")}
                                </h4>
                                <p id="libraries.server.net.configuration.advanced.proxy.text">
                                {T.translate("libraries.server.net.configuration.advanced.proxy.text")}
                                </p>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        'using System.Net;\n' +
                                        '\n' +
                                        'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Debug, NpsSdk.NpsEnvironment.SandBox, "_YOUR_SECRET_KEY_", "YOUR_PROXY_URL", 8989, "YOUR_USER", "YOUR_PASSWORD", new DebugLogger(), 60));'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>


                            <div className="col-12">
                                <h4 id="libraries.server.net.configuration.advanced.customUrl.title">
                                    {T.translate("libraries.server.net.configuration.advanced.customUrl.title")}
                                </h4>
                                <p id="libraries.server.net.configuration.advanced.customUrl.text">
                                {T.translate("libraries.server.net.configuration.advanced.customUrl.text")}
                                </p>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk; \n' +
                                        'using System.Net; \n' +
                                        '\n' +
                                        'string[] customEnvUrls = new string [] { \n' +
                                        '   "https://first_url.com.ar",\n' +
                                        '   "https://second_url.com.ar" \n '+
                                        '}; \n ' +
                                        '\n' +
                                        'var npsSdk = new NpsSdk(new NpsSdk.WsdlHandlerConfiguration(LogLevel.Info, NpsSdk.NpsEnvironment.CustomEnv, "_YOUR_SECRET_KEY_", new DebugLogger(), customEnvUrls));'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>


                            <div className="col-12">
                                <h4 id="libraries.server.net.configuration.advanced.tls.4.title">
                                    {T.translate("libraries.server.net.configuration.advanced.tls.4.title")}
                                </h4>
                                <div id="libraries.server.net.configuration.advanced.tls.4.text">
                                    {T.translate("libraries.server.net.configuration.advanced.tls.4.text")}
                                    </div>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        'using System.Net;\n' +
                                        '\n' +
                                        'System.Net.ServicePointManager.SecurityProtocol |= SecurityProtocolType.Tls | (SecurityProtocolType)768 | (SecurityProtocolType)3072;'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.net.configuration.advanced.tls.45.title">
                                    {T.translate("libraries.server.net.configuration.advanced.tls.45.title")}
                                </h4>
                                <div id="libraries.server.net.configuration.advanced.tls.45.text">
                                    {T.translate("libraries.server.net.configuration.advanced.tls.45.text")}
                                </div>
                                <CodeHighlight languague="VB.NET">
                                    {
                                        'using NpsSdk;\n' +
                                        'using System.Net;\n' +
                                        '\n' +
                                        'System.Net.ServicePointManager.SecurityProtocol |= SecurityProtocolType.Tls | SecurityProtocolType.Tls11 | SecurityProtocolType.Tls12;'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.net.environments.title">
                        {T.translate("libraries.server.net.environments.title")}
                    </h4>
                    <p id="libraries.server.net.environments.text">
                        {T.translate("libraries.server.net.environments.text")}
                    </p>
                    <CodeHighlight languague="VB.NET">
                        {
                            'NpsSdk.NpsEnvironment.SandBox\n' +
                            'NpsSdk.NpsEnvironment.Implementation\n' +
                            'NpsSdk.NpsEnvironment.Production'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.net.error.title">
                        {T.translate("libraries.server.net.error.title")}
                    </h4>
                    <p id="libraries.server.net.error.text">
                        {T.translate("libraries.server.net.error.text")}
                    </p>
                    <CodeHighlight languague="VB.NET">
                        {
                            '//Code\n' +
                            'try\n' +
                            '{\n' +
                            '    //code or sdk call\n' +
                            '}\n' +
                            'catch (Exception ex)\n' +
                            '{\n' +
                            '    //Code to handle error\n' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.net.example.title">
                        {T.translate("libraries.server.net.example.title")}
                    </h4>
                    <p id="libraries.server.net.example.text">
                        {T.translate("libraries.server.net.example.text")}
                    </p>
                    <CodeHighlight languague="VB.NET">
                        {
                            'using NpsSdk;\n' +
                            '\n' +
                            'try\n' +
                            '    {\n' +
                            '        RootElement data = new RootElement();\n' +
                            '\n' +
                            '        data.Add("psp_Version", "2.2");\n' +
                            '        data.Add("psp_MerchantId", "psp_test");\n' +
                            '        data.Add("psp_TxSource", "WEB");\n' +
                            '        data.Add("psp_MerchTxRef", "ORDER69461-3");\n' +
                            '        data.Add("psp_MerchOrderId", "ORDER69461");\n' +
                            '        data.Add("psp_Amount", "15050");\n' +
                            '        data.Add("psp_NumPayments", "1");\n' +
                            '        data.Add("psp_Currency", "032");\n' +
                            '        data.Add("psp_Country", "ARG");\n' +
                            '        data.Add("psp_Product", "14");\n' +
                            '        data.Add("psp_CardNumber", "4507990000000010");\n' +
                            '        data.Add("psp_CardExpDate", "1612");\n' +
                            '        data.Add("psp_CardSecurityCode", "325");\n' +
                            '        data.Add("psp_PosDateTime", "2016-12-01 12:00:00");\n' +
                            '        RootElement response = npsSdk.PayOnLine_2p(data);\n' +
                            '    }\n' +
                            'catch (Exception ex)\n' +
                            '    {\n' +
                            '    //Code to handle error\n' +
                            '    }'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default NET;