import React from 'react';
import T from "i18react";

import CodeHighlight from '../../../components/CodeHighlight';
import binFormImg from '../../../assets/img/bin-preset-form.png'
import {merchantName} from "../../../env";

class Promotions extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            bigImg: false,
        };
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.promotions.title">
                            { T.translate("guideReference.services.promotions.title") }</h3>
                        <p id="guideReference.services.promotions.text">
                            { T.translate("guideReference.services.promotions.text") }
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="guideReference.services.promotions.control.title">
                            {T.translate("guideReference.services.promotions.control.title")}
                        </h4>
                        <div id="guideReference.services.promotions.control.text">
                            {T.translate("guideReference.services.promotions.control.text",{"whiteLabel": <T.text text={merchantName}/>})}
                        </div>
                        <p>{T.translate("guideReference.services.promotions.methods.title")}</p>
                        <ul>
                            <li>{T.translate("guideReference.services.promotions.methods.list.1")}</li>
                            <li>{T.translate("guideReference.services.promotions.methods.list.2")}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="guideReference.services.promotions.promotionCode.title">
                            {T.translate("guideReference.services.promotions.promotionCode.title")}
                        </h4>
                        <p id="guideReference.services.promotions.promotionCode.text">
                            {T.translate("guideReference.services.promotions.promotionCode.text")}
                        </p>
                        <ul>
                            <li id="guideReference.services.promotions.promotionCode.list.1">
                                {T.translate("guideReference.services.promotions.promotionCode.list.1",{"whiteLabel": <T.text text={merchantName}/>})}</li>
                            <li id="guideReference.services.promotions.promotionCode.list.2">
                                {T.translate("guideReference.services.promotions.promotionCode.list.2")}</li>
                            <li id="guideReference.services.promotions.promotionCode.list.3">
                                {T.translate("guideReference.services.promotions.promotionCode.list.3",{"whiteLabel": <T.text text={merchantName}/>})}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <br/><br/>
                        <table className="table  table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="5" style={{textAlign: 'center'}}>
                                        <strong>Promotions </strong>(request)
                                        <table style={{margin: '0 auto'}}>
                                            <tr>
                                                <td>PayOnline_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>Authorize_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>SplitAuthorize_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>SplitPayOnLine_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>PayOnline_2p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>Authorize_2p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                            </tr>
                                        </table></td>
                                </tr>
                                <tr>
                                    <td>Field</td>
                                    <td>Type</td>
                                    <td>Data Type</td>
                                    <td>Length <br/>
                                        [Min,Max]</td>
                                    <td>Example of field content</td>
                                </tr>
                                <tr>
                                    <td>psp_PromotionCode</td>
                                    <td>O</td>
                                    <td>OrderType</td>
                                    <td>0,32</td>
                                    <td>VISA_SANTANDER</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   "psp_PromotionCode": "VISA_SANTANDER", \n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <br/>
                        <br/><br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5" style={{textAlign: 'center'}}>
                                    <strong>Promotions </strong>(response)
                                </td>
                            </tr>
                            <tr>
                                <td>Field</td>
                                <td>Type</td>
                                <td>Data Type</td>
                                <td>Length <br/>
                                    [Min,Max]</td>
                                <td>Example of field content</td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    There will not be output fields for this function.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4>{T.translate("guideReference.services.promotions.promotionCode.process.title")}</h4>
                        <ul>
                            <li>{T.translate("guideReference.services.promotions.promotionCode.process.list.1",{"whiteLabel": <T.text text={merchantName}/>})}</li>
                            <li>{T.translate("guideReference.services.promotions.promotionCode.process.list.2",{"whiteLabel": <T.text text={merchantName}/>})}</li>
                        </ul>
                        <br/><br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Field</strong>
                                </td>
                                <td>
                                    <strong>Response</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    psp_ResponseCod
                                </td>
                                <td>
                                    6
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    psp_ResponseMsg
                                </td>
                                <td>
                                    Tarjeta inv&aacute;lida
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    psp_ResponseExtended
                                </td>
                                <td>
                                    Error Interno 1053 - Invalid credit card for the promotion
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="guideReference.services.promotions.bin.title">
                            {T.translate("guideReference.services.promotions.bin.title")}
                        </h4>
                        <p id="guideReference.services.promotions.bin.text">
                            {T.translate("guideReference.services.promotions.bin.text")}
                        </p>
                        <br/>
                        <img src={binFormImg} alt="Bin form"
                             className={this.state.bigImg ? 'img-fluid cursor-pointer shadow': 'img-small cursor-pointer shadow'}
                             onClick={() => this.setState({bigImg: !this.state.bigImg})}/>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <br/><br/>
                        <table className="table  table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5" style={{textAlign: 'center'}}>
                                    <strong>Promotions </strong>(request)
                                    <table style={{margin: '0 auto'}}>
                                        <tr>
                                            <td>PayOnline_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>Authorize_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>SplitAuthorize_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>SplitPayOnLine_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>PayOnline_2p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>Authorize_2p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                        </tr>
                                    </table></td>
                            </tr>
                            <tr>
                                <td>Field</td>
                                <td>Type</td>
                                <td>Data Type</td>
                                <td>Length <br/>
                                    [Min,Max]</td>
                                <td>Example of field content</td>
                            </tr>
                            <tr>
                                <td>psp_PresetCardIIN</td>
                                <td>S</td>
                                <td>INN Code</td>
                                <td>6,8</td>
                                <td>450799</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   "psp_PresetCardIIN": "450799", \n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <br/>
                        <br/><br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5" style={{textAlign: 'center'}}>
                                    <strong>Promotions </strong>(response)
                                </td>
                            </tr>
                            <tr>
                                <td>Field</td>
                                <td>Type</td>
                                <td>Data Type</td>
                                <td>Length <br/>
                                    [Min,Max]</td>
                                <td>Example of field content</td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    There will not be output fields for this function.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4>{T.translate("guideReference.services.promotions.bin.process.title")}</h4>
                        <ul>
                            <li>{T.translate("guideReference.services.promotions.bin.process.list.1",{"whiteLabel": <T.text text={merchantName}/>})}</li>
                            <li>{T.translate("guideReference.services.promotions.bin.process.list.2",{"whiteLabel": <T.text text={merchantName}/>})}</li>
                        </ul>
                        <br/><br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Field</strong>
                                    </td>
                                    <td>
                                        <strong>Response</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_ResponseCod
                                    </td>
                                    <td>
                                        6
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_ResponseMsg
                                    </td>
                                    <td>
                                        Tarjeta inv&aacute;lida
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_ResponseExtended
                                    </td>
                                    <td>
                                        Error Interno 1123 - PresetCardIIN does not match card number
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="guideReference.services.promotions.faq.title">
                            {T.translate("guideReference.services.promotions.faq.title")}
                        </h4>
                        <ul>
                            <li id="guideReference.services.promotions.faq.list.1">
                                {T.translate("guideReference.services.promotions.faq.list.1")}
                            </li>
                            <li id="guideReference.services.promotions.faq.list.2">
                                {T.translate("guideReference.services.promotions.faq.list.2")}
                            </li>
                            <li id="guideReference.services.promotions.faq.list.3">
                                {T.translate("guideReference.services.promotions.faq.list.3")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Promotions;