import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import {apiReferenceURL} from "../../../../env";

const Tokenization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.objectiveC.tokenization.title">
               Step 4 - {T.translate("advancedCheckout.objectiveC.tokenization.title")}
            </h4>
            <p id="advancedCheckout.objectiveC.tokenization.text">
                {T.translate("advancedCheckout.objectiveC.tokenization.text")}
            </p>
            <Accordion id="advancedCheckout.objectiveC.tokenization.create.title" open
                title={ "4.1 - " + T.translate("advancedCheckout.objectiveC.tokenization.create.title")}>
                <div className="row">
                    <div className="col-12">
                        <div id="advancedCheckout.objectiveC.tokenization.create.informatio"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.objectiveC.tokenization.create.information")}
                        </div>
                        <CodeHighlight language="Objective-C">
                            {
                                'Nps *nps = [[Nps alloc]initWithEnvironment:NPSSANDBOX];\n' +
                                'nps.merchantId = @"__YOUR_NPS_MERCHANT_ID__";\n' +
                                'nps.clientSession = @"__YOUR_NPS_CLIENT_SESSION__";\n' +
                                '\n' +
                                'NpsCardDetails *card = [[NpsCardDetails alloc]init];\n' +
                                '\n' +
                                'card.number = @"4507990000000010";\n' +
                                'card.holderName = @"JOHN DOE";\n' +
                                'card.securityCode = @"123";\n' +
                                'card.expirationDate = @"1909";\n' +
                                '\n' +
                                'NpsBilling *billing = [[NpsBilling alloc]init];\n' +
                                '\n' +
                                'billing.pspPerson.firstName = @"JOHN";\n' +
                                'billing.pspPerson.lastName = @"Smith";\n' +
                                'billing.pspPerson.dateOfBirth = @"1987-01-01";\n' +
                                'billing.pspPerson.gender = @"M";\n' +
                                'billing.pspPerson.nationality = @"ARG";\n' +
                                'billing.pspPerson.idType = @"DNI";\n' +
                                'billing.pspPerson.idNumber = @"32123123";\n' +
                                'billing.pspPerson.phoneNumber1 = @"4123-1234";\n' +
                                'billing.pspPerson.phoneNumber2 = @"4123-5678";\n' +
                                '\n' +
                                'billing.pspAddress.additionalInfo = @"JOHN";\n' +
                                'billing.pspAddress.city = @"Smith";\n' +
                                'billing.pspAddress.stateProvince = @"Buenos Aires";\n' +
                                'billing.pspAddress.country = @"M";\n' +
                                'billing.pspAddress.zipCode = @"ARG";\n' +
                                'billing.pspAddress.street = @"DNI";\n' +
                                'billing.pspAddress.houseNumber = @"32123123";\n' +
                                '\n' +
                                '[nps createPaymentMethodToken:card\n' +
                                '               billingDetails:billing\n' +
                                '              methodResponse:^(NpsCreatePaymentMethodTokenResponse* methodResponse, NSError *error) {\n' +
                                '                if(!error){\n' +
                                '                    NSLog(@"%@", [methodResponse responseCod]);\n' +
                                '                }\n' +
                                '}];'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.objectiveC.tokenization.create.response.text">
                            {T.translate("advancedCheckout.objectiveC.tokenization.create.response.text")}
                        </p>
                        <p id="advancedCheckout.objectiveC.tokenization.create.error.text">
                            {T.translate("advancedCheckout.objectiveC.tokenization.create.error.text")}
                        </p>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.objectiveC.tokenization.paymentMethod.title" open
                title={ "4.2 - " + T.translate("advancedCheckout.objectiveC.tokenization.paymentMethod.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.objectiveC.tokenization.paymentMethod.text">
                            {T.translate("advancedCheckout.objectiveC.tokenization.paymentMethod.text",{"apiLink": <T.a href={ `${apiReferenceURL}/paymentMethodToken/Create` } target="_blank" text="CreatePaymentMethodToken"/>})}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="Objective-C">
                            {
                                'Nps *nps = [[Nps alloc]initWithEnvironment:NPSSANDBOX];\n' +
                                'nps.merchantId = @"__YOUR_NPS_MERCHANT_ID__";\n' +
                                'nps.clientSession = @"__YOUR_NPS_CLIENT_SESSION__";\n' +
                                '\n' +
                                '\n' +
                                'Billing *billingDetailss = [[Billing alloc]init];\n' +
                                '\n' +
                                'billingDetailss.pspPerson.firstName = @"JOHN DOE";\n' +
                                '\n' +
                                '[nps recachePaymentMethodToken:@"kWRZGcAxy5D7MoB6BDACugHYrlFzP9Eg"\n' +
                                '              cardSecurityCode:@"123"\n' +
                                '                billingDetails:billingDetailss\n' +
                                '                methodResponse:^(NpsRecachePaymentMethodTokenResponse *methodResponse, NSError *error) {\n' +
                                '                    if (!error){\n' +
                                '                        NSLog(@"%@", [methodResponse responseCod]);\n' +
                                '                    }\n' +
                                '}];'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.objectiveC.tokenization.paymentMethod.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.objectiveC.tokenization.paymentMethod.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Tokenization;