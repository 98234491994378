import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/UpdatePaymentMethod-request';
import response from '../../../documentation/documentation/UpdatePaymentMethod-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Update extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            Through this method you can update only some card details, like the billing address,
                            expiration date or holder name without having to re-enter the full card details.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='UpdatePaymentMethod' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Update;