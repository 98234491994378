import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/RetrieveCustomer-request';
import response from '../../../documentation/documentation/RetrieveCustomer-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Retrieve extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            Retrieves all the details given a CustomerId.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='RetrieveCustomer' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Retrieve;