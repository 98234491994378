import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';

const Installments = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.objectiveC.installments.title">
                (Optional) Step 6 - {T.translate("advancedCheckout.objectiveC.installments.title")}
            </h4>
            <p id="advancedCheckout.objectiveC.installments.text">
                {T.translate("advancedCheckout.objectiveC.installments.text")}
            </p>
            <CodeHighlight language="Objective-C">
                {
                    'Nps *nps = [[Nps alloc]initWithEnvironment:NPSSANDBOX];\n' +
                    'nps.merchantId = @"__YOUR_NPS_MERCHANT_ID__";\n' +
                    'nps.clientSession = @"__YOUR_NPS_CLIENT_SESSION__";\n' +
                    '\n' +
                    '[nps getInstallmentsOptions:@"100"\n' +
                    '                    product:@"14"\n' +
                    '                   currency:@"152"\n' +
                    '                    country:@"CHL"\n' +
                    '                numPayments:@"2"\n' +
                    '         paymentMethodToken:@"kkvKuOfD2bNKXCBYDkunIRqImvNFNxB3"\n' +
                    '             methodResponse:^(GetInstallmentsOptionsResponse *methodResponse, NSError *error) {\n' +
                    '                     if (!error){\n' +
                    '                         NSLog(@"%@", [methodResponse responseCod]);\n' +
                    '                         for (Installments *inst in [methodResponse installments]){\n' +
                    '                             NSLog(@"%@", [inst installmentAmount]);\n' +
                    '                             NSLog(@"%@", [inst interestRate]);\n' +
                    '                             NSLog(@"%@", [inst numPayments]);\n' +
                    '                         }\n' +
                    '                     }\n' +
                    '                 }];'
                }
            </CodeHighlight>
        </div>
    );
};

export default Installments;