import React from 'react';
import { Link } from 'react-router-dom';
import T from 'i18react';

import DocBuilder from '../../../components/DocBuilder';
import guideReferenceIcon from '../../../assets/img/guide_reference.svg';
import request from '../../../documentation/documentation/CashPayment_3p-request';
import response from '../../../documentation/documentation/CashPayment_3p-response';
import {guideReferenceURL} from '../../../env';
import Breadcrumbs from "../../../components/Breadcrumbs";

class CashPayment extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid example-selector-container">
                <div className="row">
                    <div className="col-12">
                        {
                            this.state.showDocumentation &&
                                <div>
                                    <Breadcrumbs/>
                                    <div className="alert alert-info">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                                <span>
                                            For more information please go to&nbsp;
                                                    <Link to={`${guideReferenceURL}/reference/simple/voucher`}>
                                              Guide Reference: Simple Checkout
                                            </Link>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {T.translate("apiReference.cashPayment.text")}
                                        </div>
                                    </div>
                                </div>
                        }
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='CashPayment_3p' showDocumentation={this.state.showDocumentation} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CashPayment;