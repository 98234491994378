import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Internationalization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.react.i18n.title">
                Step 5 - {T.translate("advancedCheckout.react.i18n.title")}
            </h4>
            <p id="advancedCheckout.react.i18n.text">
                {T.translate("advancedCheckout.react.i18n.text")}
            </p>
            <Accordion id="advancedCheckout.react.i18n.configure.title" open
                title={ "5.1 - " + T.translate("advancedCheckout.react.i18n.configure.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.react.i18n.configure.text">
                            {T.translate("advancedCheckout.react.i18n.configure.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="javascript">
                            {
                                'this.state.ReactNPS.setLanguage("es_AR");'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.react.i18n.dictionary.title" open
                title={ "5.2 - " + T.translate("advancedCheckout.react.i18n.dictionary.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.react.i18n.dictionary.text">
                            {T.translate("advancedCheckout.react.i18n.dictionary.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="javascript">
                            {
                                'var newDictionary = {\n' +
                                '    "service unavailable, please try again later": "service non disponible, s.v.p. réessayez plus tard",\n' +
                                '    "Invalid security code for the card (%s).": "Code de sécurité non valide pour la carte (%s).",\n' +
                                '    "Invalid installments choice for the card (%s).": "Choix des acomptes non valides pour la carte (%s).",\n' +
                                '    "Invalid card holder name for the card (%s).": "Porte-carte invalide pour la carte (%s).",\n' +
                                '    "Invalid card number for the card (%s).": "Numéro invalide pour la carte (%s).",\n' +
                                '    "Invalid expiration date for the card (%s).": "Date d\'expiration invalide pour la carte (%s).",\n' +
                                '    "Invalid amounts.": "Montants non valides.",\n' +
                                '    "Invalid security code.": "Code de sécurité non valide.",\n' +
                                '    "Invalid installments choice.": "Choix des acomptes non valides",\n' +
                                '    "Invalid card holder name.": "Nom du titulaire de carte non valide.",\n' +
                                '    "Invalid card number.": "Numéro de carte non valide.",\n' +
                                '    "Invalid expiration date.": "Date d\'expiration non valide.",\n' +
                                '    "The card could not be processed, please try again later": "La carte n\'a pas pu être traitée, s.v.p. réessayez plus tard"\n' +
                                '}\n' +
                                '\n' +
                                '// Set the dictionary\n' +
                                'this.state.ReactNPS..setTranslationDictionary("fr_FR", newDictionary);\n' +
                                '\n' +
                                '// Set as many dictionaries as you like\n' +
                                'this.state.ReactNPS..setTranslationDictionary("pt_BR", anotherDictionary);\n' +
                                'this.state.ReactNPS..setTranslationDictionary("it_IT", andAnotherOne);'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Internationalization;