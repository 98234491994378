import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = (props) => {
    return (
        <div className="container-fluid error error-404">
            <div className="row">
                <div className="col-12">
                    Oops seems that you lost your wallet.
                    <br/>
                    But don't worry, you can
                    <br/>
                    <span onClick={() => props.history.goBack()} className="go-back">Go back</span> and see if you can find it.
                    <br/>
                    Go to our <Link to="/search">Search service</Link> and ask for it.
                    <br/>
                    Or maybe <Link to="/">Start over</Link> again.
                </div>
            </div>
        </div>
    );
};

export default Error404;