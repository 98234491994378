import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Validators = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.objectiveC.validators.title">
               Step 5 - {T.translate("advancedCheckout.objectiveC.validators.title")}
            </h4>
            <div id="advancedCheckout.objectiveC.validators.information"
                className="alert alert-info"
                 role="alert">
                {T.translate("advancedCheckout.objectiveC.validators.information")}
            </div>
            <Accordion id="advancedCheckout.objectiveC.validators.methods.title" open
                title={ "5.1 - " + T.translate("advancedCheckout.objectiveC.validators.methods.title")}>
                <div className="row">
                    <div className="col-12">
                        <h6 id="advancedCheckout.objectiveC.validators.methods.name.title">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.name.title")}
                        </h6>
                        <p id="advancedCheckout.objectiveC.validators.methods.name.text">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.name.text")}
                        </p>
                        <CodeHighlight language="Objective-C">
                            {
                                'if([nps validateCardHolderName:@"JOHN DOE"]) {\n' +
                                '    NSLog(@"Holder name is valid");\n' +
                                '}else{\n' +
                                '    NSLog(@"Holder Name is invalid");\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.objectiveC.validators.methods.number.title">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.number.title")}
                        </h6>
                        <p id="advancedCheckout.objectiveC.validators.methods.number.text">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.number.text")}
                        </p>
                        <CodeHighlight language="Objective-C">
                            {
                                'if([nps validateCardNumber:@"4111000000000010"]) {\n' +
                                '    NSLog(@"Card number is valid");\n' +
                                '}else{\n' +
                                '    NSLog(@"Card number is invalid");\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.objectiveC.validators.methods.expiration.title">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.expiration.title")}
                        </h6>
                        <p id="advancedCheckout.objectiveC.validators.methods.expiration.text">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.expiration.text")}
                        </p>
                        <CodeHighlight language="Objective-C">
                            {
                                'if([nps validateCardExpDate:2017 month:12]) {\n' +
                                '    NSLog(@"Expiration date is valid");\n' +
                                '}else{\n' +
                                '    NSLog(@"Expiration date is invalid");\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.objectiveC.validators.methods.security.title">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.security.title")}
                        </h6>
                        <p id="advancedCheckout.objectiveC.validators.methods.security.text">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.security.text")}
                        </p>
                        <CodeHighlight language="Objective-C">
                            {
                                'if([nps validateCardSecurityCode:@"123"]) {\n' +
                                '    NSLog(@"CVV is valid");\n' +
                                '}else{\n' +
                                '    NSLog(@"CVV is invalid");\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.objectiveC.validators.methods.inn.title">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.inn.title")}
                        </h6>
                        <p id="advancedCheckout.objectiveC.validators.methods.inn.text">
                            {T.translate("advancedCheckout.objectiveC.validators.methods.inn.text")}
                        </p>
                        <CodeHighlight language="Objective-C">
                            {
                                'Nps *nps = [[Nps alloc]initWithEnvironment:NPSSANDBOX];\n' +
                                '\n' +
                                '[nps getIINDetails:@"424242"\n' +
                                '   postDateTime:@"2016-12-01 12:00:00"\n' +
                                ' methodResponse:^(GetIINDetailsResponse *methodResponse, NSError *error) {\n' +
                                '    if(!error){\n' +
                                '        NSLog(@"%@", [methodResponse responseCod]);\n' +
                                '    }\n' +
                                '}];'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Validators;