import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const NodeJs = () => {
    return (
        <div className="col-12">
            <div className="nodejs-library"/>
            <div className="row">
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-nodejs" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        4 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.nodejs.install">
                        {T.translate("libraries.server.nodejs.install")}
                    </h4>
                    <CodeHighlight languague="bash">
                        {
                            'npm install nps_sdk'
                        }
                    </CodeHighlight>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.nodejs.configuration.title">
                        {T.translate("libraries.server.nodejs.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.nodejs.configuration.basic" open
                        title={T.translate("libraries.server.nodejs.configuration.basic")}>
                        <CodeHighlight languague="Javascript">
                            {
                                'var Sdk = require(\'nps_sdk\');\n' +
                                'var sdk = new Sdk();\n' +
                                '\n' +
                                'var constants = sdk.getConstants();\n' +
                                '\n' +
                                'sdk.connect({environment: constants.SANDBOX,\n' +
                                '   secretKey: \'YOUR_SECRET_KEY\',\n});'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.nodejs.configuration.advanced.title" open
                        title={T.translate("libraries.server.nodejs.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <h4 id="libraries.server.nodejs.configuration.advanced.log.title">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.nodejs.configuration.advanced.log.text">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="Javascript">
                                    {
                                        'var winston = require(\'winston\');\n' +
                                        'var Sdk = require(\'nps_sdk\');\n' +
                                        'var sdk = new Sdk();\n' +
                                        '\n' +
                                        'const wLogger = winston.createLogger({\n' +
                                        '  level: \'info\',\n' +
                                        '  format: winston.format.simple(),\n' +
                                        '  transports: [\n' +
                                        '    new winston.transports.Console()\n' +
                                        '  ]\n' +
                                        '});\n' +
                                        '\n' +
                                        'var constants = sdk.getConstants();\n' +
                                        '\n' +
                                        'sdk.connect({environment: constants.SANDBOX,\n' +
                                        '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                                        '   logger: logger});'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.nodejs.configuration.advanced.log.level">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.log.level")}
                                </p>
                                <CodeHighlight languague="Javascript">
                                    {
                                        'var Sdk = require(\'nps_sdk\');\n' +
                                        'var sdk = new Sdk();\n' +
                                        '\n' +
                                        'var constants = sdk.getConstants();\n' +
                                        '\n' +
                                        'sdk.connect({environment: constants.SANDBOX,\n' +
                                        '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                                        '   logLevel: DEBUG});'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.nodejs.configuration.advanced.sanitize.title">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.sanitize.title")}
                                </h4>
                                <p id="libraries.server.nodejs.configuration.advanced.sanitize.text">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.sanitize.text")}
                                </p>
                                <CodeHighlight languague="Javascript">
                                    {
                                        'var Sdk = require(\'nps_sdk\');\n' +
                                        'var sdk = new Sdk();\n' +
                                        '\n' +
                                        'var constants = sdk.getConstants();\n' +
                                        '\n' +
                                        'sdk.connect({environment: constants.SANDBOX,\n' +
                                        '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                                        '   sanitize: true});'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.nodejs.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.nodejs.configuration.advanced.timeout.text">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.timeout.text")}
                                </p>
                                <CodeHighlight languague="Javascript">
                                    {
                                        'var Sdk = require(\'nps_sdk\');\n' +
                                        'var sdk = new Sdk();\n' +
                                        '\n' +
                                        'var constants = sdk.getConstants();\n' +
                                        '\n' +
                                        'sdk.connect({environment: constants.SANDBOX,\n' +
                                        '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                                        '   timeout: 60000});'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.nodejs.configuration.advanced.proxy.title">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.proxy.title")}
                                </h4>
                                <p id="libraries.server.nodejs.configuration.advanced.proxy.text">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.proxy.text")}
                                </p>
                                <CodeHighlight languague="Javascript">
                                    {
                                        'var Sdk = require(\'nps_sdk\');\n' +
                                        'var sdk = new Sdk();\n' +
                                        '\n' +
                                        'var constants = sdk.getConstants();\n' +
                                        '\n' +
                                        'sdk.connect({environment: constants.SANDBOX,\n' +
                                        '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                                        '   proxyUrl: \'_YOUR_PROXY_URL_\'});'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.nodejs.configuration.advanced.cache.title">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.cache.title")}
                                </h4>
                                <p id="libraries.server.nodejs.configuration.advanced.cache.text">
                                    {T.translate("libraries.server.nodejs.configuration.advanced.cache.text")}
                                </p>
                                <CodeHighlight languague="Javascript">
                                    {
                                        'var Sdk = require(\'nps_sdk\');\n' +
                                        'var sdk = new Sdk();\n' +
                                        '\n' +
                                        'var constants = sdk.getConstants();\n' +
                                        '\n' +
                                        'sdk.connect({environment: constants.SANDBOX,\n' +
                                        '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                                        '   cache: true});'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.nodejs.environments.title">
                        {T.translate("libraries.server.nodejs.environments.title")}
                    </h4>
                    <p id="libraries.server.nodejs.environments.text">
                        {T.translate("libraries.server.nodejs.environments.text")}
                    </p>
                    <CodeHighlight languague="Javascript">
                        {
                            'var Sdk = require(\'nps_sdk\');\n' +
                            'var sdk = new Sdk();\n' +
                            '\n' +
                            'var constants = sdk.getConstants();\n' +
                            '\n' +
                            'constants.SANDBOX\n' +
                            'constants.STAGING\n' +
                            'constants.PRODUCTION'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.nodejs.error.title">
                        {T.translate("libraries.server.nodejs.error.title")}
                    </h4>
                    <p id="libraries.server.nodejs.error.text">
                        {T.translate("libraries.server.nodejs.error.text")}
                    </p>
                    <div id="libraries.server.nodejs.error.information"
                        className="alert alert-info"
                        role="alert">
                        {T.translate("libraries.server.nodejs.error.information")}
                    </div>
                    <CodeHighlight languague="Javascript">
                        {
                            'var Sdk = require(\'nps_sdk\');\n' +
                            'var sdk = new Sdk();\n' +
                            '\n' +
                            'Sdk.payOnline2p({\n' +
                            '        parameters: "params"\n' +
                            '    },\n' +
                            '    function (error, response) {\n' +
                            '        if (error) {\n' +
                            '            console.log(error) \n' +
                            '        } else {\n' +
                            '            //Code to handle the response\n' +
                            '        }\n' +
                            '    });'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.nodejs.example.title">
                        {T.translate("libraries.server.nodejs.example.title")}
                    </h4>
                    <p id="libraries.server.nodejs.example.text">
                        {T.translate("libraries.server.nodejs.example.text")}
                    </p>
                    <CodeHighlight languague="Javascript">
                        {
                            'var Sdk = require(\'nps_sdk\');\n' +
                            'var sdk = new Sdk();\n' +
                            '\n' +
                            'var constants = sdk.getConstants();\n' +
                            '\n' +
                            'sdk.connect({environment: constants.SANDBOX,\n' +
                            '   secretKey: \'YOUR_SECRET_KEY\',\n' +
                            '   cache: true});\n' +
                            '\n' +
                            'Sdk.payOnline2p({\n' +
                            '        psp_Version: \'2.2\',\n' +
                            '        psp_MerchantId: \'psp_test\',\n' +
                            '        psp_TxSource: \'WEB\',\n' +
                            '        psp_MerchTxRef: \'ORDER66666-2\',\n' +
                            '        psp_MerchOrderId: \'ORDER66666\',\n' +
                            '        psp_Amount: \'15050\',\n' +
                            '        psp_NumPayments: \'1\',\n' +
                            '        psp_Currency: \'032\',\n' +
                            '        psp_Country: \'ARG\',\n' +
                            '        psp_Product: \'14\',\n' +
                            '        psp_CardNumber: \'4507990000000010\',\n' +
                            '        psp_CardExpDate: \'1612\',\n' +
                            '        psp_CardSecurityCode: \'123\',\n' +
                            '        psp_PosDateTime: \'2016-12-01 12:00:00\'\n' +
                            '    },\n' +
                            '    function (error, response) {\n' +
                            '        if (error) {\n' +
                            '            console.log(error) //Http Error\n' +
                            '        } else {\n' +
                            '            // Code to handle response\n' +
                            '        }\n' +
                            '    });'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default NodeJs;