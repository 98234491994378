import React from 'react';
import T from 'i18react';
import {Link} from 'react-router-dom';
import Typist from 'react-typist';
import ReactGA from 'react-ga';

import TypistContainer from '../components/TypistContainer';

import guide from '../assets/img/landing/guide.svg';
import api from '../assets/img/gettingStarted/apiReference.svg';
import sdk from '../assets/img/gettingStarted/sdk.svg';
import plugins from '../assets/img/gettingStarted/plugins.svg';

// import controlPanel from '../assets/img/landing/panel.png';
// import panelTablet from '../assets/img/landing/panel-tablet.png';
// import panelphone from '../assets/img/landing/panel-phone.png';

import controlPanel from '../assets/img/landing/wl-panel.png';
import panelTablet from '../assets/img/landing/wl-panel-tablet.png';
import panelphone from '../assets/img/landing/panel-phone.png';

import fraudIcon from '../assets/img/landing/icons/fraud.svg';
import simpleIcon from '../assets/img/landing/icons/simple.svg';
import secureIcon from '../assets/img/landing/icons/secure.svg';
import solutionIcon from '../assets/img/landing/icons/solution.svg';
import mobileIcon from '../assets/img/landing/icons/mobile.svg';
import whitelabelIcon from '../assets/img/landing/icons/whitelabel.svg';
import cartsIcon from '../assets/img/landing/icons/cart.svg';
import promotionIcon from '../assets/img/landing/icons/promotion.svg';
import tokenIcon from '../assets/img/landing/icons/token.svg';
import wideIcon from '../assets/img/landing/icons/wide.svg';
import panelIcon from '../assets/img/landing/icons/panel.svg';
import conciliatorIcon from '../assets/img/landing/icons/conciliator.svg';
import widePaymentIcon from '../assets/img/landing/icons/widePayment.svg';
import splitIcon from '../assets/img/landing/icons/split.svg';
import innovationIcon from '../assets/img/landing/icons/innovation.svg';

import * as env from "../env";
import FooterLanding from '../components/FooterLanding';

class Landing extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            typingContent: [
                'From developers to developers -_-',
                //'You will find libraries and SDKs that will make the integration experience easier',
                //'Mobile first philosophy tools aimed at empowering your business'
            ]
        };
        this.menuScroll = this.menuScroll.bind(this);
        this.createTypings = this.createTypings.bind(this);
    }

    menuScroll(id) {
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }

    createTypings(){
        let text = [];

        for(let i = 0; i < this.state.typingContent.length; i++){
            text.push(<span key={i}>{this.state.typingContent[i]}</span>);
            text.push(<Typist.Delay ms={300000}  key={i+'A'}/>);
            text.push(<Typist.Backspace  key={i+'B'} count={this.state.typingContent[i].length} delay={500} />);
        }

        return text;
    }

    componentDidMount(){
        if(env.googleAnalitics.active){
            ReactGA.initialize(env.googleAnalitics.code);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="container-fluid main-landing">
                <div className="row">
                    <div className="col-12">
                        <img src={env.logoWhite} className="img-fluid brand-logo" alt=""/>
                        <header>
                            <nav>
                                <li>
                                    <span onClick={() => { this.menuScroll('landingOurTools') }}>
                                        {T.translate("landing.menu.tools")}
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => { this.menuScroll('landingControlPanel') }}>
                                        {T.translate("landing.menu.panel")}
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => { this.menuScroll('landingOurServices') }}>
                                        {T.translate("landing.menu.services")}
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => { this.menuScroll('landingContact') }}>
                                        {T.translate("landing.menu.contact")}
                                    </span>
                                </li>
                            </nav>
                        </header>

                        <section className="hero">
                            <div className="background-image"/>
                            <h1>{T.translate("landing.title")}</h1>
                            <TypistContainer timeout={200} avgTypingDelay={50}>
                                {this.createTypings()}
                            </TypistContainer>
                        </section>


                        <section className="our-work" id="landingOurTools">
                            <h3 className="title">{T.translate("landing.tools.title")}</h3>
                            <p>
                                {T.translate("landing.tools.text")}
                            </p>
                            <hr/>
                            <ul className="grid">
                                <li>
                                    <Link to={`${env.guideReferenceURL}/`}>
                                        <img src={guide} alt="Avatar" className="image"/>
                                        <div className="middle">
                                            <div className="text">Guide Reference</div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${env.apiReferenceURL}/`}>
                                        <img src={api} alt="Avatar" className="image"/>
                                        <div className="middle">
                                            <div className="text">Api reference</div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${env.guideReferenceURL}/libraries`}>
                                        <img src={sdk} alt="Avatar" className="image"/>
                                        <div className="middle">
                                            <div className="text">SDK</div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`${env.guideReferenceURL}/libraries/plugins`}>
                                        <img src={plugins} alt="Avatar" className="image"/>
                                        <div className="middle">
                                            <div className="text">Cart Plugins</div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </section>

                        <section className="control-panel" id="landingControlPanel">

                            <div className="row">
                                <div className="col-12 col-xl-8">
                                    <div className="content-padded">
                                        <h3 className="title">
                                            {T.translate("landing.panel.title")} <br/>
                                            <small>
                                                {T.translate("landing.panel.text")}
                                            </small>
                                        </h3>
                                        <div className="row">
                                            <div className="col-12 col-md-6 control-panel-feature">
                                                <div className="hexagon">
                                                    <i className="fa fa-mobile" aria-hidden="true"/>
                                                </div>
                                                <span>
                                            {T.translate("landing.panel.features.mobile")}
                                        </span>

                                            </div>
                                            <div className="col-12 col-md-6 control-panel-feature">
                                                <div className="hexagon">
                                                    <i className="fa fa-arrows-alt" aria-hidden="true"/>
                                                </div>
                                                <span>
                                            {T.translate("landing.panel.features.responsive")}
                                        </span>
                                            </div>
                                            <div className="col-12 col-md-6 control-panel-feature">
                                                <div className="hexagon">
                                                    <i className="fa fa-cogs" aria-hidden="true"/>
                                                </div>
                                                <span>
                                            {T.translate("landing.panel.features.permissions")}
                                        </span>
                                            </div>
                                            <div className="col-12 col-md-6 control-panel-feature">
                                                <div className="hexagon">
                                                    <i className="fa fa-users" aria-hidden="true"/>
                                                </div>
                                                <span>
                                            {T.translate("landing.panel.features.groups")}
                                        </span>
                                            </div>
                                            <div className="col-12 col-md-6 control-panel-feature">
                                                <div className="hexagon">
                                                    <i className="fa fa-user-plus" aria-hidden="true"/>
                                                </div>
                                                <span>
                                                    {T.translate("landing.panel.features.admin")}
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-6 control-panel-feature">
                                                <div className="hexagon">
                                                    <i className="fa fa-language" aria-hidden="true"/>
                                                </div>
                                                <span>
                                            {T.translate("landing.panel.features.multilanguage")}
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4 hidden-md-down">
                                    <img src={controlPanel} className="control-panel-img" alt=""/>
                                    <img src={panelTablet} className="control-panel-tablet" alt=""/>
                                    <img src={panelphone} className="control-panel-phone" alt=""/>
                                </div>
                            </div>
                        </section>
                        <section className="features" id="landingOurServices">
                            <h3 className="title">
                                {T.translate("landing.services.title")}
                            </h3>
                            <p>
                                {T.translate("landing.services.text")}
                            </p>
                            <hr/>
                            <ul className="grid">
                                <li>
                                    <div className="card">
                                        <div className="card-image-icon hidden-sm-down">
                                            <img src={secureIcon} alt="Fraud" />
                                        </div>
                                        <div className="card-content-text">
                                            <div className="card-content">
                                                <h4>
                                                    {T.translate("landing.services.secure.title")}
                                                </h4>
                                                <p>
                                                    {T.translate("landing.services.secure.text")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-image-icon hidden-sm-down">
                                            <img src={simpleIcon} alt="Fraud" />
                                        </div>
                                        <div className="card-content-text">
                                            <div className="card-content">
                                                <h4>
                                                    {T.translate("landing.services.simple.title")}
                                                </h4>
                                                <p>
                                                    {T.translate("landing.services.simple.text")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                        <div className="card-image-icon hidden-sm-down">
                                            <img src={mobileIcon} alt="Fraud" />
                                        </div>
                                        <div className="card-content-text">
                                            <div className="card-content">
                                                <h4>
                                                    {T.translate("landing.services.mobile.title")}
                                                </h4>
                                                <p>
                                                    {T.translate("landing.services.mobile.text")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={whitelabelIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.whiteLabel.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.whiteLabel.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={cartsIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.carts.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.carts.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={solutionIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.solution.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.solution.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={fraudIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.fraud.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.fraud.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={splitIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.split.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.split.text")}
                                                    </p>
                                                </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={tokenIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.token.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.token.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={promotionIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.promotion.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.promotion.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={panelIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.panel.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.panel.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={conciliatorIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.reconciliation.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.reconciliation.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={innovationIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.innovation.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.innovation.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={wideIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.wide.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.wide.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="card">
                                            <div className="card-image-icon hidden-sm-down">
                                                <img src={widePaymentIcon} alt="Fraud" />
                                            </div>
                                            <div className="card-content-text">
                                                <div className="card-content">
                                                    <h4>
                                                        {T.translate("landing.services.widePayment.title")}
                                                    </h4>
                                                    <p>
                                                        {T.translate("landing.services.widePayment.text")}
                                                    </p>
                                                </div>
                                            </div>
                                    </div>
                                </li>
                            </ul>
                        </section>

                        {/* <section className="contact" id="landingContact">
                            <h3 className="title">
                                {T.translate("landing.contact.title")}
                            </h3>
                            <p>
                                {T.translate("landing.contact.text")}
                            </p>
                            <hr/>

                            <form>
                                <input type="email" placeholder="Email"/>
                                <button className="btn">Contact me</button>
                            </form>
                        </section> */}
                      {<FooterLanding/>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Landing;