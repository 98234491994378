import React from 'react';

const Rejected = () => {

    let images = {};
    let folder = require.context('../../../assets/img/cards/', false, /\.(png|jpe?g|svg)$/)
    folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

    return (
        <table className="table table-documentation table-responsive image-testing-cards">
            <tbody>
            <tr>
                <td></td>
                <td><strong>Product</strong></td>
                <td><strong>PAN</strong></td>
                <td><strong>Security Code</strong></td>
                <td><strong>Exp. Date</strong></td>
                <td style={{width: 150}}>
                    <strong>Expected Result </strong><br/>
                    &lt;Error ID&gt;</td>
                <td><strong>Expected Result </strong><br/>
                    &lt;Error Description&gt;</td>
            </tr>
            <tr>
                <td rowSpan="9" className="no-padding">
                    <div className="amex"/>
                </td>
                <td rowSpan="9">American Express</td>
                <td>375645646328764</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>370932559974742</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>03</td>
                <td>Invalid Merchant</td>
            </tr>
            <tr>
                <td>376911234531006</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>30</td>
                <td>Format Error</td>
            </tr>
            <tr>
                <td>376811234531008</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>54</td>
                <td>Expired Card</td>
            </tr>
            <tr>
                <td>376711234531000</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>65</td>
                <td>Exceeds Limit</td>
            </tr>
            <tr>
                <td>376011234531005</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>14</td>
                <td>Invalid Card</td>
            </tr>
            <tr>
                <td>377285089067575</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>47</td>
                <td>Requires PIN</td>
            </tr>
            <tr>
                <td>376611234531002</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>80</td>
                <td>Batch Number Error</td>
            </tr>
            <tr>
                <td>376511234531004</td>
                <td>1234</td>
                <td>12/2023</td>
                <td>45</td>
                <td>Installments Not Allowed</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="argencard"/>
                </td>
                <td>Argencard</td>
                <td>5011050000000050</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="argenta"/>
                </td>
                <td>Argenta</td>
                <td>6034889800000021</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="bbps"/>
                </td>
                <td>BBPS</td>
                <td>6274010000000021</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="cabal"/>
                </td>
                <td>Cabal</td>
                <td>6035220083740050</td>
                <td>123</td>
                <td>12/2023</td>
                <td>39</td>
                <td>Invalid Card Verification Code</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="cabal"/>
                </td>
                <td>Cabal (Debit)</td>
                <td>6042010000000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="carefour"/>
                </td>
                <td>Carrefour</td>
                <td>507858110000000020</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="clarin-365"/>
                </td>
                <td>Clar&iacute;n 365</td>
                <td>3086250011038020053</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-arnet"/>
                </td>
                <td>Club Arnet</td>
                <td>6033850000000000055</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-dia"/>
                </td>
                <td>Club Dia</td>
                <td>6368970000000023</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-la-nacion"/>
                </td>
                <td>Club La Naci&oacute;n</td>
                <td>6391300000000057</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-la-voz"/>
                </td>
                <td>Club La Voz</td>
                <td>3086254000000000058</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-personal"/>
                </td>
                <td>Club Personal</td>
                <td>6048710000000000053</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="speedy"/>
                </td>
                <td>Club Speedy</td>
                <td>8954540000000000056</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="consumax"/>
                </td>
                <td>Consumax</td>
                <td>6034160000222220</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="coopeplus"/>
                </td>
                <td>Coopeplus</td>
                <td>6276200000024</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="credimas"/>
                </td>
                <td>Credimas</td>
                <td>5045200000000028</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="mi-gift-card"/>
                </td>
                <td>CTC Group</td>
                <td>6375040000000000028</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td rowSpan="4" className="no-padding">
                    <div className="diners"/>
                </td>
                <td rowSpan="4">
                    Diners</td>
                <td>30228693059744</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td>30269811089262</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>36463200000022</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>36995800838194</td>
                <td>123</td>
                <td>12/2023</td>
                <td>39</td>
                <td>Invalid Card Verification Code</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="discover"/>
                </td>
                <td>Discover</td>
                <td>6011020000245052</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="favacard"/>
                </td>
                <td>Favacard</td>
                <td>504408000000000025</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="grupar"/>
                </td>
                <td>Grupar</td>
                <td>6063010000000026</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="italcred"/>
                </td>
                <td rowSpan="2">Italcred</td>
                <td>5043380000220404</td>
                <td>123</td>
                <td>12/2023</td>
                <td>39</td>
                <td>Invalid Card Verification Code</td>
            </tr>
            <tr>
                <td>5043380000354054</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="jcb"/>
                </td>
                <td>JCB</td>
                <td>3589999999999994</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="kadicard"/>
                </td>
                <td>Kadicard</td>
                <td>6271700000000053</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="lider"/>
                </td>
                <td>Lider</td>
                <td>5010900000000024</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="maestro"/>
                </td>
                <td>Maestro</td>
                <td>5010150000028</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>Maestro</td>
                <td>5010150000000000028</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="mas"/>
                </td>
                <td>Mas</td>
                <td>6034930085581077</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td rowSpan="11" className="no-padding">
                    <div className="mastercard"/>
                </td>
                <td rowSpan="11">
                    Mastercard</td>
                <td>5323620000000004</td>
                <td>123</td>
                <td>12/2023</td>
                <td>39</td>
                <td>Invalid Card Verification Code</td>
            </tr>
            <tr>
                <td>5323622000000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td>5323622200000008</td>
                <td>123</td>
                <td>12/2023</td>
                <td>30</td>
                <td>Format Error</td>
            </tr>
            <tr>
                <td>5323622220000004</td>
                <td>123</td>
                <td>12/2023</td>
                <td>54</td>
                <td>Expired Card</td>
            </tr>
            <tr>
                <td>5323222220000003</td>
                <td>123</td>
                <td>12/2023</td>
                <td>43</td>
                <td>Stolen Card</td>
            </tr>
            <tr>
                <td>5323212220000004</td>
                <td>123</td>
                <td>12/2023</td>
                <td>56</td>
                <td>Card not enabled</td>
            </tr>
            <tr>
                <td>5323232220000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td>47</td>
                <td>Requires PIN</td>
            </tr>
            <tr>
                <td>5323332220000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td>61</td>
                <td>Exceeds Daily Limit</td>
            </tr>
            <tr>
                <td>5323432220000008</td>
                <td>123</td>
                <td>12/2023</td>
                <td>80</td>
                <td>Batch Number Error</td>
            </tr>
            <tr>
                <td>5323532220000005</td>
                <td>123</td>
                <td>12/2023</td>
                <td>45</td>
                <td>Installments Not Allowed</td>
            </tr>
            <tr>
                <td>5010150000000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="naranja"/>
                </td>
                <td rowSpan="2">Naranja</td>
                <td>5895620000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>5895620000000028</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="nativa"/>
                </td>
                <td>Nativa</td>
                <td>4870170067535740</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td rowSpan="4" className="no-padding">
                    <div className="nevada"/>
                </td>
                <td rowSpan="4">Nevada</td>
                <td>5043630021318420</td>
                <td>123</td>
                <td>12/2023</td>
                <td>39</td>
                <td>Invalid Card Verification Code</td>
            </tr>
            <tr>
                <td>5043630027679650</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td>5043630063802180</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>5043631155884010</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="nexo"/>
                </td>
                <td>Nexo</td>
                <td>6049400000000051</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="patagonia-365"/>
                </td>
                <td>Patagonia 365</td>
                <td>5046560000000024</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="pyme-nacion"/>
                </td>
                <td>Pyme Naci&oacute;n</td>
                <td>5049100199111120</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="quida"/>
                </td>
                <td>Qida</td>
                <td>5045700000000027</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="shopping"/>
                </td>
                <td>Shopping</td>
                <td>6034880000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="sol"/>
                </td>
                <td>Sol</td>
                <td>5046390000000026</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="sucredito"/>
                </td>
                <td>Sucredito</td>
                <td>6219789990000996</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="tuya"/>
                </td>
                <td>Tuya</td>
                <td>5888000000000022</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="ultra"/>
                </td>
                <td>Ultra</td>
                <td>6375040010000024</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td rowSpan="15" className="no-padding">
                    <div className="visa"/>
                </td>
                <td rowSpan="15">Visa</td>
                <td>4507910000000018</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td>4507920000000017</td>
                <td>123</td>
                <td>12/2023</td>
                <td>39</td>
                <td>Invalid Card Verification Code</td>
            </tr>
            <tr>
                <td>4507930000000016</td>
                <td>123</td>
                <td>12/2023</td>
                <td>51</td>
                <td>Insufficient Funds</td>
            </tr>
            <tr>
                <td>4507940000000015</td>
                <td>123</td>
                <td>12/2023</td>
                <td>03</td>
                <td>Invalid Merchant</td>
            </tr>
            <tr>
                <td>4507950000000014</td>
                <td>123</td>
                <td>12/2023</td>
                <td>30</td>
                <td>Format Error</td>
            </tr>
            <tr>
                <td>4507960000000013</td>
                <td>123</td>
                <td>12/2023</td>
                <td>54</td>
                <td>Expired Card</td>
            </tr>
            <tr>
                <td>4507970000000012</td>
                <td>123</td>
                <td>12/2023</td>
                <td>43</td>
                <td>Stolen Card</td>
            </tr>
            <tr>
                <td>4507980000000011</td>
                <td>123</td>
                <td>12/2023</td>
                <td>56</td>
                <td>Card not enabled</td>
            </tr>
            <tr>
                <td>4507190000000090</td>
                <td>123</td>
                <td>12/2023</td>
                <td>65</td>
                <td>Exceeds Limit</td>
            </tr>
            <tr>
                <td>4507290000000080</td>
                <td>123</td>
                <td>12/2023</td>
                <td>14</td>
                <td>Invalid Card</td>
            </tr>
            <tr>
                <td>4507390000000070</td>
                <td>123</td>
                <td>12/2023</td>
                <td>47</td>
                <td>Requires PIN</td>
            </tr>
            <tr>
                <td>4507490000000060</td>
                <td>123</td>
                <td>12/2023</td>
                <td>61</td>
                <td>Exceeds Daily Limit</td>
            </tr>
            <tr>
                <td>4507590000000059</td>
                <td>123</td>
                <td>12/2023</td>
                <td>80</td>
                <td>Batch Number Error</td>
            </tr>
            <tr>
                <td>4507690000000016</td>
                <td>123</td>
                <td>12/2023</td>
                <td>45</td>
                <td>Installments Not Allowed</td>
            </tr>
            <tr>
                <td>4508690000000015</td>
                <td>123</td>
                <td>12/2023</td>
                <td>41</td>
                <td>Lost Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="visa-debito"/>
                </td>
                <td>Visa Debito</td>
                <td>4001020000000025</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="don-credito"/>
                </td>
                <td>Don Credito</td>
                <td>6087800000000022</td>
                <td>123</td>
                <td>12/2023</td>
                <td>05</td>
                <td>Reject Card</td>
            </tr>
            </tbody>
        </table>
    );
};

export default Rejected;