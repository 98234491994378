import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Tokenization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.react.tokenization.title">
                Step 3 - {T.translate("advancedCheckout.react.tokenization.title")}
            </h4>
            <p id="advancedCheckout.react.tokenization.text">
                {T.translate("advancedCheckout.react.tokenization.text")}
            </p>
            <Accordion id="advancedCheckout.react.tokenization.create.title" open
                title={ "3.1 - " + T.translate("advancedCheckout.react.tokenization.create.title")}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <p id="advancedCheckout.react.tokenization.create.simple">
                            {T.translate("advancedCheckout.react.tokenization.create.simple")}
                        </p>
                        <CodeHighlight language="JSON">
                            {
                                'let PaymentMethodTokenParams = {\n' +
                                '      card: {\n' +
                                '        holder_name: "John Smith",\n' +
                                '        number: "4507990000000010",\n' +
                                '        exp_month: "01",\n' +
                                '        exp_year: "2019",\n' +
                                '        security_code: "123",\n' +
                                '      }, \n' +
                                '      billing: { // optional\n' +
                                '        person: { // optional\n' +
                                '          firstname: "John",  // mandatory\n' +
                                '          middlename: "Jay", // optional\n' +
                                '          lastname: "Smith", // optional\n' +
                                '          phonenumber1: "4123-1234", // optional\n' +
                                '          phonenumber2: "4123-1234", // optional\n' +
                                '          gender: "M", // optional\n' +
                                '          birthday: "1987-01-01", // optional\n' +
                                '          nationality: "ARG", // optional\n' +
                                '          idtype: "500", // optional\n' +
                                '          idnumber: "500" // optional\n' +
                                '        },\n' +
                                '        address: { // optional\n' +
                                '          street: "Fakestreet", // mandatory\n' +
                                '          housenumber: "999", // mandatory\n' +
                                '          city: "Fakecity", // mandatory\n' +
                                '          country: "ARG", // mandatory\n' +
                                '          zip: "1234", // optional\n' +
                                '          state: "Fakestate", // optional\n' +
                                '          addinfo: "Fakeinfo", // optional\n' +
                                '      }\n' +
                                '   }\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12 col-md-6">
                        <p id="advancedCheckout.react.tokenization.create.multiple">
                            {T.translate("advancedCheckout.react.tokenization.create.multiple")}
                        </p>
                        <CodeHighlight language="JSON">
                            {
                                'let PaymentMethodTokenParams = {\n' +
                                '       cards: [\n' +
                                '           {\n' +
                                '               holder_name: "John Smith",\n' +
                                '               number: "4507990000000010",\n' +
                                '               exp_month: "01",\n' +
                                '               exp_year: "2019",\n' +
                                '               security_code: "123",\n' +
                                '           },\n' +
                                '           {\n' +
                                '               holder_name: "John Smith",\n' +
                                '               number: "5323601111111112",\n' +
                                '               exp_month: "01",\n' +
                                '               exp_year: "2019",\n' +
                                '               security_code: "123",          \n' +
                                '           }\n' +
                                '      ],\n' +
                                '      billing: { // optional\n' +
                                '        person: { // optional\n' +
                                '          firstname: "John",  // mandatory\n' +
                                '          middlename: "Jay", // optional\n' +
                                '          lastname: "Smith", // optional\n' +
                                '          phonenumber1: "4123-1234", // optional\n' +
                                '          phonenumber2: "4123-1234", // optional\n' +
                                '          gender: "M", // optional\n' +
                                '          birthday: "1987-01-01", // optional\n' +
                                '          nationality: "ARG", // optional\n' +
                                '          idtype: "500", // optional\n' +
                                '          idnumber: "500" // optional\n' +
                                '        },\n' +
                                '        address: { // optional\n' +
                                '          street: "Fakestreet", // mandatory\n' +
                                '          housenumber: "999", // mandatory\n' +
                                '          city: "Fakecity", // mandatory\n' +
                                '          country: "ARG", // mandatory\n' +
                                '          zip: "1234", // optional\n' +
                                '          state: "Fakestate", // optional\n' +
                                '          addinfo: "Fakeinfo", // optional\n' +
                                '      }\n' +
                                '   }\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <div id="advancedCheckout.react.tokenization.create.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.react.tokenization.create.information")}
                        </div>
                        <p id="advancedCheckout.react.tokenization.create.make">
                            {T.translate("advancedCheckout.react.tokenization.create.make")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'await this.state.ReactNPS.createPaymentMethodToken(PaymentMethodTokenParams).then((e)=>{\n' +
                                '      console.log(e);\n' +
                                '}).catch((error)=>{\n' +
                                '      console.warn(error);\n' +
                                '});'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.react.tokenization.create.response.text">
                            {T.translate("advancedCheckout.react.tokenization.create.response.text")}
                        </p>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p id="advancedCheckout.react.tokenization.create.response.simple">
                                    {T.translate("advancedCheckout.react.tokenization.create.response.simple")}
                                </p>
                                <CodeHighlight language="javascript">
                                    {
                                        '{\n' +
                                        '  "result": {\n' +
                                        '    "id": "ilLWgeH13lCfumFMNwpmmhUdPQdFOLJZ",\n' +
                                        '    "used": false,\n' +
                                        '    "object": "paymentMethodToken",\n' +
                                        '    ....\n' +
                                        '    }\n' +
                                        '}'
                                    }
                                </CodeHighlight>
                            </div>
                            <div className="col-12 col-md-6">
                                <p id="advancedCheckout.react.tokenization.create.response.multiple">
                                    {T.translate("advancedCheckout.react.tokenization.create.response.multiple")}
                                </p>
                                <CodeHighlight language="javascript">
                                    {
                                        '"result": [\n' +
                                        '    {\n' +
                                        '      "id": "2GBx8OSDolSdN2eD10bJIY2xR1EGCF0R",\n' +
                                        '      "used": false,\n' +
                                        '      "object": "paymentMethodToken",\n' +
                                        '      ......\n' +
                                        '    },\n' +
                                        '    {\n' +
                                        '      "id": "gWtT7pSHkgX3tAM1gjqjXBjRIOqQ5ref",\n' +
                                        '      "used": false,\n' +
                                        '      "object": "paymentMethodToken",\n' +
                                        '      ......\n' +
                                        '   }\n' +
                                        ']'
                                    }
                                </CodeHighlight>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.react.tokenization.create.error.text">
                            {T.translate("advancedCheckout.react.tokenization.create.error.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                '{\n' +
                                '  "result": {},\n' +
                                '  "error": {\n' +
                                '    "object": "error",\n' +
                                '    "type": "invalid_request_error",\n' +
                                '    "message": "...",\n' +
                                '    "message_to_purchaser": "The card could not be processed, please try again later"\n' +
                                '  }\n' +
                                '}'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.react.tokenization.paymentMethod.title" open
                title={ "3.2 - " + T.translate("advancedCheckout.react.tokenization.paymentMethod.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.react.tokenization.paymentMethod.text">
                            {T.translate("advancedCheckout.react.tokenization.paymentMethod.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="javascript">
                            {
                                'let PaymentMethodTokenParams = {\n' +
                                '      card: {\n' +
                                '        payment_method_id: \'gXnsNd7hhfIzAd9PkRov1MJeEVGlSKe7\',\n' +
                                '        security_code: \'123\',\n' +
                                '      }, \n' +
                                '      billing: { // optional\n' +
                                '        person: { // optional\n' +
                                '          firstname: \'John\',  // mandatory\n' +
                                '          middlename: \'Jay\', // optional\n' +
                                '          lastname: \'Smith\', // optional\n' +
                                '          phonenumber1: \'4123-1234\', // optional\n' +
                                '          phonenumber2: \'4123-1234\', // optional\n' +
                                '          gender: \'M\', // optional\n' +
                                '          birthday: \'1987-01-01\', // optional\n' +
                                '          nationality: \'ARG\', // optional\n' +
                                '          idtype: \'500\', // optional\n' +
                                '          idnumber: \'500\' // optional\n' +
                                '        },\n' +
                                '        address: { // optional\n' +
                                '          street: \'Fakestreet\', // mandatory\n' +
                                '          housenumber: \'999\', // mandatory\n' +
                                '          city: \'Fakecity\', // mandatory\n' +
                                '          country: \'ARG\', // mandatory\n' +
                                '          zip: \'1234\', // optional\n' +
                                '          state: \'Fakestate\', // optional\n' +
                                '          addinfo: \'Fakeinfo\', // optional\n' +
                                '      }\n' +
                                '   }\n' +
                                '}\n' +
                                '\n' +
                                'await this.state.ReactNPS.recachePaymentMethodToken(PaymentMethodTokenParams).then((e)=>{\n' +
                                '    console.log(e);\n' +
                                '}).catch((error)=>{\n' +
                                '    console.warn(error);\n' +
                                '});'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.react.tokenization.paymentMethod.information"
                            className="alert alert-info"
                             role="alert">
                            {T.translate("advancedCheckout.react.tokenization.paymentMethod.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.react.tokenization.retrieve.title" open
                title={ "3.3- " + T.translate("advancedCheckout.react.tokenization.retrieve.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.react.tokenization.retrieve.text">
                            {T.translate("advancedCheckout.react.tokenization.retrieve.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="javascript">
                            {
                                'await this.state.ReactNPS.retrivePaymentMethodToken(PaymentMethodToken).then((e)=>{\n' +
                                '    console.log(e);\n' +
                                '}).catch((error)=>{\n' +
                                '    console.warn(error);\n' +
                                '});'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.react.tokenization.retrieve.information"
                            className="alert alert-info"
                             role="alert">
                            {T.translate("advancedCheckout.react.tokenization.retrieve.information")}
                        </div>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.react.tokenization.retrieve.response.text">
                            {T.translate("advancedCheckout.react.tokenization.retrieve.response.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.react.tokenization.create.error.text">
                            {T.translate("advancedCheckout.react.tokenization.create.error.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                '{\n' +
                                '  "result": {},\n' +
                                '  "error": {\n' +
                                '    "object": "error",\n' +
                                '    "type": "invalid_request_error",\n' +
                                '    "message": "Error Interno 1206 - PaymentMethod invalido",\n' +
                                '    "message_to_purchaser": "The card could not be processed, please try again later"\n' +
                                '  }\n' +
                                '}'
                            }
                        </CodeHighlight>
                    </div>                    
                </div>
            </Accordion>            
            <hr/>
        </div>
    );
};

export default Tokenization;