import React from 'react';
import DocBuilder from '../../../../components/DocBuilder';

import request from '../../../../documentation/documentation/QueryTxs-request';
import response from '../../../../documentation/documentation/QueryTxs-response';
import Breadcrumbs from "../../../../components/Breadcrumbs";

class QueryTxs extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {this.state.showDocumentation &&
                            <div>
                                <Breadcrumbs/>
                                <br/>
                                <br/>
                                <p>
                                    By using this method you can ask for detailed information for more than one transaction
                                </p>
                            </div>
                        }
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='QueryTxs' showDocumentation={this.state.showDocumentation} />
                    </div>
                </div>
            </div>
        );
    }
}

export default QueryTxs;