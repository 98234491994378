import React from 'react';
import { Link } from 'react-router-dom';

import {guideReferenceURL} from "../../../env";
import DocBuilder from '../../../components/DocBuilder';
import guideReferenceIcon from '../../../assets/img/guide_reference.svg';

import request from '../../../documentation/documentation/RecachePaymentMethodToken-request';
import response from '../../../documentation/documentation/RecachePaymentMethodToken-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Recache extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            ###!!! DEPRECATED DO NOT USE !!!### 
 Merchant should stop using RecachePaymentMethodToken method and start sending psp_CardSecurityCode in Authorize and PayOnline with the PaymentMethodId
                        </p>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-12">
                                    <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                    <span>
                                        For more information please go to&nbsp;
                                        <Link to={`${guideReferenceURL}/reference/advanced`}>
                                          Guide Reference: Advanced Checkout
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <DocBuilder showEndpoint={true} examples={true} onlyClientExamples={true} data={{request:request, response: response}} endpoint='RecachePaymentMethodToken' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Recache;