import React from 'react';

class Contact extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            contactModal: false,
            contact:  {
                type: '',
                firstName: '',
                lastName: '',
                email: '',
                company: '',
                website: '',
                notes: ''
            }
        };
        this.contactModalClickOutside = this.contactModalClickOutside.bind(this);
        this.updateText = this.updateText.bind(this);
    }

    componentDidUpdate(){
        if(this.props.openContact !== this.state.contactModal){
            this.setState({
                contactModal: this.props.openContact
            });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.contactModalClickOutside);
        document.addEventListener('touchend', this.contactModalClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.contactModalClickOutside);
        document.removeEventListener('touchend', this.contactModalClickOutside);
    }

    contactModalClickOutside(event) {
        if (this.state.contactModal && !this.contactModalRef.contains(event.target)) {
            this.props.openContactForm();
        }
    }

    updateText(event){
        let prevState = this.state.contact;

        if(event.target.id === 'firstName'){
            prevState.firstName = event.target.value;
        }
        if(event.target.id === 'lastName') {
            prevState.lastName = event.target.value;
        }
        if(event.target.id === 'email') {
            prevState.email = event.target.value;
        }
        if(event.target.id === 'company') {
            prevState.company = event.target.value;
        }
        if(event.target.id === 'website') {
            prevState.website = event.target.value;
        }
        if(event.target.id === 'commercial' || event.target.id === 'technical' ) {
            prevState.type = event.target.value;
        }

        this.setState({
            contact: prevState
        });
    }

    render() {
        return (
            <div>
                <div className={this.state.contactModal ? 'modal fade show' : 'modal fade'} role="dialog" style={{top: '5%', zIndex: '100000'}}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content"
                             ref={(node) => this.contactModalRef = node}>
                            <div className="modal-header">
                                <h5 className="modal-title">Contact Form</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({contactModal: false})}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form className="contact-form">
                                    <div className="switch-toggle switch-holo">
                                        <input id="technical"
                                               name="requestType"
                                               value="technical"
                                               type="radio"
                                               onChange={this.updateText}
                                               checked={this.state.contact.type === 'technical'}/>
                                        <label htmlFor="technical">Technical</label>

                                        <input id="commercial"
                                               name="requestType"
                                               value="commercial"
                                               type="radio"
                                               onChange={this.updateText}
                                               checked={this.state.contact.type === 'commercial'} />
                                        <label htmlFor="commercial">Commercial</label>
                                    </div>
                                    <br/><br/>
                                    <div className="md-form">
                                        <input type="text" id="firstName"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="firstName"
                                               className={this.state.contact.firstName ? 'active': ''}>
                                            First Name
                                        </label>
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="lastName"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="lastName"
                                               className={this.state.contact.lastName ? 'active': ''}>
                                            Last Name
                                        </label>
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="email"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="email"
                                               className={this.state.contact.email ? 'active': ''}>
                                            Email
                                        </label>
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="company"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="company"
                                               className={this.state.contact.company ? 'active': ''}>
                                            Company
                                        </label>
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="website"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="website"
                                               className={this.state.contact.website ? 'active': ''}>
                                            Website
                                        </label>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-block btn-primary">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.contactModal ? 'modal-backdrop fade show' : 'modal-backdrop fade'}/>
            </div>
        );
    }
}

export default Contact;