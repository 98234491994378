import React from 'react';
import DocBuilder from '../../../../components/DocBuilder';

import request from '../../../../documentation/documentation/SimpleQueryTx-request';
import response from '../../../../documentation/documentation/SimpleQueryTx-response';
import Breadcrumbs from "../../../../components/Breadcrumbs";

class SimpleQueryTx extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {this.state.showDocumentation &&
                            <div>
                                <Breadcrumbs/>
                                <br/>
                                <br/>
                                <p>
                                    This method gives you the detailed information from one transaction.
                                </p>
                            </div>
                        }
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='SimpleQueryTx' showDocumentation={this.state.showDocumentation} />
                    </div>
                </div>
            </div>
        );
    }
}

export default SimpleQueryTx;