import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

class Perl extends React.Component {

    render() {
        return (
            <div className="col-12">
                <div className="perl-library"/>
                <div className="row">
                    <div className="col-12 col-md-2 text-center download">
                        <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-perl" className="get-started-in"
                           target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-github" />
                            Download
                        </a>
                        <br />
                        <br />
                        <span className="badge badge-success">
                            5.24.1 or above
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr />
                        <h4 id="libraries.server.perl.install">
                            {T.translate("libraries.server.perl.install")}
                        </h4>
                        <CodeHighlight languague="Bash">
                            {
                                'cpan install NpsSDK::Nps'
                            }
                        </CodeHighlight>
                        <hr />
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.perl.configuration.title">
                            {T.translate("libraries.server.perl.configuration.title")}
                        </h4>
                        <Accordion id="libraries.server.perl.configuration.basic" open
                            title={T.translate("libraries.server.perl.configuration.basic")}>
                            <CodeHighlight languague="Perl">
                                {
                                    'use NpsSDK::Nps;\n' +
                                    'use warnings;\n' +
                                    'use strict;\n' +
                                    '\n' +
                                    'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                    '                                 secret_key  => "_YOUR_SECRET_KEY_");'
                                }
                            </CodeHighlight>
                        </Accordion>
                        <Accordion id="libraries.server.perl.configuration.advanced.title" open
                            title={T.translate("libraries.server.perl.configuration.advanced.title")}>
                            <div className="row">
                                <div className="col-12">
                                    <h4 id="libraries.server.perl.configuration.advanced.log.title">
                                        {T.translate("libraries.server.perl.configuration.advanced.log.title")}
                                    </h4>
                                    <p id="libraries.server.perl.configuration.advanced.log.text">
                                        {T.translate("libraries.server.perl.configuration.advanced.log.text")}
                                    </p>
                                    <CodeHighlight languague="Perl">
                                        {
                                            'use NpsSDK::Nps;\n' +
                                            'use warnings;\n' +
                                            'use strict;\n' +
                                            '\n' +
                                            'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                            '                                 secret_key  => "_YOUR_SECRET_KEY_",\n' +
                                            '                                 logger      => $logger);'
                                        }
                                    </CodeHighlight>
                                    <p id="libraries.server.perl.configuration.advanced.log.levels">
                                        {T.translate("libraries.server.perl.configuration.advanced.log.levels")}
                                    </p>
                                    <p id="libraries.server.perl.configuration.advanced.log.example">
                                        {T.translate("libraries.server.perl.configuration.advanced.log.level")}
                                    </p>
                                    <CodeHighlight languague="Perl">
                                        {
                                            'use NpsSDK::Nps;\n' +
                                            'use warnings;\n' +
                                            'use strict;\n' +
                                            '\n' +
                                            'use Log::Log4perl;\n' +
                                            '\n' +
                                            'Log::Log4perl->init(<<CONFIG);\n' +
                                            'log4perl.rootLogger = INFO, screen\n' +
                                            '\n' +
                                            'log4perl.appender.screen = Log::Log4perl::Appender::Screen\n' +
                                            'log4perl.appender.screen.stderr = 0\n' +
                                            'log4perl.appender.screen.layout = PatternLayout\n' +
                                            'log4perl.appender.screen.layout.ConversionPattern = %d %p %m%n\n' +
                                            '\n' +
                                            'CONFIG\n' +
                                            '\n' +
                                            'my $logger = Log::Log4perl::get_logger();\n' +
                                            '\n' +
                                            'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                            '                                 secret_key  => "_YOUR_SECRET_KEY_",\n' +
                                            '                                 logger      => $logger);'
                                        }
                                    </CodeHighlight>                                    
                                    <p id="libraries.server.perl.configuration.advanced.log.file">
                                        {T.translate("libraries.server.perl.configuration.advanced.log.file")}
                                    </p>
                                    <CodeHighlight languague="Perl">
                                        {
                                            'use NpsSDK::Nps;\n' +
                                            'use warnings;\n' +
                                            'use strict;\n' +
                                            '\n' +
                                            'use Log::Log4perl;\n' +
                                            '\n' +
                                            'Log::Log4perl->init(<<CONFIG);\n' +
                                            'log4perl.rootLogger = DEBUG, screen, file\n' +
                                            '\n' +
                                            'log4perl.appender.screen = Log::Log4perl::Appender::Screen\n' +
                                            'log4perl.appender.screen.stderr = 0\n' +
                                            'log4perl.appender.screen.layout = PatternLayout\n' +
                                            'log4perl.appender.screen.layout.ConversionPattern = %d %p %m%n\n' +
                                            '\n' +
                                            'log4perl.appender.file = Log::Log4perl::Appender::File\n' +
                                            'log4perl.appender.file.filename = YOUR_LOG_FILE\n' +
                                            'log4perl.appender.file.mode = append\n' +
                                            'log4perl.appender.file.layout = PatternLayout\n' +
                                            'log4perl.appender.file.layout.ConversionPattern = %d %p %m%n\n' +
                                            '\n' +                                          
                                            'CONFIG\n' +
                                            '\n' +
                                            'my $logger = Log::Log4perl::get_logger();\n' +
                                            '\n' +
                                            'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                            '                                 secret_key  => "_YOUR_SECRET_KEY_",\n' +
                                            '                                 logger      => $logger);'
                                        }
                                    </CodeHighlight>
                                
                                    <hr />
                                </div>
                                <div className="col-12">
                                    <h4 id="libraries.server.perl.configuration.advanced.sanitize.title">
                                        {T.translate("libraries.server.perl.configuration.advanced.sanitize.title")}
                                    </h4>
                                    <p id="libraries.server.perl.configuration.advanced.sanitize.title">
                                        {T.translate("libraries.server.perl.configuration.advanced.sanitize.text")}
                                    </p>
                                    <CodeHighlight languague="Perl">
                                        {
                                            'use NpsSDK::Nps;\n' +
                                            'use warnings;\n' +
                                            'use strict;\n' +
                                            '\n' +
                                            'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                            '                                 secret_key  => "_YOUR_SECRET_KEY_",\n' +
                                            '                                 sanitize    => 1);'
                                        }
                                    </CodeHighlight>
                                    <hr />
                                </div>
                                <div className="col-12">
                                    <h4 id="libraries.server.perl.configuration.advanced.timeout.title">
                                        {T.translate("libraries.server.perl.configuration.advanced.timeout.title")}
                                    </h4>
                                    <p id="libraries.server.perl.configuration.advanced.advanced.timeout.text">
                                        {T.translate("libraries.server.perl.configuration.advanced.timeout.text")}
                                    </p>
                                    <CodeHighlight languague="Perl">
                                        {
                                            'use NpsSDK::Nps;\n' +
                                            'use warnings;\n' +
                                            'use strict;\n' +
                                            '\n' +
                                            'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                            '                                 secret_key  => "_YOUR_SECRET_KEY_",\n' +
                                            '                                 timeout     => 60);'
                                        }
                                    </CodeHighlight>
                                    <hr />
                                </div>                                
                            </div>
                        </Accordion>
                        <hr />
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.perl.environments.title">
                            {T.translate("libraries.server.perl.environments.title")}
                        </h4>
                        <p id="libraries.server.perl.environments.text">
                            {T.translate("libraries.server.perl.environments.text")}
                        </p>
                        <CodeHighlight languague="Perl">
                            {
                                'use NpsSDK::Nps;\n' +
                                '$NpsSDK::Constants::PRODUCTION_ENV\n' +
                                '$NpsSDK::Constants::STAGING_ENV\n' +
                                '$NpsSDK::Constants::SANDBOX_ENV\n' 
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.perl.error.title">
                            {T.translate("libraries.server.perl.error.title")}
                        </h4>
                        <p id="libraries.server.perl.error.text">
                            {T.translate("libraries.server.perl.error.text")}
                        </p>
                        <div id="libraries.server.perl.error.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("libraries.server.perl.error.information")}
                        </div>
                        <CodeHighlight languague="Perl">
                            {
                                'use NpsSDK::Nps;\n' +
                                'use warnings;\n' +
                                'use strict;\n' +
                                '\n' +
                                'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                '                                 secret_key  => "_YOUR_SECRET_KEY_",\n' +
                                '                                 timeout     => 60);\n' +
                                '\n' +
                                'my $response = NpsSDK::Nps::pay_online_2p($params);\n' +
                                '\n' +
                                'if (ref($response) eq "NpsSDK::TimeoutException") {\n' +
                                '\t#Your code to handle the error\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <h4 id="libraries.server.perl.example.title">
                            {T.translate("libraries.server.perl.example.title")}
                        </h4>
                        <p id="libraries.server.perl.example.text">
                            {T.translate("libraries.server.perl.example.text")}
                        </p>
                        <CodeHighlight languague="Perl">
                            {
                                'use NpsSDK::Nps;\n' +
                                'use warnings;\n' +
                                'use strict;\n' +
                                '\n' +
                                'NpsSDK::Configuration::configure(environment => $NpsSDK::Constants::SANDBOX_ENV,\n' +
                                '                                 secret_key  => "_YOUR_SECRET_KEY_");\n' +
                                '\n' +
                                'my $params = {\n' +
                                '    \'psp_Version\' => \'2.2\',\n' +
                                '    \'psp_MerchantId\' => \'psp_test\',\n' +
                                '    \'psp_TxSource\' => \'WEB\',\n' +
                                '    \'psp_MerchTxRef\' => \'ORDER69461-3\',\n' +
                                '    \'psp_MerchOrderId\' => \'ORDER69461\',\n' +
                                '    \'psp_Amount\' => \'15050\',\n' +
                                '    \'psp_NumPayments\' => \'1\',\n' +
                                '    \'psp_Currency\' => \'032\',\n' +
                                '    \'psp_Country\' => \'ARG\',\n' +
                                '    \'psp_Product\' => \'14\',\n' +
                                '    \'psp_CardNumber\' => \'4507990000000010\',\n' +
                                '    \'psp_CardExpDate\' => \'1612\',\n' +
                                '    \'psp_PosDateTime\' => \'2016-12-01 12:00:00\',\n' +
                                '    \'psp_CardSecurityCode\' => \'123\'\n' +
                                '};\n' +
                                '\n' +
                                'my $response = NpsSDK::Nps::pay_online_2p($params);'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </div>
        );
    }
}

export default Perl;