import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/QueryCardNumber-request';
import response from '../../../documentation/documentation/QueryCardNumber-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class CardNumber extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            The availability of the method depends on an authorization of the processors
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='QueryCardNumber' />
                    </div>
                </div>
            </div>
        );
    }
}

export default CardNumber;