import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const Lua = () => {
    return (
        <div className="col-12">
            <div className="lua-library"/>
            <div className="row">
                <div className="col-12 col-md-2 text-center download">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-lua" className="get-started-in" target="_blank"  rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        Lua 5.1 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.lua.install">
                        {T.translate("libraries.server.lua.install")}
                    </h4>
                    <CodeHighlight languague="Lua">
                        {
                            'luarocks install npssdk'
                        }
                    </CodeHighlight>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.lua.configuration.title">
                        {T.translate("libraries.server.lua.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.lua.configuration.basic" open
                        title={T.translate("libraries.server.lua.configuration.basic")}>
                        <CodeHighlight languague="Lua">
                            {
                                'local nps = require("npssdk")\n' +
                                'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                                'nps.configuration.secret_key = "_YOUR_SECRET_KEY_"'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.lua.configuration.advanced.title" open
                        title={T.translate("libraries.server.lua.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <h4 id="libraries.server.lua.configuration.advanced.log.title">
                                    {T.translate("libraries.server.lua.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.lua.configuration.advanced.log.text">
                                    {T.translate("libraries.server.lua.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="Lua">
                                    {
                                        'local nps = require("npssdk")\n' +
                                        'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                                        'nps.configuration.secret_key = "_YOUR_SECRET_KEY_"\n' +
                                        'nps.configuration.debug = true'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.lua.configuration.advanced.log.file">
                                    {T.translate("libraries.server.lua.configuration.advanced.log.file")}
                                </p>
                                <CodeHighlight languague="Lua">
                                    {
                                        'local nps = require("npssdk")\n' +
                                        'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                                        'nps.configuration.secret_key = "_YOUR_SECRET_KEY_"\n' +
                                        'nps.configuration.debug = true\n' +
                                        'nps.configuration.log_file = "path/to/your/file.log"'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.lua.configuration.advanced.log.level">
                                    {T.translate("libraries.server.lua.configuration.advanced.log.level")}
                                </p>
                                <CodeHighlight languague="Lua">
                                    {
                                        'local nps = require("npssdk")\n' +
                                        'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                                        'nps.configuration.secret_key = "_YOUR_SECRET_KEY_"\n' +
                                        'nps.configuration.debug = true\n' +
                                        'nps.configuration.log_level = "DEBUG"'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.lua.configuration.advanced.sanitize.title">
                                    {T.translate("libraries.server.lua.configuration.advanced.sanitize.title")}
                                </h4>
                                <p id="libraries.server.lua.configuration.advanced.sanitize.text">
                                    {T.translate("libraries.server.lua.configuration.advanced.sanitize.text")}
                                </p>
                                <CodeHighlight languague="Lua">
                                    {
                                        'local nps = require("npssdk")\n' +
                                        'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                                        'nps.configuration.secret_key = "_YOUR_SECRET_KEY_"\n' +
                                        'nps.configuration.sanitize = true'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.lua.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.lua.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.lua.configuration.advanced.timeout.text">
                                    {T.translate("libraries.server.lua.configuration.advanced.timeout.text")}
                                </p>
                                <CodeHighlight languague="Lua">
                                    {
                                        'local nps = require("npssdk")\n' +
                                        'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                                        'nps.configuration.secret_key = "_YOUR_SECRET_KEY_"\n' +
                                        'nps.configuration.timeout = 60'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.lua.environments.title">
                        {T.translate("libraries.server.lua.environments.title")}
                    </h4>
                    <p id="libraries.server.lua.environments.text">
                        {T.translate("libraries.server.lua.environments.text")}
                    </p>
                    <CodeHighlight languague="Lua">
                        {
                            'local nps = require("npssdk")\n' +
                            'nps.configuration.environment = nps.SANDBOX_ENV\n' +
                            'nps.configuration.environment = nps.STAGING_ENV\n' +
                            'nps.configuration.environment = nps.PRODUCTION_ENV'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.lua.example.title">
                        {T.translate("libraries.server.lua.example.title")}
                    </h4>
                    <p id="libraries.server.lua.example.text">
                        {T.translate("libraries.server.lua.example.text")}
                    </p>
                    <CodeHighlight languague="Lua">
                        {
                            'local nps = require("npssdk")\n' +
                            '\n' +
                            'local p2p = {}\n' +
                            '\n' +
                            'p2p.psp_Version="2.2"\n' +
                            'p2p.psp_MerchantId="psp_test"\n' +
                            'p2p.psp_TxSource="WEB"\n' +
                            'p2p.psp_MerchTxRef=uuid()\n' +
                            'p2p.psp_MerchOrderId=uuid()\n' +
                            'p2p.psp_Amount="1000"\n' +
                            'p2p.psp_NumPayments="1"\n' +
                            'p2p.psp_Currency="032"\n' +
                            'p2p.psp_Product="14"\n' +
                            'p2p.psp_Country="ARG"\n' +
                            'p2p.psp_CardNumber="4242424242420010"\n' +
                            'p2p.psp_CardExpDate="1909"\n' +
                            'p2p.psp_CardSecurityCode="123"\n' +
                            'p2p.psp_CardHolderName="Gustavo Diaz"\n' +
                            'p2p.psp_PosDateTime="2017-01-12 13:05:00"\n' +
                            '\n' +
                            'resp = nps.payonline_2p(p2p)'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default Lua;