import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/CreatePaymentMethod-request';
import response from '../../../documentation/documentation/CreatePaymentMethod-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Create extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            Creates a re-usable Payment Method
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='CreatePaymentMethod' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Create;