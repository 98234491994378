import React from 'react';
import T from 'i18react';

import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import {apiReferenceURL} from "../../../../env";

const Configure = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.android.configure.title">
                 Step 3 - {T.translate("advancedCheckout.android.configure.title")}
            </h4>
            <p id="advancedCheckout.android.configure.text">
                {T.translate("advancedCheckout.android.configure.text")}
            </p>
            <CodeHighlight language="java">
                {
                    'Nps nps = new Nps(Nps.SANDBOX, "_YOUR_CLIENT_SESSION", "YOUR_MERCHANT_ID_");'
                }
            </CodeHighlight>
            <Accordion id="advancedCheckout.android.configure.client.title"
                open
                title={ "3.1 - " + T.translate("advancedCheckout.android.configure.client.title")}>
                <p id="advancedCheckout.android.configure.client.text">
                    {T.translate("advancedCheckout.android.configure.client.text",{"apiLink": <T.a href={ `${apiReferenceURL}/services/ClientSession` } target="_blank" text="CreateClientSession"/>})}
                </p>
            </Accordion>
            <Accordion id="advancedCheckout.android.configure.merchant.title"
                open
                title={ "3.2 - " + T.translate("advancedCheckout.android.configure.merchant.title")}>
                <p id="advancedCheckout.android.configure.merchant.text">
                    {T.translate("advancedCheckout.android.configure.merchant.text")}
                </p>
            </Accordion>
            <Accordion id="advancedCheckout.android.configure.fingerprint.title"
                open
                title={ "(Optional) - " + T.translate("advancedCheckout.android.configure.fingerprint.title")}>
                <p id="advancedCheckout.android.configure.fingerprint.text">
                    {T.translate("advancedCheckout.android.configure.fingerprint.text")}
                </p>
                <CodeHighlight language="java">
                    {
                        'Nps.getDeviceFingerprint(getApplicationContext());'
                    }
                </CodeHighlight>
                <div id="advancedCheckout.android.configure.fingerprint.information"
                     className="alert alert-warning" role="alert">
                    {T.translate("advancedCheckout.android.configure.fingerprint.information")}
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Configure;