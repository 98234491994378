import React from 'react';
import T from 'i18react';

import Approved from './Testing/Approved';
import Rejected from './Testing/Rejected';
import Retail from './Testing/Retail';
import {implementationtMail, merchantName, supportPhone} from "../../env";
// import lockIcon from '../../assets/img/lock-content.svg';

class Testing extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.scrollToElement = this.scrollToElement.bind(this);
        this.openLogin = this.openLogin.bind(this);
    }

    openLogin(){
        document.getElementById('loginClickOpen').click();
    }

    scrollToElement(id, time = 500){
        let self = this;
        let element = document.getElementById(id);
        let uniqueMainPanelId = document.getElementById('uniqueMainPanelId');
        let to = (element.offsetTop - 50);
        let difference = to - uniqueMainPanelId.scrollTop;
        let perTick = difference / time * 10;

        setTimeout(function() {
            uniqueMainPanelId.scrollTop = uniqueMainPanelId.scrollTop + perTick;
            if (uniqueMainPanelId.scrollTop === to) return;
            self.scrollToElement(id, time - 10);
        }, 10);
    }

    render() {
        return (
            <div className="container-fluid testing" id="thisIdIsUnique">
                <div className="row">
                    <div className="col-12">
                        <h3 id="testing.title">
                            {T.translate("testing.title")}
                        </h3>
                    </div>
                    <div className="col-12 col-md-6">
                        <h4>
                            {T.translate("testing.implementation.title")}
                        </h4>
                        <p>
                            {T.translate("testing.implementation.text")}
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <h4>
                            {T.translate("testing.implementation.sandbox.title")}
                        </h4>
                        <p>
                            {T.translate("testing.implementation.sandbox.text",{"whiteLabel": <T.text text={merchantName}/>})}
                        </p>
                        <ul>
                            <li>{T.translate("testing.implementation.sandbox.list.1")}</li>
                            <li>{T.translate("testing.implementation.sandbox.list.2")}</li>
                            <li>{T.translate("testing.implementation.sandbox.list.3")}</li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <div className="alert alert-info">
                            <strong>{T.translate("testing.implementation.availability.title")}</strong>
                            <br/>
                            {T.translate("testing.implementation.availability.text")}
                        </div>
                    </div>
                    <div className="col-12">
                        <h4>
                            {T.translate("testing.implementation.technical.title")}
                        </h4>
                        <p>
                            {T.translate("testing.implementation.technical.text")}
                        </p>
                        <ul>
                            <li>{T.translate("testing.implementation.technical.list.2",{"email": implementationtMail})}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>
                            {T.translate("testing.implementation.testing.title")}
                        </h3>
                        <p>
                            {T.translate("testing.implementation.testing.text")}
                        </p>
                        <p>
                            {T.translate("testing.implementation.testing.listApprovedText")}
                        </p>
                        <ul>
                            <li>{T.translate("testing.implementation.testing.listApproved.1")}</li>
                            <li>{T.translate("testing.implementation.testing.listApproved.2")}</li>
                            <li>{T.translate("testing.implementation.testing.listApproved.3")}</li>
                        </ul>
                        <p>
                            {T.translate("testing.implementation.testing.listCVCText")}
                        </p>
                        <ul>
                            <li>{T.translate("testing.implementation.testing.listCVC.1")}</li>
                            <li>{T.translate("testing.implementation.testing.listCVC.2")}</li>
                            <li>{T.translate("testing.implementation.testing.listCVC.3")}</li>
                        </ul>
                    <br/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <h4>
                            Internal Testing Environments
                        </h4>
                        <br/>
                        <div className="col-12 buttons-transactions">
                            <button type="button"
                                    onClick={() => this.scrollToElement('testing.transactions.approved')}
                                    className="btn btn-info">
                                Approved
                            </button>
                            <button type="button"
                                    onClick={() => this.scrollToElement('testing.transactions.rejected')}
                                    className="btn btn-info">
                                Rejected
                            </button>
                            <button type="button"
                                    onClick={() => this.scrollToElement('testing.transactions.retail')}
                                    className="btn btn-info">
                                Risk Assessment
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <h4>
                            External Testing Environments
                        </h4>
                        <br/>
                        <div className="col-12 text-center">
                            <button type="button"
                                    onClick={() => this.scrollToElement('testing.transactions.external')}
                                    className="btn btn-info">
                                Go!
                            </button>
                        </div>
                    </div>
                </div>


                <div className="row" id="testing.transactions.approved">
                    <div className="col-12 table-overflow-x">
                        <hr/>
                        <h3>
                            {T.translate("testing.implementation.testing.approved.title")}
                        </h3>
                        <p>
                            {T.translate("testing.implementation.testing.approved.text")}
                        </p>
                        <Approved/>
                    </div>
                </div>
                <div className="row" id="testing.transactions.rejected">
                    <div className="col-12 table-overflow-x">
                        <hr/>
                        <h3>
                            {T.translate("testing.implementation.testing.rejected.title")}
                        </h3>
                        {T.translate("testing.implementation.testing.rejected.text")}
                        <Rejected/>
                    </div>
                </div>
                <div className="row" id="testing.transactions.retail">
                    <div className="col-12 table-overflow-x">
                        <Retail/>
                    </div>
                </div>

                {/*<div className="row">*/}
                    {/*<div className="col-12">*/}
                        {/*<br/>*/}
                        {/*<div className="alert alert-danger" id="testing.transactions.external">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-10">*/}
                                    {/*<h3 id="guideReference.services.3dSecure.locked.title">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p id="guideReference.services.3dSecure.locked.text" onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default Testing;