import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Tokenization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.javascript.tokenization.title">
                Step 3 - {T.translate("advancedCheckout.javascript.tokenization.title")}
            </h4>
            <p id="advancedCheckout.javascript.tokenization.text">
                {T.translate("advancedCheckout.javascript.tokenization.text")}
            </p>
            <Accordion id="advancedCheckout.javascript.tokenization.create.title" open
                title={ "3.1 - " + T.translate("advancedCheckout.javascript.tokenization.create.title")}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <p id="advancedCheckout.javascript.tokenization.create.simple">
                            {T.translate("advancedCheckout.javascript.tokenization.create.simple")}
                        </p>
                        <CodeHighlight language="JSON">
                            {
                                'PaymentMethodTokenParams = {\n' +
                                '      card: {\n' +
                                '        holder_name: "John Smith",\n' +
                                '        number: "4507990000000010",\n' +
                                '        exp_month: "01",\n' +
                                '        exp_year: "2019",\n' +
                                '        security_code: "123",\n' +
                                '      }, \n' +
                                '      billing: { // optional\n' +
                                '        person: { // optional\n' +
                                '          firstname: "John",  // mandatory\n' +
                                '          middlename: "Jay", // optional\n' +
                                '          lastname: "Smith", // optional\n' +
                                '          phonenumber1: "4123-1234", // optional\n' +
                                '          phonenumber2: "4123-1234", // optional\n' +
                                '          gender: "M", // optional\n' +
                                '          birthday: "1987-01-01", // optional\n' +
                                '          nationality: "ARG", // optional\n' +
                                '          idtype: "500", // optional\n' +
                                '          idnumber: "500" // optional\n' +
                                '        },\n' +
                                '        address: { // optional\n' +
                                '          street: "Fakestreet", // mandatory\n' +
                                '          housenumber: "999", // mandatory\n' +
                                '          city: "Fakecity", // mandatory\n' +
                                '          country: "ARG", // mandatory\n' +
                                '          zip: "1234", // optional\n' +
                                '          state: "Fakestate", // optional\n' +
                                '          addinfo: "Fakeinfo", // optional\n' +
                                '      }\n' +
                                '   }\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12 col-md-6">
                        <p id="advancedCheckout.javascript.tokenization.create.multiple">
                            {T.translate("advancedCheckout.javascript.tokenization.create.multiple")}
                        </p>
                        <CodeHighlight language="JSON">
                            {
                                'PaymentMethodTokenParams = {\n' +
                                '       cards: [\n' +
                                '           {\n' +
                                '               holder_name: "John Smith",\n' +
                                '               number: "4507990000000010",\n' +
                                '               exp_month: "01",\n' +
                                '               exp_year: "2019",\n' +
                                '               security_code: "123",\n' +
                                '           },\n' +
                                '           {\n' +
                                '               holder_name: "John Smith",\n' +
                                '               number: "5323601111111112",\n' +
                                '               exp_month: "01",\n' +
                                '               exp_year: "2019",\n' +
                                '               security_code: "123",          \n' +
                                '           }\n' +
                                '      ],\n' +
                                '      billing: { // optional\n' +
                                '        person: { // optional\n' +
                                '          firstname: "John",  // mandatory\n' +
                                '          middlename: "Jay", // optional\n' +
                                '          lastname: "Smith", // optional\n' +
                                '          phonenumber1: "4123-1234", // optional\n' +
                                '          phonenumber2: "4123-1234", // optional\n' +
                                '          gender: "M", // optional\n' +
                                '          birthday: "1987-01-01", // optional\n' +
                                '          nationality: "ARG", // optional\n' +
                                '          idtype: "500", // optional\n' +
                                '          idnumber: "500" // optional\n' +
                                '        },\n' +
                                '        address: { // optional\n' +
                                '          street: "Fakestreet", // mandatory\n' +
                                '          housenumber: "999", // mandatory\n' +
                                '          city: "Fakecity", // mandatory\n' +
                                '          country: "ARG", // mandatory\n' +
                                '          zip: "1234", // optional\n' +
                                '          state: "Fakestate", // optional\n' +
                                '          addinfo: "Fakeinfo", // optional\n' +
                                '      }\n' +
                                '   }\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <div id="advancedCheckout.javascript.tokenization.create.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.javascript.tokenization.create.information")}
                        </div>
                        <p id="advancedCheckout.javascript.tokenization.create.make">
                            {T.translate("advancedCheckout.javascript.tokenization.create.make")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'window.onload = function() {\n' +
                                '  document.getElementById("payment-method-form").onsubmit = function(event) {\n' +
                                '    var paymentMethodForm;\n' +
                                '    paymentMethodForm = this;\n' +
                                '\n' +
                                '    // Void submition more than once' +
                                '\n' +
                                '    document.getElementById("payment-method-form-submit").setAttribute("disabled","disabled");\n' +
                                '\n' +
                                '    // Request a token from NPS:' +
                                '\n' +
                                '    NPS.paymentMethodToken.create(paymentMethodForm, npsSuccessResponseHandler, npsErrorResponseHandler);\n' +
                                '\n' +
                                '    // Only submit when token is already loaded' +
                                '\n' +
                                '    return false;\n' +
                                '  };\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.tokenization.create.response.text">
                            {T.translate("advancedCheckout.javascript.tokenization.create.response.text")}
                        </p>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p id="advancedCheckout.javascript.tokenization.create.response.simple">
                                    {T.translate("advancedCheckout.javascript.tokenization.create.response.simple")}
                                </p>
                                <CodeHighlight language="javascript">
                                    {
                                        'var npsSuccessResponseHandler;\n' +
                                        'npsSuccessResponseHandler = function(paymentMethodToken) {\n' +
                                        '  var paymentMethodForm, npsPaymentMethodTokenId;\n' +
                                        '  paymentMethodForm = document.getElementById("payment-method-form");\n' +
                                        '\n' +
                                        '  // Append token_id in a way that allow the server to recieve the token by post\n' +
                                        '  npsPaymentMethodTokenId = document.createElement("input"); \n' +
                                        '  npsPaymentMethodTokenId.setAttribute("type", "hidden");\n' +
                                        '  npsPaymentMethodTokenId.setAttribute("name", "npsPaymentMethodTokenId");\n' +
                                        '  npsPaymentMethodTokenId.setAttribute("id", "npsPaymentMethodTokenId");\n' +
                                        '  npsPaymentMethodTokenId.setAttribute("value", paymentMethodToken.id);\n' +
                                        '  paymentMethodForm.insertBefore(npsPaymentMethodTokenId,paymentMethodForm.firstChild);\n' +
                                        '\n' +
                                        '  // And submit\n' +
                                        '  paymentMethodForm.submit();\n' +
                                        '};'
                                    }
                                </CodeHighlight>
                            </div>
                            <div className="col-12 col-md-6">
                                <p id="advancedCheckout.javascript.tokenization.create.response.multiple">
                                    {T.translate("advancedCheckout.javascript.tokenization.create.response.multiple")}
                                </p>
                                <CodeHighlight language="javascript">
                                    {
                                        'var npsSuccessResponseHandler;\n' +
                                        'npsSuccessResponseHandler = function(paymentMethodToken) {\n' +
                                        '  var paymentMethodForm, npsPaymentMethodTokenId;\n' +
                                        '  paymentMethodForm = document.getElementById("payment-method-form");\n' +
                                        '\n' +
                                        '  // Append token_id in a way that allow the server to recieve the token by post\n' +
                                        '  if( Object.prototype.toString.call( paymentMethodToken ) === \'[object Array]\' ) {\n' +
                                        '    for(i in paymentMethodToken) {\n' +
                                        '      npsPaymentMethodTokenId = document.createElement("input"); \n' +
                                        '      npsPaymentMethodTokenId.setAttribute("type", "hidden");\n' +
                                        '      npsPaymentMethodTokenId.setAttribute("name", "npsPaymentMethodTokenId[]");\n' +
                                        '      npsPaymentMethodTokenId.setAttribute("id", "npsPaymentMethodTokenId_"+i);\n' +
                                        '      npsPaymentMethodTokenId.setAttribute("value", paymentMethodToken[i].id);\n' +
                                        '      paymentMethodForm.insertBefore(npsPaymentMethodTokenId,paymentMethodForm.firstChild);         \n' +
                                        '    }\n' +
                                        '  }\n' +
                                        '\n' +
                                        '  // And submit\n' +
                                        '  paymentMethodForm.submit();\n' +
                                        '};'
                                    }
                                </CodeHighlight>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.tokenization.create.error.text">
                            {T.translate("advancedCheckout.javascript.tokenization.create.error.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'var npsErrorResponseHandler;\n' +
                                'npsErrorResponseHandler = function(response) {\n' +
                                '  // To retrieve errors on create token\n' +
                                '  console.log(response.message);\n' +
                                '  document.getElementById("payment-method-errors").innerHTML = response.message_to_purchaser;\n' +
                                '  document.getElementById("payment-method-form-submit").removeAttribute("disabled");\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.tokenization.paymentMethod.title" open
                title={ "3.2 - " + T.translate("advancedCheckout.javascript.tokenization.paymentMethod.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.tokenization.paymentMethod.text">
                            {T.translate("advancedCheckout.javascript.tokenization.paymentMethod.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="javascript">
                            {
                                'PaymentMethodTokenParams = {\n' +
                                '      card: {\n' +
                                '        payment_method_id: \'gXnsNd7hhfIzAd9PkRov1MJeEVGlSKe7\',\n' +
                                '        security_code: \'123\',\n' +
                                '      }, \n' +
                                '      billing: { // optional\n' +
                                '        person: { // optional\n' +
                                '          firstname: \'John\',  // mandatory\n' +
                                '          middlename: \'Jay\', // optional\n' +
                                '          lastname: \'Smith\', // optional\n' +
                                '          phonenumber1: \'4123-1234\', // optional\n' +
                                '          phonenumber2: \'4123-1234\', // optional\n' +
                                '          gender: \'M\', // optional\n' +
                                '          birthday: \'1987-01-01\', // optional\n' +
                                '          nationality: \'ARG\', // optional\n' +
                                '          idtype: \'500\', // optional\n' +
                                '          idnumber: \'500\' // optional\n' +
                                '        },\n' +
                                '        address: { // optional\n' +
                                '          street: \'Fakestreet\', // mandatory\n' +
                                '          housenumber: \'999\', // mandatory\n' +
                                '          city: \'Fakecity\', // mandatory\n' +
                                '          country: \'ARG\', // mandatory\n' +
                                '          zip: \'1234\', // optional\n' +
                                '          state: \'Fakestate\', // optional\n' +
                                '          addinfo: \'Fakeinfo\', // optional\n' +
                                '      }\n' +
                                '   }\n' +
                                '}\n' +
                                '\n'+
                                'NPS.paymentMethodToken.recache(PaymentMethodTokenParams, npsSuccessResponseHandler,npsErrorResponseHandler);'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.javascript.tokenization.paymentMethod.information"
                            className="alert alert-info"
                             role="alert">
                            {T.translate("advancedCheckout.javascript.tokenization.paymentMethod.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.tokenization.retrieve.title" open
                title={ "3.3- " + T.translate("advancedCheckout.javascript.tokenization.retrieve.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.tokenization.retrieve.text">
                            {T.translate("advancedCheckout.javascript.tokenization.retrieve.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="javascript">
                            {
                                'NPS.paymentMethodToken.retrieve(paymentMethodTokenId, npsSuccessResponseHandler,npsErrorResponseHandler);'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.javascript.tokenization.retrieve.information"
                            className="alert alert-info"
                             role="alert">
                            {T.translate("advancedCheckout.javascript.tokenization.retrieve.information")}
                        </div>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.tokenization.retrieve.response.text">
                            {T.translate("advancedCheckout.javascript.tokenization.retrieve.response.text")}
                        </p>
                        <div className="row">
                            <div className="col-12">
                                <p id="advancedCheckout.javascript.tokenization.retrieve.response.simple">
                                    {T.translate("advancedCheckout.javascript.tokenization.retrieve.response.simple")}
                                </p>
                                <CodeHighlight language="javascript">
                                    {
                                        'var npsSuccessResponseHandler;\n' +
                                        'npsSuccessResponseHandler = function(paymentMethodToken) {\n' +
                                        'console.log(paymentMethodToken.id);\n' + 
                                        '};'
                                    }
                                </CodeHighlight>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.tokenization.create.error.text">
                            {T.translate("advancedCheckout.javascript.tokenization.create.error.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'var npsErrorResponseHandler;\n' +
                                'npsErrorResponseHandler = function(response) {\n' +
                                '  // List errors on token retrieve\n' +
                                '  console.log(response.message);\n' +
                                '  document.getElementById("payment-method-errors").innerHTML = response.message_to_purchaser;\n' +
                                '  document.getElementById("payment-method-form-submit").removeAttribute("disabled");\n' +
                                '};'
                            }
                        </CodeHighlight>
                    </div>                    
                </div>
            </Accordion>            
            <hr/>
        </div>
    );
};

export default Tokenization;