import React from 'react';
import vtexLogo from '../../assets/img/thirdParty/hands.png';

class ThirdParty extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showModal: false
        };
        this.contactModalClickOutside = this.contactModalClickOutside.bind(this);
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.contactModalClickOutside);
        document.addEventListener('touchend', this.contactModalClickOutside);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.contactModalClickOutside);
        document.removeEventListener('touchend', this.contactModalClickOutside);
    }

    contactModalClickOutside(event) {
        if (this.state.showModal && (this.modalRef && !this.modalRef.contains(event.target))) {
            this.setState({
                showModal: false
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        return (
            <div className="container-fluid third-party">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 img-card-icon"
                                         onClick={() => this.setState({showModal: true})}>
                                        <img src={vtexLogo} alt="Vtex"/>
                                    </div>
                                    <div className="col-12 card-content">
                                        <h5 className="card-title">Title</h5>
                                        <hr/>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 img-card-icon"
                                         onClick={() => this.setState({showModal: true})}>
                                        <img src={vtexLogo} alt="Vtex"/>
                                    </div>
                                    <div className="col-12 card-content">
                                        <h5 className="card-title">Title</h5>
                                        <hr/>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 img-card-icon"
                                         onClick={() => this.setState({showModal: true})}>
                                        <img src={vtexLogo} alt="Vtex"/>
                                    </div>
                                    <div className="col-12 card-content">
                                        <h5 className="card-title">Title</h5>
                                        <hr/>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 img-card-icon"
                                         onClick={() => this.setState({showModal: true})}>
                                        <img src={vtexLogo} alt="Vtex"/>
                                    </div>
                                    <div className="col-12 card-content">
                                        <h5 className="card-title">Title</h5>
                                        <hr/>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 img-card-icon"
                                         onClick={() => this.setState({showModal: true})}>
                                        <img src={vtexLogo} alt="Vtex"/>
                                    </div>
                                    <div className="col-12 card-content">
                                        <h5 className="card-title">Title</h5>
                                        <hr/>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 img-card-icon"
                                         onClick={() => this.setState({showModal: true})}>
                                        <img src={vtexLogo} alt="Vtex"/>
                                    </div>
                                    <div className="col-12 card-content">
                                        <h5 className="card-title">Title</h5>
                                        <hr/>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={this.state.showModal ? 'modal fade show' : 'modal fade'} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content"
                             ref={(node) => this.modalRef = node}>
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({showModal: false})}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4>Title</h4>
                                <div className="head-modal-content">
                                    <img src={vtexLogo} alt="Vtex"/>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised in
                                        the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                        and more recently with desktop publishing software like Aldus PageMaker
                                        including versions of Lorem Ipsum
                                    </p>
                                </div>
                                <div className="modal-contact">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Contact</h5>
                                            <div className="icon">
                                                <i className="fa fa-address-card"/>
                                            </div>
                                            <div className="content">
                                                <span className="name">John Doe</span> <br/>
                                                <span className="email">johndoe@company.com</span> <br/>
                                                <span className="phone">+1 123 4567 8901</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-certifications">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Certifications</h5>
                                            <div className="icon">
                                                <i className="fa fa-certificate"/>
                                            </div>
                                            <div className="content">
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                                                    <li>Aliquam tincidunt mauris eu risus.</li>
                                                    <li>Vestibulum auctor dapibus neque.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-industries">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Industries</h5>
                                            <div className="icon">
                                                <i className="fa fa-suitcase"/>
                                            </div>
                                            <div className="content">
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                                                    <li>Aliquam tincidunt mauris eu risus.</li>
                                                    <li>Vestibulum auctor dapibus neque.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-cases">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Success Cases</h5>
                                            <div className="icon">
                                                <i className="fa fa-commenting"/>
                                            </div>
                                            <div className="content">
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                                                    <li>Aliquam tincidunt mauris eu risus.</li>
                                                    <li>Vestibulum auctor dapibus neque.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.showModal ? 'modal-backdrop fade show' : 'modal-backdrop fade'}/>
            </div>
        );
    }
}

export default ThirdParty;