import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Installments = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.javascript.installments.title">
                Step 7 - {T.translate("advancedCheckout.javascript.installments.title")} (Optional)
            </h4>
            <p id="advancedCheckout.javascript.installments.text">
                {T.translate("advancedCheckout.javascript.installments.text")}
            </p>
            <Accordion id="advancedCheckout.javascript.installments.amount.title" open
                title={ "7.1 - " + T.translate("advancedCheckout.javascript.installments.amount.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.installments.amount.text">
                            {T.translate("advancedCheckout.javascript.installments.amount.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'NPS.setAmount(\'120000\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.installments.country.title" open
                title={ "7.2 - " + T.translate("advancedCheckout.javascript.installments.country.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.installments.country.text">
                            {T.translate("advancedCheckout.javascript.installments.country.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'NPS.setCountry(\'CHL\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.installments.currency.title" open
                title={ "7.3 - " + T.translate("advancedCheckout.javascript.installments.currency.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.installments.currency.text">
                            {T.translate("advancedCheckout.javascript.installments.currency.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'NPS.setCurrency(\'152\');'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.installments.form.title" open
                title={ "7.4 - " + T.translate("advancedCheckout.javascript.installments.form.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.installments.form.text">
                            {T.translate("advancedCheckout.javascript.installments.form.text")}
                        </p>
                        <CodeHighlight language="HTML">
                            {
                                '<select name="card[installments]" id="payment-method-card-installments" data-nps="card[installments]">\n' +
                                '  <option value="">Choose an option</option>\n' +
                                '  <option value="1">1</option>\n' +
                                '  <option value="2">2</option>\n' +
                                '  <option value="3">3</option>\n' +
                                '  <option value="4">4</option>\n' +
                                '  <option value="5">5</option>\n' +
                                '  <option value="6">6</option>\n' +
                                '</select>\n' +
                                '<span id="payment-method-installments-description"></span>'
                            }
                        </CodeHighlight>
                        <p id="advancedCheckout.javascript.installments.refactor.cards.text">
                            {T.translate("advancedCheckout.javascript.installments.refactor.onsplit")}
                        </p>                        
                        <CodeHighlight language="HTML">
                            {
                                '<select name="cards[0][installments]" id="payment-method-card-installments" data-nps="cards[0][installments]">\n' +
                                '  <option value="">Choose an option</option>\n' +
                                '  <option value="1">1</option>\n' +
                                '  <option value="2">2</option>\n' +
                                '  <option value="3">3</option>\n' +
                                '  <option value="4">4</option>\n' +
                                '  <option value="5">5</option>\n' +
                                '  <option value="6">6</option>\n' +
                                '</select>\n' +
                                '<span id="payment-method-installments-description-0"></span>'
                            }
                        </CodeHighlight>                        
                        <div id="advancedCheckout.javascript.installments.form.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.javascript.installments.form.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.installments.request.title" open
                title={ "7.5 - " + T.translate("advancedCheckout.javascript.installments.request.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.installments.request.text">
                            {T.translate("advancedCheckout.javascript.installments.request.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'NPS.setClientSession(\'__YOUR_NPS_CLIENT_SESSION__\');\n' +
                                'NPS.setMerchantId(\'__YOUR_NPS_MERCHANT_ID__\');\n' +
                                'NPS.setAmount(\'120000\');\n' +
                                'NPS.setCountry(\'CHL\');\n' +
                                'NPS.setCurrency(\'152\');\n' +
                                '\n' +
                                'var psp_PaymentMethodToken = \'achDZFSa36MDdIkV6qIvExllzZvbguNi\';\n' +
                                'var psp_Product = \'14\';\n' +
                                'var psp_NumPayments=\'3\';\n' +
                                'NPS.card.getInstallmentsOptions(psp_PaymentMethodToken, psp_Product, psp_NumPayments);\n' +
                                '\n' +
                                '/**\n' +
                                '  * Use the same token to retrieve all the installments information that you are in need\n' +
                                ' */\n' +
                                '\n' +
                                'var psp_NumPayments=\'6\';\n' +
                                'NPS.card.getInstallmentsOptions(psp_PaymentMethodToken, psp_Product, psp_NumPayments);\n' +
                                '\n' +
                                'var psp_NumPayments=\'12\';\n' +
                                'NPS.card.getInstallmentsOptions(psp_PaymentMethodToken, psp_Product, psp_NumPayments);\n' +
                                '\n' +
                                'var psp_NumPayments=\'18\';\n' +
                                'NPS.card.getInstallmentsOptions(psp_PaymentMethodToken, psp_Product, psp_NumPayments);'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.installments.refactor.title" open
                title={ "7.6 - " + T.translate("advancedCheckout.javascript.installments.refactor.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.installments.refactor.text">
                            {T.translate("advancedCheckout.javascript.installments.refactor.text")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'var npsSuccessResponseHandler;\n' +
                                'npsSuccessResponseHandler = function(paymentMethodToken) {\n' +
                                '  var paymentMethodForm, npsPaymentMethodTokenId;\n' +
                                '  paymentMethodForm = document.getElementById("payment-method-form");\n' +
                                '\n' +
                                '  // Append token_id in a way that allow the server to recieve the token by post\n' +
                                '  npsPaymentMethodTokenId = document.createElement("input"); \n' +
                                '  npsPaymentMethodTokenId.setAttribute("type", "hidden");\n' +
                                '  npsPaymentMethodTokenId.setAttribute("name", "npsPaymentMethodTokenId");\n' +
                                '  npsPaymentMethodTokenId.setAttribute("id", "npsPaymentMethodTokenId");\n' +
                                '  npsPaymentMethodTokenId.setAttribute("value", paymentMethodToken.id);\n' +
                                '  paymentMethodForm.insertBefore(npsPaymentMethodTokenId,paymentMethodForm.firstChild);\n' +
                                '\n' +
                                '  // Look for installments options\n' +
                                '  if(paymentMethodToken.installments_options) {\n' +
                                '    npsShowInstallmentsDetails(paymentMethodToken.installments_options);\n' +
                                '\n' +
                                '    // Only submit when installments is already confirmed\n' +
                                '    return false;\n' +
                                '  }\n' +
                                '\n' +
                                '  // And submit\n' +
                                '  paymentMethodForm.submit();\n' +
                                '};\n' +
                                '\n' +
                                'var npsShowInstallmentsDetails;\n' +
                                'npsShowInstallmentsDetails = function(installmentsOptions) {\n' +
                                '  if(installmentsOptions && installmentsOptions[0]) {\n' +
                                '    var num =(installmentsOptions[0].installment_amount / 100).toLocaleString((null, {style:"currency", currency:null}));\n' +
                                '    document.getElementById("payment-method-installments-description").innerHTML = "make "+installmentsOptions[0].num_payments+" monthly payments of "+num;\n' +
                                '    // e.g: make 12 monthly payments of 12312313132\n' +
                                '\n' +
                                '    document.getElementById("payment-method-form-submit").removeAttribute("disabled");\n' +
                                '  }  \n' +
                                '}'
                            }
                        </CodeHighlight>
                        <p id="advancedCheckout.javascript.installments.refactor.onsplit">
                            {T.translate("advancedCheckout.javascript.installments.npsSuccessResponseHandler.onsplit")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'var npsSuccessResponseHandler;\n' +
                                'npsSuccessResponseHandler = function(paymentMethodToken) {\n' +
                                '  var paymentMethodForm, npsPaymentMethodTokenId;\n' +
                                '  isReady4Submit = true;' + 
                                '\n' +
                                '  paymentMethodForm = document.getElementById("payment-method-form");\n' +
                                '\n' +
                                '  // Append token_id in a way that allow the server to recieve the token by post\n' +
                                '  if( Object.prototype.toString.call( paymentMethodToken ) === \'[object Array]\' ) {\n' +
                                '    for(i in paymentMethodToken) {\n' +
                                '      npsPaymentMethodTokenId = document.createElement("input"); \n' +
                                '      npsPaymentMethodTokenId.setAttribute("type", "hidden");\n' +
                                '      npsPaymentMethodTokenId.setAttribute("name", "npsPaymentMethodTokenId[]");\n' +
                                '      npsPaymentMethodTokenId.setAttribute("id", "npsPaymentMethodTokenId_"+i);\n' +
                                '      npsPaymentMethodTokenId.setAttribute("value", paymentMethodToken[i].id);\n' +
                                '      paymentMethodForm.insertBefore(npsPaymentMethodTokenId,paymentMethodForm.firstChild);\n' +
                                '\n' +
                                '      // Look for installments options\n' +
                                '      if(paymentMethodToken.installments_options) {\n' +
                                '        npsShowInstallmentsDetails(paymentMethodToken.installments_options, i);\n' +
                                '        isReady4Submit = false;\n' +
                                '      }\n' +
                                '    }\n' +
                                '\n' +
                                '    if(!isReady4Submit) {\n' +
                                '      // Only submit when installments is already confirmed\n' +
                                '      return false;\n' +
                                '    }\n' +
                                '  }\n' +                                
                                '  // And submit\n' +
                                '  paymentMethodForm.submit();\n' +
                                '};\n' +
                                '\n' +
                                'var npsShowInstallmentsDetails;\n' +
                                'npsShowInstallmentsDetails = function(installmentsOptions, cardIndex) {\n' +
                                '  var customSelector, customSelector, num;\n'+
                                '  customSelector = !isNaN(cardIndex) ? "payment-method-installments-description-"+cardIndex : "payment-method-installments-description";\n' + 
                                '  if(installmentsOptions && installmentsOptions[0]) {\n' +
                                '    var num =(installmentsOptions[0].installmentAmount / 100).toLocaleString((null, {style:"currency", currency:null}));\n' +
                                '    document.getElementById(customSelector).innerHTML = "make "+installmentsOptions[0].numPayments+" monthly payments of "+num;\n' +
                                '    // e.g: make 12 monthly payments of 12312313132\n' +
                                '\n' +
                                '    document.getElementById("payment-method-form-submit").removeAttribute("disabled");\n' +
                                '  }  \n' +
                                '}'
                            }
                        </CodeHighlight>                        
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

export default Installments;