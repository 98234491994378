import React from 'react';
import T from 'i18react';

import CodeHighlight from '../../../components/CodeHighlight';
// import lockIcon from '../../../assets/img/lock-content.svg';
import arFlag from '../../../assets/img/flags/flags-iso/flat/64/AR.png';
import brFlag from '../../../assets/img/flags/flags-iso/flat/64/BR.png';
import coFlag from '../../../assets/img/flags/flags-iso/flat/64/CO.png';
import mxFlag from '../../../assets/img/flags/flags-iso/flat/64/MX.png';
import peFlag from '../../../assets/img/flags/flags-iso/flat/64/PE.png';
import uyFlag from '../../../assets/img/flags/flags-iso/flat/64/UY.png';
import clFlag from '../../../assets/img/flags/flags-iso/flat/64/CL.png';

class Installments extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.openLogin = this.openLogin.bind(this);
    }

    openLogin(){
        document.getElementById('loginClickOpen').click();
    }

    render() {
        return (
            <div className="container-fluid fraud-screening">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.installments.title">
                            { T.translate("guideReference.services.installments.title") }
                        </h3>
                        <br/>
                    </div>
                    <div className="col-12">
                        <h4 id="guideReference.services.installments.availability.title">
                            { T.translate("guideReference.services.installments.availability.title") }
                        </h4>
                        <div className="availability-flags">
                            <div className="flag-container">
                                <img src={arFlag} alt="Argentina" title="Argentina" className="image"/>
                                <div className="overlay">
                                    <div className="text">Argentina</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={brFlag} alt="Brasil" title="Brasil" className="image"/>
                                <div className="overlay">
                                    <div className="text">Brasil</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={clFlag} alt="Chile" title="Chile" className="image"/>
                                <div className="overlay">
                                    <div className="text">Chile</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={coFlag} alt="Colombia" title="Colombia" className="image"/>
                                <div className="overlay">
                                    <div className="text">Colombia</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={mxFlag} alt="Mexico" title="Mexico" className="image"/>
                                <div className="overlay">
                                    <div className="text">Mexico</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={peFlag} alt="Peru" title="Peru" className="image"/>
                                <div className="overlay">
                                    <div className="text">Peru</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={uyFlag} alt="Uruguay" title="Uruguay" className="image"/>
                                <div className="overlay">
                                    <div className="text">Uruguay</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                    <div className="col-12">
                        <h4 id="guideReference.services.installments.plans.merchant">
                            {T.translate("guideReference.services.installments.plans.merchant.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.installments.plans.merchant.text")}
                        </p>
                        <br/>
                        <h4 id="guideReference.services.installments.plans.merchant">
                            {T.translate("guideReference.services.installments.plans.issuer.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.installments.plans.issuer.text")}
                        </p>
                        <br/>
                        <h4 id="guideReference.services.installments.plans.merchant">
                            {T.translate("guideReference.services.installments.plans.debit.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.installments.plans.debit.text")}
                        </p>
                        <br/>
                        <h4 id="guideReference.services.installments.plans.merchant">
                            {T.translate("guideReference.services.installments.plans.naranja.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.installments.plans.naranja.text")}
                        </p>
                        <br/>
                        <h4 id="guideReference.services.installments.plans.merchant">
                            {T.translate("guideReference.services.installments.plans.nevaplan.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.installments.plans.nevaplan.text")}
                        </p>
                    </div>
                    <div className="col-12 table-overflow-x">
                        <br/>
                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5">
                                    <p><strong>Installment Plans </strong>(request)</p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    <p>In case you want to implement this function, only the following fields have to be completed.</p></td>
                            </tr>
                            <tr>
                                <td>Field</td>
                                <td>Type</td>
                                <td>Data Type</td>
                                <td>Length <br/>
                                    [Min,Max]</td>
                                <td>Example of field content</td>
                            </tr>
                            <tr>
                                <td>psp_Plan</td>
                                <td>O</td>
                                <td>Alphanumeric</td>
                                <td>1,10</td>
                                <td>CC</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <br/>

                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Plan": "CC", \n'+
                                '   ...'
                            }
                        </CodeHighlight>

                        <br/>
                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5">
                                    <p><strong>Installment Plans </strong>(response)</p>
                                    <table style={{margin: '0 auto'}}>
                                        <tbody>
                                        <tr>
                                            <td>PayOnline_2p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>Authorize_2p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>PayOnline_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>Authorize_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>SplitPayOnLine_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>Field</td>
                                <td>Type</td>
                                <td>Data Type</td>
                                <td>Length <br/>
                                    [Min,Max]</td>
                                <td>Example of field content</td>
                            </tr>
                            <tr>
                                <td>psp_Plan</td>
                                <td>E</td>
                                <td>Alphanumeric</td>
                                <td>1,10</td>
                                <td>CC</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <br/>


                        <table className="table table-documentation table-sm">
                            <tbody>
                            <tr>
                                <td><strong>Identifier</strong></td>
                                <td><strong>Description</strong></td>
                            </tr>
                            <tr>
                                <td>CC</td>
                                <td>Merchant Installments</td>
                            </tr>
                            <tr>
                                <td>CA</td>
                                <td>Issuer Installments</td>
                            </tr>
                            <tr>
                                <td>DEBIT</td>
                                <td>Debit</td>
                            </tr>
                            <tr>
                                <td>Nevaplan</td>
                                <td>Nevaplan - Tarjeta Nevada</td>
                            </tr>
                            <tr>
                                <td>PlanZ</td>
                                <td>PlanZ - Tarjeta Naranja</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*<div className="col-12">*/}
                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                    <br/>
                        <h4 id="guideReference.services.installments.plans.merchant">
                            {T.translate("guideReference.services.installments.plans.deferral.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.services.installments.plans.deferral.text")}
                        </p>


                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5">
                                    <p><strong>Deferal of the firtst installment </strong>(request)</p>
                                    <table style={{margin: '0 auto'}}>
                                        <tbody>
                                        <tr>
                                            <td>PayOnline_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>Authorize_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                            <td>SplitPayOnLine_3p</td>
                                            <td><i className="fa fa-check-circle"/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    In case you want to implement this functionality, only the following fields must be completed.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Field
                                </td>
                                <td>
                                    Type
                                </td>
                                <td>
                                    Data Type
                                </td>
                                <td>
                                    Length <br/>
                                    [Min,Max]
                                </td>
                                <td>
                                    Example of field content
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    psp_FirstPaymentDeferral
                                </td>
                                <td>
                                    O
                                </td>
                                <td>
                                    Numeric
                                </td>
                                <td>
                                    1,1
                                </td>
                                <td>
                                    3
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_FirstPaymentDeferral": "3", \n'+
                                '   ...'
                            }
                        </CodeHighlight>

                        <br/>
                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5">
                                    <strong>Deferal of the firtst installment </strong>(response)
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    In the response transaction, apart from the basic fields, the following fields will be received.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Field
                                </td>
                                <td>
                                    Type
                                </td>
                                <td>
                                    Data Type
                                </td>
                                <td>
                                    Length <br/>
                                    [Min,Max]
                                </td>
                                <td colSpan="2">
                                    Example of field content
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    psp_FirstPaymentDeferral
                                </td>
                                <td>
                                    E
                                </td>
                                <td>
                                    Numeric
                                </td>
                                <td>
                                    1,1
                                </td>
                                <td colSpan="2">
                                    3
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>

        );
    }
}

export default Installments;