import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import {apiReferenceURL} from "../../../../env";

const Validators = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.swift.validators.title">
               Step 5 - {T.translate("advancedCheckout.swift.validators.title")}
            </h4>
            <div id="advancedCheckout.swift.validators.information"
                className="alert alert-info"
                role="alert">
                {T.translate("advancedCheckout.swift.validators.information")}
            </div>
            <Accordion id="advancedCheckout.swift.validators.methods.title" open
                title={ "5.1 - " + T.translate("advancedCheckout.swift.validators.methods.title")}>
                <div className="row">
                    <div className="col-12">
                        <h6 id="advancedCheckout.swift.validators.methods.name.title">
                            {T.translate("advancedCheckout.swift.validators.methods.name.title")}
                        </h6>
                        <p id="advancedCheckout.swift.validators.methods.name.text">
                            {T.translate("advancedCheckout.swift.validators.methods.name.text")}
                        </p>
                        <CodeHighlight language="Swift">
                            {
                                'if(nps.validateCardHolderName("JOHN DOE")){\n' +
                                '    print("Holder name is valid")\n' +
                                '} else {\n' +
                                '    print("Holder name is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.swift.validators.methods.number.title">
                            {T.translate("advancedCheckout.swift.validators.methods.number.title")}
                        </h6>
                        <p id="advancedCheckout.swift.validators.methods.number.text">
                            {T.translate("advancedCheckout.swift.validators.methods.number.text",{"apiLink": <T.a href={ `${apiReferenceURL}/services/IINLookup` } target="_blank" text="GetIINDetails"/>})}
                        </p>
                        <CodeHighlight language="Swift">
                            {
                                'if(nps.validateCardNumber("4111000000000010")){\n' +
                                '    print("Card number is valid")\n' +
                                '} else {\n' +
                                '    print("Card number is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.swift.validators.methods.expiration.title">
                            {T.translate("advancedCheckout.swift.validators.methods.expiration.title")}
                        </h6>
                        <p id="advancedCheckout.swift.validators.methods.expiration.text">
                            {T.translate("advancedCheckout.swift.validators.methods.expiration.text")}
                        </p>
                        <CodeHighlight language="Swift">
                            {
                               'if(nps.validateCardExpDate("2017", month:12)){\n' +
                               '    print("Expiration is valid")\n' +
                               '} else {\n' +
                               '    print("Expiration is invalid")\n' +
                               '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.swift.validators.methods.security.title">
                            {T.translate("advancedCheckout.swift.validators.methods.security.title")}
                        </h6>
                        <p id="advancedCheckout.swift.validators.methods.security.text">
                            {T.translate("advancedCheckout.swift.validators.methods.security.text")}
                        </p>
                        <CodeHighlight language="Swift">
                            {
                                'if(nps.validateCardSecurityCode("132")){\n' +
                                '    print("CVV is valid")\n' +
                                '} else {\n' +
                                '    print("CVV is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.swift.validators.methods.inn.title">
                            {T.translate("advancedCheckout.swift.validators.methods.inn.title")}
                        </h6>
                        <p id="advancedCheckout.swift.validators.methods.inn.text">
                            {T.translate("advancedCheckout.swift.validators.methods.inn.text",{"apiLink": <T.a href={ `${apiReferenceURL}/services/IINLookup` } target="_blank" text="GetIINDetails"/>})}
                        </p>
                        <CodeHighlight language="Swift">
                            {
                                'let nps = Nps(environment: NPSSANDBOX)!\n' +
                                'nps.merchantId = "__YOUR_NPS_MERCHANT_ID__"\n' +
                                'nps.clientSession = "__YOUR_NPS_CLIENT_SESSION__"\n' +
                                '\n' +
                                'nps.getProduct("424242",\n' +
                                '    methodResponse:{( methodResponse: GetIProductResponse?, error: Error?) -> Void in\n' +
                                '        if error == nil{\n' +
                                '            print(methodResponse?.responseCod as Any)\n' +
                                '        }\n' +
                                '    })'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Validators;