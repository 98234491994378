import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Tokenization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.android.tokenization.title">
                 Step 4 - {T.translate("advancedCheckout.android.tokenization.title")}
            </h4>
            <p id="advancedCheckout.android.tokenization.text">
                {T.translate("advancedCheckout.android.tokenization.text")}
            </p>
            <Accordion id="advancedCheckout.android.tokenization.create.title" open
                title={ "4.1 - " + T.translate("advancedCheckout.android.tokenization.create.title")}>
                <div className="row">
                    <div className="col-12">
                        <div id="advancedCheckout.android.tokenization.create.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.android.tokenization.create.information")}
                        </div>
                        <CodeHighlight language="java">
                            {
                                'Nps nps = new Nps(Nps.SANDBOX, "__YOUR_NPS_CLIENT_SESSION__", "__YOUR_NPS_MERCHANT_ID__");\n' +
                                '\n' +
                                'Card card = new Card()\n' +
                                '        .setHolderName("John Smith")\n' +
                                '        .setNumber("4507990000000010")\n' +
                                '        .setExpirationDate("1912")\n' +
                                '        .setSecurityCode("123")\n' +
                                '        .setNumPayments("1");\n' +
                                '\n' +
                                'Billing billing = new Billing();\n' +
                                '\n' +
                                'billing.getPerson()\n' +
                                '          .setFirstName("John")\n' +
                                '          .setLastName("Smith")\n' +
                                '          .setDateOfBirth("1987-01-01")\n' +
                                '          .setGender("M")\n' +
                                '          .setNationality("ARG")\n' +
                                '          .setIdType(Billing.IDTYPE_AR_DNI)\n' +
                                '          .setIdNumber("32123123")\n' +
                                '          .setPhoneNumber1("4123-1234")\n' +
                                '          .setPhoneNumber2("4123-5678");\n' +
                                '\n' +
                                'billing.getAddress()\n' +
                                '          .setAdditionalInfo("adding additional info")\n' +
                                '          .setCity("Capital Federal")\n' +
                                '          .setStateProvince("Capital Federal")\n' +
                                '          .setCountry("ARG")\n' +
                                '          .setZipCode("1414")\n' +
                                '          .setStreet("Calle Falsa")\n' +
                                '          .setHouseNumber("123");\n' +
                                '\n' +
                                'nps.createPaymentMethodToken(card, billing, \n' +
                                '    new Nps.ResponseHandler() {\n' +
                                '        @Override\n' +
                                '        public void onSuccess(PaymentMethodToken paymentMethodToken) {\n' +
                                '            Log.d("Nps", "token success = " + paymentMethodToken.getId());\n' +
                                '        }\n' +
                                '\n' +
                                '        @Override\n' +
                                '        public void onError(Exception error) {\n' +
                                '            Log.d("Nps", "token error");\n' +
                                '        }\n' +
                                '    });'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.android.tokenization.create.response.text">
                            {T.translate("advancedCheckout.android.tokenization.create.response.text")}
                        </p>
                        <p id="advancedCheckout.android.tokenization.create.error.text">
                            {T.translate("advancedCheckout.android.tokenization.create.error.text")}
                        </p>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.android.tokenization.paymentMethod.title" open
                title={ "4.2 - " + T.translate("advancedCheckout.android.tokenization.paymentMethod.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.android.tokenization.paymentMethod.text">
                            {T.translate("advancedCheckout.android.tokenization.paymentMethod.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="java">
                            {
                                'Nps nps = new Nps(Nps.SANDBOX, "__YOUR_NPS_CLIENT_SESSION__", "__YOUR_NPS_MERCHANT_ID__");\n' +
                                '\n' +
                                'PaymentMethod paymentMethod = new PaymentMethod();\n' +
                                'paymentMethod.setId(\'51e0kuKSwkG3GlaGq2fQaNdBsfOY0EHY\');\n' +
                                'paymentMethod.setCardSecurityCode("123");\n' +
                                '\n' +
                                'Billing billing = new Billing();\n' +
                                '\n' +
                                'billing.getPerson()\n' +
                                '          .setFirstName("John")\n' +
                                '          .setLastName("Smith")\n' +
                                '          .setDateOfBirth("1987-01-01")\n' +
                                '          .setGender("M")\n' +
                                '          .setNationality("ARG")\n' +
                                '          .setIdType(Billing.IDTYPE_AR_DNI)\n' +
                                '          .setIdNumber("32123123")\n' +
                                '          .setPhoneNumber1("4123-1234")\n' +
                                '          .setPhoneNumber2("4123-5678");\n' +
                                '\n' +
                                'billing.getAddress()\n' +
                                '          .setAdditionalInfo("adding additional info")\n' +
                                '          .setCity("Capital Federal")\n' +
                                '          .setStateProvince("Capital Federal")\n' +
                                '          .setCountry("ARG")\n' +
                                '          .setZipCode("1414")\n' +
                                '          .setStreet("Calle Falsa")\n' +
                                '          .setHouseNumber("123");\n' +
                                '\n' +
                                'nps.recachePaymentMethodToken(paymentMethod, billing, new Nps.ResponseHandler() {\n' +
                                '        @Override\n' +
                                '        public void onSuccess(PaymentMethodToken paymentMethodToken) {\n' +
                                '            Log.d("Nps", "token success = " + paymentMethodToken.getId());\n' +
                                '        }\n' +
                                '\n' +
                                '        @Override\n' +
                                '        public void onError(Exception error) {\n' +
                                '            Log.d("Nps", "token error");\n' +
                                '        }\n' +
                                '    });'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.android.tokenization.paymentMethod.information"
                            className="alert alert-info"
                             role="alert">
                            {T.translate("advancedCheckout.android.tokenization.paymentMethod.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Tokenization;