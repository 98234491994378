import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/Refund-request';
import response from '../../../documentation/documentation/Refund-response';
import Breadcrumbs from "../../../components/Breadcrumbs";
import T from "i18react/dist/i18react";

class Refund extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <div className="row">
                            <div className="col-12">
                                {T.translate("apiReference.refund.text")}
                            </div>
                        </div>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='Refund' showDocumentation={this.state.showDocumentation}  />
                    </div>
                </div>
            </div>
        );
    }
}

export default Refund;