import React from 'react';
import DocBuilder from '../../../components/DocBuilder';
import Breadcrumbs from "../../../components/Breadcrumbs";

import request from '../../../documentation/documentation/CreateCustomer-request';
import response from '../../../documentation/documentation/CreateCustomer-response';

class Create extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            Creates a Customer in your vault. The customer entity is very useful to attach multiples
                            PaymentMethods and reusing them using the CustomerId as group identifier. If you use the
                            Simple Checkout integration method consider implementing this functionality to simplify the
                            multiple re-usable PaymentMethods administration.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='CreateCustomer' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Create;