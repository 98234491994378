import React from 'react';
import T from "i18react/dist/i18react";
import ReactSVG from 'react-svg';

import {apiReferenceURL, guideReferenceURL} from "../../env";

import Sale from '../ApiReference/Payments/CardPayment/Sale'
import DeviceFingerprint from './DeviceFingerprint';
import diagram from '../../assets/img/DirectPayment.svg';
import apiIcon from '../../assets/img/api.svg';
import * as env from "../../env";

class DirectPayment extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showImage: false
        };
        this.loadImagesAndPick = this.loadImagesAndPick.bind(this);
    }

    loadImagesAndPick(){
        let images = {};
        let folder = require.context('../../assets/img/users/', false, /\.(png|jpe?g|svg)$/);
        folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

        let result;
        let count = 0;
        for (let prop in images)
            if (Math.random() < 1/++count)
                result = prop;

        return images[result];
    }

    render() {
        return (
            <div className="container-fluid direct-payment">
                <div className="row">
                    <div className="col-12">
                        <h3 id="directPayment.title">
                            {T.translate("directPayment.title")}
                        </h3>
                        <div id="directPayment.text">
                            {T.translate("directPayment.text",{"link": <T.a href={ `${apiReferenceURL}/overview` } text="Connection environment"/>})}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="alert alert-info">
                            <b id="directPayment.information.title">
                                {T.translate("directPayment.information.title")}
                            </b>
                            <ul>
                                <li id="directPayment.information.list.1">
                                    {T.translate("directPayment.information.list.1")}</li>
                                <li id="directPayment.information.list.2">
                                    {T.translate("directPayment.information.list.2")}</li>
                                <li id="directPayment.information.list.3">
                                    {T.translate("directPayment.information.list.3")}</li>
                                <li id="directPayment.information.list.4">
                                    {T.translate("directPayment.information.list.4")}</li>
                                <li id="directPayment.information.list.5">
                                    {T.translate("directPayment.information.list.5")}</li>
                                <li id="directPayment.information.list.6">
                                    {T.translate("directPayment.information.list.6")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="directPayment.diagram">
                            {T.translate("directPayment.diagram")}
                        </h3>
                        <div className="diagram-container">
                            <ReactSVG src={diagram}
                                 className="img-fluid checkout-image"
                                 alt={T.translate("directPayment.diagram")}/>
                            <img src={this.loadImagesAndPick()} alt="1" className="img-fluid checkout-user"/>
                            <img src={env.logo} alt="1" className="img-fluid checkout-logo"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ol>
                            <li id="directPayment.list.1">
                                {T.translate("directPayment.list.1")}</li>
                            <li id="directPayment.list.2">
                                {T.translate("directPayment.list.2")}</li>
                            <li id="directPayment.list.3">
                                {T.translate("directPayment.list.3")}</li>
                            <li id="directPayment.list.4">
                                {T.translate("directPayment.list.4")}</li>
                            <li id="directPayment.list.5">
                                {T.translate("directPayment.list.5")}</li>
                            <li id="directPayment.list.6">
                                {T.translate("directPayment.list.6")}</li>
                        </ol>
                    </div>
                </div>
                <DeviceFingerprint showDocumentation={false}/>
                <div className="row">
                    <div className="col-12">
                        <h3 id="directPayment.methods.title">
                            {T.translate("directPayment.methods.title")}</h3>
                        <br/><br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("directPayment.methods.detail",
                                            {
                                                "auth": <T.a href={`${apiReferenceURL}/payments/CardPayment/Authorization` } text="Authorization"/>,
                                                "sale": <T.a href={`${apiReferenceURL}/payments/CardPayment/Sale` } text="Sale"/>
                                            }
                                        )}
                                        <br/>
                                        {T.translate("directPayment.methods.all",
                                            {
                                                "api": <T.a href={`${apiReferenceURL}/`} text="Api Reference"/>
                                            }
                                        )}
                                        <br/><br/>
                                        {T.translate("directPayment.methods.example")}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Sale showDocumentation={false}/>
                        <div className="alert alert-danger">
                            <p id="directPayment.methods.warning">
                                {T.translate("directPayment.methods.warning")}
                            </p>
                        </div>
                        <div className="alert alert-info">
                            <b id="directPayment.methods.information.title">
                                {T.translate("directPayment.methods.information.title")}</b>
                            <div id="directPayment.methods.information.text">
                                {T.translate("directPayment.methods.information.text",{"CommonInterface": <T.a href={`${guideReferenceURL}/reference/commonInterface`} text="Common Interface Specification"/>})}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DirectPayment;