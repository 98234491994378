import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const C = () => {
    return (
        <div className="col-12">
            <div className="row">
               <div className="c-library"/>
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-c" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download (Beta)
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        1.7 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.c.preRequisites.title">
                        {T.translate("libraries.server.c.preRequisites.title")}
                    </h4>
                    <div className="col-12">
                        <h5 id="libraries.serve r.c.preRequisites.dependencies.ssl">
                            {T.translate("libraries.server.c.preRequisites.dependencies.ssl")}
                        </h5>
                        <CodeHighlight languague="bash">
                            {
                                'sudo apt-get install libcurl4-openssl-dev libssl-dev'
                            }
                        </CodeHighlight>
                        <h5 id="libraries.server.c.preRequisites.dependencies.xml">
                            {T.translate("libraries.server.c.preRequisites.dependencies.xml")}
                        </h5>
                        <CodeHighlight languague="bash">
                            {
                                'sudo apt-get install libxml2-dev'
                            }
                        </CodeHighlight>
                    </div>
                    <hr/>
                    <h4 id="libraries.server.c.use.title">
                            {T.translate("libraries.server.c.use.title")}
                    </h4>
                        <br/>
                        <ul>
                            <li id="libraries.server.c.use.list.1">
                                {T.translate("libraries.server.c.use.list.1",
                                    {
                                        "download": <T.a href={'https://github.com/Ingenico-NPS-Latam/nps-sdk-c.git'}  text="download"/>
                                    }
                                )}
                            </li>
                            <li id="libraries.server.c.use.list.2">
                                {T.translate("libraries.server.c.use.list.2")}
                            </li>
                        </ul>
                    <hr/>
                    <h4 id="libraries.server.c.build">
                        {T.translate("libraries.server.c.build")}
                    </h4>
                        <br/>
                    <CodeHighlight languague="bash">
                        {
                            'cc -o test test.c npsSdk.c xml_save.c nps_utils.c parse_xml.c -I/usr/include/curl -I/usr/include/libxml2 -I/usr/include/openssl -lcurl -lxml2 -lcrypto 2>err.log'
                        }
                    </CodeHighlight>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.c.configuration.title">
                        {T.translate("libraries.server.c.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.c.configuration.basic" open
                        title={T.translate("libraries.server.c.configuration.basic")}>
                        <CodeHighlight languague="C">
                            {
                                '#include <ctype.h>\n' +
                                '#include <stdio.h>\n' +
                                '#include <stdlib.h>\n' +
                                '#include <string.h>\n' +
                                '#include <unistd.h>\n' +
                                '#include <time.h>\n' +
                                '#include <stdbool.h>\n' +
                                '#include <stddef.h> \n' +
                                '\n' +
                                '#include "npsSdk.h"\n' +
                                '\n' +
                                'int main(int argc, char **argv)\n' +
                                '{\n' +
                                ' \n' +
                                '  int env=SANBOX_ENV; /*PROD_ENV | SANBOX_ENV | STAGING_ENV*/\n' +
                                '\n' +
                                '  FILE * auxFd = fopen ( "test.log", "a" ) ;\n' +
                                '\n' +
                                '  if (setLog( DEBUG, NULL, auxFd)<0)\n' +
                                '    return;\n' +
                                '\n' +
                                '  if (setEnvironment(env)<0)\n' +
                                '    return;\n' +
                                '}'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.c.configuration.advanced.title" open
                        title={T.translate("libraries.server.c.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <hr/>
                                <h4 id="libraries.server.c.configuration.advanced.log.title">
                                    {T.translate("libraries.server.c.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.c.configuration.advanced.log.text">
                                    {T.translate("libraries.server.c.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="C">
                                    {
                                       '#include <ctype.h>\n' +
                                       '#include <stdio.h>\n' +
                                       '#include <stdlib.h>\n' +
                                       '#include <string.h>\n' +
                                       '#include <unistd.h>\n' +
                                       '#include <time.h>\n' +
                                       '#include <stdbool.h>\n' +
                                       '#include <stddef.h> /* offsetof */\n' +
                                       '\n' +
                                       '#include "npsSdk.h"\n' +
                                       '\n' +
                                       '/*Using an already opened Fd*/\n' +
                                       'int main( int argc, char **argv)\n' +
                                       '{\n' +
                                       ' \n' +
                                       '  FILE * auxFd = fopen ( "test.log", "a" ) ;\n' +
                                       '\n' +
                                       '  if (setLog( DEBUG, NULL, auxFd)<0) {\n' +
                                       '    printf(auxFd, "ERROR: %s", getNpsErrDesc());\n' +
                                       '    return;\n' +
                                       '  }\n' +
                                       '\n' +
                                       '}\n' +
                                       '\n' +
                                       '/*Opening a file and log*/\n' +
                                       'int main( int argc, char **argv)\n' +
                                       '{\n' +
                                       ' \n' +
                                       '  if (setLog( DEBUG, test.log, NULL)<0) {\n' +
                                       '    printf(auxFd, "ERROR: %s", getNpsErrDesc());\n' +
                                       '    return;\n' +
                                       '  }\n' +
                                       '\n' +
                                       '}\n' +
                                       '\n' +
                                       '/*Logging to stdout*/\n' +
                                       'int main( int argc, char **argv)\n' +
                                       '{\n' +
                                       ' \n' +
                                       '  if (setLog( DEBUG, NULL, NULL)<0) {\n' +
                                       '    printf(auxFd, "ERROR: %s", getNpsErrDesc());\n' +
                                       '    return;\n' +
                                       '  }\n' +
                                       '\n' +
                                       '}'
                                    }
                                </CodeHighlight>
                                <p>
                                    {T.translate("libraries.server.c.configuration.advanced.log.level")}
                                </p>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.c.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.c.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.c.configuration.advanced.timeout.connection">
                                    {T.translate("libraries.server.c.configuration.advanced.timeout.connection")}
                                </p>
                                <p id="libraries.server.c.configuration.advanced.timeout.execution">
                                    {T.translate("libraries.server.c.configuration.advanced.timeout.execution")}
                                </p>
                                <CodeHighlight languague="C">
                                    {
                                        '#include <ctype.h>\n' +
                                        '#include <stdio.h>\n' +
                                        '#include <stdlib.h>\n' +
                                        '#include <string.h>\n' +
                                        '#include <unistd.h>\n' +
                                        '#include <time.h>\n' +
                                        '#include <stdbool.h>\n' +
                                        '#include <stddef.h> /* offsetof */\n' +
                                        '\n' +
                                        '#include "npsSdk.h"\n' +
                                        '\n' +
                                        'int main( int argc, char **argv)\n' +
                                        '{\n' +
                                        '\n' +
                                        '  if (setConnTimeout(10) <0) {\n' +
                                        '    printf("ERROR: %s", getNpsErrDesc());\n' +
                                        '    return;\n' +
                                        '  }\n' +
                                        '\n' +
                                        '  if (setExecTimeout(60) <0) {\n' +
                                        '    printf("ERROR: %s", getNpsErrDesc());\n' +
                                        '    return;\n' +
                                        '  }\n' +
                                        '\n' +
                                        '}'
                                    }
                                </CodeHighlight>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.c.environments.title">
                        {T.translate("libraries.server.c.environments.title")}
                    </h4>
                    <p id="libraries.server.c.environments.text">
                        {T.translate("libraries.server.c.environments.text")}
                    </p>
                    <CodeHighlight languague="C">
                        {
                            '#include <ctype.h>\n' +
                            '#include <stdio.h>\n' +
                            '#include <stdlib.h>\n' +
                            '#include <string.h>\n' +
                            '#include <unistd.h>\n' +
                            '#include <time.h>\n' +
                            '#include <stdbool.h>\n' +
                            '#include <stddef.h> /* offsetof */\n' +
                            '\n' +
                            '#include "npsSdk.h"\n' +
                            '\n' +
                            'int main( int argc, char **argv)\n' +
                            '{' +
                            '\n' +
                            '  if (setEnvironment(PROD_ENV)<0) {\n' +
                            '    printf("%s\\n", getNpsErrDesc());\n' +
                            '    return;\n' +
                            '  }\n' +
                            '\n' +
                            '  if (setEnvironment(SANBOX_ENV)<0) {\n' +
                            '    printf("%s\\n", getNpsErrDesc());\n' +
                            '    return;\n' +
                            '  }\n' +
                            '\n' +
                            '  if (setEnvironment(STAGING_ENV)<0) {\n' +
                            '    printf("%s\\n", getNpsErrDesc());\n' +
                            '    return;\n' +
                            '  }\n' +
                            '\n' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.c.error.title">
                        {T.translate("libraries.server.c.error.title")}
                    </h4>
                    <p id="libraries.server.c.error.text">
                        {T.translate("libraries.server.c.error.text")}
                    </p>
                    <CodeHighlight languague="C">
                        {
                           'if (sendRequest(PAY_ONLINE_2P_TYPE, apiKey, (char *)pRequest, pResponse) < 0) {\n' +
                           '  printf("ERROR: %s\\n", getNpsErrDesc());\n' +
                           '}\n' +
                           'else {\n' +
                           '  showResponse(PAY_ONLINE_2P_TYPE,(char *)pResponse);\n' +
                           '}'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.c.example.title">
                        {T.translate("libraries.server.c.example.title")}
                    </h4>
                    <p id="libraries.server.c.example.text">
                        {T.translate("libraries.server.c.example.text")}
                    </p>
                    <CodeHighlight languague="C">
                        {
                            '#include <ctype.h>\n' +
                            '#include <stdio.h>\n' +
                            '#include <stdlib.h>\n' +
                            '#include <string.h>\n' +
                            '#include <unistd.h>\n' +
                            '#include <time.h>\n' +
                            '#include <stdbool.h>\n' +
                            '#include <stddef.h> /* offsetof */\n' +
                            '\n' +
                            '#include "npsSdk.h"\n' +
                            '\n' +
                            'FillOrderItemStruct(ORDER_ITEM_STRUCT *ptr, char *qty, char *unitPrice, char *descrip,char *type, char *skuCode, char *manFactPartNum, char *risk){\n' +
                            '    memset(ptr, 0, sizeof(ORDER_ITEM_STRUCT));\n' +
                            '    \n' +
                            '    ptr->Quantity = malloc(strlen(qty)+1);\n' +
                            '    strcpy(ptr->Quantity, qty);\n' +
                            '    \n' +
                            '    ptr->UnitPrice = malloc(strlen(unitPrice)+1);\n' +
                            '    strcpy(ptr->UnitPrice, unitPrice);\n' +
                            '    \n' +
                            '    ptr->Description = malloc(strlen(descrip)+1);\n' +
                            '    strcpy(ptr->Description, descrip);\n' +
                            '    \n' +
                            '    ptr->Type = malloc(strlen(type)+1);\n' +
                            '    strcpy(ptr->Type, type);\n' +
                            '    \n' +
                            '    ptr->SkuCode = malloc(strlen(skuCode)+1);\n' +
                            '    strcpy(ptr->SkuCode, skuCode);\n' +
                            '    \n' +
                            '    ptr->ManufacturerPartNumber = malloc(strlen(manFactPartNum)+1);\n' +
                            '    strcpy(ptr->ManufacturerPartNumber, manFactPartNum);\n' +
                            '    \n' +
                            '    ptr->Risk = malloc(strlen(risk)+1);\n' +
                            '    strcpy(ptr->Risk, risk);\n' +
                            '    \n' +
                            '}\n' +
                            '\n' +
                            'void FillOrderItems(ARRAYOF_ORDERITEMSTRUCT *pOrderItems, int count) {\n' +
                            '  int i;\n' +
                            '  char qty[20];\n' +
                            '  char unitPrice[20];\n' +
                            '  char descrip[20];\n' +
                            '  char type[20];\n' +
                            '  char skuCode[20];\n' +
                            '  char manFactPartNum[20];\n' +
                            '  char risk[20];\n' +
                            '  \n' +
                            '  ORDER_ITEM_STRUCT *ptr;\n' +
                            '  \n' +
                            '  memset(pOrderItems, 0, sizeof(ARRAYOF_ORDERITEMSTRUCT));\n' +
                            '  \n' +
                            '  pOrderItems->__size = count;\n' +
                            '  \n' +
                            '  pOrderItems->__ptr=(ORDER_ITEM_STRUCT *)malloc(sizeof(ORDER_ITEM_STRUCT *)*count);\n' +
                            '  for (i = 0; i < pOrderItems->__size; ++i){\n' +
                            '    ptr=(ORDER_ITEM_STRUCT *)malloc(sizeof(ORDER_ITEM_STRUCT));\n' +
                            '    sprintf(qty, "%d", i+1);\n' +
                            '    sprintf(unitPrice, "%d", i+100);\n' +
                            '    sprintf(descrip, "descrip%d", i+1);\n' +
                            '    sprintf(type, "type%d", i+1);\n' +
                            '    sprintf(skuCode, "skucode%d", i+1);\n' +
                            '    sprintf(manFactPartNum, "manFactPartNum%d", i+1);\n' +
                            '    sprintf(risk, "L");\n' +
                            '\n' +
                            '    FillOrderItemStruct(ptr, qty, unitPrice, descrip,type, skuCode, manFactPartNum, risk);\n' +
                            '    \n' +
                            '    pOrderItems->__ptr[i]=ptr;\n' +
                            '  }\n' +
                            '  \n' +
                            '}\n' +
                            '\n' +
                            'void FillOrderDetailsStruct(ORDER_DETAILS_STRUCT *pOrderDetails){\n' +
                            '    ARRAYOF_ORDERITEMSTRUCT *pOrderItems;\n' +
                            '    int i;\n' +
                            '    \n' +
                            '    pOrderItems=(ARRAYOF_ORDERITEMSTRUCT *)malloc(sizeof(ARRAYOF_ORDERITEMSTRUCT));\n' +
                            '    memset(pOrderItems, 0, sizeof(ARRAYOF_ORDERITEMSTRUCT));\n' +
                            '    \n' +
                            '    pOrderItems->__size=2;\n' +
                            '    FillOrderItems(pOrderItems, 2);\n' +
                            '    \n' +
                            '    pOrderDetails->OrderItems=pOrderItems;\n' +
                            '}\n' +
                            '\n' +
                            'void FillCustomerAdditionalDetailsStruct(CUSTOMER_ADDITIONAL_DETAILS_STRUCT *pCustomerAdditionalDetails) {\n' +
                            '    memset(pCustomerAdditionalDetails, 0, sizeof(CUSTOMER_ADDITIONAL_DETAILS_STRUCT));\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->EmailAddress=malloc(strlen("jdoe@email.com")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->EmailAddress,"jdoe@email.com");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->AlternativeEmailAddress=malloc(strlen("Jdoe79@email.com")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->AlternativeEmailAddress,"Jdoe79@email.com");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->IPAddress=malloc(strlen("192.168.158.190")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->IPAddress,"192.168.158.190");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->AccountID=malloc(strlen("2")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->AccountID,"2");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->AccountCreatedAt=malloc(strlen("2010-10-23")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->AccountCreatedAt,"2010-10-23");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->AccountPreviousActivity=malloc(strlen("0")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->AccountPreviousActivity,"0");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->AccountHasCredentials=malloc(strlen("0")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->AccountHasCredentials,"0");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->DeviceType=malloc(strlen("2")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->DeviceType,"2");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->DeviceFingerPrint=malloc(strlen("22")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->DeviceFingerPrint,"22");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->BrowserLanguage=malloc(strlen("ES")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->BrowserLanguage,"ES");\n' +
                            '    \n' +
                            '    pCustomerAdditionalDetails->HttpUserAgent=malloc(strlen("Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:21.0) Gecko/20100101 Firefox/21.0")+1);\n' +
                            '    strcpy(pCustomerAdditionalDetails->HttpUserAgent,"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:21.0) Gecko/20100101 Firefox/21.0");\n' +
                            '}\n' +
                            '\n' +
                            'void FillPersonStruct(PERSON_STRUCT *pPerson) {\n' +
                            '    memset(pPerson, 0, sizeof(PERSON_STRUCT));\n' +
                            '    \n' +
                            '    pPerson->FirstName=malloc(strlen("John")+1);\n' +
                            '    strcpy(pPerson->FirstName, "John");\n' +
                            '    \n' +
                            '    pPerson->LastName=malloc(strlen("Doe")+1);\n' +
                            '    strcpy(pPerson->LastName, "Doe");\n' +
                            '    \n' +
                            '    pPerson->MiddleName=malloc(strlen("Michael")+1);\n' +
                            '    strcpy(pPerson->MiddleName, "Michael");\n' +
                            '    \n' +
                            '    pPerson->PhoneNumber1=malloc(strlen("+1 011 11111111")+1);\n' +
                            '    strcpy(pPerson->PhoneNumber1, "+1 011 11111111");\n' +
                            '    \n' +
                            '    pPerson->PhoneNumber2=malloc(strlen("+1 011 22222222")+1);\n' +
                            '    strcpy(pPerson->PhoneNumber2, "+1 011 22222222");\n' +
                            '    \n' +
                            '    pPerson->Gender=malloc(strlen("F")+1);\n' +
                            '    strcpy(pPerson->Gender,"F");\n' +
                            '    \n' +
                            '    pPerson->IDNumber=malloc(strlen("54111111")+1);\n' +
                            '    strcpy(pPerson->IDNumber,"54111111");\n' +
                            '    \n' +
                            '    pPerson->IDType=malloc(strlen("200")+1);\n' +
                            '    strcpy(pPerson->IDType,"200");\n' +
                            '  \n' +
                            '}\n' +
                            '\n' +
                            'void FillAddressStruct(ADDRESS_STRUCT *pAddress) {\n' +
                            '    memset(pAddress, 0, sizeof(ADDRESS_STRUCT));\n' +
                            '    \n' +
                            '    pAddress->Street=malloc(strlen("Av. Collins")+1);\n' +
                            '    strcpy(pAddress->Street,"Av. Collins");\n' +
                            '    \n' +
                            '    pAddress->HouseNumber=malloc(strlen("1245")+1);\n' +
                            '    strcpy(pAddress->HouseNumber,"1245");\n' +
                            '    \n' +
                            '    pAddress->AdditionalInfo=malloc(strlen("2 A")+1);\n' +
                            '    strcpy(pAddress->AdditionalInfo,"2 A");\n' +
                            '    \n' +
                            '    pAddress->City=malloc(strlen("Miami")+1);\n' +
                            '    strcpy(pAddress->City, "Miami");\n' +
                            '    \n' +
                            '    pAddress->StateProvince=malloc(strlen("Florida")+1);\n' +
                            '    strcpy(pAddress->StateProvince, "Florida");\n' +
                            '    \n' +
                            '    pAddress->Country=malloc(strlen("USA")+1);\n' +
                            '    strcpy(pAddress->Country, "USA");\n' +
                            '    \n' +
                            '    pAddress->ZipCode=malloc(strlen("33140")+1);\n' +
                            '    strcpy(pAddress->ZipCode, "33140");\n' +
                            '  \n' +
                            '}\n' +
                            '\n' +
                            'void FillSellerDetails(SELLER_DETAILS_STRUCT *pSellerDetails) {\n' +
                            '    memset(pSellerDetails, 0, sizeof(SELLER_DETAILS_STRUCT));\n' +
                            '\n' +
                            '    pSellerDetails->IDNumber=malloc(strlen("27087764-0")+1);\n' +
                            '    strcpy(pSellerDetails->IDNumber,"27087764-0");\n' +
                            '    \n' +
                            '    pSellerDetails->IDType=malloc(strlen("200")+1);\n' +
                            '    strcpy(pSellerDetails->IDType,"200");\n' +
                            '    \n' +
                            '    pSellerDetails->Name=malloc(strlen("John Doe")+1);\n' +
                            '    strcpy(pSellerDetails->Name,"John Doe");\n' +
                            '    \n' +
                            '    pSellerDetails->Invoice=malloc(strlen("54877555")+1);\n' +
                            '    strcpy(pSellerDetails->Invoice,"54877555");\n' +
                            '    \n' +
                            '    pSellerDetails->PurchaseDescription=malloc(strlen("Descripcion")+1);\n' +
                            '    strcpy(pSellerDetails->PurchaseDescription,"Descripcion");\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'void FillBillingDetails(BILLING_DETAILS_STRUCT *pBillingDetails) {\n' +
                            '    memset(pBillingDetails, 0, sizeof(BILLING_DETAILS_STRUCT));\n' +
                            '    \n' +
                            '    pBillingDetails->Invoice = malloc(strlen("54877555")+1);\n' +
                            '    strcpy(pBillingDetails->Invoice,"54877555");\n' +
                            '    \n' +
                            '   \n' +
                            '    pBillingDetails->InvoiceAmount = malloc(strlen("15050")+1);\n' +
                            '    strcpy(pBillingDetails->InvoiceAmount, "15050");\n' +
                            '    \n' +
                            '    pBillingDetails->InvoiceCurrency = malloc(strlen("032")+1);\n' +
                            '    strcpy(pBillingDetails->InvoiceCurrency, "032");\n' +
                            '    \n' +
                            '};    \n' +
                            '    \n' +
                            'void FillMerchantAdditionalDetails(MERCHANT_ADDITIONAL_DETAILS_STRUCT *pMerchantAdditionalDetails) {\n' +
                            '  memset(pMerchantAdditionalDetails, 0, sizeof(MERCHANT_ADDITIONAL_DETAILS_STRUCT));\n' +
                            '  \n' +
                            '  pMerchantAdditionalDetails->ShoppingCartInfo = malloc(strlen("ShoppingCartInfo")+1);\n' +
                            '  strcpy(pMerchantAdditionalDetails->ShoppingCartInfo,"ShoppingCartInfo");\n' +
                            '  \n' +
                            '  pMerchantAdditionalDetails->ShoppingCartPluginInfo = malloc(strlen("ShoppingCartPluginInfo")+1);\n' +
                            '  strcpy(pMerchantAdditionalDetails->ShoppingCartPluginInfo,"ShoppingCartPluginInfo");\n' +
                            '  \n' +
                            '}\n' +
                            '\n' +
                            'void FillTaxesRequestStruct(TAXES_REQUEST_STRUCT *ptr, char *typeId, char *Amount) {\n' +
                            '    memset(ptr, 0, sizeof(TAXES_REQUEST_STRUCT));\n' +
                            '    \n' +
                            '    ptr->TypeId = malloc(strlen(typeId)+1);\n' +
                            '    strcpy(ptr->TypeId, typeId);\n' +
                            '    \n' +
                            '    ptr->Amount = malloc(strlen(Amount)+1);\n' +
                            '    strcpy(ptr->Amount,Amount);\n' +
                            '    \n' +
                            '    ptr->BaseAmount = malloc(strlen("11111")+1);\n' +
                            '    strcpy(ptr->BaseAmount,"11111");\n' +
                            '};\n' +
                            '\n' +
                            'void FillTaxes(ARRAYOF_TAXESREQUESTSTRUCT *pTaxes, int count) {\n' +
                            '  int i;\n' +
                            '  char typeId[10];\n' +
                            '  char amount[10];\n' +
                            '  \n' +
                            '  TAXES_REQUEST_STRUCT *ptr;\n' +
                            '  \n' +
                            '  memset(pTaxes, 0, sizeof(ARRAYOF_TAXESREQUESTSTRUCT));\n' +
                            '  \n' +
                            '  pTaxes->__size = count;\n' +
                            '  \n' +
                            '  pTaxes->__ptr=(TAXES_REQUEST_STRUCT *)malloc(sizeof(TAXES_REQUEST_STRUCT *)*count);\n' +
                            '  \n' +
                            '  for (i = 0; i < pTaxes->__size; ++i){\n' +
                            '    ptr=(TAXES_REQUEST_STRUCT *)malloc(sizeof(TAXES_REQUEST_STRUCT));\n' +
                            '    sprintf(typeId, "%d", i+500);\n' +
                            '    sprintf(amount, "%d", i+1500);\n' +
                            '    FillTaxesRequestStruct(ptr, typeId, amount);\n' +
                            '    pTaxes->__ptr[i]=ptr;\n' +
                            '  }\n' +
                            '}\n' +
                            '\n' +
                            'void FillAmountAdditionalDetails(AMOUNT_ADDITIONAL_DETAILS_REQUEST_STRUCT *pAmountAddic) {\n' +
                            '    ARRAYOF_TAXESREQUESTSTRUCT *pTaxes;\n' +
                            '    \n' +
                            '    memset(pAmountAddic, 0, sizeof(AMOUNT_ADDITIONAL_DETAILS_REQUEST_STRUCT));\n' +
                            '    \n' +
                            '    pAmountAddic->Tip = malloc(strlen("1111")+1);\n' +
                            '    strcpy(pAmountAddic->Tip, "1111");\n' +
                            '    \n' +
                            '    pAmountAddic->Discount = malloc(strlen("22")+1);\n' +
                            '    strcpy(pAmountAddic->Discount, "22");\n' +
                            '    \n' +
                            '    pTaxes=(ARRAYOF_TAXESREQUESTSTRUCT *)malloc(sizeof(ARRAYOF_TAXESREQUESTSTRUCT));\n' +
                            '    memset(pTaxes, 0, sizeof(ARRAYOF_TAXESREQUESTSTRUCT));\n' +
                            '    \n' +
                            '    pTaxes->__size=2;\n' +
                            '    FillTaxes(pTaxes, 2);\n' +
                            '    pAmountAddic->Taxes=pTaxes;\n' +
                            '    \n' +
                            '}\n' +
                            '\n' +
                            'void FillRequestPayOnLine_2p(PAY_ONLINE_2P_REQ_STRUCT *pRequest) {\n' +
                            '  \n' +
                            '    char psp_MerchTxRef[30];\n' +
                            '    memset(pRequest, 0, sizeof(PAY_ONLINE_2P_REQ_STRUCT));\n' +
                            '    pRequest->psp_Version =  malloc(strlen("2.2")+1);\n' +
                            '    strcpy(pRequest->psp_Version, "2.2");\n' +
                            '    printf("pRequest->psp_Version [%s]\\n", pRequest->psp_Version);\n' +
                            '    \n' +
                            '    pRequest->psp_MerchantId =  malloc(strlen(psp_MerchantId)+1);\n' +
                            '    strcpy(pRequest->psp_MerchantId, psp_MerchantId);\n' +
                            '    printf("pRequest->psp_MerchantId [%s]\\n", pRequest->psp_MerchantId);\n' +
                            '    \n' +
                            '    pRequest->psp_TxSource =  malloc(strlen("WEB")+1);\n' +
                            '    strcpy(pRequest->psp_TxSource, "WEB");\n' +
                            '    \n' +
                            '    pRequest->psp_Recurrent =  malloc(strlen("0")+1);\n' +
                            '    strcpy(pRequest->psp_Recurrent, "0");\n' +
                            '    \n' +
                            '    pRequest->psp_NumPayments =  malloc(strlen("1")+1);\n' +
                            '    strcpy(pRequest->psp_NumPayments, "1");\n' +
                            '    \n' +
                            '    pRequest->psp_Currency =  malloc(strlen(psp_Currency)+1);\n' +
                            '    strcpy(pRequest->psp_Currency, psp_Currency);\n' +
                            '    \n' +
                            '    pRequest->psp_CardExpDate =  malloc(strlen("1912")+1);\n' +
                            '    strcpy(pRequest->psp_CardExpDate, "1912");\n' +
                            '    \n' +
                            '    pRequest->psp_MerchOrderId =  malloc(strlen("ORDERX1466Xz")+1);\n' +
                            '    strcpy(pRequest->psp_MerchOrderId, "ORDERX1466Xz");\n' +
                            '    \n' +
                            '    pRequest->psp_Product =  malloc(strlen(psp_Product)+1);\n' +
                            '    strcpy(pRequest->psp_Product, psp_Product);\n' +
                            '    \n' +
                            '    pRequest->psp_Amount =  malloc(strlen(psp_Amount)+1);\n' +
                            '    strcpy(pRequest->psp_Amount, psp_Amount);\n' +
                            '    \n' +
                            '    pRequest->psp_SoftDescriptor =  malloc(strlen("000000223500001")+1);\n' +
                            '    strcpy(pRequest->psp_SoftDescriptor, "000000223500001");\n' +
                            '    \n' +
                            '    pRequest->psp_PosDateTime =  malloc(strlen("2017-03-23 04:13:37")+1);\n' +
                            '    strcpy(pRequest->psp_PosDateTime, "2017-03-23 04:13:37");\n' +
                            '    \n' +
                            '    pRequest->psp_PurchaseDescription =  malloc(strlen("My Items")+1);\n' +
                            '    strcpy(pRequest->psp_PurchaseDescription, "My Items");\n' +
                            '\n' +
                            '    time_t t = time(NULL);\n' +
                            '    struct tm tm = *localtime(&t);\n' +
                            '    sprintf(psp_MerchTxRef,"ANSIC-CLIENT-%4d%02d%02d%02d%02d%02d", tm.tm_year + 1900, tm.tm_mon + 1, tm.tm_mday, tm.tm_hour, tm.tm_min, tm.tm_sec);    \n' +
                            '\n' +
                            '    pRequest->psp_MerchTxRef =  malloc(strlen(psp_MerchTxRef)+1);\n' +
                            '    strcpy(pRequest->psp_MerchTxRef, psp_MerchTxRef);\n' +
                            '    \n' +
                            '    pRequest->psp_CardNumber =  malloc(strlen(psp_CardNumber)+1);\n' +
                            '    strcpy(pRequest->psp_CardNumber, psp_CardNumber);\n' +
                            '\n' +
                            '    pRequest->psp_MerchantMail =  malloc(strlen("systemtest@globalcollect.nl")+1);\n' +
                            '    strcpy(pRequest->psp_MerchantMail, "systemtest@globalcollect.nl");\n' +
                            '    \n' +
                            '    pRequest->psp_Country =  malloc(strlen(psp_Country)+1);\n' +
                            '    strcpy(pRequest->psp_Country, psp_Country);\n' +
                            '\n' +
                            '}\n' +
                            '\n' +
                            'int SendPayOnLine_2p(void) {\n' +
                            '\n' +
                            '  int i;\n' +
                            '  ORDER_DETAILS_STRUCT OrderDetails, *pOrderDetails;\n' +
                            '  CUSTOMER_ADDITIONAL_DETAILS_STRUCT CustomerAdditionalDetails, *pCustomerAdditionalDetails;\n' +
                            '  ADDRESS_STRUCT Address, *pAddress;\n' +
                            '  SELLER_DETAILS_STRUCT SellerDetails, *pSellerDetails;\n' +
                            '  MERCHANT_ADDITIONAL_DETAILS_STRUCT MerchantAdditionalDetails, *pMerchantAdditionalDetails;\n' +
                            '  AMOUNT_ADDITIONAL_DETAILS_REQUEST_STRUCT AmountAdditionalDetailsRequest, *pAmountAdditionalDetailsRequest;\n' +
                            '  \n' +
                            '  PAY_ONLINE_2P_REQ_STRUCT Request, *pRequest;\n' +
                            '  PAY_ONLINE_2P_RESP_STRUCT Response, *pResponse;\n' +
                            '  \n' +
                            '  printf("\\n******************SendPayOnLine_2p************************************\\n");\n' +
                            '    pRequest = &Request;\n' +
                            '\n' +
                            '    pAmountAdditionalDetailsRequest = &AmountAdditionalDetailsRequest;\n' +
                            '    pMerchantAdditionalDetails = &MerchantAdditionalDetails;\n' +
                            '    pSellerDetails = &SellerDetails;\n' +
                            '    pAddress = &Address;\n' +
                            '    pCustomerAdditionalDetails = &CustomerAdditionalDetails;\n' +
                            '    pOrderDetails = &OrderDetails;\n' +
                            '    \n' +
                            '    FillOrderDetailsStruct(pOrderDetails);\n' +
                            '    \n' +
                            '    FillCustomerAdditionalDetailsStruct(pCustomerAdditionalDetails);\n' +
                            '    FillAddressStruct(pAddress);\n' +
                            '    \n' +
                            '    FillSellerDetails(pSellerDetails);\n' +
                            '    pSellerDetails->Address = pAddress;\n' +
                            '    \n' +
                            '    FillMerchantAdditionalDetails(pMerchantAdditionalDetails);\n' +
                            '    pMerchantAdditionalDetails->SellerDetails = pSellerDetails;\n' +
                            '    \n' +
                            '    FillAmountAdditionalDetails(pAmountAdditionalDetailsRequest);\n' +
                            '    \n' +
                            '    FillRequestPayOnLine_2p(pRequest);\n' +
                            '    \n' +
                            '    pRequest->psp_AmountAdditionalDetails = pAmountAdditionalDetailsRequest;\n' +
                            '    pRequest->psp_MerchantAdditionalDetails = pMerchantAdditionalDetails;\n' +
                            '    pRequest->psp_CustomerAdditionalDetails = pCustomerAdditionalDetails;\n' +
                            '    pRequest->psp_OrderDetails = pOrderDetails;\n' +
                            ' \n' +
                            '    pResponse = &Response;\n' +
                            '    showRequest(PAY_ONLINE_2P_TYPE,(char *)pRequest);\n' +
                            '    if (sendRequest(PAY_ONLINE_2P_TYPE, apiKey, (char *)pRequest, pResponse) < 0) {\n' +
                            '      printf("%s\\n", getNpsErrDesc());\n' +
                            '    }\n' +
                            '    else {\n' +
                            '      if (pResponse->psp_ResponseCod && pResponse->psp_ResponseMsg) {\n' +
                            '        printf("psp_ResponseCod [%s]\\n", pResponse->psp_ResponseCod);\n' +
                            '        printf("psp_ResponseMsg [%s]\\n", pResponse->psp_ResponseMsg);\n' +
                            '      }\n' +
                            '\n' +
                            '      showResponse(PAY_ONLINE_2P_TYPE,(char *)pResponse);\n' +
                            '    }\n' +
                            '    \n' +
                            '  return 0;\n' +
                            '}\n' +
                            '\n' +
                            '\n' +
                            'int main( int argc, char **argv)\n' +
                            '{\n' +
                            ' \n' +
                            '  int env=SANBOX_ENV; /*PROD_ENV | SANBOX_ENV | STAGING_ENV*/\n' +
                            '\n' +
                            '  FILE * auxFd = fopen ( "test.log", "a" ) ;\n' +
                            '\n' +
                            '  if (setLog( DEBUG, NULL, auxFd)<0)\n' +
                            '    return;\n' +
                            '\n' +
                            '  if (setEnvironment(env)<0)\n' +
                            '    return;\n' +
                            '\n' +
                            '  SendPayOnLine_2p();\n' +
                            '\n' +
                            '  LogClose();\n' +
                            '\n' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default C;