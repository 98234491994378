import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";


class SimpleCheckout extends React.Component {

    render() {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Simple Checkout</h3>
                    </div>
                    <div className="col-12">
                        <p>
                            This method can only be used by applications developed in the Web technology.
                            The merchant has to communicate through the API in order to tell Worldline
                            Latam server the request and then redirect the customer either to Worldline Latam Platform
                            or to Acquirer environment to fulfill the transaction. <br/><br/>
                            This method also know as 3-party method
                        </p>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Link to={`${apiReferenceURL}/simpleCheckout/CardPayment`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Card Payment
                            </button>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Card Split Payment
                            </button>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={`${apiReferenceURL}/simpleCheckout/CashPayment`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Cash Payment
                            </button>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={`${apiReferenceURL}/simpleCheckout/BankPayment`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Bank Payment
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default SimpleCheckout;