import React from 'react';
import {withRouter} from "react-router";

class Breadcrumbs extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            breadcrumbs: []
        };
    }

    componentDidMount() {
        let location = this.props.location.pathname.split('/');
        let locationFix = [];
        location.shift();
        location.map(function(item){
            let newString = '';
            let sL = item.length;
            let i = 0;
            for (i; i < sL; i++) {
                if (item.charAt(i) === item.charAt(i).toUpperCase() && (item.charAt(i) !== 'I' && item.charAt(i) !== 'N')) {
                    newString += ' '+item.charAt(i);
                }else{
                    newString += item.charAt(i);
                }
            }
            let locationString = newString.trim();
            locationString = locationString.charAt(0).toUpperCase() + locationString.slice(1);
            locationFix.push(locationString);
            return '';
        });
        locationFix = locationFix.filter(function(e){return e}); //Cleanup any empty value
        this.setState({
            breadcrumbs: locationFix
        });
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <nav aria-label="breadcrumb">
                <ul id="breadcrumb-custom">
                    {
                        this.state.breadcrumbs &&
                            this.state.breadcrumbs.map((item,index) =>
                                <li key={index}
                                    aria-current="page">
                                    <span>{item}</span>
                                </li>
                            )
                    }
                </ul>
            </nav>
        );
    }
}

export default withRouter(Breadcrumbs);