import React from 'react';
import T from 'i18react';
import ReactSVG from 'react-svg';

import BankPayment from '../../ApiReference/SimpleCheckout/BankPayment';
import diagram from '../../../assets/img/Bank.svg';
import apiIcon from '../../../assets/img/api.svg';
import * as env from '../../../env';
import {apiReferenceURL} from "../../../env";

class Bank extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showImage: false
        };
        this.loadImagesAndPick = this.loadImagesAndPick.bind(this);
    }

    loadImagesAndPick(){
        let images = {};
        let folder = require.context('../../../assets/img/users/', false, /\.(png|jpe?g|svg)$/)
        folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

        let result;
        let count = 0;
        for (let prop in images)
            if (Math.random() < 1/++count)
                result = prop;

        return images[result];
    }

    render() {
        return (
            <div className="container-fluid simple-checkout bank-checkout">
                <div className="row">
                    <div className="col-12">
                        <h3 id="simpleCheckout.bank.title">
                            {T.translate("simpleCheckout.bank.title")}</h3>
                        <div id="simpleCheckout.bank.text">
                            {T.translate("simpleCheckout.bank.text")}
                        </div>
                    </div>
                    <div className="col-12">
                        <h3 id="simpleCheckout.bank.diagram.title">
                            {T.translate("simpleCheckout.bank.diagram.title")}
                        </h3>
                        <br/><br/>
                        <div className="col-12 checkout-container">
                            <ReactSVG src={diagram}
                                 alt="bank diagram"
                                 className="checkout-image"/>
                            <img src={this.loadImagesAndPick()} alt="1" className="img-fluid checkout-user"/>
                            <img src={env.logo} alt="1" className="img-fluid checkout-logo"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p id="simpleCheckout.bank.flow.text">
                            {T.translate("simpleCheckout.bank.flow.text")}
                        </p>
                        <ol>
                            <li id="simpleCheckout.bank.flow.list.0">
                                {T.translate("simpleCheckout.bank.flow.list.0")}</li>
                            <li id="simpleCheckout.bank.flow.list.1">
                                {T.translate("simpleCheckout.bank.flow.list.1")}</li>
                            <li id="simpleCheckout.bank.flow.list.2.text">
                                {T.translate("simpleCheckout.bank.flow.list.2.text")}
                                <ul>
                                    <li id="simpleCheckout.bank.flow.list.2.list.1">
                                        {T.translate("simpleCheckout.bank.flow.list.2.list.1")}</li>
                                </ul>
                            </li>
                            <li id="simpleCheckout.bank.flow.list.3">
                                {T.translate("simpleCheckout.bank.flow.list.3")}</li>
                            <li id="simpleCheckout.bank.flow.list.4">
                                {T.translate("simpleCheckout.bank.flow.list.4")}</li>
                            <li id="simpleCheckout.bank.flow.list.5">
                                {T.translate("simpleCheckout.bank.flow.list.5")}</li>
                            <li id="simpleCheckout.bank.flow.list.6">
                                {T.translate("simpleCheckout.bank.flow.list.6")}</li>
                            <li id="simpleCheckout.bank.flow.list.7">
                                {T.translate("simpleCheckout.bank.flow.list.7")}</li>
                            <li id="simpleCheckout.bank.flow.list.8">
                                {T.translate("simpleCheckout.bank.flow.list.8")}</li>
                            <li id="simpleCheckout.bank.flow.list.9">
                                {T.translate("simpleCheckout.bank.flow.list.9")}</li>
                            <li id="simpleCheckout.bank.flow.list.10">
                                {T.translate("simpleCheckout.bank.flow.list.10")}</li>
                            <li id="simpleCheckout.bank.flow.list.11">
                                {T.translate("simpleCheckout.bank.flow.list.11")}</li>
                            <li id="simpleCheckout.bank.flow.list.12">
                                {T.translate("simpleCheckout.bank.flow.list.12")}</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("guideReference.apiHelp.bank.detail")}
                                        <br/>
                                        {T.translate("guideReference.apiHelp.all",
                                            {
                                                "api": <T.a href={`${apiReferenceURL}/`} text="Api Reference"/>
                                            }
                                        )}
                                        <br/><br/>
                                        {T.translate("guideReference.apiHelp.example",
                                            {
                                                "method": <T.text text="BankPayment_3p"/>
                                            }
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <BankPayment showDocumentation={false}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Bank;