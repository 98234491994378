import React from 'react';
import CodeHighlight from './CodeHighlight';
import Cookies from '../helpers/Cookies';

import {cookieExpDays} from "../env";

class ExampleSelector extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            cookiesHandler: new Cookies(),
            openDropdown: false,
            options: {},
            clientOptions: {},
            code: '',
            language: 'Language',
            intervalCheck: {}
        };
        this.changeExample = this.changeExample.bind(this);
        this.buildClientLanguage = this.buildClientLanguage.bind(this);
        this.buildLanguageChange = this.buildLanguageChange.bind(this);
        this.correctLangText = this.correctLangText.bind(this);
        this.buildOptions = this.buildOptions.bind(this);
        this.checkCookie = this.checkCookie.bind(this);
        this.createDivider = this.createDivider.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillReceiveProps(nextProps){
        let self = this;
        let prevOptions = this.buildOptions(nextProps.examples);

        let rawCookie = this.state.cookiesHandler.getCookie('language');
        let language = {
            variation: '',
            language: ''
        };
        if(rawCookie){
            language = JSON.parse(rawCookie);
        }
        if(language.language !== ''){
            let example = prevOptions[language.language];
            this.setState({
                options: prevOptions,
                clientOptions: nextProps.clientExamples,
                code: example,
                language: this.correctLangText(language),
                openDropdown: false
            });
        }else if(language.language !== '' && this.props.clientExamples){
            let example =  nextProps.clientExamples[language.language];
            this.setState({
                options: prevOptions,
                clientOptions: nextProps.clientExamples,
                code: example,
                language: this.correctLangText(language),
                openDropdown: false
            });
        }else{
            self.setState({
                options: prevOptions,
                clientOptions: nextProps.clientExamples,
                code: '',
                language: 'Language'
            });
        }
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchend', this.handleClickOutside);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchend', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.state.openDropdown && (this._exampleSelector && !this._exampleSelector.contains(event.target))) {
            this.setState({
                openDropdown: false
            });
        }
    }

    checkCookie(){
        let self = this;
        let prevOptions = this.state.options;
        let rawCookie = this.state.cookiesHandler.getCookie('language');
        let language = {
            variation: '',
            language: ''
        };
        setTimeout(() => {

            if(rawCookie){
                language = JSON.parse(rawCookie);
            }

            if(language.language !== ''){
                let example = prevOptions[language.language];
                self.setState({
                    options: prevOptions,
                    clientOptions: self.props.clientExamples,
                    code: example,
                    language: self.correctLangText(language),
                });
            }else if(language.language !== '' && self.props.clientExamples){
                let example =  self.props.clientExamples[language.language];
                self.setState({
                    options: prevOptions,
                    clientOptions: self.props.clientExamples,
                    code: example,
                    language: self.correctLangText(language),
                });
            }else{
                self.setState({
                    options: prevOptions,
                    clientOptions: self.props.clientExamples,
                    code: '',
                    language: 'Language'
                });
            }
        },2000);
    }

    changeExample(example, language){

        let clientLang = '';
        let serverLang = '';
        let rawCookie = this.state.cookiesHandler.getCookie('language');
        let cookieLang = {};

        if(this.state.clientOptions && this.state.clientOptions[language])
            clientLang = language;

        if(this.state.options && this.state.options[language])
            serverLang = language;

        if(rawCookie){
            let cookieParse = JSON.parse(rawCookie);

            if(serverLang !== '' || clientLang !== ''){
                cookieLang = {
                    variation: cookieParse.variation,
                    language: serverLang ? serverLang : clientLang
                };
            }else{
                cookieLang = {
                    variation: cookieParse.variation,
                    language: cookieParse.language
                };
            }
        }else{
            cookieLang = {
                variation: "",
                language: serverLang ? serverLang : clientLang
            };
        }

        this.state.cookiesHandler.setCookie('language', JSON.stringify(cookieLang), cookieExpDays);
        this.setState({
            code: example,
            language: this.correctLangText(language),
            openDropdown: !this.state.openDropdown
        });

    }

    createDivider(){
        return <div className="dropdown-divider"></div>
    }

    buildClientLanguage(){
        let self = this;
        return Object.getOwnPropertyNames(this.state.clientOptions).map(((value,index) => {
            return <span key={index} className="dropdown-item" onClick={() => self.changeExample(self.state.clientOptions[value],value)}>{self.correctLangText(value)}</span>;
        }));
    }

    buildLanguageChange(){
        let self = this;
        return Object.getOwnPropertyNames(this.state.options).map(((value,index) => {
            return <span key={index} className="dropdown-item" onClick={() => self.changeExample(self.state.options[value],value)}>{self.correctLangText(value)}</span>;
        }));
    }

    buildOptions(options){
        let self = this;
        let prevOptions = this.state.options;
        Object.getOwnPropertyNames(options).forEach(((value) => {
            if(value !== 'length'){
                prevOptions[value] = self.props.examples[value];
            }
        }));
        return prevOptions
    }

    correctLangText(lang){
        let correctLang = '';
        let usrLang = '';
        if(typeof(lang) === 'object'){
            usrLang = lang.language;
        }else{
            usrLang = lang;
        }

        if(usrLang){
            switch (usrLang){
                case 'dotnet':
                    correctLang = '.Net';
                    break;
                case 'node':
                    correctLang = 'Node.js';
                    break;
                default:
                    correctLang = usrLang.charAt(0).toUpperCase() + usrLang.slice(1);
                    break;
            }
        }else{
            correctLang = 'Select';
        }

        return correctLang;
    }

    render() {
        return (
            <div className="panel panel-default example-selector">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-12">
                            <small>Please use selector to change between programming languages</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <div className={this.state.openDropdown ? "dropdown show" : "dropdown"}
                                 ref={(ref) => this._exampleSelector = ref}>
                                <button className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        onClick={() => this.setState({ openDropdown: !this.state.openDropdown })}
                                        aria-expanded="false">
                                    SDK: {this.state.language}
                                </button>
                                <div className={this.state.openDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                    {
                                        this.state.clientOptions &&
                                            this.buildClientLanguage()
                                    }
                                    {
                                        this.state.clientOptions &&
                                           this.createDivider()
                                    }
                                    {
                                        Object.getOwnPropertyNames(this.state.options).length > 0 &&
                                            this.buildLanguageChange()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    {
                        this.state.code === '' &&
                        <CodeHighlight language='bash'>
                            {
                                'if (noLanguageSelected()){\n' +
                                '   echo "Please Select a language;"\n' +
                                '} else {\n' +
                                '   showAwesomeCodeExample();\n' +
                                '}'
                            }
                        </CodeHighlight>
                    }
                    {
                        this.state.code !== '' &&
                        <CodeHighlight language={this.state.language}>
                            {this.state.code}
                        </CodeHighlight>
                    }
                </div>
            </div>
        );
    }
}

export default ExampleSelector;