import React from 'react';
import T from 'i18react';

import Screening from '../../ApiReference/Fraud/Screening';
import Manual from '../../ApiReference/Fraud/Manual';
import {apiReferenceURL, merchantName} from "../../../env";

class RiskAssessment extends React.Component {

    render() {
        return (
            <div className="container-fluid fraud-screening">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.fraudScreening.title">
                            { T.translate("guideReference.services.fraudScreening.title") }
                        </h3>
                        <p id="guideReference.services.fraudScreening.text">
                            { T.translate("guideReference.services.fraudScreening.text") }
                        </p>
                        <p id="guideReference.services.fraudScreening.alternatives.text">
                            { T.translate("guideReference.services.fraudScreening.alternatives.text") }
                        </p>
                        <ul>
                            <li id="guideReference.services.fraudScreening.alternatives.simple">
                                { T.translate("guideReference.services.fraudScreening.alternatives.simple") }</li>
                            <li id="guideReference.services.fraudScreening.alternatives.advanced">
                                { T.translate("guideReference.services.fraudScreening.alternatives.advanced") }</li>
                        </ul>
                        <div className="alert alert-info"
                             id="guideReference.services.fraudScreening.alternatives.information">
                            { T.translate("guideReference.services.fraudScreening.alternatives.information") }
                        </div>
                    </div>
                    <div className="col-12">
                        <p id="guideReference.services.fraudScreening.feature.text">
                            { T.translate("guideReference.services.fraudScreening.feature.text") }
                        </p>
                        <ul>
                            <li id="guideReference.services.fraudScreening.feature.list.1">
                                {T.translate("guideReference.services.fraudScreening.feature.list.1")}</li>
                            <li id="guideReference.services.fraudScreening.feature.list.2">
                                {T.translate("guideReference.services.fraudScreening.feature.list.2")}</li>
                            <li id="guideReference.services.fraudScreening.feature.list.3">
                                { T.translate("guideReference.services.fraudScreening.feature.list.3") }</li>
                        </ul>
                    </div>
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.fraudScreening.methods.title">
                            { T.translate("guideReference.services.fraudScreening.methods.title") }
                        </h4>
                        { T.translate("guideReference.services.fraudScreening.methods.text") }

                        <table className="table additional-information table-responsive">
                            <thead>
                                <tr>
                                    <th rowSpan="2">Field</th>
                                    <th rowSpan="2">Type</th>
                                    <th colSpan="5" style={{textAlign: 'center'}}>Industry</th>
                                </tr>
                                <tr>
                                    <th>Retail</th>
                                    <th>Travel Airlines</th>
                                    <th>Digital good</th>
                                    <th>Ticketing</th>
                                    <th>General</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>psp_CustomerAdditionalDetails</td>
                                    <td>CustomerDetailsType</td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                </tr>
                                <tr>
                                    <td>psp_BillingDetails</td>
                                    <td>BillingDetailsType</td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                </tr>
                                <tr>
                                    <td>psp_ShippingDetails</td>
                                    <td>ShippingDetailsType</td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                </tr>
                                <tr>
                                    <td>psp_OrderDetails</td>
                                    <td>OrderDetailsType</td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                </tr>
                                <tr>
                                    <td>psp_AirlineDetails</td>
                                    <td>AirlineDetailsType</td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                    <td><i className="fa fa-circle-o" aria-hidden="true"/></td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <p id="guideReference.services.fraudScreening.methods.return">
                            { T.translate("guideReference.services.fraudScreening.methods.return") }
                        </p>
                        <table className="table table-documentation  table-responsive">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Brief Description</th>
                                    <th>Detailed Description</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>A</td>
                                <td>ACCEPTED</td>
                                <td>No fraud suspected</td>
                            </tr>
                            <tr>
                                <td>D</td>
                                <td>DENIED</td>
                                <td>Fraud suspected</td>
                            </tr>
                            <tr>
                                <td>R</td>
                                <td>REVIEW</td>
                                <td>Manual revision is required</td>
                            </tr>
                            <tr>
                                <td>N</td>
                                <td>NO SCORE</td>
                                <td>No fraud score</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td>ERROR</td>
                                <td>Technical error. <br/> Service communication or configuration error</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 table-overflow-x">
                        <h3 id="guideReference.services.fraudScreening.integrated.title">
                            { T.translate("guideReference.services.fraudScreening.integrated.title") }
                        </h3>
                        <p id="guideReference.services.fraudScreening.integrated.text">
                            { T.translate("guideReference.services.fraudScreening.integrated.text",{"whiteLabel": <T.text text={merchantName}/>})}
                        </p>
                        <h4 id="guideReference.services.fraudScreening.integrated.before.title">
                            { T.translate("guideReference.services.fraudScreening.integrated.before.title") }
                        </h4>
                        <p id="guideReference.services.fraudScreening.integrated.before.text">
                            { T.translate("guideReference.services.fraudScreening.integrated.before.text") }
                        </p>
                        <p id="guideReference.services.fraudScreening.integrated.before.actions">
                            { T.translate("guideReference.services.fraudScreening.integrated.before.actions") }
                        </p>
                        <ul>
                            <li id="guideReference.services.fraudScreening.integrated.before.list.1">
                                {T.translate("guideReference.services.fraudScreening.integrated.before.list.1")}</li>
                            <li id="guideReference.services.fraudScreening.integrated.before.list.2">
                                {T.translate("guideReference.services.fraudScreening.integrated.before.list.2")}</li>
                            <li id="guideReference.services.fraudScreening.integrated.before.list.3">
                                {T.translate("guideReference.services.fraudScreening.integrated.before.list.3")}</li>
                            <li id="guideReference.services.fraudScreening.integrated.before.list.4">
                                { T.translate("guideReference.services.fraudScreening.integrated.before.list.4") }</li>
                        </ul>

                        <p id="guideReference.services.fraudScreening.integrated.before.result">
                            { T.translate("guideReference.services.fraudScreening.integrated.before.result") }
                        </p>
                        <table className="table table-documentation table-responsive">
                            <thead>
                                <tr>
                                    <th>Method</th>
                                    <th>Manual Review Result</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Authorization + Capture</td>
                                <td>ACCEPTED</td>
                                <td>Send Capture Transaction</td>
                            </tr>
                            <tr>
                                <td>Authorization + Capure</td>
                                <td>DENIED</td>
                                <td>No send the Capture Transaction <br/> Invoke the method NotifyFraudScreeningReview(D)</td>
                            </tr>
                            <tr>
                                <td>PayOnline</td>
                                <td>ACCEPTED</td>
                                <td>Invoke the method NotifyFraudScreeningReview(A)</td>
                            </tr>
                            <tr>
                                <td>PayOnline</td>
                                <td>DENIED</td>
                                <td>Invoke the method NotifyFraudScreeningReview(D) <br/> Cancel (Refund) the payment transaction</td>
                            </tr>
                            </tbody>
                        </table>

                        <h4 id="guideReference.services.fraudScreening.integrated.after.title">
                            { T.translate("guideReference.services.fraudScreening.integrated.after.title") }
                        </h4>
                        <p id="guideReference.services.fraudScreening.integrated.after.text">
                            { T.translate("guideReference.services.fraudScreening.integrated.after.text") }
                        </p>
                        <div className="alert alert-warning"
                             id="guideReference.services.fraudScreening.integrated.after.warning.text">
                            <b id="guideReference.services.fraudScreening.integrated.after.warning.title">
                                { T.translate("guideReference.services.fraudScreening.integrated.after.warning.title") }</b><br/>
                            { T.translate("guideReference.services.fraudScreening.integrated.after.warning.text") }
                        </div>
                    </div>
                    <div className="col-12">
                        <h3 id="guideReference.services.fraudScreening.standalone.title">
                            { T.translate("guideReference.services.fraudScreening.standalone.title") }
                        </h3>
                        <p id="guideReference.services.fraudScreening.standalone.text">
                            { T.translate("guideReference.services.fraudScreening.standalone.text") }
                            <br/><br/>
                            { T.translate("guideReference.services.fraudScreening.standalone.api") }
                            <a href={`${apiReferenceURL}/fraud`}>
                                Api Reference - Fraud
                            </a>
                            <br/><br/>
                        </p>
                        <Screening showDocumentation={false}/>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.fraudScreening.manual.title">
                            { T.translate("guideReference.services.fraudScreening.manual.title") }
                        </h3>
                        <p id="guideReference.services.fraudScreening.manual.text">
                            { T.translate("guideReference.services.fraudScreening.manual.text") }
                        </p>
                        <p id="guideReference.services.fraudScreening.manual.listText">
                            { T.translate("guideReference.services.fraudScreening.manual.listText") }
                        </p>
                        <ul>
                            <li id="guideReference.services.fraudScreening.manual.list.1">
                                { T.translate("guideReference.services.fraudScreening.manual.list.1") }</li>
                            <li id="guideReference.services.fraudScreening.manual.list.2">
                                { T.translate("guideReference.services.fraudScreening.manual.list.2") }</li>
                        </ul>
                        <Manual showDocumentation={false}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default RiskAssessment;