import React from 'react';
import T from "i18react/dist/i18react";
import {Tabs, Tab} from 'react-bootstrap-tabs';

import Create from '../../../../ApiReference/PaymentMethodToken/Create';
import Sale from '../../../../ApiReference/Payments/CardPayment/Sale';
import CodeHighlight from '../../../../../components/CodeHighlight';
import masterPassIcon from '../../../../../assets/img/wallets/masterpass.png';

class MasterpassNonPCI extends React.Component {

    render() {
        return (
            <div className="container-fluid wallets">
                <div className="row">
                    <div className="col-12">
                        <h4 className="wallets-title">
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.title")}
                        </h4>
                        <img src={masterPassIcon} alt="VisaCheckout" className="watermark-image"/>
                        <p>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.text")}
                        </p>
                        <ul>
                            <li>{T.translate("guideReference.services.wallets.masterpass.nonPci.list.1")}</li>
                            <li>{T.translate("guideReference.services.wallets.masterpass.nonPci.list.2")}</li>
                            <li>{T.translate("guideReference.services.wallets.masterpass.nonPci.list.3")}</li>
                            <li>{T.translate("guideReference.services.wallets.masterpass.nonPci.list.4")}</li>
                        </ul>
                        <h5>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.title")}
                        </h5>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.text",
                                {
                                    "onboarding": <T.a
                                        href="https://developer.mastercard.com/documentation/masterpass-merchant-onboarding"
                                        target="_blank" text="Mastercard developers portal"/>
                                })}

                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.use")}
                        </p>
                        <Tabs>
                            <Tab label="Sandbox">
                                <CodeHighlight language="HTML">
                                    {
                                        '<script type="text/javascript" src="https://sandbox.masterpass.com/integration/merchant.js"></script>'
                                    }
                                </CodeHighlight>
                            </Tab>
                            <Tab label="Production">
                                <CodeHighlight language="HTML">
                                    {
                                        '<script type="text/javascript" src="https://masterpass.com/integration/merchant.js"></script>'
                                    }
                                </CodeHighlight>
                            </Tab>
                        </Tabs>
                        <p>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.button")}
                        </p>

                        <CodeHighlight language="HTML">
                            {
                                '<img id="BUTTON_ID" src="https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg"/>'
                            }
                        </CodeHighlight>
                        <div className="alert alert-info">
                            Insert the Masterpass Button URL on your checkout page
                            (refer to the <a href="https://developer.mastercard.com/page/masterpass-digital-assets#masterpass-button" target="_blank" rel="noopener noreferrer">Masterpass Digital Assets</a> page for all approved button and acceptance mark URLs).
                            <br/><br/>
                            Note: The masterpass button must be hosted on a page containing a domain name rather than IP address in the URL otherwise you will not be able to successfully invoke the Masterpass UI, as discuss below.
                        </div>
                        <p>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.handler")}
                        </p>
                        <CodeHighlight language="javascript">
                            {
                                'document.getElementById("BUTTON_ID")\n' +
                                '   .addEventListener("click", function(){ \n' +
                                '       masterpass.checkout({\n' +
                                '           "checkoutId": "c26966b0eae94a3fbe47f994b94394b2",                                   // Required\n' +
                                '           "allowedCardTypes": ["master,amex,diners,discover,jcb,maestro,visa"],               // Required\n' +
                                '           "amount": "1200.50",                                                                // Required\n' +
                                '           "currency": "USD",                                                                  // Required\n' +
                                '           "shippingLocationProfile": "US,AU,BE",                                              // Optional\n' +
                                '           "suppress3Ds": false,                                                               // Optional\n' +
                                '           "suppressShippingAddress": false,                                                   // Optional\n' +
                                '           "cartId": "1efed583-1824-436a-869f-286ebdb22ae9",                                   // Required\n' +
                                '           "callbackUrl": "{MERCHANT_CALLBACK_URL}"                                            // Optional\n' +
                                '       });\n' +
                                '   }\n' +
                                ');'
                            }
                        </CodeHighlight>
                        <p>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.requirements")}
                        </p>
                    </div>
                    <div className="col-12 table-overflow-x">
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td><strong>Parameter</strong></td>
                                <td><strong>Data Type</strong></td>
                                <td><strong>Required</strong></td>
                                <td><strong>Description</strong></td>
                            </tr>
                            <tr>
                                <td>&nbsp;<strong>checkoutId</strong></td>
                                <td>&nbsp;String</td>
                                <td>&nbsp;Yes</td>
                                <td>Unique 32-character alphanumeric identifier generated by Masterpass, which identifies your settings during a checkout; you can find this value in your Mastercard Developers account.&nbsp;
                                    <br />Example: 003901a19ce64f86992d1c0d3eeda26d</td>
                            </tr>
                            <tr>
                                <td>&nbsp;<strong>allowedCardTypes</strong></td>
                                <td>&nbsp;String array</td>
                                <td>&nbsp;Yes</td>
                                <td>List of payment card brands supported by you; default list of payment card brands can be set up in Merchant Portal and overwritten at run time.&nbsp;
                                    <br />Example:&nbsp;["master,amex,diners,discover,jcb,maestro,visa"]</td>
                            </tr>
                            <tr>
                                <td>&nbsp;<strong>amount</strong></td>
                                <td>&nbsp;String</td>
                                <td>&nbsp;Yes</td>
                                <td>Decimal transaction amount encoded as a JSON string. Note that only a . (period) can be used as the decimal separator irrespective of locale. No other digit separators should be used. For example, do not use commas for 1000s.&nbsp;
                                    <br />Example: 1200.50 ($1200.50)</td>
                            </tr>
                            <tr>
                                <td>&nbsp;<strong>currency</strong></td>
                                <td>&nbsp;String</td>
                                <td>&nbsp;Yes</td>
                                <td>ISO-4217 code for currency of the transaction.&nbsp;<br />Example: USD</td>
                            </tr>
                            <tr>
                                <td>&nbsp;<strong>cartId</strong></td>
                                <td>&nbsp;String</td>
                                <td>&nbsp;Yes</td>
                                <td>Merchant Cart identifier</td>
                            </tr>
                            <tr>
                                <td>&nbsp;<strong>callbackUrl</strong></td>
                                <td>&nbsp;String</td>
                                <td>&nbsp;No</td>
                                <td>This optional parameter can be used to override the callbackUrl specified in the Merchant Portal. Note that the callbackUrl value specified must match the same top level domain (as identified by Google&rsquo;s domain library) as the callbackUrl configured in the Merchant Portal.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12">
                        <br/>
                        <div className="alert alert-info">
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.1.info",
                                {
                                    "link": <T.a
                                        href="https://developer.mastercard.com/documentation/masterpass-merchant-integration-v7/7"
                                        target="_blank" text="MasterPass Integration"/>
                                })}
                        </div>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.2.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.2.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="col-12">
                            <strong>2.1: Get callback url parameters.</strong> <br/>

                            After you made the masterpass checkout, the library calls the url provided in the previous step with the following parameters.
                            <br/>
                            <CodeHighlight language="bash">
                                {
                                    'https://{MERCHANT_CALLBACK_URL}/?\n' +
                                    'oauth_token=a561da1c18a89c53a4f9875f9d43fc46cd9bf3e1                       # Request Token \n' +
                                    '&oauth_verifier=d084703513e87cd1540a114cd7317e6642eca04e                   # Verification token, required for next steps \n' +
                                    '&checkoutId=7906244152148449430                                            # Unique identifier for the checkout process\n' +
                                    '&checkout_resource_url={MASTERPASS_ENVIRONMENT}                            # Masterpass environment setting where request was made\n' +
                                    '&mpstatus=success                                                          # Flag to check if it any error was made\n'
                                }
                            </CodeHighlight>

                            <div className="alert alert-danger">
                                After you get the parameters on your application you must save the <code>oauth_verifier</code> field and use it as the <code>WalletKey</code>
                                for the next step.
                            </div>
                        </div>
                        <div className="col-12">
                            <strong>2.2: Create a token for the provided payment method.</strong> <br/>
                            <Create showDocumentation={false} onlyClientExamples={false} masterpass={true} onlyFiles='masterpass'/>

                            <div className="alert alert-info flex-v-center">
                                <p>
                                    If you want to receive the Shipping Details you must provide the <code>MerchOrderId</code>, which is the same as the <code>cart_id</code> provided before.
                                </p>
                            </div>

                        </div>
                        <div className="col-12">
                            <strong>2.3: After your create a token, you must make a PayOnline_2p.</strong>
                            <br/>
                            <Sale showDocumentation={false} onlyFiles='masterpass_non_pci'/>
                            <br/>
                            <div className="alert alert-info">
                                The Payment method token is the one in the response of previous step (CreatePaymentMethodToken)
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <br/>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.3.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.3.text",
                                {"link": <T.a href="https://developer.mastercard.com/page/masterpass-sandbox-testing-guidelines" target="_blank" text="Masterpass testing documentation"/>})}

                        </p>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.4.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.nonPci.how.steps.4.text",
                                {"link": <T.a href="mailto:merchantservices@worldline.com" text="Support Team"/>})}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default MasterpassNonPCI;