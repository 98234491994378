import React from 'react';
import { Link } from 'react-router-dom';

import {apiReferenceURL} from "../../env";

class Landing extends React.Component {
    render() {
        return (
            <div className="container-fluid landing">
                <div className="row">
                    <div className="col-12">
                        <h3>Api Reference</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/simpleCheckout`}>
                                    Simple Checkout
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/simpleCheckout/CardPayment`}>
                                            Card Payment
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link to={`${apiReferenceURL}/simpleCheckout/CardPayment/Sale`}>
                                                    Sale
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${apiReferenceURL}/simpleCheckout/CardPayment/Authorization`}>
                                                    Authorization
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment`}>
                                            Card split Payment
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Sale`}>
                                                    Sale
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Authorization`}>
                                                    Authorization
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/simpleCheckout/CashPayment`}>
                                            Cash Payment
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/simpleCheckout/BankPayment`}>
                                            Bank Payment
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/payments`}>
                                    Payments
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/payments/CardPayment`}>
                                            Card Payment
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link to={`${apiReferenceURL}/payments/CardPayment/Sale`}>
                                                    Sale
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${apiReferenceURL}/payments/CardPayment/Authorization`}>
                                                    Authorization
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/payments/Capture`}>
                                            Capture
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/payments/Refund`}>
                                            Refund
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/payments/RetrieveTransactions`}>
                                            Retrieve Transactions
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link to={`${apiReferenceURL}/payments/RetrieveTransactions/SimpleQueryTx`}>
                                                    SimpleQueryTx
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${apiReferenceURL}/payments/RetrieveTransactions/QueryTxs/`}>
                                                    QueryTxs
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/payments/RetrieveInstallments`}>
                                            Retrieve Installment
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/services`}>
                                    Services
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/services/CardNumber`}>
                                            Get Card Number
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/services/IINLookup`}>
                                            IIN Lookup
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/services/ClientSession`}>
                                            Client Session
                                        </Link>
                                    </li><li>
                                        <Link to={`${apiReferenceURL}/services/ChangeSecretKey`}>
                                            Change Secret Key
                                        </Link>
                                    </li>
                                    {/*<li>*/}
                                        {/*<Link to={`${apiReferenceURL}/services/QueryCardDetails`}>*/}
                                            {/*Query Card Details*/}
                                        {/*</Link>*/}
                                    {/*</li>*/}
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <hr/>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/paymentMethodToken`}>
                                    Payment Method Token
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethodToken/Create`}>
                                            Create
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethodToken/Recache`}>
                                            Recache
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethodToken/Retrieve`}>
                                            Retrieve
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/paymentMethod`}>
                                    Payment Method
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethod/Create`}>
                                            Create
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethod/CreateFromPayment`}>
                                            Create From Payment
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethod/Retrieve`}>
                                            Retrieve
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethod/Update`}>
                                            Update
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/paymentMethod/Delete`}>
                                            Delete
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/customer`}>
                                    Customer
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/customer/Create`}>
                                            Create
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/customer/Retrieve`}>
                                            Retrieve
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/customer/Update`}>
                                            Update
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/customer/Delete`}>
                                            Delete
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <hr/>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/fraud`}>
                                    Fraud Prevention
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`${apiReferenceURL}/fraud/Screening`}>
                                            Fraud Screening
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${apiReferenceURL}/fraud/Manual`}>
                                            Notify Manual Review
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/complex`}>
                                Complex Types
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/reference`}>
                                    Reference Tables
                                </Link>
                            </li>
                        </ul>
                        <ul className="tree">
                            <li>
                                <Link to={`${apiReferenceURL}/overview`}>
                                    Api Environments
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Landing;