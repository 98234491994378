import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";

class Customer extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: "PaymentMethod Component"
        };
    }

    render() {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Customer</h3>
                    </div>
                    <div className="col-12">
                        <p>
                            By using this entity you can save and link information like Payment Method, address, person,
                            etc. And it is not necessary to create in your Backend a structure or logic to store Tokens (Payment Methods).
                        </p>
                        <hr/>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 25}}>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/customer/Create`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Create
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/customer/Update`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Update
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <Link to={`${apiReferenceURL}/customer/Retrieve`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Retrieve
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/customer/Delete`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Delete
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Customer;