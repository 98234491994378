import React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import HelperFunctions from '../../helpers/PaymentMethodsSelects'

import paymentMethods from '../../documentation/payment_methods';

class PaymentMethods2 extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            helperFunctions: new HelperFunctions(),
            selectValues: {
                country: [],
                currency: [],
                type: []
            },
            flags: [],
            products: {
                card: [],
                bank: [],
                cash: []
            },
            productsFiltered: [],
            productsFilteredRaw: {},
            countries: [],
            types: [],
            currency: [],
            activeFilter: false,
            active:{
                countries:[],
                types: [],
                currencies: []
            }
        };

        this.filter = this.filter.bind(this);
        this.formatData = this.formatData.bind(this);
        this.setActiveType = this.setActiveType.bind(this);
        this.setActiveCountry = this.setActiveCountry.bind(this);
        this.setActiveCurrency = this.setActiveCurrency.bind(this);
    }
    componentDidMount() {
        let dataFixed = this.formatData(paymentMethods);

        this.setState({
            products: dataFixed.products,
            countries: dataFixed.countries,
            types: dataFixed.types,
            currency: dataFixed.currency
        });

        this.setActiveType = this.setActiveType.bind(this);
        this.setActiveCountry = this.setActiveCountry.bind(this);
        this.setActiveCurrency = this.setActiveCurrency.bind(this);
    }

    setActiveCurrency(selectedOption){
        let options = [];

        if (selectedOption) {
            selectedOption.forEach((option) => {
                options.push(option.value);
            });
        }

        this.setState({
            productsFiltered: [],
            selectValues: {
                currency: selectedOption,
                country: this.state.selectValues.country,
                type: this.state.selectValues.type
            },
            active: {
                countries: this.state.active.countries,
                types: this.state.active.types,
                currencies: options
            }
        });

        setTimeout(() => {
            this.filter();
        },200);
    }
    setActiveCountry(selectedOption) {
        let options = [];

        if (selectedOption) {
            selectedOption.forEach((option) => {
                options.push(option.value);
            });
        }

        this.setState({
            productsFiltered: [],
            selectValues: {
                currency: this.state.selectValues.currency,
                country: selectedOption,
                type: this.state.selectValues.type
            },
            active: {
                countries: options,
                types: this.state.active.types,
                currencies: this.state.active.currencies
            }
        });

        setTimeout(() => {
            this.filter();
        },200);
    }
    setActiveType(selectedOption){
        let options = [];
        if (selectedOption) {
            options.push(selectedOption.value);
        }

        this.setState({
            productsFiltered: [],
            selectValues: {
                currency: this.state.selectValues.currency,
                country: this.state.selectValues.country,
                type: selectedOption
            },
            active: {
                countries: this.state.active.countries,
                types: options,
                currencies: this.state.active.currencies
            }
        });

        setTimeout(() => {
            this.filter();
        },200);
    }

    filter(){
        let results = paymentMethods;
        let self = this;
        if(this.state.active.types.length > 0){
            let tmp = {};
            tmp[this.state.active.types[0]] = results[this.state.active.types[0]];

            if(Object.keys(tmp).length !== 0 ){
                results = tmp;
            }
        }

        if(this.state.active.countries.length > 0){
            let tmp = {};
            Object.keys(results).forEach((type) => {
                tmp[type] = {};
                Object.keys(results[type]).forEach((country) => {
                    if(self.state.active.countries.includes(country)){
                        tmp[type][country] = results[type][country];
                    }
                });
            });
            if(Object.keys(tmp).length !== 0 ){
                results = tmp;
            }
        }

        if(this.state.active.currencies.length > 0){
            let tmp = {};
            Object.keys(results).forEach((type) => {
                tmp[type] = {};
                Object.keys(results[type]).forEach((country) => {
                    tmp[type][country] = [];
                    results[type][country].forEach((data) => {
                        self.state.active.currencies.forEach((curren) => {
                            let tmpArray = data.currency.split(',');
                            if(tmpArray.indexOf(curren) !== -1){
                                tmp[type][country].push(data);
                            }
                        });
                    });
                });
            });

            if(Object.keys(tmp).length !== 0 ){
                results = tmp;
            }
        }


        //TODO: ONLY GET THOSE WHO ARE SHARED
        let productsOnly = [];

        Object.keys(results).forEach((type) => {
            productsOnly[type] = [];
            if(Object.keys(results[type]).length !== 0 ){
                Object.keys(results[type]).forEach((country, countryIndex) => {
                    let tmp = [];
                    if((type === 'bank' || type === 'cash') && country === 'AR'){
                        console.log(results);
                        if(Object.keys(results['card']).length < 2){
                            results[type][country].forEach((products) => {
                                if(countryIndex === 0){
                                    tmp.push(products);
                                }else{
                                    if(productsOnly.indexOf(products)){
                                        tmp.push(products);
                                    }
                                }
                            });
                        }

                    }else{
                        results[type][country].forEach((products) => {
                            if(countryIndex === 0){
                                tmp.push(products);
                            }else{
                                if(productsOnly.indexOf(products)){
                                    tmp.push(products);
                                }
                            }
                        });
                    }



                    if(tmp.length > 0){
                        productsOnly[type] = tmp;
                    }
                });
            }
        });

        let dataFixed = this.formatData(results);
        let dataFixed2 = this.formatData(paymentMethods);
            dataFixed.countries = dataFixed2.countries;
            dataFixed.types = dataFixed2.types;
            dataFixed.currency = dataFixed2.currency;
        if(this.state.active.currencies.length > 0 || this.state.active.countries.length > 0 || this.state.active.countries.length > 0) {
            dataFixed.products = productsOnly;
        }


        console.log(dataFixed);

        this.setState({
            products: dataFixed.products,
            countries: dataFixed.countries,
            types: dataFixed.types,
            currency: dataFixed.currency
        });
    }

    formatData(items){
        let products = {
            card: [],
            bank: [],
            cash: []
        };

        let countries = [];
        let types = [];
        let currency = [];

        Object.keys(items).forEach((type) => {
            types.push(type);
            Object.keys(items[type]).forEach((country) => {
                if(countries.indexOf(country) === -1){
                    countries.push(country);
                }
                items[type][country].forEach((product) => {
                    if(!products[type][product.product]){
                        products[type][product.product] = product;
                    }
                    let tmpCurrency = product.currency.split(',');
                    tmpCurrency.map((tmp) =>{
                        if(currency.indexOf(tmp) === -1){
                            currency.push(tmp);
                        }
                        return false;
                    });
                })
            });
        });

        return {products,countries,types,currency}
    }


    render() {
        let countryOptions = [];
        let typeOptions = [];
        let currencyOptions = [];

        this.state.countries.forEach((country) => {
            countryOptions.push({
                value: country,
                label: country
            });
        });
        this.state.types.map((type) => {
            typeOptions.push({
                value: type,
                label: type
            });
            return false;
        });
        this.state.currency.map((currency) => {
            currencyOptions.push({
                value: currency,
                label: currency
            });
            return false;
        });


        return (
            <div className="container-fluid payment-methods">
                <div className="row">
                    <div className="col-12">

                    </div>
                    <div className="col-12 col-md-4 countries-filters">
                        <h3>
                            Search by Country</h3>
                        <div className="countries-wrapper">
                            <Select
                                name="form-field-name"
                                multi={true}
                                value={this.state.selectValues.country}
                                onChange={this.setActiveCountry}
                                optionComponent={this.state.helperFunctions.countryOptionRender}
                                valueRenderer={this.state.helperFunctions.countryValueRender}
                                options={countryOptions}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 type-filters">
                        <h3>
                            Search by Type
                        </h3>
                        <div className="types-wrapper">
                            <Select
                                name="form-field-name"
                                value={this.state.selectValues.type}
                                onChange={this.setActiveType}
                                optionComponent={this.state.helperFunctions.typeOptionRender}
                                valueRenderer={this.state.helperFunctions.typeValueRender}
                                options={typeOptions}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 currency-filters">
                        <h3>
                            Search by Currency
                        </h3>
                        <div className="currency-wrapper">
                            <Select
                                name="form-field-name"
                                multi={true}
                                value={this.state.selectValues.currency}
                                onChange={this.setActiveCurrency}
                                optionComponent={this.state.helperFunctions.currencyOptionRender}
                                valueRenderer={this.state.helperFunctions.currencyValueRender}
                                options={currencyOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>
                            {
                                !this.state.activeFilter &&
                                'Cards'
                            }
                        </h3>
                    </div>
                    <div className="col-12 image-cards-filters">
                        {
                            this.state.products.card.map((product,index) => {
                                return <div key={index} className={this.state.helperFunctions.translateCardCode(product)}/>
                            })
                        }
                        {
                            this.state.productsFiltered.map((product,index) => {
                                return <div key={index} className={this.state.helperFunctions.translateCardCode(product)}/>
                            })
                        }
                    </div>
                </div>
                    <div className="row">
                        <div className="col-12">
                            <h3>Bank</h3>
                        </div>
                        <div className="col-12 image-cards-filters">
                            {
                                this.state.products.bank.map((product,index) => {
                                    return <div key={index} className={this.state.helperFunctions.translateCardCode(product)}/>
                                })
                            }
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h3>Cash</h3>
                        </div>
                        <div className="col-12 image-cards-filters">
                            {
                                this.state.products.cash.map((product,index) => {
                                    return <div key={index} className={this.state.helperFunctions.translateCardCode(product)}/>
                                })
                            }
                        </div>

                    </div>
            </div>
        );
    }
}

export default PaymentMethods2;