import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const Ruby = () => {
    return (
        <div className="col-12">
            <div className="ruby-library"/>
            <div className="row">
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-ruby" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        2.2 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.ruby.install">
                        {T.translate("libraries.server.ruby.install")}
                    </h4>
                    <CodeHighlight languague="Bash">
                        {
                            'gem install nps_sdk'
                        }
                    </CodeHighlight>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.ruby.configuration.title">
                        {T.translate("libraries.server.ruby.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.ruby.configuration.basic" open
                        title={T.translate("libraries.server.ruby.configuration.basic")}>
                        <CodeHighlight languague="Ruby">
                            {
                                'require \'nps_sdk\'\n' +
                                '\n' +
                                'conf = Nps::Configuration.new\n' +
                                'conf.environment = Nps::Environments::SANDBOX_ENV' +
                                'conf.key = "_YOUR_SECRET_KEY_"\n'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.ruby.configuration.advanced.title" open
                        title={T.translate("libraries.server.ruby.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <h4 id="libraries.server.ruby.configuration.advanced.log.title">
                                    {T.translate("libraries.server.ruby.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.ruby.configuration.advanced.log.text">
                                    {T.translate("libraries.server.ruby.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="Ruby">
                                    {
                                        'require \'nps_sdk\'\n' +
                                        'require \'logger\'\n' +
                                        '\n' +
                                        'conf = Nps::Configuration.new\n' +
                                        'conf.environment = Nps::Environments::SANDBOX_ENV' +
                                        'conf.key = "_YOUR_SECRET_KEY_"\n' +
                                        'conf.logger = Logger.new(STDOUT)\n'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.ruby.configuration.advanced.log.level">
                                    {T.translate("libraries.server.ruby.configuration.advanced.log.level")}
                                </p>
                                <CodeHighlight languague="Ruby">
                                    {
                                        'require \'nps_sdk\'\n' +
                                        'require \'logger\'\n' +
                                        '\n' +
                                        'conf = Nps::Configuration.new\n' +
                                        'conf.environment = Nps::Environments::SANDBOX_ENV' +
                                        'conf.key = "_YOUR_SECRET_KEY_"\n' +
                                        'conf.logger = Logger.new(STDOUT)\n' +
                                        'conf.log_level = Logger::DEBUG'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.ruby.configuration.advanced.sanitize.title">
                                    {T.translate("libraries.server.ruby.configuration.advanced.sanitize.title")}
                                </h4>
                                <p id="libraries.server.ruby.configuration.advanced.sanitize.text">
                                    {T.translate("libraries.server.ruby.configuration.advanced.sanitize.text")}
                                </p>
                                <CodeHighlight languague="Ruby">
                                    {
                                        'require \'nps_sdk\'\n' +
                                        '\n' +
                                        'conf = Nps::Configuration.new\n' +
                                        'conf.environment = Nps::Environments::SANDBOX_ENV' +
                                        'conf.key = "_YOUR_SECRET_KEY_"\n' +
                                        'conf.sanitize = true'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.ruby.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.ruby.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.ruby.configuration.advanced.timeout.text">
                                    {T.translate("libraries.server.ruby.configuration.advanced.timeout.text")}
                                </p>
                                <CodeHighlight languague="Ruby">
                                    {
                                        'require \'nps_sdk\'\n' +
                                        '\n' +
                                        'conf = Nps::Configuration.new\n' +
                                        'conf.environment = Nps::Environments::SANDBOX_ENV' +
                                        'conf.key = "_YOUR_SECRET_KEY_"\n' +
                                        'conf.o_timeout = 10\n' +
                                        'conf.r_timeout = 60'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.ruby.configuration.advanced.proxy.title">
                                    {T.translate("libraries.server.ruby.configuration.advanced.proxy.title")}
                                </h4>
                                <p id="libraries.server.ruby.configuration.advanced.proxy.text">
                                    {T.translate("libraries.server.ruby.configuration.advanced.proxy.text")}
                                </p>
                                <CodeHighlight languague="Ruby">
                                    {
                                        'require \'nps_sdk\'\n' +
                                        '\n' +
                                        'conf = Nps::Configuration.new\n' +
                                        'conf.environment = Nps::Environments::SANDBOX_ENV' +
                                        'conf.key = "_YOUR_SECRET_KEY_"\n' +
                                        'conf.proxy_url = "YOUR/PROXY/URL"\n' +
                                        'conf.proxy_username = "USERNAME"\n' +
                                        'conf.proxy_password = "YOUR_PASSWORD"'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.ruby.configuration.advanced.customUrl.title">
                                    {T.translate("libraries.server.ruby.configuration.advanced.customUrl.title")}
                                </h4>
                                <p id="libraries.server.ruby.configuration.advanced.customUrl.text">
                                    {T.translate("libraries.server.ruby.configuration.advanced.customUrl.text")}
                                </p>
                                <CodeHighlight languague="Ruby">
                                    {
                                        'require \'nps_sdk\'\n' +
                                        'conf.key = "Secret key" \n' +
                                        'conf = Nps::Configuration.new \n' +
                                        'conf.environment = Nps::Environments::CUSTOM_ENV \n' +
                                        'conf.custom_env_urls = ["https://first_url.com.ar", "https://second_url.com.ar"]'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.ruby.environments.title">
                        {T.translate("libraries.server.ruby.environments.title")}
                    </h4>
                    <p id="libraries.server.ruby.environments.text">
                        {T.translate("libraries.server.ruby.environments.text")}
                    </p>
                    <CodeHighlight languague="Ruby">
                        {
                            'require \'nps_sdk\'\n' +
                            '\n' +
                            'conf = Nps::Configuration.new\n' +
                            'conf.environment = Nps::Environments::SANDBOX_ENV\n' +
                            'conf.environment = Nps::Environments::STAGING_ENV\n' +
                            'conf.environment = Nps::Environments::PRODUCTION_ENV'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.ruby.error.title">
                        {T.translate("libraries.server.ruby.error.title")}
                    </h4>
                    <p id="libraries.server.ruby.error.text">
                        {T.translate("libraries.server.ruby.error.text")}
                    </p>
                    <div id="libraries.server.ruby.error.information"
                        className="alert alert-info"
                         role="alert">
                        {T.translate("libraries.server.ruby.error.information")}
                    </div>
                    <CodeHighlight languague="Ruby">
                        {
                            'require \'nps_sdk\'\n' +
                            '\n' +
                            'conf = Nps::Configuration.new\n' +
                            '\n' +
                            '#Code\n' +
                            'begin\n' +
                            '  #Code or sdk call\n' +
                            'rescue => e\n' +
                            '  puts e.message\n' +
                            'end'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.ruby.example.title">
                        {T.translate("libraries.server.ruby.example.title")}
                    </h4>
                    <p id="libraries.server.ruby.example.text">
                        {T.translate("libraries.server.ruby.example.text")}
                    </p>
                    <CodeHighlight languague="Ruby">
                        {
                            'require \'nps_sdk\'\n' +
                            '\n' +
                            'conf = Nps::Configuration.new\n' +
                            'conf.environment = Nps::Environments::SANDBOX_ENV\n' +
                            'conf.key = "_YOUR_SECRET_KEY_"\n' +
                            '\n' +
                            'npssdk = Nps::Sdk.new(conf)\n' +
                            '\n' +
                            'params = {\n' +
                            '    \'psp_Version\'          => \'2.2\',\n' +
                            '    \'psp_MerchantId\'       => \'psp_test\',\n' +
                            '    \'psp_TxSource\'         => \'WEB\',\n' +
                            '    \'psp_MerchTxRef\'       => \'ORDER5rf666-3\',\n' +
                            '    \'psp_MerchOrderId\'     => \'ORDER5rf666\',\n' +
                            '    \'psp_Amount\'           => \'1000\',\n' +
                            '    \'psp_NumPayments\'      => \'1\',\n' +
                            '    \'psp_Currency\'         => \'032\',\n' +
                            '    \'psp_Country\'          => \'ARG\',\n' +
                            '    \'psp_Product\'          => \'14\',\n' +
                            '    \'psp_CustomerMail\'     => \'john.doe@example.com\',\n' +
                            '    \'psp_CardNumber\'       => \'4507990000000010\',\n' +
                            '    \'psp_CardExpDate\'      => \'1903\',\n' +
                            '    \'psp_CardSecurityCode\' => \'306\',\n' +
                            '    \'psp_SoftDescriptor\'   => \'Sol Tropical E\',\n' +
                            '    \'psp_PosDateTime\'      => \'2016-12-01 12:00:00\'\n' +
                            '}\n' +
                            '\n' +
                            'begin\n' +
                            '  resp = npssdk.authorize_2p(params)\n' +
                            'rescue => e\n' +
                            '  #Code to handle the error\n' +
                            'end'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default Ruby;