import React from 'react';
import T from 'i18react';

import CodeHighlight from '../../components/CodeHighlight';
import * as env from '../../env';

class GuideReference extends React.Component {

    componentDidMount(){
        this.props.scrollToElement();
    }

    componentDidUpdate(){
        this.props.scrollToElement();
    }

    render() {
        return (
            <div className="container-fluid guide-reference">
                <div className="row">
                    <div className="col-12">
                        <h3>
                            {T.translate("guideReference.title")}
                        </h3>
                        <p>
                            {T.translate("guideReference.text")}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4>
                            {T.translate("guideReference.overview.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.overview.text")}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4>
                            {T.translate("guideReference.requirements.title")}
                        </h4>
                        <p>
                            {T.translate("guideReference.requirements.text")}
                        </p>
                        <ol>
                            <li>
                                {T.translate("guideReference.requirements.list.1")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.2")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.3")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.4")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.5")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.6")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.7")}
                            </li>
                            <li>
                                {T.translate("guideReference.requirements.list.8")}
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4>
                            {T.translate("guideReference.technical.title")}
                        </h4>
                        <div className="alert alert-info" role="alert">
                            <p>
                                {T.translate("guideReference.technical.text",{
                                    "emailSupport": env.supportMail,
                                    "emailImplementation": env.implementationtMail
                                })}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.environments.title">
                            {T.translate("guideReference.environments.title")}
                        </h3>
                        <p id="guideReference.environments.text">
                            {T.translate("guideReference.environments.text")}
                        </p>
                        <dl>
                            <dt id="guideReference.environments.production">
                                {T.translate("guideReference.environments.production")}
                            </dt>
                            <dd>
                                <a href="https://services2.nps.com.ar/ws.php" target="_blank" rel="noopener noreferrer">
                                    https://services2.nps.com.ar/ws.php
                                </a>
                            </dd>
                            <dd>
                                <a href="https://services2.nps.com.ar/ws.php?wsdl" target="_blank" rel="noopener noreferrer">
                                    https://services2.nps.com.ar/ws.php?wsdl
                                </a>
                            </dd>
                            <dt id="guideReference.environments.staging">
                                {T.translate("guideReference.environments.staging")}
                            </dt>
                            <dd>
                                <a href="https://implementacion.nps.com.ar/ws.php" target="_blank" rel="noopener noreferrer">
                                    https://implementacion.nps.com.ar/ws.php
                                </a>
                            </dd>
                            <dd>
                                <a href="https://implementacion.nps.com.ar/ws.php?wsdl" target="_blank" rel="noopener noreferrer">
                                    https://implementacion.nps.com.ar/ws.php?wsdl
                                </a>
                            </dd>
                            <dt id="guideReference.environments.sandbox">
                                {T.translate("guideReference.environments.sandbox")}
                            </dt>
                            <dd>
                                <a href="https://sandbox.nps.com.ar/ws.php" target="_blank" rel="noopener noreferrer">
                                    https://sandbox.nps.com.ar/ws.php
                                </a>
                            </dd>
                            <dd>
                                <a href="https://sandbox.nps.com.ar/ws.php?wsdl" target="_blank" rel="noopener noreferrer">
                                    https://sandbox.nps.com.ar/ws.php?wdsl
                                </a>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-info" id="guideReference.timeout.text">
                            <b id="guideReference.timeout.title">
                                {T.translate("guideReference.timeout.title")}
                            </b> <br/>
                            {T.translate("guideReference.timeout.text")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.characterSet.title">
                            {T.translate("guideReference.characterSet.title")}
                        </h3>
                        <p id="guideReference.characterSet.text">
                            {T.translate("guideReference.characterSet.text")}
                        </p>
                        <CodeHighlight language="bash">
                            {
                                '\'Pragma\' => \'no-cache\',\n' +
                                '\'Connection\' => \'Keep-Alive\',\n' +
                                '\'Host\' => \'implementacion.nps.com.ar:443\',\n' +
                                '\'Content-Type\' => \'text/xml; charset=utf-8\',\n' +
                                '\'Content-Length\' => \'858\',\n' +
                                '\'SOAPAction\' => \'https://implementacion.nps.com.ar/ws.php/Authorize_2p\','
                            }
                        </CodeHighlight>
                        <div className="alert alert-info"
                             id="guideReference.characterSet.information">
                            {T.translate("guideReference.characterSet.information")}
                        </div>
                        <p id="guideReference.characterSet.additional">
                            {T.translate("guideReference.characterSet.additional")}
                        </p>
                        <p id="guideReference.characterSet.iso">
                            {T.translate("guideReference.characterSet.iso")}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.secureHash.title">
                            {T.translate("guideReference.secureHash.title")}
                        </h3>
                        <div id="guideReference.secureHash.text">
                            {T.translate("guideReference.secureHash.text")}
                        </div>
                        <div className="alert alert-warning">
                            <b>{T.translate("guideReference.secureHash.sdk")}</b>
                        </div>
                    </div>
                    <div className="col-12">
                        <h4 id="guideReference.secureHash.creation.title">
                            {T.translate("guideReference.secureHash.creation.title")}
                        </h4>
                        <p id="guideReference.secureHash.creation.text">
                            {T.translate("guideReference.secureHash.creation.text")}
                        </p>
                        <p id="guideReference.secureHash.creation.listText">
                            {T.translate("guideReference.secureHash.creation.listText")}
                        </p>
                        <ul>
                            <li id="guideReference.secureHash.creation.list.1">
                                {T.translate("guideReference.secureHash.creation.list.1")}
                            </li>
                            <li id="guideReference.secureHash.creation.list.2">
                                {T.translate("guideReference.secureHash.creation.list.2")}
                            </li>
                            <li id="guideReference.secureHash.creation.list.3.text">
                                {T.translate("guideReference.secureHash.creation.list.3.text")}
                            </li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <div className="alert alert-info">
                            <b id="guideReference.secureHash.creation.information.text">
                                {T.translate("guideReference.secureHash.creation.information.text")}
                            </b>
                            <CodeHighlight lang="bash">
                                {
                                    'hmac_request_fields = psp_Amount + psp_Country + psp_Currency + psp_CustomerMail + psp_FrmBackButtonURL + psp_FrmLanguage + \n' +
                                    'psp_MerchOrderId + psp_MerchTxRef + psp_MerchantId + psp_MerchantMail + psp_NumPayments + psp_PosDateTime + psp_Product + \n' +
                                    'psp_PurchaseDescription + psp_ReturnURL + psp_TxSource + psp_Version \n' +
                                    'hmac_key = YOUR_SECRET_KEY'
                                }
                            </CodeHighlight>
                        </div>
                    </div>
                    <div className="col-12">
                        <h4 id="guideReference.secureHash.creation.example">
                            {T.translate("guideReference.secureHash.creation.example")}
                        </h4>
                        <CodeHighlight lang="bash">
                            {
                                'hmac_request_fields = "98350ARG032customer@psp.com.arhttp://urlbackes_AR2404317537psp_testmerchant@psp.com.ar12010-03-08 16:49:1414RemeraAAA01http://127.0.0.1/psp_api_php/simple_query_tx.phpWEB1" \n' +
                                'hmac_key = YOUR_SECRET_KEY \n' +
                                '\n' +
                                '**Then the hmac_sha256() algorithm must be applied to the strings and incorporated the result to the psp_SecureHash field.\n' +
                                '\n' +
                                'psp_SecureHash = hmac_sha256(hmac_request_fields, hmac_key)\n' +
                                'psp_SecureHash = 1b279c250edd4c714cf96f92998a3d354d9c2b38e29353ad11a6a2c634e42c39'
                            }
                        </CodeHighlight>
                        <div id="guideReference.secureHash.creation.additional">
                            {T.translate("guideReference.secureHash.creation.additional")}
                        </div>
                    </div>
                    <div className="col-12">
                        <b id="guideReference.secureHash.creation.utf.title">
                            {T.translate("guideReference.secureHash.creation.utf.title")}
                        </b>
                        <p id="guideReference.secureHash.creation.utf.text">
                            {T.translate("guideReference.secureHash.creation.utf.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="alert alert-info"
                             id="guideReference.secureHash.creation.information">
                            {T.translate("guideReference.secureHash.information")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GuideReference;