import React from 'react';

class Accordion extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
            class: "accordion"
        };
        this.handleClick = this.handleClick.bind(this);

    }

    componentDidMount(){
        if(this.props.open === true){
            this.setState({
                open: true,
                class: "accordion open"
            });
        }
    }

    handleClick(){
        if(this.state.open) {
            this.setState({
                open: false,
                class: "accordion"
            });
        }else{
            this.setState({
                open: true,
                class: "accordion open"
            });
        }
    }

    render() {
        return (
            <div id={this.props.id}
                className={this.state.class + ' ' + this.props.additionalClass}>
                <button>toggle</button>
                <div className="accordion-head" onClick={this.handleClick}>{this.props.title}</div>
                <div className="accordion-body">
                    <div className="article">
                        {this.props.children}
                    </div>
                </div>
            </div>

        );
    }
}

export default Accordion;