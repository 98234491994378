import React from 'react';
import { Link } from 'react-router-dom';
import DocBuilder from '../../../components/DocBuilder';

import guideReferenceIcon from '../../../assets/img/guide_reference.svg';
import request from '../../../documentation/documentation/CreateClientSession-request';
import response from '../../../documentation/documentation/CreateClientSession-response';
import Breadcrumbs from "../../../components/Breadcrumbs";
import {guideReferenceURL} from "../../../env";

class ClientSession extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-12">
                                    <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                    <span>
                                                For more information please go to&nbsp;
                                        <Link to={`${guideReferenceURL}/reference/advanced`}>
                                                  Guide Reference: Advanced Checkout
                                                </Link>
                                            </span>
                                </div>
                            </div>
                        </div>
                        <p>
                            Creates a Client Session to be used from the client device for authentication purposes.
                            For example: By using a Payment Method Token (one time used token) from NPS.js, the app
                            inside the device needs to authenticate with Worldline Latam Platform before use it.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='CreateClientSession' />
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientSession;