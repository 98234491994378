import React from 'react';
import T from "i18react/dist/i18react";

import CodeHighlight from '../../../components/CodeHighlight';
import brFlag from '../../../assets/img/flags/flags-iso/flat/64/BR.png';
import coFlag from '../../../assets/img/flags/flags-iso/flat/64/CO.png';
import uyFlag from '../../../assets/img/flags/flags-iso/flat/64/UY.png';
import ecFlag from '../../../assets/img/flags/flags-iso/flat/64/EC.png';
import doFlag from '../../../assets/img/flags/flags-iso/flat/64/DO.png';


class Taxes extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.taxes.title">
                            { T.translate("guideReference.services.taxes.title") }</h3>
                        <div id="guideReference.services.taxes.text">
                            { T.translate("guideReference.services.taxes.text") }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-warning">
                            { T.translate("guideReference.services.taxes.warning") }
                        </div>
                        <h4 id="guideReference.services.taxes.availability.title">
                            { T.translate("guideReference.services.taxes.availability.title") }
                        </h4>
                        <div className="availability-flags">
                            <div className="flag-container">
                                <img src={brFlag} alt="Brasil" title="Brasil" className="image"/>
                                <div className="overlay">
                                    <div className="text">Brasil</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={coFlag} alt="Colombia" title="Colombia" className="image"/>
                                <div className="overlay">
                                    <div className="text">Colombia</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={ecFlag} alt="Ecuador" title="Ecuador" className="image"/>
                                <div className="overlay">
                                    <div className="text">Ecuador</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={uyFlag} alt="Uruguay" title="Uruguay" className="image"/>
                                <div className="overlay">
                                    <div className="text">Uruguay</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={doFlag} alt="Republica Dominicana" title="Republica Dominicana" className="image"/>
                                <div className="overlay">
                                    <div className="text">Republica Dominicana</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.implementation.title">
                            { T.translate("guideReference.services.taxes.implementation.title") }</h4>
                        <p id="guideReference.services.taxes.implementation.text">
                            { T.translate("guideReference.services.taxes.implementation.text") }
                        </p>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="5"><p><strong>Taxes </strong>(request)</p></td>
                                </tr>
                                <tr>
                                    <td colSpan="5">In case you want to implement this functionality, only the following fields must be completed.</td>
                                </tr>
                                <tr>
                                    <td>Field</td>
                                    <td>Type</td>
                                    <td>Data Type</td>
                                    <td>Length <br/>
                                        [Min,Max]</td>
                                    <td>Example of field content</td>
                                </tr>
                                <tr>
                                    <td>psp_AmountAdditionalDetails</td>
                                    <td>O</td>
                                    <td>AmountDetailsType</td>
                                    <td>-</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.availability.title">
                            { T.translate("guideReference.services.taxes.availability.title") }</h4>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td><strong>Identifier</strong></td>
                                    <td><strong>Description</strong></td>
                                    <td><strong>Country</strong></td>
                                </tr>
                                <tr>
                                    <td>100</td>
                                    <td>Airport Tax</td>
                                    <td>COL / BRA</td>
                                </tr>
                                <tr>
                                    <td>500</td>
                                    <td>VAT</td>
                                    <td>COL</td>
                                </tr>
                                <tr>
                                    <td>501</td>
                                    <td>Consumption Tax</td>
                                    <td>COL</td>
                                </tr>
                                <tr>
                                    <td>600</td>
                                    <td>VAT (Law 17934)</td>
                                    <td>URY</td>
                                </tr>
                                <tr>
                                    <td>601</td>
                                    <td>VAT (Law 19210)</td>
                                    <td>URY</td>
                                </tr>
                                <tr>
                                    <td>700</td>
                                    <td>VAT</td>
                                    <td>ECU</td>
                                </tr>
                                <tr>
                                    <td>701</td>
                                    <td>VAT Exemption</td>
                                    <td>ECU</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.examples.colombia.title">
                            { T.translate("guideReference.services.taxes.examples.colombia.title") }</h4>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="8"><strong>Colombia</strong> - Request Fields</td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Field</strong></td>
                                    <td><strong>Value</strong></td>
                                    <td><strong>Type</strong></td>
                                    <td><strong> </strong></td>
                                    <td><strong>Description</strong></td>
                                    <td><strong>Value</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="3">psp_Amount</td>
                                    <td>= 1360000</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>$13600.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_AmountAdditionalDetails</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Taxes</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>TypeId</td>
                                    <td>= 100</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Airport Tax</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Amount</td>
                                    <td>= 200000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Value Tax</td>
                                    <td>$2000.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rate</td>
                                    <td>= NULL</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>BaseAmount</td>
                                    <td>= NULL</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>TypeId</td>
                                    <td>= 500</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>VAT</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Amount</td>
                                    <td>= 160000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>VAT Amount</td>
                                    <td>$1600.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rate</td>
                                    <td>= 1600</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>% VAT</td>
                                    <td>16%</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>BaseAmount</td>
                                    <td>= 1000000</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>Base Amount</td>
                                    <td>$10000.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="guideReference.services.taxes.examples.colombia.text">
                            { T.translate("guideReference.services.taxes.examples.colombia.text") }
                        </div>
                        <br/>

                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1360000,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": [\n' +
                                '                  {\n' +
                                '                      "TypeId": 100,\n' +
                                '                      "BaseAmount": 200000,\n' +
                                '                  }\n' +
                                '                  {\n' +
                                '                     "TypeId": 500,\n' +
                                '                     "Amount": 160000,\n' +
                                '                     "Rate": 1600, //Optional\n' +
                                '                     "BaseAmount": 1000000, //Optional\n' +
                                '                  },\n' +

                                '               ]\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>

                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="8"><p><strong>Colombia</strong> - Response Fields</p></td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Field</strong></td>
                                    <td><strong>Value</strong></td>
                                    <td><strong>Type</strong></td>
                                    <td><strong> </strong></td>
                                    <td><strong>Description</strong></td>
                                    <td><strong>Value</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="3">psp_Amount</td>
                                    <td>= 1360000</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>$13600.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_AmountAdditionalDetails</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Taxes</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>TypeId</td>
                                    <td>= 100</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Airport Tax</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Amount</td>
                                    <td>= 200000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Value Tax</td>
                                    <td>$2000.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rate</td>
                                    <td>= NULL</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>BaseAmount</td>
                                    <td>= NULL</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>TypeId</td>
                                    <td>= 500</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>VAT</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Amount</td>
                                    <td>= 160000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>VAT Amount</td>
                                    <td>$1600.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rate</td>
                                    <td>= 1600</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>% VAT</td>
                                    <td>16%</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>BaseAmount</td>
                                    <td>= 1000000</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>Base Amount</td>
                                    <td>$10000.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1360000,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": [\n' +
                                '                  {\n' +
                                '                      "TypeId": 100,\n' +
                                '                      "BaseAmount": 200000,\n' +
                                '                  }\n' +
                                '                  {\n' +
                                '                     "TypeId": 500,\n' +
                                '                     "Amount": 160000,\n' +
                                '                     "Rate": 1600, //Optional\n' +
                                '                     "BaseAmount": 1000000, //Optional\n' +
                                '                  },\n' +

                                '               ]\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.examples.brazil.title">
                            { T.translate("guideReference.services.taxes.examples.brazil.title") }</h4>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="8">
                                    <p><strong>Brazil</strong> - Request Fields</p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                    <strong>Field</strong>
                                </td>
                                <td>
                                    <strong>Value</strong>
                                </td>
                                <td>
                                    <strong>Type</strong>
                                </td>
                                <td>
                                    <strong> </strong>
                                </td>
                                <td>
                                    <strong>Description</strong>
                                </td>
                                <td>
                                    <strong>Value</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="3">psp_Amount</td>
                                <td>= 1200000</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>$12000.00</td>
                            </tr>
                            <tr>
                                <td colSpan="4">psp_AmountAdditionalDetails</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Taxes</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>TypeId</td>
                                <td>= 100</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td>Airport Tax</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Amount</td>
                                <td>= 200000</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td>Value Tax</td>
                                <td>$2000.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Rate</td>
                                <td>= NULL</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>BaseAmount</td>
                                <td>= NULL</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        <div id="guideReference.services.taxes.examples.brazil.text">
                            { T.translate("guideReference.services.taxes.examples.brazil.text") }
                        </div>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1200000,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": {\n' +
                                '           "TypeId": 100,\n' +
                                '           "Amount": 200000,\n' +
                                '       }\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>

                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="8"><p><strong>Brazil</strong> -  Response Fields</p></td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Field</strong></td>
                                    <td><strong>Value</strong></td>
                                    <td><strong>Type</strong></td>
                                    <td><strong> </strong></td>
                                    <td><strong>Description</strong></td>
                                    <td><strong>Value</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="3">psp_Amount</td>
                                    <td>= 1200000</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>$12000.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_AmountAdditionalDetails</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Taxes</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>TypeId</td>
                                    <td>= 100</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Airport Tax</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Amount</td>
                                    <td>= 200000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Value Tax</td>
                                    <td>$2000.00</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Rate</td>
                                    <td>= NULL</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>BaseAmount</td>
                                    <td>= NULL</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1200000,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": {\n' +
                                '           "TypeId": 100,\n' +
                                '           "Amount": 200000,\n' +
                                '       }\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.examples.uruguay.title">
                            { T.translate("guideReference.services.taxes.examples.uruguay.title") }</h4>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="8"><strong>Uruguay</strong> - Request Fields</td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Field</strong></td>
                                    <td><strong>Value</strong></td>
                                    <td><strong>Type</strong></td>
                                    <td><strong> </strong></td>
                                    <td><strong>Description</strong></td>
                                    <td><strong>Value</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="3">psp_Amount</td>
                                    <td>= 1220000</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>$12200.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_AmountAdditionalDetails</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Taxes</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>TypeId</td>
                                    <td>= 601</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>VAT (Law 19210)</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Amount</td>
                                    <td>= 220000</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>VAT Amount</td>
                                    <td>$2200.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rate</td>
                                    <td>= 2200</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>% VAT</td>
                                    <td>22%</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>BaseAmount</td>
                                    <td>= 1000000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Base Amount</td>
                                    <td>$10000.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_BillingDetails</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td colSpan="2">Invoice</td>
                                    <td>= A00024144</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Invoice number</td>
                                    <td>A00024144</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td colSpan="2">InvoiceAmount</td>
                                    <td>= 1600000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Invoice amount</td>
                                    <td>$16000.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td colSpan="2">InvoiceCurrency</td>
                                    <td>= 858</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>Invoice currency</td>
                                    <td>Pesos (UYU)</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="guideReference.services.taxes.examples.uruguay.text">
                            { T.translate("guideReference.services.taxes.examples.uruguay.text") }
                        </div>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1220000,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": {\n' +
                                '           "TypeId": 601,\n' +
                                '           "Amount": 220000,  //Optional\n' +
                                '           "Rate": 2200,  //Optional\n' +
                                '           "BaseAmount": 1000000,\n' +
                                '       }\n' +
                                '   },\n' +
                                '   "psp_BillingDetails": {\n'+
                                '       "Invoice": \'A00024144\'\n'+
                                '       "InvoiceAmount": 1600000\n'+
                                '       "InvoiceCurrency": 858  //Optional\n'+
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="8"><strong>Uruguay</strong> - Response Fields</td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Field</strong></td>
                                    <td><strong>Value</strong></td>
                                    <td><strong>Type</strong></td>
                                    <td><strong> </strong></td>
                                    <td><strong>Description</strong></td>
                                    <td><strong>Value</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan="3">psp_Amount</td>
                                    <td>= 1220000</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td>$12200.00</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_AmountAdditionalDetails</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td>Taxes</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>TypeId</td>
                                    <td>= 601</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>VAT (Law 19210)</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Amount</td>
                                    <td>= 220000</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>VAT Amount</td>
                                    <td>$2200.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Rate</td>
                                    <td>= 2200</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>% VAT</td>
                                    <td>22%</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>BaseAmount</td>
                                    <td>= 1000000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Base Amount</td>
                                    <td>$10000.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>AppliedAmount</td>
                                    <td>= 0</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Applied Amount</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> </td>
                                    <td>Remarks</td>
                                    <td>= 19210</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>Remarks</td>
                                    <td>Ley 19210</td>
                                </tr>
                                <tr>
                                    <td colSpan="4">psp_BillingDetails</td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td colSpan="2">Invoice</td>
                                    <td>= A00024144</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Invoice number</td>
                                    <td>A00024144</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td colSpan="2">InvoiceAmount</td>
                                    <td>= 1600000</td>
                                    <td>Mandatory</td>
                                    <td>&rarr;</td>
                                    <td>Invoice amount</td>
                                    <td>$16000.00</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td colSpan="2">InvoiceCurrency</td>
                                    <td>= 858</td>
                                    <td>Optional</td>
                                    <td>&rarr;</td>
                                    <td>Invoice currency</td>
                                    <td>Pesos (UYU)</td>
                                </tr>
                            </tbody>
                        </table>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1220000,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": {\n' +
                                '           "TypeId": 601,\n' +
                                '           "Amount": 220000,  //Optional\n' +
                                '           "Rate": 2200,  //Optional\n' +
                                '           "BaseAmount": 1000000,\n' +
                                '           "AppliedAmount": 0,\n' +
                                '           "Remarks": 19210,  //Optional\n' +
                                '       }\n' +
                                '   },\n' +
                                '   "psp_BillingDetails": {\n'+
                                '       "Invoice": \'A00024144\'\n'+
                                '       "InvoiceAmount": 1600000\n'+
                                '       "InvoiceCurrency": 858  //Optional\n'+
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <div id="guideReference.services.taxes.examples.uruguay.important">
                            { T.translate("guideReference.services.taxes.examples.uruguay.important") }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.examples.ecuador.title">
                            { T.translate("guideReference.services.taxes.examples.ecuador.title") }</h4>
                        <div id="guideReference.services.taxes.examples.ecuador.text">
                            { T.translate("guideReference.services.taxes.examples.ecuador.text") }
                        </div>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="19">
                                    <p><strong>Ecuador</strong> &ndash; Request Fields</p></td>
                            </tr>
                            <tr>
                                <td colSpan="8"><strong>Field</strong></td>
                                <td colSpan="5"><strong>Value</strong></td>
                                <td><strong>Type</strong></td>
                                <td><strong>&nbsp;</strong></td>
                                <td colSpan="2"><strong>Description</strong></td>
                                <td colSpan="2"><strong>Value</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="8">psp_Amount</td>
                                <td colSpan="5">= 31200</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">$312.00</td>
                            </tr>
                            <tr>
                                <td colSpan="13">psp_AmountAdditionalDetails</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="4" >Taxes</td>
                                <td colSpan="3" >&nbsp;</td>
                                <td colSpan="5">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">TypeId</td>
                                <td colSpan="5">= 700</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">VAT</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Amount</td>
                                <td colSpan="5">= 1200</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">VAT Amount</td>
                                <td colSpan="2">$12.00</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Rate</td>
                                <td colSpan="5">= 1200</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td colSpan="2">% VAT</td>
                                <td colSpan="2">12%</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">BaseAmount</td>
                                <td colSpan="5">= 10000</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">Base Amount</td>
                                <td colSpan="2">$100.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colSpan="3"></td>
                                <td colSpan="4"></td>
                                <td colSpan="5"></td>
                                <td></td>
                                <td></td>
                                <td colSpan="2"></td>
                                <td colSpan="2"></td>
                            </tr>
                            <tr>
                                <td colSpan="3">&nbsp;</td>
                                <td >&nbsp;</td>
                                <td colSpan="5">TypeId</td>
                                <td colSpan="3">= 701</td>
                                <td colSpan="2">Mandatory</td>
                                <td colSpan="2">&rarr;</td>
                                <td colSpan="2">VAT Exemption</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td colSpan="3">&nbsp;</td>
                                <td >&nbsp;</td>
                                <td colSpan="5">BaseAmount</td>
                                <td colSpan="3">= 20000</td>
                                <td colSpan="2">Mandatory</td>
                                <td colSpan="2">&rarr;</td>
                                <td>Base Amount</td>
                                <td>$200.00</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 31200,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": [' +
                                '                   {\n' +
                                '                      "TypeId": 700,\n' +
                                '                      "Amount": 1200,\n' +
                                '                      "Rate": 1200, //Optional\n' +
                                '                      "BaseAmount": 10000,\n' +
                                '                  },\n' +
                                '                  {\n' +
                                '                      "TypeId": 701,\n' +
                                '                      "BaseAmount": 20000,\n' +
                                '                  }\n' +
                                '               ]' +
                                '   }'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <br/>
                        <div id="guideReference.services.taxes.examples.ecuador.response">
                            { T.translate("guideReference.services.taxes.examples.ecuador.response") }
                        </div>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="19">
                                    <p><strong>Ecuador</strong> &ndash; Response Fields</p></td>
                            </tr>
                            <tr>
                                <td colSpan="8">
                                    <strong>Field</strong></td>
                                <td colSpan="5">
                                    <strong>Value</strong></td>
                                <td>
                                    <strong>Type</strong></td>
                                <td>
                                    <strong>&nbsp;</strong></td>
                                <td colSpan="2">
                                    <strong>Description</strong></td>
                                <td colSpan="2">
                                    <strong>Value</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="8">psp_Amount</td>
                                <td colSpan="5">= 31200</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">$312.00</td>
                            </tr>
                            <tr>
                                <td colSpan="13">psp_AmountAdditionalDetails</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="4">Taxes</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="5">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">TypeId</td>
                                <td colSpan="5">= 700</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">VAT</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Amount</td>
                                <td colSpan="5">= 1200</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">VAT Amount</td>
                                <td colSpan="2">$12.00</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Rate</td>
                                <td colSpan="5">= 1200</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td colSpan="2">% VAT</td>
                                <td colSpan="2">12%</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">BaseAmount</td>
                                <td colSpan="5">= 10000</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">Base Amount</td>
                                <td colSpan="2">$100.00</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colSpan="3"></td>
                                <td colSpan="4"></td>
                                <td colSpan="5"></td>
                                <td></td>
                                <td></td>
                                <td colSpan="2"></td>
                                <td colSpan="2"></td>
                            </tr>
                            <tr>
                                <td colSpan="3">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="5">TypeId</td>
                                <td colSpan="3">= 701</td>
                                <td colSpan="2">Mandatory</td>
                                <td colSpan="2">&rarr;</td>
                                <td colSpan="2">VAT Exemption</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td colSpan="3">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="5">BaseAmount</td>
                                <td colSpan="3">= 20000</td>
                                <td colSpan="2">Mandatory</td>
                                <td colSpan="2">&rarr;</td>
                                <td>Base Amount</td>
                                <td>$200.00</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 31200,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": [\n' +
                                '                   {\n' +
                                '                      "TypeId": 700,\n' +
                                '                      "Amount": 1200,\n' +
                                '                      "Rate": 1200, //Optional\n' +
                                '                      "BaseAmount": 10000,\n' +
                                '                  },\n' +
                                '                  {\n' +
                                '                      "TypeId": 701,\n' +
                                '                      "BaseAmount": 20000,\n' +
                                '                  }\n' +
                                '               ]\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.taxes.examples.dominicana.title">
                            { T.translate("guideReference.services.taxes.examples.dominicana.title") }</h4>
                        <div id="guideReference.services.taxes.examples.dominicana.text">
                            { T.translate("guideReference.services.taxes.examples.dominicana.text") }
                        </div>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="19">
                                    <p><strong>Republica Dominicana</strong> &ndash; Request Fields</p></td>
                            </tr>
                            <tr>
                                <td colSpan="8"><strong>Field</strong></td>
                                <td colSpan="5"><strong>Value</strong></td>
                                <td><strong>Type</strong></td>
                                <td><strong>&nbsp;</strong></td>
                                <td colSpan="2"><strong>Description</strong></td>
                                <td colSpan="2"><strong>Value</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="8">psp_Amount</td>
                                <td colSpan="5">= 1700</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">$17.00</td>
                            </tr>
                            <tr>
                                <td colSpan="13">psp_AmountAdditionalDetails</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="4" >Taxes</td>
                                <td colSpan="3" >&nbsp;</td>
                                <td colSpan="5">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">TypeId</td>
                                <td colSpan="5">= 1100</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">ISV</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Amount</td>
                                <td colSpan="5">= 140</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">Value Tax</td>
                                <td colSpan="2">$1.40</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Rate</td>
                                <td colSpan="5">= 900</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td colSpan="2"></td>
                                <td colSpan="2">9%</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">BaseAmount</td>
                                <td colSpan="5">= 1560</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td colSpan="2"></td>
                                <td colSpan="2">$15.60</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1700,\n' +
                                '   "psp_Currency": 340,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": [\n' +
                                '                   {\n' +
                                '                      "TypeId": 1100,\n' +
                                '                      "Amount": 140,\n' +
                                '                      "Rate": 900, //Optional\n' +
                                '                      "BaseAmount": 1560, //Optional\n' +
                                '                   },\n' +
                                '                ]\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                        <br/>
                        <div id="guideReference.services.taxes.examples.dominicana.response">
                            { T.translate("guideReference.services.taxes.examples.dominicana.response") }
                        </div>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="19">
                                    <p><strong>Republica Dominicana</strong> &ndash; Request Fields</p></td>
                            </tr>
                            <tr>
                                <td colSpan="8"><strong>Field</strong></td>
                                <td colSpan="5"><strong>Value</strong></td>
                                <td><strong>Type</strong></td>
                                <td><strong>&nbsp;</strong></td>
                                <td colSpan="2"><strong>Description</strong></td>
                                <td colSpan="2"><strong>Value</strong></td>
                            </tr>
                            <tr>
                                <td colSpan="8">psp_Amount</td>
                                <td colSpan="5">= 1700</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">$17.00</td>
                            </tr>
                            <tr>
                                <td colSpan="13">psp_AmountAdditionalDetails</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="4" >Taxes</td>
                                <td colSpan="3" >&nbsp;</td>
                                <td colSpan="5">&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                                <td colSpan="2">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">TypeId</td>
                                <td colSpan="5">= 1100</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">ISV</td>
                                <td colSpan="2">-</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Amount</td>
                                <td colSpan="5">= 140</td>
                                <td>Mandatory</td>
                                <td>&rarr;</td>
                                <td colSpan="2">Value Tax</td>
                                <td colSpan="2">$1.40</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">Rate</td>
                                <td colSpan="5">= 900</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td colSpan="2"></td>
                                <td colSpan="2">9%</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td colSpan="3">&nbsp;</td>
                                <td colSpan="4">BaseAmount</td>
                                <td colSpan="5">= 1560</td>
                                <td>Optional</td>
                                <td>&rarr;</td>
                                <td colSpan="2"></td>
                                <td colSpan="2">$15.60</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Amount": 1700,\n' +
                                '   "psp_Currency": 340,\n' +
                                '   "psp_AmountAdditionalDetails": {\n' +
                                '       "Taxes": [\n' +
                                '                   {\n' +
                                '                      "TypeId": 1100,\n' +
                                '                      "Amount": 140,\n' +
                                '                      "Rate": 900, //Optional\n' +
                                '                      "BaseAmount": 1560, //Optional\n' +
                                '                   },\n' +
                                '                ]\n' +
                                '   }\n'+
                                '   ...'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </div>
        );
    }
}

export default Taxes;