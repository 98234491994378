import React from 'react';
import T from 'i18react';

import CodeHighlight from '../../../../components/CodeHighlight';

class Install extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            tabActive: 0
        };
    }

    render() {
        return (
            <div className="col-12">
                <h4 id="advancedCheckout.react.install.title">
                    Step 1 - {T.translate("advancedCheckout.react.install.title")}
                </h4>
                <CodeHighlight language="bash">
                    {
                        'npm install nps-sdk-react --save'
                    }
                </CodeHighlight>
                <p>
                    And import it into your project
                </p>
                <CodeHighlight language="javascript">
                    {
                        'import ReactNPS from "nps-sdk-react"'
                    }
                </CodeHighlight>
                <hr/>
            </div>
        );
    }
}

export default Install;