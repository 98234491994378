import React from 'react';
import T from 'i18react';

import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import {apiReferenceURL} from "../../../../env";

const Configure = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.dart.configure.title">
                 Step 3 - {T.translate("advancedCheckout.dart.configure.title")}
            </h4>
            <p id="advancedCheckout.dart.configure.text">
                {T.translate("advancedCheckout.dart.configure.text")}
            </p>
            <CodeHighlight language="java">
                {
                    'Nps nps = new Nps(sandbox);'
                }
            </CodeHighlight>
            <Accordion id="advancedCheckout.dart.configure.client.title"
                open
                title={ "3.1 - " + T.translate("advancedCheckout.dart.configure.client.title")}>
                <p id="advancedCheckout.dart.configure.client.text">
                    {T.translate("advancedCheckout.dart.configure.client.text",{"apiLink": <T.a href={ `${apiReferenceURL}/services/ClientSession` } target="_blank" text="CreateClientSession"/>})}
                </p>
            </Accordion>
            <Accordion id="advancedCheckout.dart.configure.merchant.title"
                open
                title={ "3.2 - " + T.translate("advancedCheckout.dart.configure.merchant.title")}>
                <p id="advancedCheckout.dart.configure.merchant.text">
                    {T.translate("advancedCheckout.dart.configure.merchant.text")}
                </p>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Configure;