import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Tokenization = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.swift.tokenization.title">
               Step 4 - {T.translate("advancedCheckout.swift.tokenization.title")}
            </h4>
            <p id="advancedCheckout.swift.tokenization.text">
                {T.translate("advancedCheckout.swift.tokenization.text")}
            </p>
            <Accordion id="advancedCheckout.swift.tokenization.create.title" open
                title={ "4.1 - " + T.translate("advancedCheckout.swift.tokenization.create.title")}>
                <div className="row">
                    <div className="col-12">
                        <div id="advancedCheckout.swift.tokenization.create.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.swift.tokenization.create.information")}
                        </div>
                        <CodeHighlight language="Swift">
                            {
                                'let nps = Nps(environment: NPSSANDBOX)!\n' +
                                'nps.merchantId = "__YOUR_NPS_MERCHANT_ID__"\n' +
                                'nps.clientSession = "__YOUR_NPS_CLIENT_SESSION__"\n' +
                                '\n' +
                                'let card = CardDetails()\n' +
                                '\n' +
                                'card.number = "4507990000000010";\n' +
                                'card.holderName = "JOHN DOE";\n' +
                                'card.securityCode = "123";\n' +
                                'card.expirationDate = "1909";\n' +
                                '\n' +
                                '\n' +
                                'let billing = Billing()\n' +
                                '\n' +
                                'billing.pspPerson.firstName = "JOHN";\n' +
                                'billing.pspPerson.lastName = "Smith";\n' +
                                'billing.pspPerson.dateOfBirth = "1987-01-01";\n' +
                                'billing.pspPerson.gender = "M";\n' +
                                'billing.pspPerson.nationality = "ARG";\n' +
                                'billing.pspPerson.idType = "200";\n' +
                                'billing.pspPerson.idNumber = "95665091";\n' +
                                'billing.pspPerson.phoneNumber1 = "4123-1234";\n' +
                                'billing.pspPerson.phoneNumber2 = "4123-5678";\n' +
                                '\n' +
                                'billing.pspAddress.additionalInfo = "JOHN";\n' +
                                'billing.pspAddress.city = "Buenos Aires";\n' +
                                'billing.pspAddress.stateProvince = "CABA";\n' +
                                'billing.pspAddress.country = "ARG";\n' +
                                'billing.pspAddress.zipCode = "1425";\n' +
                                'billing.pspAddress.street = "suipacha";\n' +
                                'billing.pspAddress.houseNumber = "32123123";\n' +
                                '\n' +
                                'nps.createPaymentMethodToken(card, billingDetails: billing, methodResponse: {( methodResponse: CreatePaymentMethodTokenResponse?, error: Error?) -> Void in\n' +
                                '    if error == nil{\n' +
                                '        print(methodResponse?.responseExtended as Any)\n' +
                                '    }\n' +
                                '})'
                            }
                        </CodeHighlight>
                    </div>
                    <div className="col-12">
                        <p id="advancedCheckout.swift.tokenization.create.response.text">
                            {T.translate("advancedCheckout.swift.tokenization.create.response.text")}
                        </p>
                        <p id="advancedCheckout.swift.tokenization.create.error.text">
                            {T.translate("advancedCheckout.swift.tokenization.create.error.text")}
                        </p>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.swift.tokenization.paymentMethod.title" open
                title={ "4.2 - " + T.translate("advancedCheckout.swift.tokenization.paymentMethod.title")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.swift.tokenization.paymentMethod.text">
                            {T.translate("advancedCheckout.swift.tokenization.paymentMethod.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="Swift">
                            {
                                'let nps = Nps(environment: NPSSANDBOX)!\n' +
                                'nps.merchantId = "__YOUR_NPS_MERCHANT_ID__"\n' +
                                'nps.clientSession = "__YOUR_NPS_CLIENT_SESSION__"\n' +
                                '\n' +
                                'let billing = Billing()\n' +
                                'billing.pspPerson.firstName = "JOHN DOE"\n' +
                                '\n' +
                                'nps.recachePaymentMethodToken("kWRZGcAxy5D7MoB6BDACugHYrlFzP9Eg", \n' +
                                '            cardSecurityCode: "123", \n' +
                                '              billingDetails: billing,\n' +
                                '              methodResponse: {( methodResponse: RecachePaymentMethodTokenResponse?, error: Error?) -> Void in\n' +
                                '                    if error == nil{\n' +
                                '                        print(methodResponse?.responseCod as Any)\n' +
                                '                    }\n' +
                                '})'
                            }
                        </CodeHighlight>
                        <div id="advancedCheckout.swift.tokenization.paymentMethod.information"
                            className="alert alert-info"
                            role="alert">
                            {T.translate("advancedCheckout.swift.tokenization.paymentMethod.information")}
                        </div>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Tokenization;