import React from 'react';

import logo from '../../../../assets/img/dev/prestashop.svg';

const Prestashop = () => {
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md-2 text-center">
                    <img src={logo} alt="Prestashop" className="img-fluid language-logo"/>
                    <br/>
                    <span className="badge badge-success">
                        1.5.0.0.15 - 1.5.6.2.x.x / 1.6.1.x
                    </span>
                </div>
                <div className="col-12 col-md-10 download text-center text-md-right">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-plugin-prestashop-1.5" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download 1.5
                    </a>
                    <br/>
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-plugin-prestashop-1.6" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download 1.6.1.x
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Prestashop;