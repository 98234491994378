import React from 'react';
import T from "i18react/dist/i18react";

import coFlag from '../../../assets/img/flags/flags-iso/flat/64/CO.png';
import brFlag from '../../../assets/img/flags/flags-iso/flat/64/BR.png';
import mxFlag from '../../../assets/img/flags/flags-iso/flat/64/MX.png';
// import lockIcon from '../../../assets/img/lock-content.svg';
import CodeHighlight from '../../../components/CodeHighlight';

class Recurring extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: "Recurring Component"
        };
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.recurring.title">
                            { T.translate("guideReference.services.recurring.title") }</h3>
                        <p id="guideReference.services.recurring.text">
                            { T.translate("guideReference.services.recurring.text") }
                        </p>

                        <h3>Availability</h3>
                        <br/>
                        <div className="availability-flags">
                            <div className="flag-container">
                                <img src={coFlag} alt="Colombia" title="Colombia" className="image"/>
                                <div className="overlay">
                                    <div className="text">Colombia</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={brFlag} alt="Brasil" title="Brasil" className="image"/>
                                <div className="overlay">
                                    <div className="text">Brasil</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={mxFlag} alt="Mexico" title="Mexico" className="image"/>
                                <div className="overlay">
                                    <div className="text">Mexico</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <br/>
                        <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td colSpan="5">
                                    In case you want to implement this function, only the following fields have to be completed.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Field
                                </td>
                                <td>
                                    Type
                                </td>
                                <td>
                                    Data Type
                                </td>
                                <td>
                                    Length <br/>
                                    [Min,Max]
                                </td>
                                <td>
                                    Example of field content
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    psp_Recurrent
                                </td>
                                <td>
                                    O
                                </td>
                                <td valign="top">
                                    Numeric
                                </td>
                                <td valign="top">
                                    1,1
                                </td>
                                <td valign="top">
                                    1
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="alert alert-info" id="guideReference.services.recurring.information">
                            { T.translate("guideReference.services.recurring.information") }
                        </div>
                        <br/>
                        <br/>
                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_Recurrent": "1", \n'+
                                '   ...'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </div>

        );
    }
}

export default Recurring;