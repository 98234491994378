import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const Go = () => {
    return (
        <div className="col-12">
            <div className="row">
               <div className="go-library"/>
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-go" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        1.7 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.go.install">
                        {T.translate("libraries.server.go.install")}
                    </h4>
                    <ul>
                        <li>
                            <a id="libraries.server.go.install.list.1"
                               href="https://github.com/Ingenico-NPS-Latam/nps-sdk-go/releases">
                                {T.translate("libraries.server.go.install.list.1")}
                            </a>
                        </li>
                        <li id="libraries.server.go.install.list.2">
                            {T.translate("libraries.server.go.install.list.2")}
                        </li>
                    </ul>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.go.configuration.title">
                        {T.translate("libraries.server.go.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.go.configuration.basic" open
                        title={T.translate("libraries.server.go.configuration.basic")}>
                        <CodeHighlight languague="Go">
                            {
                                'package main\n' +
                                '\n' +
                                'import (\n' +
                                '    "log"\n' +
                                '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                                '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                                ')\n' +
                                '\n' +
                                'func main() {\n' +
                                '\n' +
                                '  err := npsSdk.Configure(map[string]interface{}{\n' +
                                '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                                '      "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                                '    })\n' +
                                '\n' +
                                '  if err != nil {\n' +
                                '    log.Fatalf("error configuring sdk: %v", err)\n' +
                                '  }\n' +
                                '}'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.go.configuration.advanced.title" open
                        title={T.translate("libraries.server.go.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <hr/>
                                <h4 id="libraries.server.go.configuration.advanced.log.title">
                                    {T.translate("libraries.server.go.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.go.configuration.advanced.log.text">
                                    {T.translate("libraries.server.go.configuration.advanced.log.text")}
                                </p>
                                <CodeHighlight languague="Go">
                                    {
                                        'package main\n' +
                                        '\n' +
                                        'import (\n' +
                                        '    "log"\n' +
                                        '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                                        '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                                        ')\n' +
                                        '\n' +
                                        'func main() {\n' +
                                        '\n' +
                                        '  err := npsSdk.Configure(map[string]interface{}{\n' +
                                        '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                                        '      "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                                        '      "debug":       true,\n' +
                                        '      "log_level":   CONSTANTS.INFO,\n' +
                                        '    })' +
                                        '}'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.go.configuration.advanced.log.level">
                                    {T.translate("libraries.server.go.configuration.advanced.log.level")}
                                </p>
                                <p id="libraries.server.go.configuration.advanced.log.file">
                                    {T.translate("libraries.server.go.configuration.advanced.log.file")}
                                </p>
                                <CodeHighlight languague="Go">
                                    {
                                        'package main\n' +
                                        '\n' +
                                        'import (\n' +
                                        '    "log"\n' +
                                        '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                                        '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                                        ')\n' +
                                        '\n' +
                                        'func main() {\n' +
                                        '\n' +
                                        '  var appLog = log.New(os.Stdout, "", log.Ldate|log.Ltime|log.Lshortfile)\n' +
                                        '  f, err := os.OpenFile("appLog.log", os.O_RDWR|os.O_CREATE|os.O_APPEND, 0666)\n' +
                                        '  defer f.Close()\n' +
                                        '  if err != nil {\n' +
                                        '    log.Fatalf("error opening file: %v", err)\n' +
                                        '  }\n' +
                                        '  appLog.SetOutput(f)\n' +
                                        '  appLog.Println("MAIN begin")\n' +
                                        '\n' +
                                        '  err := npsSdk.Configure(map[string]interface{}{\n' +
                                        '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                                        '      "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                                        '      "debug":       true,\n' +
                                        '      "log_level":   CONSTANTS.DEBUG,\n' +
                                        '      "npsLog":      appLog,      \n' +
                                        '    })' +
                                        '}'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.go.configuration.advanced.sanitize.title">
                                    {T.translate("libraries.server.go.configuration.advanced.sanitize.title")}
                                </h4>
                                <p id="libraries.server.go.configuration.advanced.sanitize.text">
                                    {T.translate("libraries.server.go.configuration.advanced.sanitize.text")}
                                </p>
                                <CodeHighlight languague="Go">
                                    {
                                        'package main\n' +
                                        '\n' +
                                        'import (\n' +
                                        '    "log"\n' +
                                        '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                                        '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                                        ')\n' +
                                        '\n' +
                                        'func main() {\n' +
                                        '\n' +
                                        '  err := npsSdk.Configure(map[string]interface{}{\n' +
                                        '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                                        '      "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                                        '      "sanitize":    true,\n' +
                                        '    })' +
                                        '}'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.go.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.go.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.go.configuration.advanced.timeout.text">
                                    {T.translate("libraries.server.go.configuration.advanced.timeout.text")}
                                </p>
                                <CodeHighlight languague="Go">
                                    {
                                        'package main\n' +
                                        '\n' +
                                        'import (\n' +
                                        '   "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                                        '   CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                                        ')\n' +
                                        '\n' +
                                        'func main() {\n' +
                                        '\n' +
                                        '  err := npsSdk.Configure(map[string]interface{}{\n' +
                                        '       "environment": CONSTANTS.SANDBOX_ENV,\n' +
                                        '       "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                                        '       "conn_timeout": 10,\n' +
                                        '       "timeout": 60,\n' +
                                        '   })\n' +
                                        '}'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.go.configuration.advanced.proxy.title">
                                    {T.translate("libraries.server.go.configuration.advanced.proxy.title")}
                                </h4>
                                <p id="libraries.server.go.configuration.advanced.proxy.text">
                                    {T.translate("libraries.server.go.configuration.advanced.proxy.text")}
                                </p>
                                <CodeHighlight languague="Go">
                                    {
                                        'package main\n' +
                                        '\n' +
                                        'import (\n' +
                                        '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                                        '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                                        ')\n' +
                                        '\n' +
                                        'func main() {\n' +
                                        '\n' +
                                        '  err := npsSdk.Configure(map[string]interface{}{\n' +
                                        '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                                        '      "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                                        '      "proxy_url": "http://yourproxy",\n' +
                                        '      "proxy_username": "proxyUsername",\n' +
                                        '      "proxy_password": "proxyPassword",\n' +
                                        '    })' +
                                        '}'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.go.environments.title">
                        {T.translate("libraries.server.go.environments.title")}
                    </h4>
                    <p id="libraries.server.go.environments.text">
                        {T.translate("libraries.server.go.environments.text")}
                    </p>
                    <CodeHighlight languague="Go">
                        {
                            'package main\n' +
                            '\n' +
                            'import (\n' +
                            '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                            '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                            ')\n' +
                            '\n' +
                            'func main() {\n' +
                            '\n' +
                            '  err := npsSdk.Configure(map[string]interface{}{\n' +
                            '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                            '    })\n' +
                            '  err := npsSdk.Configure(map[string]interface{}{\n' +
                            '      "environment": CONSTANTS.PRODUCTION_ENV,\n' +
                            '    })\n' +
                            '  err := npsSdk.Configure(map[string]interface{}{\n' +
                            '      "environment": CONSTANTS.STAGING_ENV,\n' +
                            '    })' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.go.error.title">
                        {T.translate("libraries.server.go.error.title")}
                    </h4>
                    <p id="libraries.server.go.error.text">
                        {T.translate("libraries.server.go.error.text")}
                    </p>
                    <CodeHighlight languague="Go">
                        {
                            'err = SendPayOnLine_2p(service)\n' +
                            '\n' +
                            '  if err != nil {\n' +
                            '    fmt.Printf("\\n")\n' +
                            '    fmt.Printf("ERROR [%s]\\n", err)\n' +
                            '  }'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.go.example.title">
                        {T.translate("libraries.server.go.example.title")}
                    </h4>
                    <p id="libraries.server.go.example.text">
                        {T.translate("libraries.server.go.example.text")}
                    </p>
                    <CodeHighlight languague="Go">
                        {
                            'package main\n' +
                            '\n' +
                            'import (\n' +
                            '    "fmt"\n' +
                            '    "log"\n' +
                            '    "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk"\n' +
                            '    CONSTANTS "github.com/Ingenico-NPS-Latam/nps-sdk-go/npsSdk/constants"\n' +
                            ')\n' +
                            '\n' +
                            'func SendPayOnLine_2p(service *npsSdk.PaymentServicePlatformPortType) error {\n' +
                            '\n' +
                            '  Person := npsSdk.NewPersonStruct()\n' +
                            '  Person.FirstName = "First Name"\n' +
                            '  Person.LastName = "Last Name"\n' +
                            '  Person.PhoneNumber1 = "52520960"\n' +
                            '\n' +
                            '  AmountAdditionalDetails := npsSdk.NewAmountAdditionalDetailsRequestStruct()\n' +
                            '  AmountAdditionalDetails.Tip = "10"\n' +
                            '  AmountAdditionalDetails.Discount = "5"\n' +
                            '\n' +
                            '  Billing := npsSdk.NewBillingDetailsStruct()\n' +
                            '  Billing.Invoice = "100001234"\n' +
                            '  Billing.InvoiceAmount = "990"\n' +
                            '  Billing.InvoiceCurrency = "032"\n' +
                            '  Billing.Person = Person\n' +
                            '\n' +
                            '  SellerAddress := npsSdk.NewAddressStruct()\n' +
                            '  SellerAddress.City = "CABA"\n' +
                            '  SellerAddress.Country = "ARG"\n' +
                            '  SellerAddress.Street = "SellerStreet"\n' +
                            '  SellerAddress.HouseNumber = "1234"\n' +
                            '\n' +
                            '  SellerDetails := npsSdk.NewSellerDetailsStruct()\n' +
                            '  SellerDetails.Name = "Seller Name"\n' +
                            '  SellerDetails.Address = SellerAddress\n' +
                            '\n' +
                            '  MerchantAdditionalDetails := npsSdk.NewMerchantAdditionalDetailsStruct()\n' +
                            '  MerchantAdditionalDetails.ShoppingCartInfo = "ShoppingCartInfo"\n' +
                            '  MerchantAdditionalDetails.SellerDetails = SellerDetails\n' +
                            '  CustomerAdditionalDetails := npsSdk.NewCustomerAdditionalDetailsStruct()\n' +
                            '  CustomerAdditionalDetails.EmailAddress = "mailAddr@mail.com.ar"\n' +
                            '\n' +
                            '  order1 := npsSdk.NewOrderItemStruct()\n' +
                            '  order1.Description = "producto 1"\n' +
                            '  order1.UnitPrice = "10"\n' +
                            '  order2 := npsSdk.NewOrderItemStruct()\n' +
                            '  order2.Description = "producto 2"\n' +
                            '  order2.UnitPrice = "20"\n' +
                            '\n' +
                            '  OrderDetails := npsSdk.NewOrderDetailsStruct()\n' +
                            '  OrderDetails.OrderItems = npsSdk.NewArrayOf_OrderItemStruct()\n' +
                            '  OrderDetails.OrderItems.Items = make([]*npsSdk.OrderItemStruct, 0)\n' +
                            '  OrderDetails.OrderItems.Items = append(OrderDetails.OrderItems.Items, order1)\n' +
                            '  OrderDetails.OrderItems.Items = append(OrderDetails.OrderItems.Items, order2)\n' +
                            '\n' +
                            '  payOnline2p := npsSdk.NewRequerimientoStruct_PayOnLine_2p()\n' +
                            '\n' +
                            '  payOnline2p.Psp_Version = "2.2"\n' +
                            '  payOnline2p.Psp_MerchantId = "psp_test"\n' +
                            '  payOnline2p.Psp_TxSource = "WEB"\n' +
                            '  payOnline2p.Psp_MerchTxRef = "ORDER56666-3"\n' +
                            '  payOnline2p.Psp_MerchOrderId = "ORDER56666"\n' +
                            '  payOnline2p.Psp_Amount = "1000"\n' +
                            '  payOnline2p.Psp_NumPayments = "1"\n' +
                            '  payOnline2p.Psp_Currency = "032"\n' +
                            '  payOnline2p.Psp_Country = "ARG"\n' +
                            '  payOnline2p.Psp_Product = "14"\n' +
                            '  payOnline2p.Psp_CustomerMail = "yourmail@gmail"\n' +
                            '  payOnline2p.Psp_CardNumber = "4507990000000010"\n' +
                            '  payOnline2p.Psp_CardExpDate = "1903"\n' +
                            '  payOnline2p.Psp_CardSecurityCode = "306"\n' +
                            '  payOnline2p.Psp_SoftDescriptor = "Sol Tropical E"\n' +
                            '  payOnline2p.Psp_PosDateTime = "2016-12-01 12:00:00"\n' +
                            '\n' +
                            '  payOnline2p.Psp_OrderDetails = OrderDetails\n' +
                            '  payOnline2p.Psp_CustomerAdditionalDetails = CustomerAdditionalDetails\n' +
                            '  payOnline2p.Psp_AmountAdditionalDetails = AmountAdditionalDetails\n' +
                            '  payOnline2p.Psp_BillingDetails = Billing\n' +
                            '  payOnline2p.Psp_MerchantAdditionalDetails = MerchantAdditionalDetails\n' +
                            '  resp, err := service.PayOnLine_2p(payOnline2p)\n' +
                            '  if err != nil {\n' +
                            '    return err\n' +
                            '  }\n' +
                            '\n' +
                            '  fmt.Printf("\\n")\n' +
                            '  fmt.Printf("Response = [%s] [%s]\\n", resp.Psp_ResponseCod, resp.Psp_ResponseMsg)\n' +
                            '  fmt.Printf("Extended = [%s]\\n", resp.Psp_ResponseExtended)\n' +
                            '  return nil\n' +
                            '}\n' +
                            '\n' +
                            'func main() {\n' +
                            '\n' +
                            '  err := npsSdk.Configure(map[string]interface{}{\n' +
                            '      "environment": CONSTANTS.SANDBOX_ENV,\n' +
                            '      "secret_key":  "_YOUR_SECRET_KEY_",\n' +
                            '    })\n' +
                            '\n' +
                            '  if err != nil {\n' +
                            '    log.Fatalf("error configuring sdk: %v", err)\n' +
                            '  }\n' +
                            '\n' +
                            '  service := npsSdk.NewPaymentServicePlatformPortType(true)\n' +
                            '\n' +
                            '  err = SendPayOnLine_2p(service)\n' +
                            '\n' +
                            '  if err != nil {\n' +
                            '    fmt.Printf("\\n")\n' +
                            '    fmt.Printf("ERROR [%s]\\n", err)\n' +
                            '  }\n' +
                            '\n' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};

export default Go;