import React from 'react';
import T from "i18react/dist/i18react";

import Sale from '../../../../ApiReference/Payments/CardPayment/Sale';
import masterPassIcon from '../../../../../assets/img/wallets/masterpass.png'

class MasterpassPCI extends React.Component {

    render() {
        return (
            <div className="container-fluid wallets">
                <div className="row">
                    <div className="col-12">
                        <h4 className="wallets-title">
                            {T.translate("guideReference.services.wallets.masterpass.pci.title")}
                        </h4>
                        <img src={masterPassIcon} alt="VisaCheckout" className="watermark-image"/>
                        <p>
                            {T.translate("guideReference.services.wallets.masterpass.pci.text")}
                        </p>
                        <ul>
                            <li>{T.translate("guideReference.services.wallets.masterpass.pci.list.1")}</li>
                            <li>{T.translate("guideReference.services.wallets.masterpass.pci.list.2")}</li>
                            <li>{T.translate("guideReference.services.wallets.masterpass.pci.list.3")}</li>
                            <li>{T.translate("guideReference.services.wallets.masterpass.pci.list.4")}</li>
                        </ul>
                        <h5>
                            {T.translate("guideReference.services.wallets.masterpass.pci.how.title")}
                        </h5>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.pci.how.steps.1.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.pci.how.steps.1.text",
                                {
                                    "onboarding": <T.a
                                        href="https://developer.mastercard.com/documentation/masterpass-merchant-onboarding"
                                        target="_blank" text="MasterPass Merchant Onboarding"/>,
                                    "integration": <T.a
                                        href="https://developer.mastercard.com/documentation/masterpass-merchant-integration-v7/7"
                                        target="_blank" text="MasterPass Merchant Integration"/>
                                })}
                        </p>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.pci.how.steps.2.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.pci.how.steps.2.text")}
                        </p>
                        <Sale showDocumentation={false} onlyFiles="masterpass_pci"/>
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.pci.how.steps.3.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.pci.how.steps.3.text",
                                {"link": <T.a href="https://developer.mastercard.com/page/masterpass-sandbox-testing-guidelines" target="_blank" text="Masterpass testing documentation"/>})}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>
                            <strong>{T.translate("guideReference.services.wallets.masterpass.pci.how.steps.4.title")}</strong>
                            <br/>
                            {T.translate("guideReference.services.wallets.masterpass.pci.how.steps.4.text",
                                {"link": <T.a href="mailto:merchantservices@worldline.com" text="Support Team"/>})}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default MasterpassPCI;