import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

import versions from '../../../../documentation/sdk_versions';

const Install = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.android.install.title">
               Step 1 - {T.translate("advancedCheckout.android.install.title")}
            </h4>
            <p id="advancedCheckout.android.install.text">
                {T.translate("advancedCheckout.android.install.text")}
            </p>
            <Accordion id="advancedCheckout.android.install.androidStudio.title" open
                title={  "1.1 - " + T.translate("advancedCheckout.android.install.androidStudio.title")}>
               <ul>
                   <li id="advancedCheckout.android.install.androidStudio.list.1">
                       {T.translate("advancedCheckout.android.install.androidStudio.list.1")}
                   </li>
                   <li id="advancedCheckout.android.install.androidStudio.list.2">
                       {T.translate("advancedCheckout.android.install.androidStudio.list.2")}
                   </li>
                   <li id="advancedCheckout.android.install.androidStudio.list.3">
                       {T.translate("advancedCheckout.android.install.androidStudio.list.3")}
                   </li>

               </ul>
            </Accordion>
            <Accordion id="advancedCheckout.android.install.compile.title" open
                       title={  "1.2 - " + T.translate("advancedCheckout.android.install.compile.title")}>
                <ul>
                    <li id="advancedCheckout.android.install.compile.list.1">
                        {T.translate("advancedCheckout.android.install.compile.list.1")}
                        <CodeHighlight language="java">
                            {
                                'compile \'com.github.ingenico-nps-latam:nps-sdk-android:'+versions.android+'\''
                            }
                        </CodeHighlight>
                    </li>
                    <li id="advancedCheckout.android.install.compile.list.2">
                        {T.translate("advancedCheckout.android.install.compile.list.2")}
                        <CodeHighlight language="java">
                            {
                                'maven { url \'https://oss.sonatype.org/content/repositories/ksoap2-android-releases/\' }'
                            }
                        </CodeHighlight>
                    </li>
                </ul>
            </Accordion>
            <hr/>
            <h4 id="advancedCheckout.android.install.integration.title">
                Step 2 {T.translate("advancedCheckout.android.install.integration.title")}
            </h4>
            <Accordion id="advancedCheckout.android.install.integration.permissions.title" open
                title={ "2.1 - " + T.translate("advancedCheckout.android.install.integration.permissions.title")}>
                <p id="advancedCheckout.android.install.integration.permissions.text">
                    {T.translate("advancedCheckout.android.install.integration.permissions.text")}
                </p>
                <CodeHighlight language="xml">
                    {
                        '<uses-permission android:name="android.permission.INTERNET" />\n' +
                        '<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" />\n' +
                        '<uses-permission android:name="android.permission.ACCESS_WIFI_STATE"/>'
                    }
                </CodeHighlight>
            </Accordion>
            <Accordion id="advancedCheckout.android.install.integration.import.title" open
                title={ "2.2 - " + T.translate("advancedCheckout.android.install.integration.import.title")}>
                <p id="advancedCheckout.android.install.integration.import.text">
                    {T.translate("advancedCheckout.android.install.integration.import.text")}
                </p>
                <CodeHighlight language="java">
                    {
                       'import com.github.ingeniconpslatam.nps.Card;\n' +
                       'import com.github.ingeniconpslatam.nps.InstallmentOption;\n' +
                       'import com.github.ingeniconpslatam.nps.Nps;\n' +
                       'import com.github.ingeniconpslatam.nps.PaymentMethod;\n' +
                       'import com.github.ingeniconpslatam.nps.PaymentMethodToken;'
                    }
                </CodeHighlight>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Install;