import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";

class PaymentMethod extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: "PaymentMethod Component"
        };
    }

    render() {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Payment Method</h3>
                    </div>
                    <div className="col-12">
                        <p>
                           Ths set of methods are used for persistent and re usable payment methods.
                        </p>
                        <hr/>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 25}}>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethod/Create`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Create
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethod/CreateFromPayment`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Create from Payment
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethod/Update`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Update
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethod/Retrieve`}>
                            <button type="button" className="btn btn-light btn-block">
                                Retrieve
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethod/Delete`}>
                            <button type="button" className="btn btn-light btn-block">
                                Delete
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentMethod;