import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Validators = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.dart.validators.title">
                (Optional) Step 6 - {T.translate("advancedCheckout.dart.validators.title")}
            </h4>
            <Accordion id="advancedCheckout.dart.validators.methods.title" open
                title={T.translate("advancedCheckout.dart.validators.methods.title")}>
                <div className="row">
                    <div className="col-12">
                        <h6 id="advancedCheckout.dart.validators.methods.name.title">
                            {T.translate("advancedCheckout.dart.validators.methods.name.title")}
                        </h6>
                        <p id="advancedCheckout.dart.validators.methods.name.text">
                            {T.translate("advancedCheckout.dart.validators.methods.name.text")}
                        </p>
                        <CodeHighlight language="dart">
                            {
                                'if(nps.validateCardHolderName("JOHN DOE")){\n' +
                                '    print("Holder name is valid")\n' +
                                '} else {\n' +
                                '    print("Holder name is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.dart.validators.methods.number.title">
                            {T.translate("advancedCheckout.dart.validators.methods.number.title")}
                        </h6>
                        <p id="advancedCheckout.dart.validators.methods.number.text">
                            {T.translate("advancedCheckout.dart.validators.methods.number.text")}
                        </p>
                        <CodeHighlight language="dart">
                            {
                                'if(nps.validateCardNumber("4111000000000010")){\n' +
                                '    print("Card number is valid")\n' +
                                '} else {\n' +
                                '    print("Card number is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.dart.validators.methods.expiration.title">
                            {T.translate("advancedCheckout.dart.validators.methods.expiration.title")}
                        </h6>
                        <p id="advancedCheckout.dart.validators.methods.expiration.text">
                            {T.translate("advancedCheckout.dart.validators.methods.expiration.text")}
                        </p>
                        <CodeHighlight language="dart">
                            {
                                'if(nps.validateCardExpDate(2017, month:12)){\n' +
                                '    print("Expiration is valid")\n' +
                                '} else {\n' +
                                '    print("Expiration is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.dart.validators.methods.security.title">
                            {T.translate("advancedCheckout.dart.validators.methods.security.title")}
                        </h6>
                        <p id="advancedCheckout.dart.validators.methods.security.text">
                            {T.translate("advancedCheckout.dart.validators.methods.security.text")}
                        </p>
                        <CodeHighlight language="dart">
                            {
                                'if(nps.validateCardSecurityCode("132")){\n' +
                                '    print("CVV is valid")\n' +
                                '} else {\n' +
                                '    print("CVV is invalid")\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <hr/>
                    </div>
                    <div className="col-12">
                        <h6 id="advancedCheckout.dart.validators.methods.inn.title">
                            {T.translate("advancedCheckout.dart.validators.methods.inn.title")}
                        </h6>
                        <p id="advancedCheckout.dart.validators.methods.inn.text">
                            {T.translate("advancedCheckout.dart.validators.methods.inn.text")}
                        </p>
                        <CodeHighlight language="dart">
                            {
                                'import \'dart:async\';\n' +
                                '\n' +
                                'import \'package:nps_sdk/nps_sdk.dart\';\n' +
                                '\n' +
                                'Nps nps = new Nps(sandbox);\n' +
                                '\n' +
                                'Map getINNDetailsParams = {\n' +
                                '  "psp_Version": "2.2",\n' +
                                '  "psp_MerchantId": "sdk_test",\n' +
                                '  "psp_IIN" : "424242",\n' +
                                '  "psp_PosDateTime" : "2019-12-01 12:00:00",\n' +
                                '  "psp_ClientSession": "YOUR_CLIENT_SESSION_HERE"\n' +
                                '};\n' +
                                '\n'+
                                'Future response = nps.getINNDetails(nps, getINNDetailsParams);\n' +
                                'response.then((resp) => print(resp["psp_ResponseCod"]))\n' +
                                '        .catchError((error) => print);\n'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Validators;