import React from 'react';

class YoutubeModal extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            modalOpen: true,
            youtubeHandler: false,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchend', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.state.modalOpen && (this.modalRef && !this.modalRef.contains(event.target))) {
            this.setState({
                modalOpen: false
            });
            this.props.youtubeHandler();
        }
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchend', this.handleClickOutside);
    }

    render() {

        return (
            <div className="youtube-wrapper">
                <div className={this.state.modalOpen ? 'modal youtube-modal fade show' : 'modal fade'} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content"
                             ref={(node) => this.modalRef = node}>
                            <div className="modal-body">
                                {
                                    this.state.modalOpen &&
                                    <iframe src={`https://www.youtube.com/embed/${this.props.youtubeUrl}`}
                                            frameBorder='0'
                                            height="390"
                                            width="640"
                                            allowFullScreen
                                            title='video'
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.modalOpen ? 'modal-backdrop fade show' : ''}/>
            </div>

        );
    }
}

export default YoutubeModal;