import React from 'react';
import T from "i18react/dist/i18react";

import CodeHighlight from '../../../components/CodeHighlight';
// import lockIcon from '../../../assets/img/lock-content.svg';

import arFlag from '../../../assets/img/flags/flags-iso/flat/64/AR.png';
import brFlag from '../../../assets/img/flags/flags-iso/flat/64/BR.png';
import mxFlag from '../../../assets/img/flags/flags-iso/flat/64/MX.png';
import peFlag from '../../../assets/img/flags/flags-iso/flat/64/PE.png';
import clFlag from '../../../assets/img/flags/flags-iso/flat/64/CL.png';

class Secure extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.openLogin = this.openLogin.bind(this);
    }

    openLogin(){
        document.getElementById('loginClickOpen').click();
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.3dSecure.title">
                            { T.translate("guideReference.services.3dSecure.title") }</h3>
                        <p id="guideReference.services.3dSecure.text">
                            { T.translate("guideReference.services.3dSecure.text") }
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="guideReference.services.3dSecure.availability.title">
                            { T.translate("guideReference.services.3dSecure.availability.title") }
                        </h4>
                        <div className="availability-flags">
                            <div className="flag-container">
                                <img src={arFlag} alt="Argentina" title="Argentina" className="image"/>
                                <div className="overlay">
                                    <div className="text">Argentina</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={brFlag} alt="Brasil" title="Brasil" className="image"/>
                                <div className="overlay">
                                    <div className="text">Brasil</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={clFlag} alt="Chile" title="Chile" className="image"/>
                                <div className="overlay">
                                    <div className="text">Chile</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={mxFlag} alt="Mexico" title="Mexico" className="image"/>
                                <div className="overlay">
                                    <div className="text">Mexico</div>
                                </div>
                            </div>
                            <div className="flag-container">
                                <img src={peFlag} alt="Peru" title="Peru" className="image"/>
                                <div className="overlay">
                                    <div className="text">Peru</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.3dSecure.merchant.title">
                            { T.translate("guideReference.services.3dSecure.merchant.title") }
                        </h4>
                        <p id="guideReference.services.3dSecure.merchant.text">
                            { T.translate("guideReference.services.3dSecure.merchant.text") }
                        </p>
                        <br/><br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="5" style={{textAlign: "center"}}>
                                        <p><strong>Merchant Own MPI - Direct integration in Merchant site </strong>(request)</p>
                                        <table style={{margin: '0 auto'}}>
                                            <tr>
                                                <td>Authorize_2p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>PayOnline_2p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5"><p>In case you want to implement this function, only the following fields have to be completed.</p></td>
                                </tr>
                                <tr>
                                    <td><b>Field</b></td>
                                    <td><b>Type</b></td>
                                    <td><b>Data Type</b></td>
                                    <td><b>Length <br/> [Min,Max]</b></td>
                                    <td><b>Example of field content</b></td>
                                </tr>
                                <tr>
                                    <td>psp_3dSecure_XID</td>
                                    <td>O</td>
                                    <td>Base64</td>
                                    <td>1,40</td>
                                    <td>CBKJB289V1PZL4TDXXWF</td>
                                </tr>
                                <tr>
                                    <td>psp_3dSecure_CAVV</td>
                                    <td>O *<sub>1</sub></td>
                                    <td>Base64</td>
                                    <td>1,40</td>
                                    <td>AAABA1GQdAAAAAAAF5B0AAAAAAA=</td>
                                </tr>
                                <tr>
                                    <td>psp_3dSecure_ECI</td>
                                    <td>O *<sub>1</sub></td>
                                    <td>Alphanumeric</td>
                                    <td>1,2</td>
                                    <td>05</td>
                                </tr>
                                <tr>
                                    <td>psp_3dSecure_Secured</td>
                                    <td>O</td>
                                    <td>Numeric</td>
                                    <td>1,1</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>psp_3dSecure_ProtocolVersion</td>
                                    <td>O *<sub>2</sub></td>
                                    <td>Enum</td>
                                    <td>5,8</td>
                                    <td>2.1.0</td>
                                </tr>
                                <tr>
                                    <td>psp_3dSecure_DirectoryServerTransactionId</td>
                                    <td>O *<sub>2</sub></td>
                                    <td>String</td>
                                    <td>36,36</td>
                                    <td>c4e59ceb-a382-4d6a-bc87-385d591fa09d</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="alert alert-info">
                            *1. If you send any of these fields, you should send all (*<sub>1</sub>) fields. <br></br>
                            *2. If you send a ProtocolVersion 2+, you should send all (*<sub>1</sub> & *<sub>2</sub>) fields.
                        </div>

                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_3dSecure_XID": "CBKJB289V1PZL4TDXXWF", \n'+
                                '   "psp_3dSecure_CAVV": "AAABA1GQdAAAAAAAF5B0AAAAAAA=", \n'+
                                '   "psp_3dSecure_ECI": "05", \n'+
                                '   "psp_3dSecure_Secured": "1", \n'+
                                '   "psp_3dSecure_ProtocolVersion": "2.1.0", \n'+
                                '   "psp_3dSecure_DirectoryServerTransactionId": "c4e59ceb-a382-4d6a-bc87-385d591fa09d", \n'+
                                '   ...'
                            }
                        </CodeHighlight>

                        {/*<hr/>*/}

                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 table-overflow-x">
                        <h4 id="guideReference.services.3dSecure.issuer.title">{ T.translate("guideReference.services.3dSecure.issuer.title") }</h4>
                            <div id="guideReference.services.3dSecure.issuer.text">
                                { T.translate("guideReference.services.3dSecure.issuer.text") }
                            </div>
                        <div id="guideReference.services.3dSecure.issuer.information"
                            className="alert alert-info">
                            { T.translate("guideReference.services.3dSecure.issuer.information") }
                        </div>
                        <br/><br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="5"  style={{textAlign: "center"}}>
                                        <p><strong>Issuer MPI - Using Acquirer/Issuer integration MPI</strong> (request)</p>
                                        <table style={{margin: '0 auto'}}>
                                            <tr>
                                                <td>Authorize_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>PayOnline_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>SplitPayment</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <p>In case you want to implement this function, only the following fields have to be completed.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Field
                                    </td>
                                    <td>
                                        Type
                                    </td>
                                    <td>
                                        Data Type
                                    </td>
                                    <td>
                                        Length <br/>
                                        [Min,Max]
                                    </td>
                                    <td>
                                        Example of field content
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <p>It does not require input fields.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <br/><br/><br/>

                        <table className="table table-documentation table-responsive">
                            <tbody>
                                <tr>
                                    <td colSpan="5" style={{textAlign: "center"}}>
                                        <p>
                                            <strong>Issuer MPI - Using Acquirer/Issuer integration MPI</strong> (response)
                                            <br/>
                                            There will not be output fields for this function.
                                        </p>
                                        <table style={{margin: '0 auto'}}>
                                            <tr>
                                                <td>PayOnline_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>Authorize_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>SplitPayOnLine_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                                <td>SplitAuthorize_3p</td>
                                                <td><i className="fa fa-check-circle"/></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5">
                                        <p>
                                            3Party - SimpleQueryTx
                                            <br/>
                                            In the response, among basic fields, the following fields will be received.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Field
                                    </td>
                                    <td>
                                        Type
                                    </td>
                                    <td>
                                        Data Type
                                    </td>
                                    <td>
                                        Length <br/>
                                        [Min,Max]
                                    </td>
                                    <td>
                                        Example of field content
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_3dSecure_XID
                                    </td>
                                    <td>
                                        O
                                    </td>
                                    <td>
                                        Base64
                                    </td>
                                    <td>
                                        1,40
                                    </td>
                                    <td>
                                        CBKJB289V1PZL4TDXXWF
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_3dSecure_CAVV
                                    </td>
                                    <td>
                                        O
                                    </td>
                                    <td>
                                        Base64
                                    </td>
                                    <td>
                                        1,40
                                    </td>
                                    <td>
                                        AAABA1GQdAAAAAAAF5B0AAAAAAA=
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_3dSecure_ECI
                                    </td>
                                    <td>
                                        O
                                    </td>
                                    <td>
                                        Alphanumeric
                                    </td>
                                    <td>
                                        1,2
                                    </td>
                                    <td>
                                        05
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        psp_3dSecure_Secured
                                    </td>
                                    <td>
                                        O
                                    </td>
                                    <td>
                                        Numeric
                                    </td>
                                    <td>
                                        1,1
                                    </td>
                                    <td>
                                        1
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <CodeHighlight lang='php'>
                            {
                                '   /* Pseudocode example */\n' +
                                '   ...\n' +
                                '   ...\n' +
                                '   "psp_3dSecure_XID": "CBKJB289V1PZL4TDXXWF", \n'+
                                '   "psp_3dSecure_CAVV": "AAABA1GQdAAAAAAAF5B0AAAAAAA=", \n'+
                                '   "psp_3dSecure_ECI": "05", \n'+
                                '   "psp_3dSecure_Secured": "1", \n'+
                                '   ...'
                            }
                        </CodeHighlight>

                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

        );
    }
}

export default Secure;