import React from 'react';
import T from 'i18react';
import {Tabs, Tab} from 'react-bootstrap-tabs';

import Prestashop from './Plugins/Prestashop';
import Magento from './Plugins/Magento';
import Woocommerce from './Plugins/Woocommerce';

/*ICONS*/
import magentoIcon from '../../../assets/img/dev/magento.svg';
import prestashopIcon from '../../../assets/img/dev/prestashop.svg';
import woocommerceIcon from '../../../assets/img/dev/woocommerce.svg';


class Client extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            tabContainer: ['row', 'tabs-container'],
            iconsContainer: ['row', 'icons-container'],
            showHelp: true,
            indexTab: 0
        };
        this.showElements = this.showElements.bind(this);
    }

    componentWillMount(){
        if(this.props.match.params.tab){
            if(this.props.match.params.tab === 'javascript'){
                this.showElements(0);
            }
            if(this.props.match.params.tab === 'android'){
                this.showElements(1);
            }
            if(this.props.match.params.tab === 'objectiveC'){
                this.showElements(2);
            }
            if(this.props.match.params.tab === 'swift'){
                this.showElements(3);
            }
        }
    }

    componentDidMount(){
        this.props.scrollToElement();
    }

    componentDidUpdate(){
        this.props.scrollToElement();
    }

    showElements(indexTab){
        let tabsClass = this.state.tabContainer;
        tabsClass.push('show');

        let iconsClass = this.state.iconsContainer;
        iconsClass.push('hide');

        this.setState({
            tabContainer: tabsClass,
            iconsContainer: iconsClass,
            showHelp: false,
            indexTab: indexTab
        });
    }

    render() {
        return (


            <div className="container-fluid libraries">
                <div className="row">
                    <div className="col-12  text-center">
                        <p id="libraries.client.text">
                            {T.translate("libraries.plugins.text")}
                        </p>
                    </div>
                </div>
                <div className={this.state.iconsContainer.join(' ')}>
                    <div className="col-12 icons" style={{maxWidth: '70%', margin: '0 auto'}}>
                        <img src={magentoIcon}
                             alt="Javascript"
                             onClick={() => this.showElements(0)}/>
                        <img src={prestashopIcon}
                             alt="Android"
                             onClick={() => this.showElements(1)}/>
                        <img src={woocommerceIcon}
                             alt="Javascript"
                             onClick={() => this.showElements(2)}/>
                    </div>
                </div>
                <div className={this.state.tabContainer.join(' ')}>
                    <div className="col-12">
                        <Tabs selected={this.state.indexTab}>
                            <Tab label="Magento">
                                <Magento/>
                            </Tab>
                            <Tab label="Prestashop">
                                <Prestashop/>
                            </Tab>
                            <Tab label="Woocommerce">
                                <Woocommerce/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default Client;