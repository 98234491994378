import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import versions from '../../../../documentation/sdk_versions';

const Install = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.dart.install.title">
               Step 1 - {T.translate("advancedCheckout.dart.install.title")}
            </h4>
            <p id="advancedCheckout.dart.install.text">
                {T.translate("advancedCheckout.dart.install.text")}
            </p>
            <Accordion id="advancedCheckout.dart.install.pub.title" open
                title={ "1.1 - " + T.translate("advancedCheckout.dart.install.pub.title")}>
               <ul>
                   <li id="advancedCheckout.dart.install.androidStudio.list.1">
                       {T.translate("advancedCheckout.dart.install.pub.list.1")}
                       <br/>
                       <CodeHighlight language="dart">
                           dependencies:
                           <br/>
                           nps_sdk: {versions.dart}
                       </CodeHighlight>
                   </li>
                   <li id="advancedCheckout.dart.install.androidStudio.list.2">
                       {T.translate("advancedCheckout.dart.install.pub.list.2")}
                       <br/>
                       <CodeHighlight language="dart">
                           pub get
                       </CodeHighlight>
                   </li>
               </ul>
            </Accordion>
            <Accordion id="advancedCheckout.dart.install.github.title" open
                       title={  "1.2 - " + T.translate("advancedCheckout.dart.install.github.title")}>
                <ul>
                    <li id="advancedCheckout.dart.install.github.list.1">
                        {T.translate("advancedCheckout.dart.install.github.list.1")}
                        <CodeHighlight language="java">
                            {
                                'git clone https://github.com/Ingenico-NPS-Latam/nps-sdk-dart.git'
                            }
                        </CodeHighlight>
                    </li>
                </ul>
            </Accordion>

            <hr/>
            <h4 id="advancedCheckout.dart.install.integration.title">
                Step 2 {T.translate("advancedCheckout.dart.install.integration.title")}
            </h4>
            <Accordion id="advancedCheckout.dart.install.integration.import.title" open
                title={T.translate("advancedCheckout.dart.install.integration.import.title")}>
                <p id="advancedCheckout.dart.install.integration.import.text">
                    {T.translate("advancedCheckout.dart.install.integration.import.text")}
                </p>
                <CodeHighlight language="java">
                {
                    'import \'dart:async\';\n' +
                    '\n' +
                    'import \'package:nps_sdk/nps_sdk.dart\';\n'

                }
                </CodeHighlight>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Install;