import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';

const Configure = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.react.configure.title">
                Step 2 - {T.translate("advancedCheckout.react.configure.title")}
            </h4>

            <p>
                The React SDK provides a way to setup the environment as you initialize your component, with this you can
                have a dedicated payment page.<br/>
                Remember that certain parameters are required, and others are handled by default.<br/>
                You must configure your env, ClientSession and your MerchantId to be able to start to use React SDK to identify your site while communicating with NPS.
                Fields like fingerprint and expDate are optional.
            </p>
            <CodeHighlight language="javascript">
                {
                    'let ReactNPSData = new ReactNPS({\n' +
                    '    env: \'sandbox\',\n' +
                    '    session: \'YOUR_CLIENT_SESSION\',\n' +
                    '    merchant: \'YOUR_MERCHANT_ID\',\n' +
                    '    fingerprint: true,\n' +
                    '    expDate: \'MMYY\'\n' +
                    '});\n' +
                    '\n' +
                    'this.state = {\n' +
                    '    ReactNPS: ReactNPSData,\n' +
                    '    ...\n' +
                    '};'
                }
            </CodeHighlight>
            <p>
                Most of the fields can be set individually too. (Environment is a required parameter to initialize the component)
            </p>
            <CodeHighlight language="javascript">
                {
                    'let ReactNPSData = new ReactNPS({\n' +
                    '    env: \'sandbox\'\n' +
                    '});\n' +
                    '\n' +
                    'this.state = {\n' +
                    '    ReactNPS: ReactNPSData,\n' +
                    '    ...\n' +
                    '};'+
                    '\n' +
                    'ReactNPSData.setClientSession(\'YOUR_CLIENT_SESSION\');\n'+
                    'ReactNPSDataS.setMerchantID(\'YOUR_MERCHANT_ID\');\n'+
                    'ReactNPSData.setUseDeviceFingerPrint(true);\n'+
                    'ReactNPSData.setExpDateFormat(\'MMYY\');\n'
                }
            </CodeHighlight>
            <hr/>
        </div>
    );
};

export default Configure;