import React from 'react';
import T from 'i18react';

import CodeHighlight from '../../../../components/CodeHighlight';

import logo from '../../../../assets/img/dev/magento.svg';

const Magento = () => {
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-md-2 text-center">
                    <img src={logo} alt="Magento" className="img-fluid language-logo"/>
                    <br/>
                    <span className="badge badge-success">
                        1.7.x.x / 1.9.x.x
                    </span>
                </div>
                <div className="col-12 col-md-10 download text-center text-md-right">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-plugin-magento" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                </div>
            </div>
            <div className="row hide">
                <div className="col-12">
                    <hr/>
                    <h4>{T.translate("libraries.plugins.magento.install.title")}</h4>
                    <ol>
                        <li>
                            {T.translate("libraries.plugins.magento.install.list.1")}
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.install.list.2")}
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.install.list.3.text")}
                            <dl>
                                <dd>{T.translate("libraries.plugins.magento.install.list.3.definitionList.1")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.3.definitionList.2")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.3.definitionList.3")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.3.definitionList.4")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.3.definitionList.5")}</dd>
                            </dl>
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.install.list.4")}
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.install.list.5.text")}
                            <dl>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.1")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.2")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.3")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.4")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.5")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.6")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.7")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.5.definitionList.8")}</dd>
                            </dl>
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.install.list.6.text")}
                            <dl>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.1")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.2")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.3")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.4")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.5")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.6")}</dd>
                                <dd>{T.translate("libraries.plugins.magento.install.list.6.definitionList.7")}</dd>
                            </dl>
                        </li>
                    </ol>
                    <div className="alert alert-warning" role="alert">
                        {T.translate("libraries.plugins.magento.install.warning")}
                    </div>
                </div>
            </div>
            <div className="row hide">
                <div className="col-12">
                    <h4>
                        {T.translate("libraries.plugins.magento.multiple.title")}
                    </h4>
                </div>
                <div className="col-12">
                    <h5>
                        {T.translate("libraries.plugins.magento.multiple.add.title")}
                    </h5>
                    <p>
                        {T.translate("libraries.plugins.magento.multiple.add.text")}
                    </p>
                    <ol>
                        <li>{T.translate("libraries.plugins.magento.multiple.add.list.1")}</li>
                        <li>{T.translate("libraries.plugins.magento.multiple.add.list.2")}</li>
                        <li>{T.translate("libraries.plugins.magento.multiple.add.list.3")}</li>
                        <li>{T.translate("libraries.plugins.magento.multiple.add.list.4")}</li>
                        <li>{T.translate("libraries.plugins.magento.multiple.add.list.5")}</li>
                        <li>{T.translate("libraries.plugins.magento.multiple.add.list.6")}</li>
                    </ol>
                </div>
                <div className="col-12">
                    <h5>
                        {T.translate("libraries.plugins.magento.multiple.store.title")}
                    </h5>
                    <p>
                        {T.translate("libraries.plugins.magento.multiple.store.text")}
                    </p>
                    <ol>
                        <li>{T.translate("libraries.plugins.magento.multiple.store.list.1")}</li>
                        <li>
                            {T.translate("libraries.plugins.magento.multiple.store.list.2.text")}
                            <ul>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.2.list.1")}</li>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.2.list.2")}</li>
                            </ul>
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.multiple.store.list.3.text")}
                            <ul>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.3.list.1")}</li>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.3.list.2")}</li>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.3.list.3")}</li>
                            </ul>
                        </li>
                        <li>
                            {T.translate("libraries.plugins.magento.multiple.store.list.4.text")}
                            <ul>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.4.list.1")}</li>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.4.list.2")}</li>
                                <li>{T.translate("libraries.plugins.magento.multiple.store.list.4.list.3")}</li>
                            </ul>
                        </li>
                        <li>{T.translate("libraries.plugins.magento.multiple.store.list.5")}</li>
                        <li>{T.translate("libraries.plugins.magento.multiple.store.list.6")}</li>
                    </ol>
                </div>
                <div className="col-12">
                    <h5>
                        {T.translate("libraries.plugins.magento.multiple.domain.title")}
                    </h5>
                    <p>
                        {T.translate("libraries.plugins.magento.multiple.domain.text")}
                    </p>
                    <ol>
                        <li>{T.translate("libraries.plugins.magento.multiple.domain.list.1")}</li>
                        <li>
                            {T.translate("libraries.plugins.magento.multiple.domain.list.2.text")}
                            <CodeHighlight languague="Apache">
                                {
                                    'SetEnvIf Host www\\.domain1\\.com MAGE_RUN_CODE=domain1_com\n' +
                                    'SetEnvIf Host www\\.domain1\\.com MAGE_RUN_TYPE=website\n' +
                                    'SetEnvIf Host ^domain1\\.com MAGE_RUN_CODE=domain1_com\n' +
                                    'SetEnvIf Host ^domain1\\.com MAGE_RUN_TYPE=website\n' +
                                    '\n' +
                                    'SetEnvIf Host www\\.domain2\\.com MAGE_RUN_CODE=domain2_com\n' +
                                    'SetEnvIf Host www\\.domain2\\.com MAGE_RUN_TYPE=website\n' +
                                    'SetEnvIf Host ^domain2\\.com MAGE_RUN_CODE=domain2_com\n' +
                                    'SetEnvIf Host ^domain2\\.com MAGE_RUN_TYPE=website'
                                }
                            </CodeHighlight>
                            <div className="alert alert-info" role="alert">
                                {T.translate("libraries.plugins.magento.multiple.domain.list.2.information")}
                            </div>
                            <CodeHighlight languague="Apache">
                                {
                                    'RewriteCond %{HTTP_HOST} www\\.domain1\\.com [NC]\n' +
                                    'RewriteRule .* - [E=MAGE_RUN_CODE:domain1_com]\n' +
                                    'RewriteCond %{HTTP_HOST} www\\.domain1\\.com [NC]\n' +
                                    'RewriteRule .* - [E=MAGE_RUN_TYPE:website]\n' +
                                    '\n' +
                                    'RewriteCond %{HTTP_HOST} www\\.domain2\\.com [NC]\n' +
                                    'RewriteRule .* - [E=MAGE_RUN_CODE:domain2_com]\n' +
                                    'RewriteCond %{HTTP_HOST} www\\.domain2\\.com [NC]\n' +
                                    'RewriteRule .* - [E=MAGE_RUN_TYPE:website]'
                                }
                            </CodeHighlight>
                            <ul>
                                <li>{T.translate("libraries.plugins.magento.multiple.domain.list.2.mageRunCode")}</li>
                                <li>{T.translate("libraries.plugins.magento.multiple.domain.list.2.mageRunType")}</li>
                            </ul>
                        </li>
                        <li>{T.translate("libraries.plugins.magento.multiple.domain.list.3")}</li>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default Magento;