import React from 'react';
import { Link } from 'react-router-dom';

import DocBuilder from '../../../../components/DocBuilder';
import guideReferenceIcon from '../../../../assets/img/guide_reference.svg';
import request from '../../../../documentation/documentation/Authorize_3p-request';
import response from '../../../../documentation/documentation/Authorize_3p-response';
import {guideReferenceURL} from '../../../../env';
import Breadcrumbs from "../../../../components/Breadcrumbs";

class Authorization extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            <br/>
                            When a delayed settlement is preferred, 2 transactions are needed:
                            <ul>
                                <li>Authorization</li>
                                <li>
                                    Capture <br/>
                                    Through this method it is possible to validate if the client's card has sufficient funds and it will reserve the amount sent from the cardholder's account. <br/>
                                    It is necessary to use a capture to do an effective payment. <br/>
                                    Authorizations have an expiration date, that depends on the acquirer. Once the authorization has expired, the capture is no longer valid, though some acquirers may approved it online, the settlement isn’t done. For more information about Capture method, please go to Capture Transaction (link al método de captura)
                                </li>
                            </ul>
                            <br/>
                        </p>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-12">
                                    <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                    <span>
                                        For more information please go to&nbsp;
                                        <Link to={`${guideReferenceURL}/reference/simple`}>
                                          Guide Reference: Simple Checkout
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='Authorize_3p' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Authorization;