import axios from 'axios';

const languagesServer = [
    'dotnet',
    'go',
    'java',
    'lua',
    'node',
    'perl',
    'php',
    'python',
    'ruby',
    'c'
];

const languagesClient = [
    'android',
    'javascript',
    'objectivec',
    'swift',
    'dart'
];

export function loadExamples(methods,haveClientExamples,endpoint) {
    let examples = {};
    let promises = [];
    let totalCalls = 0;
    let resolvedCalls = [];

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        return {
            data: ''
        };
    });

    languagesServer.forEach((language) => {
        Object.keys(methods).forEach((method) => {
            totalCalls++;
            promises.push(axios({
                method: 'get',
                url: `/api/newExamples/${language}/${method}.md`,
                onDownloadProgress: (progressEvent) => {
                    if(`${window.location.origin}/api/newExamples/${language}/${method}.md` === progressEvent.target.responseURL &&
                        resolvedCalls.indexOf(progressEvent.target.responseURL) === -1){
                            resolvedCalls.push(progressEvent.target.responseURL);
                            sessionStorage.setItem('loadedExamplesCount', resolvedCalls.length + '/' + totalCalls);
                    }
                },
            }));
        })
    });
    if(haveClientExamples === true){
        languagesClient.forEach((language) => {
            let clientMethods = [
                '__Request',
                '__Request_with_person_address',
                '__Response',
                '__Response_with_person_address'
            ];

            clientMethods.forEach((method) => {
                totalCalls++;
                promises.push(axios({
                    method: 'get',
                    url: `/api/newExamples/${language}/${endpoint + method}.md`,
                    onDownloadProgress: (progressEvent) => {
                        if(`${window.location.origin}/api/newExamples/${language}/${endpoint + method}.md` === progressEvent.target.responseURL &&
                            resolvedCalls.indexOf(progressEvent.target.responseURL) === -1){
                            resolvedCalls.push(progressEvent.target.responseURL);
                            sessionStorage.setItem('loadedExamplesCount', resolvedCalls.length + '/' + totalCalls);
                        }
                    },
                    validateStatus: function (status) {
                        return status === 200;
                    }
                }));
            })
        });
    }

    axios.all(promises)
        .then((result) =>{
           result.forEach((response) =>{
               if(response.data !== ''){
                   languagesServer.forEach((language) => {
                       Object.keys(methods).forEach((method) => {
                            if(response.config.url.indexOf(`/api/newExamples/${language}/${method}.md`) === 0){
                                if(!examples[language]){
                                    examples[language] = {};
                                }
                                examples[language][method] = response.data;
                            }
                       });
                   });

                   languagesClient.forEach((language) => {
                       let clientMethods = [
                           '__Request',
                           '__Request_with_person_address',
                           '__Response',
                           '__Response_with_person_address'
                       ];
                       clientMethods.forEach((method) => {
                           if(response.config.url.indexOf(`/api/newExamples/${language}/${endpoint + method}.md`) === 0){
                               if(!examples[language]){
                                   examples[language] = {};
                               }
                               examples[language][endpoint + method] = response.data;
                           }
                       });
                   });
               }
            });
    });

    return examples;
}