import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';

const Form = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.javascript.form.title">
                Step 4 - {T.translate("advancedCheckout.javascript.form.title")}
            </h4>
            <p id="advancedCheckout.javascript.form.text">
                {T.translate("advancedCheckout.javascript.form.text")}
            </p>
            <div id="advancedCheckout.javascript.form.danger"
                className="alert alert-danger"
                role="alert">
                {T.translate("advancedCheckout.javascript.form.danger")}
            </div>
            <div id="advancedCheckout.javascript.form.information"
                className="alert alert-info"
                 role="alert">
                {T.translate("advancedCheckout.javascript.form.information")}
            </div>
            <Accordion id="advancedCheckout.javascript.form.example.simple" open
                title={ "4.1 - " + T.translate("advancedCheckout.javascript.form.example.simple")}>
                <div className="row">
                    <div className="col-12">
                        <CodeHighlight language="HTML">
                            {
                                '<form action="" method="POST" id="payment-method-form">\n' +
                                '  <span id="payment-method-errors"></span>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Holder Name</label>\n' +
                                '      <input type="text" size="20" maxlength="26" data-nps="card[holder_name]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Number</label>\n' +
                                '      <input type="text" size="19" maxlength="19" data-nps="card[number]" value=""/>\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Security Code</label>\n' +
                                '      <input type="text" size="4" maxlength="4" data-nps="card[security_code]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (MM/YYYY)</label>\n' +
                                '    <input type="text" size="2" maxlength="2" data-nps="card[exp_month]" value="" />\n' +
                                '    <span>/</span>\n' +
                                '    <input type="text" size="4" maxlength="4" data-nps="card[exp_year]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <!-- you can choose exp_date over exp_year+exp_month to send expiry date in one field only -->\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (YYMM) or (YYYYMM)</label>\n' +
                                '    <input type="text" size="6" data-nps="card[exp_date]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <button type="submit" id="payment-method-form-submit">Tokenize!</button>\n' +
                                '</form>'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.form.example.multiple" open
                title={ "4.2 - " + T.translate("advancedCheckout.javascript.form.example.multiple")}>
                <div className="row">
                    <div className="col-12">
                        <CodeHighlight language="HTML">
                            {
                                '<form action="" method="POST" id="payment-method-form">\n' +
                                '  <span id="payment-method-errors"></span>\n' +
                                '\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Holder Name</label>\n' +
                                '      <input type="text" size="20" maxlength="26" data-nps="cards[0][holder_name]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Number</label>\n' +
                                '      <input type="text" size="19" maxlength="19" data-nps="cards[0][number]" value=""/>\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Security Code</label>\n' +
                                '      <input type="text" size="4" maxlength="4" data-nps="cards[0][security_code]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (MM/YYYY)</label>\n' +
                                '    <input type="text" size="2" maxlength="2" data-nps="cards[0][exp_month]" value="" />\n' +
                                '    <span>/</span>\n' +
                                '    <input type="text" size="4"  maxlength="4" data-nps="cards[0][exp_year]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <!-- you can choose exp_date over exp_year+exp_month to send expiry date in one field only -->\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (YYMM) or (YYYYMM)</label>\n' +
                                '    <input type="text" size="6  maxlength="6"" data-nps="cards[0][exp_date]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Holder Name</label>\n' +
                                '      <input type="text" size="20"  maxlength="26" data-nps="cards[1][holder_name]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Number</label>\n' +
                                '      <input type="text" size="19" maxlength="19" data-nps="cards[1][number]" value=""/>\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Security Code</label>\n' +
                                '      <input type="text" size="4"  maxlength="4" data-nps="cards[1][security_code]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (MM/YYYY)</label>\n' +
                                '    <input type="text" size="2" maxlength="2" data-nps="cards[1][exp_month]" value="" />\n' +
                                '    <span>/</span>\n' +
                                '    <input type="text" size="4" maxlength="4" data-nps="cards[1][exp_year]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <!-- you can choose exp_date over exp_year+exp_month to send expiry date in one field only -->\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (YYMM) or (YYYYMM)</label>\n' +
                                '    <input type="text" size="6" maxlength="6" data-nps="cards[1][exp_date]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <button type="submit" id="payment-method-form-submit">Tokenize!</button>\n' +
                                '</form>'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.form.example.additional" open
                title={ "4.3 - " + T.translate("advancedCheckout.javascript.form.example.additional")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.form.example.fraud">
                            {T.translate("advancedCheckout.javascript.form.example.fraud")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="HTML">
                            {
                                '<form action="" method="POST" id="payment-method-form">\n' +
                                '  <span class="payment-method-errors"></span>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Holder Name</label>\n' +
                                '      <input type="text" size="20" maxlength="26" data-nps="card[holder_name]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Number</label>\n' +
                                '      <input type="text" size="19" maxlength="19" data-nps="card[number]" value=""/>\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Card Security Code</label>\n' +
                                '      <input type="text" size="4" maxlength="4" data-nps="card[security_code]" value="" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (MM/YYYY)</label>\n' +
                                '    <input type="text" size="2" maxlength="2" data-nps="card[exp_month]" value="" />\n' +
                                '    <span>/</span>\n' +
                                '    <input type="text" size="4" maxlength="4" data-nps="card[exp_year]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <!-- you can choose exp_date over exp_year+exp_month to send expiry date in one field only -->\n' +
                                '  <div class="form-row">\n' +
                                '    <label>Card Expiration Date (YYMM) or (YYYYMM)</label>\n' +
                                '    <input type="text" size="6" maxlength="6" data-nps="card[exp_date]" value="" />\n' +
                                '  </div>\n' +
                                '\n' +
                                '  <h2>OPTIONAL BILLING DATA</h2>\n' +
                                '\n' +
                                '  <h3>Billing Address</h3>\n' +
                                '\n' +
                                '  <div class="form-row">\n' +
                                '      <label>Street</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[address][street]" value="" name="billing-address-street" />\n' +
                                '  </div>\n' +
                                '  <div class="form-row">\n' +
                                '      <label>House-Number</label>\n' +
                                '      <input type="text" size="32" data-nps="billing[address][housenumber]" value="" name="billing-address-housenumber" />\n' +
                                '  </div>  \n' +
                                '  <div class="form-row">\n' +
                                '      <label>Zip-Code</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[address][zip]" value="" name="billing-address-housenumber" />\n' +
                                '  </div>    \n' +
                                '  <div class="form-row">\n' +
                                '      <label>City</label>\n' +
                                '      <input type="text" size="40" data-nps="billing[address][city]" value="" name="billing-address-city" />\n' +
                                '  </div>      \n' +
                                '  <div class="form-row">\n' +
                                '      <label>State</label>\n' +
                                '      <input type="text" size="40" data-nps="billing[address][state]" value="" name="billing-address-state" />\n' +
                                '  </div>      \n' +
                                '  <div class="form-row">\n' +
                                '      <label>Country</label>\n' +
                                '      <input type="text" size="3" data-nps="billing[address][country]" value="" name="billing-address-country" />\n' +
                                '  </div>   \n' +
                                '  <div class="form-row">\n' +
                                '      <label>Additional-Info</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[address][addinfo]" value="" name="billing-address-addinfo" />\n' +
                                '  </div>    \n' +
                                '\n' +
                                '  <h3>Billing Person</h3>\n' +
                                '\n' +
                                '  <div class="form-row">\n' +
                                '      <label>First-Name</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][firstname]" value="" name="billing-person-firstname" />\n' +
                                '  </div>      \n' +
                                '  <div class="form-row">\n' +
                                '      <label>Middle-Name</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][middlename]" value="" name="billing-person-middlename" />\n' +
                                '  </div>    \n' +
                                '  <div class="form-row">\n' +
                                '      <label>Lastname</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][lastname]" value="" name="billing-person-lastname" />\n' +
                                '  </div>          \n' +
                                '  <div class="form-row">\n' +
                                '      <label>PhoneNumber1</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][phonenumber1]" value="" name="billing-person-phonenumber1" />\n' +
                                '  </div>           \n' +
                                '  <div class="form-row">\n' +
                                '      <label>PhoneNumber2</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][phonenumber2]" value="" name="billing-person-phonenumber2" />\n' +
                                '  </div>           \n' +
                                '  <div class="form-row">\n' +
                                '      <label>gender</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][gender]" value="" name="billing-person-gender" />\n' +
                                '  </div>    \n' +
                                '  <div class="form-row">\n' +
                                '      <label>birthday</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][birthday]" value="" name="billing-person-birthday" />\n' +
                                '  </div>       \n' +
                                '  <div class="form-row">\n' +
                                '      <label>nationality</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][nationality]" value="" name="billing-person-nationality" />\n' +
                                '  </div>         \n' +
                                '  <div class="form-row">\n' +
                                '      <label>idtype</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][idtype]" value="" name="billing-person-idtype" />\n' +
                                '  </div>           \n' +
                                '  <div class="form-row">\n' +
                                '      <label>idnumber</label>\n' +
                                '      <input type="text" size="128" data-nps="billing[person][idnumber]" value="" name="billing-person-idnumber" />\n' +
                                '  </div>    \n' +
                                '\n' +
                                '  <button type="submit" id="payment-method-form-submit">Tokenize!</button>\n' +
                                '</form>'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <Accordion id="advancedCheckout.javascript.form.example.list" open
                title={ "4.4 - " + T.translate("advancedCheckout.javascript.form.example.list")}>
                <div className="row">
                    <div className="col-12">
                        <p id="advancedCheckout.javascript.form.example.listText">
                            {T.translate("advancedCheckout.javascript.form.example.listText")}
                        </p>
                    </div>
                    <div className="col-12">
                        <CodeHighlight language="HTML">
                            {
                                '<div class="form-row">\n' +
                                '  <label>Card</label>\n' +
                                '  <select name="payment-method-select" id="payment-method-select" data-nps="card[payment_method_id]">\n' +
                                '    <option value="ILD1I2erfWb3xJNmcqqHGobdiHQUJnTz">************0010</option>\n' +
                                '    <option value="">Another Card</option>\n' +
                                '  </select>\n' +
                                '</div>'
                            }
                        </CodeHighlight>
                    </div>
                </div>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Form;