import React from 'react';

import T from 'i18react';
import { Link,Redirect } from 'react-router-dom';

import {apiReferenceURL, guideReferenceURL} from "../env";

class Search extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            typed: "",
            translationTexts: T.getTexts(),
            maxResults: 5,
            matches: {
                text: [],
                link: [],
                key: []
            },
            usedSections: [],
            fireRedirect: false,
            inputFocus: false
        };
        this.loopObject = this.loopObject.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.updateText = this.updateText.bind(this);
        this.checkWord = this.checkWord.bind(this);
        this.createLink = this.createLink.bind(this);
        this.highlight = this.highlight.bind(this);
        this.createCategory = this.createCategory.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchend', this.handleClickOutside);


        let apiItems = {};
        let folder = require.context('../documentation/documentation', false, /\.(json)$/)
        folder.keys().map((item, index) => {
            let tmpItems = folder(item);
            let name = item.replace('./', '').replace('.json', '');
            // if(name.indexOf('-response') !== -1){
                name = name.split('-');
                Object.keys(tmpItems).forEach(function(element){
                        if(!apiItems[name[0]]){
                            apiItems[name[0]] = {};
                        }
                        if(!apiItems[name[0]][name[1]]){
                            apiItems[name[0]][name[1]] = {};
                        }
                        if(!apiItems[name[0]][name[1]][element]){
                            apiItems[name[0]][name[1]][element] = {};
                        }
                        apiItems[name[0]][name[1]][element]['description'] = tmpItems[element].description;
                });
            // }
            return false;
        });

        let enumsFolder = require.context('../documentation/enums', false, /\.(json)$/)
        enumsFolder.keys().map((item, index) => {
            let tmpItems = enumsFolder(item);
            let name = item.replace('./_', '').replace('.json', '');

            if(!apiItems['Reference Tables']){ apiItems['Reference Tables'] = {}; }
            if(!apiItems['Reference Tables'][name]){ apiItems['Reference Tables'][name] = {}; }

            apiItems['Reference Tables'][name] = tmpItems;
            return false;
        });

        let tmpTranslation = this.state.translationTexts;

        let test = Object.assign(tmpTranslation, {api:apiItems});
        this.setState({
            translationTexts: test
        });
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchend', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                maxResults: 5,
                matches: {
                    text: [],
                    link: [],
                    key: []
                },
                usedSections: []
            });
        }
    }

    handleSubmit(event){
        if(event.key === 'Enter'){
            event.stopPropagation();
            event.preventDefault();
            this.setState({
                fireRedirect: true
            });
        }
    }

    updateText(event){
        let self = this;
        this.setState({
            typed: event.target.value,
            inputFocus: true,
            matches: {
                text: [],
                link: [],
                key: []
            },
            usedSections: []
        });

        if(event.target.value.length > 1){
            setTimeout(function(){
                self.loopObject(self.state.translationTexts);
            },5);
        }
    }

    loopObject(obj,key){
        let self = this;
        let nestedKey = key ? key : '';

        Object.keys(obj).forEach(function(key) {
            if(typeof obj[key] === 'object'){
                self.loopObject(obj[key], nestedKey + "." + key);
            }
            else{
                self.doSearch(obj[key],nestedKey + "." + key);
            }
        });
    }

    doSearch(source,key){
        let useSource = false;
        if(source){
            if(source.toLowerCase().indexOf(this.state.typed.toLowerCase()) !== -1 || key.toLowerCase().indexOf(this.state.typed.toLowerCase()) !== -1){
                let position = 0;
                if(source.toLowerCase().indexOf(this.state.typed.toLowerCase()) !== -1){
                    position = source.toLowerCase().indexOf(this.state.typed.toLowerCase());
                    useSource = true;
                }else{
                    position = key.toLowerCase().indexOf(this.state.typed.toLowerCase());
                }
                let string = '';

                if(position <= 10){
                    if(useSource){
                        let final = this.checkWord((position + this.state.typed.length), source, 'end', 30);
                        string = source.substring(0, final);
                    }else{
                        let final = this.checkWord((position + this.state.typed.length), key, 'end', 30);
                        string = key.substring(0, final);
                    }

                }else{
                    if(useSource) {
                        let start = this.checkWord(position, source, 'start', ' ', 20);
                        let final = this.checkWord((position + this.state.typed.length), source, 'end', 30);
                        string = source.substring(start, final);
                    }else{
                        let start = this.checkWord(position, key, 'start', ' ', 20);
                        let final = this.checkWord((position + this.state.typed.length), key, 'end', 30);
                        string = key.substring(start, final);
                    }

                    if(string.indexOf('.') === 0){
                        let tmp = string.split('.');
                        string = tmp[2] + " - " + tmp[3] + " - " + tmp[4];
                    }
                }

                string = this.highlight(this.state.typed,string);

                let tmpKey = key.split('.');
                if(tmpKey[1] !== 'landing'){
                    if(this.state.usedSections.indexOf(tmpKey[1]) === -1){
                        let tmp = this.state.matches;
                        let tmpSections = this.state.usedSections;
                        tmpSections.push(tmpKey[1]);
                        tmp.text.push(string);
                        tmp.key.push(key);
                        tmp.link.push(this.createLink(key));

                        this.setState({
                            matches: tmp,
                            usedSections: tmpSections
                        });
                    }
                }
            }
        }
    }

    checkWord(position,str,mode,quantity){
        let realEnd = mode === 'start' ? position-quantity : position+quantity;

        for(let i = 0; i< 50; i++){
            if(mode === 'start'){
                if(str.substr(realEnd, 1) === ' '){
                    i += 50;
                }else{
                    realEnd -= 1;
                }
            }else{
                if(str.substr((realEnd-1), 1) === ' '){
                    i += 50;
                }else{
                    realEnd += 1;
                }
            }
        }
        return realEnd;
    }

    createLink(key){
        let linkParts = key.split(".");
        let link = '';

        let route = linkParts[1];
        let tab = linkParts[2];
        let section = linkParts[3];
        if(route !== 'api'){

            if(tab === 'services'){
                if(section === 'text'){
                    link = '/' + tab;
                }else{
                    link = '/' + tab + '/' + section;
                }
            }else{

                switch (route){
                    case 'guideReference':      link = '/reference';
                        break;
                    case 'advancedCheckout':    link = '/reference/advanced';
                        break;
                    case 'libraries':           link = '/libraries';
                        break;
                    case 'simpleCheckout':      link = '/reference/simple';
                        break;
                    case 'directPayment':       link = '/reference/directPayment';
                        break;
                    case 'commonInterface':     link = '/reference/commonInterface';
                        break;
                    case 'testing':     link = '/testing';
                        break;
                    default:
                        break;
                }
                switch (tab){
                    case 'server':      link += '/server';
                        break;
                    case 'client':      link += '/client';
                        break;
                    case 'plugins':     link += '/plugins';
                        break;
                    case 'javascript':  link += '/javascript';
                        break;
                    case 'android':     link += '/android';
                        break;
                    case 'objectiveC':  link += '/objectiveC';
                        break;
                    case 'swift':       link += '/swift';
                        break;
                    case 'split':       link += '/split';
                        break;
                    case 'voucher':     link += '/voucher';
                        break;
                    case 'bank':        link += '/bank';
                        break;
                    default:
                        break;
                }
                switch (section){
                    case 'php':         link += '/php';
                        break;
                    case 'python':      link += '/python';
                        break;
                    case 'lua':         link += '/lua';
                        break;
                    case 'go':          link += '/go';
                        break;
                    case 'ruby':        link += '/ruby';
                        break;
                    case 'nodejs':      link += '/nodejs';
                        break;
                    case 'java':        link += '/java';
                        break;
                    case 'net':         link += '/net';
                        break;
                    case 'javascript':  link += '/javascript';
                        break;
                    case 'android':     link += '/android';
                        break;
                    case 'objectiveC':  link += '/objectiveC';
                        break;
                    case 'swift':       link += '/swift';
                        break;
                    case 'magento':     link += '/magento';
                        break;
                    case 'prestashop':  link += '/prestashop';
                        break;
                    default:
                        break;
                }
            }

            return guideReferenceURL + link;
        }else{
            switch (tab){
                case 'Authorize_2p': link = '/payments/CardPayment/Authorization';
                    break;
                case 'PayOnLine_2p': link = '/payments/CardPayment/Sale';
                    break;
                case 'Authorize_3p': link = '/simpleCheckout/CardPayment/Authorization';
                    break;
                case 'PayOnLine_3p': link = '/simpleCheckout/CardPayment/Sale';
                    break;
                case 'BankPayment_2p': link = '/simpleCheckout/BankPayment';
                    break;
                case 'BankPayment_3p': link = '/simpleCheckout/BankPayment';
                    break;
                case 'CashPayment_3p': link = '/simpleCheckout/CashPayment';
                    break;
                case 'Capture': link = '/payments/Capture';
                    break;
                case 'CreateClientSession': link = '/services/ClientSession';
                    break;
                case 'CreateCustomer': link = '/customer/Create';
                    break;
                case 'CreatePaymentMethod': link = '/paymentMethod/Create';
                    break;
                case 'CreatePaymentMethodFromPayment': link = '/paymentMethod/CreateFromPayment';
                    break;
                case 'CreatePaymentMethodToken': link = '/paymentMethodToken/Create';
                    break;
                case 'DeleteCustomer': link = '/customer/Delete';
                    break;
                case 'DeletePaymentMethod': link = '/paymentMethod/Delete';
                    break;
                case 'FraudScreening': link = '/fraud/Screening';
                    break;
                case 'GetINNDetails': link = '/services/INNLookup';
                    break;
                case 'GetInstallmentsOptions': link = '/payments/RetrieveInstallments';
                    break;
                case 'NotifyFraudScreeningReview': link = '/fraud/Manual';
                    break;
                case 'QueryCardNumber': link = '/services/CardNumber';
                    break;
                case 'QueryTxs': link = '/payments/RetrieveTransactions/QueryTxs/';
                    break;
                case 'RecachePaymentMethodToken': link = '/paymentMethodToken/Recache';
                    break;
                case 'Refund': link = '/payments/Refund';
                    break;
                case 'RetrieveCustomer': link = '/customer/Retrieve';
                    break;
                case 'RetrievePaymentMethod': link = '/paymentMethod/Retrieve';
                    break;
                case 'RetrievePaymentMethodToken': link = '/paymentMethodToken/Retrieve';
                    break;
                case 'SimpleQueryTx': link = '/payments/RetrieveTransactions/SimpleQueryTx';
                    break;
                case 'SplitAuthorize_3p': link = '/simpleCheckout/CardSplitPayment/Authorization';
                    break;
                case 'SplitPayOnLine_3p': link = '/simpleCheckout/CardSplitPayment/Sale';
                    break;
                case 'UpdateCustomer': link = '/paymentMethod/Retrieve';
                    break;
                case 'UpdatePaymentMethod': link = '/paymentMethod/Retrieve';
                    break;
                case 'Reference Tables': link = '/reference';
                    break;
                default: link = '/';
                    break;
            }
            return apiReferenceURL + link;
        }
    }

    createCategory(key){
        let linkParts = key.split(".");
        let category = '';

        let route = linkParts[1];

        switch (route){
            case 'gettingStarted':      category = 'Getting Started';
                break;
            case 'guideReference':
                    if(linkParts[2] === 'services'){
                        category = 'Services';
                    }else{
                        category = 'Guide Reference';
                    }
                break;
            case 'advancedCheckout':    category = 'Advanced Checkout';
                break;
            case 'directPayment':       category = 'Direct Payment';
                break;
            case 'commonInterface':     category = 'Common Interface';
                break;
            case 'libraries':           category = 'Libraries';
                break;
            case 'api':                 category = 'API Reference - '+ linkParts[2];
                break;
            case 'testing':             category = 'Testing';
                break;
            case 'referenceTables':     category = 'referenceTables';
                break;
            default:
                break;
        }

        if(category !== 'Testing'){
            if(category.length > 0){
                if(route === 'api'){
                    category += ' - ' + linkParts[3].charAt(0).toUpperCase() + linkParts[3].slice(1);
                }else{
                    category += ' - ' + linkParts[2].charAt(0).toUpperCase() + linkParts[2].slice(1);
                }
            }else{
                category += linkParts[2].charAt(0).toUpperCase() + linkParts[2].slice(1);
            }
        }

        return category;
    }

    highlight(text,string)
    {
        let newString = '';
        let index = string.toLowerCase().indexOf(text.toLowerCase());

        if ( index >= 0 )
        {
            newString = string.substring(0,index) + "<span class='highlight'>" + string.substring(index,index+text.length) + "</span>" + string.substring(index + text.length);
        }else{
            newString = string;
        }

        return newString;
    }

    htmlMarkup(text){
        return {__html: text}
    }

    render() {
        return (
            <div>
                <form className="form-inline my-2 my-lg-0 search-navbar">
                    <div className="input-group">
                        <span className={this.state.inputFocus ? 'input-group-addon input-group-addon-focus' : 'input-group-addon' }>
                            <i className="fa fa-search" aria-hidden="true"/>
                        </span>
                        <input className="form-control mr-sm-2"
                               onChange={this.updateText}
                               onKeyPress={this.handleSubmit}
                               onFocus={this.updateText}
                               onBlur={() => this.setState({inputFocus: false})}
                               type="search"
                               placeholder="Search"
                               aria-label="Search"/>
                    </div>
                    {
                        this.state.matches.text.length > 0 &&
                        <div className="search-container">
                            <ul className="search-results" ref={(node) => this.wrapperRef = node}>
                                {this.state.matches.text.slice(0,this.state.maxResults).map( (text,index) =>
                                    <li key={index}
                                        className="search-link">
                                        <Link to={this.state.matches.link[index]} onClick={() => {
                                            sessionStorage.setItem('scrollTo', this.state.matches.key[index]);
                                            sessionStorage.setItem('apiData', this.state.matches.key[index]);
                                            }
                                        }>
                                            <p dangerouslySetInnerHTML={this.htmlMarkup(text)}/>
                                            <span className="badge badge-info">
                                                {this.createCategory(this.state.matches.key[index])}
                                            </span>
                                        </Link>
                                    </li>)
                                }
                                <li className="text-center search-view-more">
                                    <Link to={`/search/${this.state.typed}`}>
                                        View More
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    }
                </form>
                {this.state.fireRedirect && (
                    <Redirect
                        exact
                        from="/"
                        to={`/search/${this.state.typed}`}
                        key={this.state.typed}
                        />
                )}
            </div>
        );
    }
}

export default Search;