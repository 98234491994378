import React from 'react';
import { Link } from 'react-router-dom';
import T from "i18react/dist/i18react";
import {guideReferenceURL} from "../../../../env";
import masterPassIcon from '../../../../assets/img/wallets/masterpass.png'

class Masterpass extends React.Component {

    render() {
        return (
            <div className="container-fluid wallets">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.wallets.masterpass.title" className="wallets-title">
                            {T.translate("guideReference.services.wallets.masterpass.title")}
                        </h3>
                        <img src={masterPassIcon} alt="VisaCheckout" className="watermark-image"/>
                        <div id="guideReference.services.wallets.masterpass.tex">
                            {T.translate("guideReference.services.wallets.masterpass.text")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4>
                            {T.translate("guideReference.services.wallets.masterpass.integration.title")}:
                        </h4>
                        <br/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="card">
                            <div className="card-header">
                                {T.translate("guideReference.services.wallets.masterpass.integration.pci.title")}
                            </div>
                            <div className="card-body">
                                <ul>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.pci.list.1")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.pci.list.2")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.pci.list.3")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.pci.list.4")}</li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <Link to={`${guideReferenceURL}/services/wallets/masterpass/Pci`}>
                                    <button type="button" className="btn btn-light btn-block cursor-pointer">Get Started</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="card">
                            <div className="card-header">
                                {T.translate("guideReference.services.wallets.masterpass.integration.nonPci.title")}
                            </div>
                            <div className="card-body">
                                <ul>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.nonPci.list.1")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.nonPci.list.2")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.nonPci.list.3")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.nonPci.list.4")}</li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <Link to={`${guideReferenceURL}/services/wallets/masterpass/nonPci`}>
                                    <button type="button" className="btn btn-light btn-block cursor-pointer">Get Started</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="card">
                            <div className="card-header">
                                {T.translate("guideReference.services.wallets.masterpass.integration.full.title")}
                            </div>
                            <div className="card-body">
                                <ul>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.full.list.1")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.full.list.2")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.full.list.3")}</li>
                                    <li>{T.translate("guideReference.services.wallets.masterpass.integration.full.list.4")}</li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <Link to={`${guideReferenceURL}/services/wallets/masterpass/Full`}>
                                    <button type="button" className="btn btn-light btn-block cursor-pointer">Get Started</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Masterpass;