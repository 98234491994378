import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';

const Installments = () => {
    return (
        <div className="col-12">
            <h4>
                (Optional) Step 5 - {T.translate("advancedCheckout.dart.installments.title")}
            </h4>
            <p>
                {T.translate("advancedCheckout.dart.installments.text")}
            </p>
            <CodeHighlight language="dart">
                {
                'import \'dart:async\';\n' +
                '\n' +
                'import \'package:nps_sdk/nps_sdk.dart\';\n' +
                '\n' +
                'main(List<String> arguments) async {\n' +
                '  Nps nps = new Nps(sandbox);\n' +
                '\n' +
                '  Map getInstallmentsOptionsParams = {\n' +
                '    "psp_Version": "2.2",\n' +
                '    "psp_MerchantId": "sdk_test",\n' +
                '    "psp_Amount": "100",\n' +
                '    "psp_Product": "14",\n' +
                '    "psp_Currency": "152",\n' +
                '    "psp_Country": "CHL",\n' +
                '    "psp_NumPayments": "1",\n' +
                '    "psp_PaymentMethodToken": "YOUR_PAYMENT_METHOD_TOKEN",\n' +
                '    "psp_ClientSession": "YOUR_CLIENT_SESSION_HERE",\n' +
                '    "psp_PosDateTime": "2017-04-04 13:35:20"\n' +
                '  };\n' +
                '\n'+
                '  Future response = nps.getInstallmentsOptions(nps, getInstallmentsOptionsParams);\n' +
                '  response.then((resp) => print(resp["psp_ResponseCod"]))\n' +
                '          .catchError((error) => print);\n' +
                '}'
                }
            </CodeHighlight>
        </div>
    );
};

export default Installments;