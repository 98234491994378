import React from 'react';
import { Link } from 'react-router-dom';
import DocBuilder from '../../../components/DocBuilder';

import guideReferenceIcon from '../../../assets/img/guide_reference.svg';
import request from '../../../documentation/documentation/NotifyFraudScreeningReview-request';
import response from '../../../documentation/documentation/NotifyFraudScreeningReview-response';
import Breadcrumbs from "../../../components/Breadcrumbs";
import {guideReferenceURL} from "../../../env";

class Manual extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid example-selector-container">
                <div className="row">
                    <div className="col-12">
                        {
                            this.state.showDocumentation &&
                            <div>
                                <Breadcrumbs/>
                                <div className="alert alert-info">
                                    <div className="row">
                                        <div className="col-12">
                                            <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                            <span>
                                                For more information please go to&nbsp;
                                                <Link to={`${guideReferenceURL}/services/risk`}>
                                                  Guide Reference: Risk Assessment
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    In case you have Fraud screening service with transaction review you must implement this
                                    method to notify the transactions acceptance or not. By using this methods the machine
                                    learning of the service will improve.
                                </p>
                            </div>
                        }
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='NotifyFraudScreeningReview' showDocumentation={this.state.showDocumentation} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Manual;