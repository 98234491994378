import React from 'react';
import T from 'i18react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/GetInstallmentsOptions-request';
import response from '../../../documentation/documentation/GetInstallmentsOptions-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class RetrieveInstallments extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <div className="row">
                            <div className="col-12">
                                {T.translate("apiReference.retrieveInstallments.text")}
                            </div>
                        </div>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='GetInstallmentsOptions' />
                    </div>
                </div>
            </div>
        );
    }
}

export default RetrieveInstallments;