import React from 'react';
import T from 'i18react';
import ReactSVG from 'react-svg';

import captureDiagram from '../../assets/img/captureDiagram.svg'
import refundDiagram from '../../assets/img/refundDiagram.svg'
import queryDiagram from '../../assets/img/QueryTxDiagram.svg'
import secretDiagram from '../../assets/img/ChangeSecretKeyDiagram.svg'

import {apiReferenceURL, logo} from "../../env";
import apiIcon from '../../assets/img/api.svg';

class CommonInterface extends React.Component {

    render() {
        return (
            <div className="container-fluid common-interface">
                <div className="row">
                    <div className="col-12">
                        <h3 id="commonInterface.title">
                            {T.translate("commonInterface.title")}
                        </h3>
                        <p id="commonInterface.text">
                            {T.translate("commonInterface.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <h3 id="commonInterface.diagram">
                            {T.translate("commonInterface.diagram")}
                        </h3>

                        <ul>
                            <li id="commonInterface.list.1">
                                {T.translate("commonInterface.list.1")}</li>
                            <li id="commonInterface.list.2">
                                {T.translate("commonInterface.list.2")}</li>
                            <li id="commonInterface.list.3">
                                {T.translate("commonInterface.list.3")}</li>
                            <li id="commonInterface.list.4">
                                {T.translate("commonInterface.list.4")}</li>
                            <li id="commonInterface.list.5">
                                {T.translate("commonInterface.list.5")}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 id="commonInterface.credit.title">
                            {T.translate("commonInterface.credit.title")}
                        </h3>
                        <div className="alert alert-info"
                             id="commonInterface.credit.information">
                            {T.translate("commonInterface.credit.information")}
                        </div>
                    </div>
                    <div className="col-12">
                        <h4 id="commonInterface.credit.capture.title">
                            {T.translate("commonInterface.credit.capture.title")}
                        </h4>
                        <p id="commonInterface.credit.capture.text">
                            {T.translate("commonInterface.credit.capture.text")}
                        </p>
                        <br/>
                        <div className="common-interface-capture">
                            <ReactSVG src={captureDiagram} style={{width: '100%'}} className="diagram-image" alt="Capture Diagram"/>
                            <img src={logo} className="logo-whitelabel" alt="Capture Diagram"/>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("commonInterface.credit.capture.information",
                                            {
                                                "link": <T.a href={`${apiReferenceURL}/payments/Capture` } text="Capture Method"/>
                                            }
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <br/>
                        <hr/>
                        <br/>
                        <h4 id="commonInterface.credit.refund.title">
                            {T.translate("commonInterface.credit.refund.title")}
                        </h4>
                        <p id="commonInterface.credit.refund.text">
                            {T.translate("commonInterface.credit.refund.text")}
                        </p>
                        <ul>
                            <li id="commonInterface.credit.refund.list.1">
                                {T.translate("commonInterface.credit.refund.list.1")}</li>
                            <li id="commonInterface.credit.refund.list.2">
                                {T.translate("commonInterface.credit.refund.list.2")}</li>
                            <li id="commonInterface.credit.refund.list.3">
                                {T.translate("commonInterface.credit.refund.list.3")}</li>
                            <li id="commonInterface.credit.refund.list.4">
                                {T.translate("commonInterface.credit.refund.list.4")}</li>
                            <li id="commonInterface.credit.refund.list.5">
                                {T.translate("commonInterface.credit.refund.list.5")}</li>
                        </ul><br/>
                        <div className="common-interface-capture">
                            <ReactSVG src={refundDiagram} style={{width: '100%'}} className="diagram-image" alt="Refund Diagram"/>
                            <img src={logo} className="logo-whitelabel" alt="Capture Diagram"/>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("commonInterface.credit.capture.information",
                                            {
                                                "link": <T.a href={`${apiReferenceURL}/payments/Refund` } text="Refund Method"/>
                                            }
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <br/>
                        <hr/>
                        <br/>
                        <h4 id="commonInterface.credit.query.title">
                            {T.translate("commonInterface.credit.query.title")}
                        </h4>
                        <p id="commonInterface.credit.query.text">
                            {T.translate("commonInterface.credit.query.text")}
                        </p>
                        <div className="row">
                            <div className="col-12"
                                 id="commonInterface.credit.query.single.title">
                                <br/><br/>
                                <div className="common-interface-query">
                                    <ReactSVG src={queryDiagram} style={{width: '100%'}} className="diagram-image" alt="QueryTx Diagram"/>
                                    <img src={logo} className="logo-whitelabel" alt="Capture Diagram"/>
                                </div>
                                <h5>
                                    {T.translate("commonInterface.credit.query.single.title")}
                                </h5>
                                <div className="alert alert-info">
                                    <div className="row">
                                        <div className="col-4 col-md-2 flex-v-center">
                                            <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                        </div>
                                        <div className="col-8 col-md-10 flex-v-center">
                                            <p>
                                                {T.translate("commonInterface.credit.capture.information",
                                                    {
                                                        "link": <T.a href={`${apiReferenceURL}/payments/RetrieveTransactions/SimpleQueryTx` } text="SimpleQueryTx Method"/>
                                                    }
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <br/><br/>
                                <h5 id="commonInterface.credit.query.multiple.title">
                                    {T.translate("commonInterface.credit.query.multiple.title")}
                                </h5>

                                <div id="commonInterface.credit.query.multiple.text">
                                    {T.translate("commonInterface.credit.query.multiple.text")}
                                </div>
                                <div className="alert alert-info">
                                    <div className="row">
                                        <div className="col-4 col-md-2 flex-v-center">
                                            <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                        </div>
                                        <div className="col-8 col-md-10 flex-v-center">
                                            <p>
                                                {T.translate("commonInterface.credit.capture.information",
                                                    {
                                                        "link": <T.a href={`${apiReferenceURL}/payments/RetrieveTransactions/QueryTxs` } text="QueryTxs Method"/>
                                                    }
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <br/>
                        <hr/>
                        <br/>
                        <h3 id="commonInterface.credit.secretKey.title">
                            {T.translate("commonInterface.credit.secretKey.title")}
                        </h3>
                        <p id="commonInterface.credit.secretKey.text">
                            {T.translate("commonInterface.credit.secretKey.text")}
                        </p>
                        <br/>
                        <div className="common-interface-query">
                            <ReactSVG src={secretDiagram} style={{width: '100%'}} className="diagram-image" alt="ChangeSecretKey Diagram"/>
                            <img src={logo} className="logo-whitelabel" alt="Capture Diagram"/>
                        </div>
                        <br/>
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("commonInterface.credit.capture.information",
                                            {
                                                "link": <T.a href={`${apiReferenceURL}/services/ChangeSecretKey` } text="ChangeSecretKey Method"/>
                                            }
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>

        );
    }
}

export default CommonInterface;