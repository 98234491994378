import React from 'react';
import T from 'i18react';
import {Tabs, Tab} from 'react-bootstrap-tabs';

import Python from './Server/Python';
import PHP from './Server/PHP';
import Ruby from './Server/Ruby';
import NodeJs from './Server/NodeJs';
import Java from './Server/Java';
import NET from './Server/NET';
import Go from './Server/Go';
import Lua from './Server/Lua';
import Perl from './Server/Perl';
import C from './Server/C';

/*ICONS*/
import pythonIcon from '../../../assets/img/dev/python.svg';
import phpIcon from '../../../assets/img/dev/php.svg';
import rubyIcon from '../../../assets/img/dev/ruby.svg';
import nodeIcon from '../../../assets/img/dev/nodejs.svg';
import javaIcon from '../../../assets/img/dev/java.svg';
import netIcon from '../../../assets/img/dev/dot-net.svg';
import goIcon from '../../../assets/img/dev/go.svg';
import luaIcon from '../../../assets/img/dev/lua.svg';
import perlIcon from '../../../assets/img/dev/perl.svg';
import cIcon from '../../../assets/img/dev/c.svg';

class Server extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            tabContainer: ['row', 'tabs-container'],
            iconsContainer: ['row', 'icons-container'],
            showHelp: true,
            indexTab: 0
        };
        this.showElements = this.showElements.bind(this);
    }

    componentWillMount(){
        if(this.props.match.params.tab){
            if(this.props.match.params.tab === 'python'){
                this.showElements(0);
            }
            if(this.props.match.params.tab === 'php'){
                this.showElements(1);
            }
            if(this.props.match.params.tab === 'ruby'){
                this.showElements(2);
            }
            if(this.props.match.params.tab === 'nodejs'){
                this.showElements(3);
            }
            if(this.props.match.params.tab === 'java'){
                this.showElements(4);
            }
            if(this.props.match.params.tab === 'net'){
                this.showElements(5);
            }
            if(this.props.match.params.tab === 'go'){
                this.showElements(6);
            }
            if(this.props.match.params.tab === 'lua'){
                this.showElements(9);
            }
            if (this.props.match.params.tab === 'perl') {
                this.showElements(7);
            }
            if (this.props.match.params.tab === 'c') {
                this.showElements(8);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.match.params.tab !== nextProps.match.params.tab){
            if(nextProps.match.params.tab === 'python'){
                this.showElements(0);
            }
            if(nextProps.match.params.tab === 'php'){
                this.showElements(1);
            }
            if(nextProps.match.params.tab === 'ruby'){
                this.showElements(2);
            }
            if(nextProps.match.params.tab === 'nodejs'){
                this.showElements(3);
            }
            if(nextProps.match.params.tab === 'java'){
                this.showElements(4);
            }
            if(nextProps.match.params.tab === 'net'){
                this.showElements(5);
            }
            if(nextProps.match.params.tab === 'go'){
                this.showElements(6);
            }
            if(nextProps.match.params.tab === 'lua'){
                this.showElements(9);
            }
            if (nextProps.match.params.tab === 'perl') {
                this.showElements(7);
            }
            if (nextProps.match.params.tab === 'c') {
                this.showElements(8);
            }
        }
    }

    componentDidMount(){
        this.props.scrollToElement();
    }

    componentDidUpdate(){
        this.props.scrollToElement();
    }


    showElements(indexTab){
        let tabsClass = this.state.tabContainer;
        tabsClass.push('show');

        let iconsClass = this.state.iconsContainer;
        iconsClass.push('hide');

        this.setState({
            tabContainer: tabsClass,
            iconsContainer: iconsClass,
            showHelp: false,
            indexTab: indexTab
        });
    }

    render() {
        return (
            <div className="container-fluid libraries">
                <div className="row">
                    <div className="col-12 text-center">
                        <p id="libraries.server.text">
                            {T.translate("libraries.server.text")}
                        </p>
                        {
                            this.state.showHelp &&
                            <p>
                                {T.translate("libraries.server.click")}
                            </p>
                        }
                    </div>
                </div>
                <div className={this.state.iconsContainer.join(' ')}>
                    <div className="col-12 icons">
                        <img src={pythonIcon}
                             alt="Python"
                             onClick={() => this.showElements(0)}/>
                        <img src={phpIcon}
                             alt="Php"
                             onClick={() => this.showElements(1)}/>
                        <img src={rubyIcon}
                             alt="Ruby"
                             onClick={() => this.showElements(2)}/>
                        <img src={nodeIcon}
                             alt="Node"
                             onClick={() => this.showElements(3)}/>
                        <img src={javaIcon}
                             alt="Java"
                             onClick={() => this.showElements(4)}/>
                        <img src={netIcon}
                             alt="Net"
                             onClick={() => this.showElements(5)}/>
                        <img src={goIcon}
                             alt="Go"
                             onClick={() => this.showElements(6)}/>
                        <img src={perlIcon}
                            alt="Perl"
                            onClick={() => this.showElements(7)} />
                        <img src={cIcon}
                             alt="C"
                             onClick={() => this.showElements(8)}/>
                        <img src={luaIcon}
                             alt="Lua"
                             onClick={() => this.showElements(9)}/>
                    </div>
                </div>
                <div className={this.state.tabContainer.join(' ')}>
                    <div className="col-12">
                        <Tabs selected={this.state.indexTab}>
                            <Tab label={T.translate("libraries.server.python.tab")}>
                                <Python/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.php.tab")}>
                                <PHP/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.ruby.tab")}>
                                <Ruby/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.nodejs.tab")}>
                                <NodeJs/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.java.tab")}>
                                <Java/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.net.tab")}>
                                <NET/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.go.tab")}>
                                <Go/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.perl.tab")}>
                                <Perl />
                            </Tab>
                            <Tab label={T.translate("libraries.server.c.tab")}>
                                <C/>
                            </Tab>
                            <Tab label={T.translate("libraries.server.lua.tab")}>
                                <Lua/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default Server;