import React from 'react';
import T from 'i18react';
import { Link } from 'react-router-dom';
import masterPassIcon from '../../../assets/img/wallets/masterpass.png'
import visaCheckoutIcon from '../../../assets/img/wallets/visacheckout.png'
// import lockIcon from '../../../assets/img/lock-content.svg';
import {guideReferenceURL} from "../../../env";

class Wallets extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.openLogin = this.openLogin.bind(this);
    }

    openLogin(){
        document.getElementById('loginClickOpen').click();
    }

    render() {
        return (
            <div className="container-fluid wallets">
                <div className="row">
                    <div className="col-12">
                        <h3>
                            {T.translate("guideReference.services.wallets.title")}
                        </h3>
                        <p>
                            {T.translate("guideReference.services.wallets.text")}
                        </p>
                        <p>
                            {T.translate("guideReference.services.wallets.our")}
                        </p>
                        <Link to={`${guideReferenceURL}/services/wallets/masterpass`}>
                            <img src={masterPassIcon} alt="MasterPass" className="masterpass-icon"/>
                        </Link>
                        <span className="wallets-separator">and</span>
                        <Link to={`${guideReferenceURL}/services/wallets/visacheckout`}>
                            <img src={visaCheckoutIcon} alt="Visa Checkout" className="visacheckout-icon"/>
                        </Link>
                        <br/><br/><br/>
                        <p>
                            {T.translate("guideReference.services.wallets.use")}
                        </p>
                        <div className="alert alert-info">
                            <p>
                                {T.translate("guideReference.services.wallets.information.text")} <br/>
                                &emsp; {T.translate("guideReference.services.wallets.information.masterpass")}
                                    <a href="https://developer.mastercard.com/documentation/masterpass-merchant-onboarding" target="_blank" rel="noopener noreferrer">MasterPass Documentation</a> <br/>
                                &emsp; {T.translate("guideReference.services.wallets.information.visacheckout")}
                                    <a href="https://developer.visa.com/capabilities/visa_checkout/docs" target="_blank" rel="noopener noreferrer">VisaCheckout Documentation</a>
                            </p>
                        </div>
                    </div>
                </div>
                {/*<div className="row">*/}
                    {/*<div className="col-12">*/}
                        {/*<h4>*/}
                            {/*{T.translate("guideReference.services.wallets.availability.title")}*/}
                        {/*</h4>*/}
                        {/*<div className="alert alert-danger">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-4 col-md-2">*/}
                                    {/*<img src={lockIcon} alt="Locked" style={{height: '100px'}}/>*/}
                                {/*</div>*/}
                                {/*<div className="col-8 col-md-10">*/}
                                    {/*<h3>*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.title") }*/}
                                    {/*</h3>*/}
                                    {/*<p onClick={() => this.openLogin()} className="cursor-pointer">*/}
                                        {/*{ T.translate("guideReference.services.3dSecure.locked.text") }*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default Wallets;