import React from 'react';

class MerchantPackage extends React.Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h3>Customize your form</h3>
                    </div>

                    <div className="col-12 col-md-6 download text-center text-md-right" style={{marginTop: 0}}>
                        <a href="https://sandbox.nps.com.ar/resource/frm_standard.tar.gz" className="get-started-in" rel="noopener noreferrer">
                            <i className="fa fa-github" />
                            Download
                        </a>
                    </div>

                    <div className="col-12">
                        <ul className="tree">
                            <li>
                                Package
                                <ul>
                                    <li>
                                        css
                                    </li>
                                    <li>
                                        fonts
                                    </li>
                                    <li>
                                        i18n
                                    </li>
                                    <li>
                                        Img
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 merchant-package table-overflow-x">

                        <br/> <br/>
                        <table className="table table-documentation table-responsive">
                            <tbody>
                            <tr>
                                <td>css</td>
                                <td>This folder contains CSS files.The filename style.css would be loaded in the form as styles</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 40}}>style.css</td>
                                <td>Normal template. The file with this name would be loaded in the form as styles</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 40}}>style.iframe.css</td>
                                <td>Iframe template. This css file is optiize for iframe use. In order to use this file, you must rename it to style.css</td>
                            </tr>
                            <tr>
                                <td>fonts</td>
                                <td>
                                    This folder contains Fonst used in the form. Should use open source fonts
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 40, width: '50%'}}>Arimo.woff, DroidSans.woff, DroidSerif.woff, Inconsolata.woff, Monserrat.woff
                                OpenSans.woff, Oswald.woff, Raleway.woff, Roboto.woff, Ubuntu.woff</td>
                                <td>Default Fonts that you can use in   CSS files. In case you need to use another one please, send it to Merchant Services</td>
                            </tr>
                            <tr>
                                <td>i18n</td>
                                <td>This folder contains default translations, you can use it for translate or change text in the form. By sending psp_FrmLanguage field, you can load the content of the file with the same name.</td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 40}}>de_DE.csv, en_AU.csv, en_CA.csv, en_IE.csv, en_NZ.csv, en_US.csv, es_AR.csv,
                                    es_CO.csv, es_ES.csv, es_MX.csv, fr_BE.csv, fr_CA.csv, fr_FR.csv, it_IT.csv,
                                    pt_BR.csv, pt_PT.csv, zh_CN.csv, zh_HK.csv, zh_SG.csv, zh_TW.csv</td>
                                <td>Languages form templates</td>
                            </tr>
                            <tr>
                                <td>img</td>
                                <td>The filename logo.png would be loaded as default logo in the form. Inside this folder you can copy any picture used in the CSS file.</td>
                            </tr>
                            </tbody>
                        </table>

                        {/*<ul className="tree">*/}
                            {/*<li>*/}
                                {/*Merchant Package*/}
                                {/*<ul>*/}
                                    {/*<li>*/}
                                        {/*CSS*/}
                                        {/*<ul>*/}
                                            {/*<li>*/}
                                                {/*style.css*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*style.iframe.css*/}
                                            {/*</li>*/}
                                        {/*</ul>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*Fonts*/}
                                        {/*<ul>*/}
                                            {/*<li>*/}
                                                {/*Arimo.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*DroidSans.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*DroidSerif.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*Inconsolata.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*Monserrat.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*OpenSans.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*Oswald.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*Raleway.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*Roboto.woff*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                                {/*Ubuntu.woff*/}
                                            {/*</li>*/}
                                        {/*</ul>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*i18n*/}
                                        {/*<ul>*/}
                                            {/*<li>de_DE.csv</li>*/}
                                            {/*<li>en_AU.csv</li>*/}
                                            {/*<li>en_CA.csv</li>*/}
                                            {/*<li>en_IE.csv</li>*/}
                                            {/*<li>en_NZ.csv</li>*/}
                                            {/*<li>en_US.csv</li>*/}
                                            {/*<li>es_AR.csv</li>*/}
                                            {/*<li>es_CO.csv</li>*/}
                                            {/*<li>es_ES.csv</li>*/}
                                            {/*<li>es_MX.csv</li>*/}
                                            {/*<li>fr_BE.csv</li>*/}
                                            {/*<li>fr_CA.csv</li>*/}
                                            {/*<li>fr_FR.csv</li>*/}
                                            {/*<li>it_IT.csv</li>*/}
                                            {/*<li>pt_BR.csv</li>*/}
                                            {/*<li>pt_PT.csv</li>*/}
                                            {/*<li>zh_CN.csv</li>*/}
                                            {/*<li>zh_HK.csv</li>*/}
                                            {/*<li>zh_SG.csv</li>*/}
                                            {/*<li>zh_TW.csv</li>*/}
                                        {/*</ul>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*img*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            {/*</li>*/}
                        {/*</ul>*/}
                    </div>
                </div>
            </div>

        );
    }
}

export default MerchantPackage;