import React from 'react';
import { Link } from 'react-router-dom';

import {guideReferenceURL} from "../../../env";
import DocBuilder from '../../../components/DocBuilder';
import guideReferenceIcon from '../../../assets/img/guide_reference.svg';

import request from '../../../documentation/documentation/CreatePaymentMethodToken-request';
import response from '../../../documentation/documentation/CreatePaymentMethodToken-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Create extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true,
            onlyClientExamples: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
        if(this.props.onlyClientExamples === false){
            this.setState({
                onlyClientExamples: this.props.onlyClientExamples
            })
        }
    }

    render() {
        return (
            <div className="container-fluid example-selector-container">
                <div className="row">
                    { this.state.showDocumentation &&
                        <div className="col-12">
                            <Breadcrumbs/>
                            <p>
                                This methods allows you to create a One-time-used token that references a Payment Method,
                                such as a credit card. The PaymentMethodToken should be sent to your server and used with
                                the server-side SDK to create Payment transaction, create a vaulted PaymentMethodId, created
                                a vaulted Customer or using GetInstallmentsOptions method. If the CustomerId is included in
                                the client session the PaymentMethodToken will be attached to the customer.
                            </p>
                            <div className="alert alert-info">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={guideReferenceIcon} alt="Locked" style={{height: '100px'}}/>
                                        <span>
                                            For more information please go to&nbsp;
                                            <Link to={`${guideReferenceURL}/reference/advanced`}>
                                              Guide Reference: Advanced Checkout
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !this.props.masterpass &&
                        <div className="col-12">
                            <DocBuilder
                                showEndpoint={true}
                                examples={true}
                                onlyClientExamples={this.state.onlyClientExamples}
                                onlyFiles={this.props.onlyFiles}
                                data={{request: request, response: response}}
                                showDocumentation={this.state.showDocumentation}
                                endpoint='CreatePaymentMethodToken'/>
                        </div>
                    }
                    <div className="col-12">
                        <DocBuilder
                            examples={true}
                            onlyServerExamples={true}
                            showEndpoint={this.props.masterpass}
                            onlyFiles={this.props.onlyFiles}
                            data={{request:request, response: response}}
                            showDocumentation={false}
                            endpoint='CreatePaymentMethodToken' />
                    </div>
                </div>
            </div>
        );
    }
}

export default Create;