import React from 'react';
import T from 'i18react';
import ReactSVG from 'react-svg';

import Install from './React/Install';
import Configure from './React/Configure';
import Tokenization from './React/Tokenization';
import Form from './React/Form';
import Internationalization from './React/Internationalization';
import Validators from './React/Validators';
import Installments from './React/Installments';
import Sale from '../../ApiReference/Payments/CardPayment/Sale';
import apiIcon from '../../../assets/img/api.svg';
import {apiReferenceURL} from "../../../env";

class ReactPage extends React.Component {
    render() {
        return (
            <div className="container-fluid sdk-client react-sdk">
                <div className="row">
                    <div className="col-12 text-center download-container-react">
                        <div className="react-library"/>
                        <div className="col-12 col-md-2 download text-center">
                            <br/>
                            <br/>
                            <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-react" className="get-started-in" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-github" />
                                Download (Beta)
                            </a>
                            <br/>
                            <br/>
                            <span className="badge badge-success">
                                v16 to above
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12">
                        <hr/>
                        <h4 id="advancedCheckout.javascript.title">
                            {T.translate("advancedCheckout.react.title")}
                        </h4>
                        <p id="advancedCheckout.javascript.text">
                            {T.translate("advancedCheckout.react.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <h4 id="advancedCheckout.javascript.how.title">
                            {T.translate("advancedCheckout.react.how.title")}
                        </h4>
                        <p id="advancedCheckout.javascript.how.text">
                            {T.translate("advancedCheckout.react.how.text")}
                        </p>
                    </div>
                    <div className="col-12">
                        <h4 id="advancedCheckout.javascript.process.title">
                            {T.translate("advancedCheckout.javascript.process.title")}
                        </h4>
                        <ul>
                            <li id="advancedCheckout.javascript.process.list.1">
                                {T.translate("advancedCheckout.javascript.process.list.1")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.2">
                                {T.translate("advancedCheckout.javascript.process.list.2")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.3">
                                {T.translate("advancedCheckout.javascript.process.list.3")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.4">
                                {T.translate("advancedCheckout.javascript.process.list.4")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.5">
                                {T.translate("advancedCheckout.javascript.process.list.5")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.6">
                                {T.translate("advancedCheckout.javascript.process.list.6")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.7">
                                {T.translate("advancedCheckout.javascript.process.list.7")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.8">
                                {T.translate("advancedCheckout.javascript.process.list.8")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.9">
                                {T.translate("advancedCheckout.javascript.process.list.9")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.10">
                                {T.translate("advancedCheckout.javascript.process.list.10")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.11">
                                {T.translate("advancedCheckout.javascript.process.list.11")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.12">
                                {T.translate("advancedCheckout.javascript.process.list.12")}
                            </li>
                            <li id="advancedCheckout.javascript.process.list.13">
                                {T.translate("advancedCheckout.javascript.process.list.13")}
                            </li>
                        </ul>
                        <hr/>
                    </div>
                    <Install/>
                    <Configure/>
                    <Tokenization/>
                    <Form/>
                    <Internationalization/>
                    <Validators/>
                    <Installments/>
                    <div className="col-12">
                        <h4 id="advancedCheckout.javascript.payonline.title">
                            Step 8 - {T.translate("advancedCheckout.javascript.payonline.title")}
                        </h4>
                        <br/><br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked"  className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("advancedCheckout.detail",
                                            {
                                                "auth": <T.a href={`${apiReferenceURL}/payments/CardPayment/Authorization` } text="Authorization"/>,
                                                "sale": <T.a href={`${apiReferenceURL}/payments/CardPayment/Sale` } text="Sale"/>
                                            }
                                        )}
                                        <br/>
                                        {T.translate("advancedCheckout.all",
                                            {
                                                "api": <T.a href={`${apiReferenceURL}/`} text="Api Reference"/>
                                            }
                                        )}
                                        <br/><br/>
                                        {T.translate("advancedCheckout.example")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Sale showDocumentation={false} onlyFiles="pmt"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReactPage;