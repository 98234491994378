import React from 'react';
import DocBuilder from '../../../components/DocBuilder';

import request from '../../../documentation/documentation/Capture-request';
import response from '../../../documentation/documentation/Capture-response';
import Breadcrumbs from "../../../components/Breadcrumbs";

class Capture extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showDocumentation: true
        };
    }

    componentDidMount(){
        if(this.props.showDocumentation === false){
            this.setState({
                showDocumentation: this.props.showDocumentation
            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumbs/>
                        <p>
                            When an authorization is made, this transaction needs to be confirmed by a capture in order
                            to generate the payment into the card holder’s bank account.
                        </p>
                        <DocBuilder showEndpoint={true} data={{request:request, response: response}} endpoint='Capture' showDocumentation={this.state.showDocumentation} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Capture;