import React from 'react';
import T from 'i18react';

const Approved = () => {

    let images = {};
    let folder = require.context('../../../assets/img/cards/', false, /\.(png|jpe?g|svg)$/)
    folder.keys().map((item, index) => {
        images[item.replace('./', '')] = folder(item);
        return true;
    });

    return (
        <table className="table table-documentation table-responsive image-testing-cards">
            <tbody>
            <tr>
                <td></td>
                <td><strong>Product</strong></td>
                <td><strong>PAN</strong></td>
                <td><strong>Security Code</strong></td>
                <td><strong>Exp. Date</strong></td>
                <td><strong>Remarks</strong></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="amex"/>
                </td>
                <td id="testing.approvedTable.americanExpress">
                    {T.translate("testing.approvedTable.americanExpress")}</td>
                <td>376411234531007</td>
                <td>1234</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="argencard"/>
                </td>
                <td id="testing.approvedTable.argencard">
                    {T.translate("testing.approvedTable.argencard")}</td>
                <td>5011050000000001</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="argenta"/>
                </td>
                <td id="testing.approvedTable.argenta">
                    {T.translate("testing.approvedTable.argenta")}</td>
                <td>6034889800000013</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="bbps"/>
                </td>
                <td id="testing.approvedTable.bbps">
                    {T.translate("testing.approvedTable.bbps")}</td>
                <td>6274010000000013</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="cabal"/>
                </td>
                <td id="testing.approvedTable.cabal">
                    {T.translate("testing.approvedTable.cabal")}</td>
                <td>5896570003105119</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="cabal"/>
                </td>
                <td id="testing.approvedTable.cabalDebit">
                    {T.translate("testing.approvedTable.cabalDebit")}</td>
                <td>6042015432819055</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="carta-automatica"/>
                </td>
                <td id="testing.approvedTable.cartaAutomatica">
                    {T.translate("testing.approvedTable.cartaAutomatica")}</td>
                <td>5045690000000004</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="carefour"/>
                </td>
                <td id="testing.approvedTable.carrefour">
                    {T.translate("testing.approvedTable.carrefour")}</td>
                <td>507858110000000012</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="cetelem"/>
                </td>
                <td id="testing.approvedTable.cetelem">
                    {T.translate("testing.approvedTable.cetelem")}</td>
                <td>5078580000000007</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="clarin-365"/>
                </td>
                <td id="testing.approvedTable.clarin365">
                    {T.translate("testing.approvedTable.clarin365")}</td>
                <td>3086250011038020004</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-arnet"/>
                </td>
                <td id="testing.approvedTable.clubArnet">
                    {T.translate("testing.approvedTable.clubArnet")}</td>
                <td>6033850000000000030</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-dia"/>
                </td>
                <td id="testing.approvedTable.clubDia">
                    {T.translate("testing.approvedTable.clubDia")}</td>
                <td>6368970000000015</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-la-nacion"/>
                </td>
                <td id="testing.approvedTable.clubNacion">
                    {T.translate("testing.approvedTable.clubNacion")}</td>
                <td>6391300085755808</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-la-voz"/>
                </td>
                <td id={"testing.approvedTable.clubVoz"}>
                    {T.translate("testing.approvedTable.clubVoz")}</td>
                <td>3086254000000000090</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="club-personal"/>
                </td>
                <td id="testing.approvedTable.clubPersonal">
                    {T.translate("testing.approvedTable.clubPersonal")}</td>
                <td>6048710000000000020</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="speedy"/>
                </td>
                <td id={"testing.approvedTable.clubSpeedy"}>
                    {T.translate("testing.approvedTable.clubSpeedy")}</td>
                <td>8954540000000000080</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="consumax"/>
                </td>
                <td id="testing.approvedTable.consumax">
                    {T.translate("testing.approvedTable.consumax")}</td>
                <td>6034160000000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="coopeplus"/>
                </td>
                <td id="testing.approvedTable.coopeplus">
                    {T.translate("testing.approvedTable.coopeplus")}</td>
                <td>6276200000016</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="credimas"/>
                </td>
                <td id="testing.approvedTable.credimas">
                    {T.translate("testing.approvedTable.credimas")}</td>
                <td>5045200000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="mi-gift-card"/>
                </td>
                <td id="testing.approvedTable.ctc">
                  {T.translate("testing.approvedTable.ctc")}
                </td>
                <td>6375040000000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="diners"/>
                </td>
                <td id="testing.approvedTable.diners">
                    {T.translate("testing.approvedTable.diners")}</td>
                <td>30093510390510</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="discover"/>
                </td>
                <td id="testing.approvedTable.discover">
                    {T.translate("testing.approvedTable.discover")}</td>
                <td>6011020000245045</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="elebar"/>
                </td>
                <td id="testing.approvedTable.elebar">
                    {T.translate("testing.approvedTable.elebar")}</td>
                <td>5047770000000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="favacard"/>
                </td>
                <td id="testing.approvedTable.favacard">
                    {T.translate("testing.approvedTable.favacard")}</td>
                <td>504408000000000017</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="grupar"/>
                </td>
                <td id="testing.approvedTable.grupar">
                    {T.translate("testing.approvedTable.grupar")}</td>
                <td>6063010000000018</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="italcred"/>
                </td>
                <td rowSpan="2" id="testing.approvedTable.italcred">
                    {T.translate("testing.approvedTable.italcred")}</td>
                <td>5043380000214977</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td>5043380200000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="jcb"/>
                </td>
                <td id="testing.approvedTable.jcb">
                    {T.translate("testing.approvedTable.jcb")}</td>
                <td>3528000000000015</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="kadicard"/>
                </td>
                <td id="testing.approvedTable.kadicard">
                    {T.translate("testing.approvedTable.kadicard")}</td>
                <td>6271700000000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="lider"/>
                </td>
                <td id="testing.approvedTable.lider">
                    {T.translate("testing.approvedTable.lider")}</td>
                <td>5010900000000016</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="maestro"/>
                </td>
                <td id="testing.approvedTable.maestro">
                    {T.translate("testing.approvedTable.maestro")}</td>
                <td>5010150000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td id="testing.approvedTable.maestro">
                    {T.translate("testing.approvedTable.maestro")}</td>
                <td>5010150000000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td>PAN Length 19</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="mas"/>
                </td>
                <td id="testing.approvedTable.mas">
                    {T.translate("testing.approvedTable.mas")}</td>
                <td>6034930410400100</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="3" className="no-padding">
                    <div className="mastercard"/>
                </td>
                <td rowSpan="3" id="testing.approvedTable.mastercard">
                    {T.translate("testing.approvedTable.mastercard")}</td>
                <td>5323629993121008</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td>5323629991234563</td>
                <td>123</td>
                <td>12/2023</td>
                <td>Using this card the response will take almost 25 seconds</td>
            </tr>
            <tr>
                <td>5010150000000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td>PAN Length 19</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="mira"/>
                </td>
                <td id="testing.approvedTable.mira">
                    {T.translate("testing.approvedTable.mira")}</td>
                <td>4210240000000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="naranja"/>
                </td>
                <td rowSpan="2" id="testing.approvedTable.naranja">
                    {T.translate("testing.approvedTable.naranja")}
                </td>
                <td>5895629000002025</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td>5895629000002126</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="nativa"/>
                </td>
                <td id="testing.approvedTable.nativa">
                    {T.translate("testing.approvedTable.nativa")}</td>
                <td>4870170040000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="nevada"/>
                </td>
                <td rowSpan="2" id="testing.approvedTable.nevada">
                    {T.translate("testing.approvedTable.nevada")}
                </td>
                <td>5043631200000001</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td>5043631100000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="nexo"/>
                </td>
                <td id="testing.approvedTable.nexo">
                    {T.translate("testing.approvedTable.nexo")}</td>
                <td>6049400000000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="patagonia-365"/>
                </td>
                <td id="testing.approvedTable.patagonia365">
                    {T.translate("testing.approvedTable.patagonia365")}</td>
                <td>5046560000000016</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="pyme-nacion"/>
                </td>
                <td id="testing.approvedTable.pymeNacion">
                    {T.translate("testing.approvedTable.pymeNacion")}</td>
                <td>5049100100000000</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="quida"/>
                </td>
                <td id="testing.approvedTable.quida">
                    {T.translate("testing.approvedTable.quida")}</td>
                <td>5045700000000019</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="shopping"/>
                </td>
                <td id="testing.approvedTable.shopping">
                    {T.translate("testing.approvedTable.shopping")}</td>
                <td>6034880000000002</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="sol"/>
                </td>
                <td id="testing.approvedTable.sol">
                    {T.translate("testing.approvedTable.sol")}</td>
                <td>5046390000000018</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="sucredito"/>
                </td>
                <td id="testing.approvedTable.sucredito">
                    {T.translate("testing.approvedTable.sucredito")}</td>
                <td>6219780000000019</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="tuya"/>
                </td>
                <td id="testing.approvedTable.tuya">
                    {T.translate("testing.approvedTable.tuya")}</td>
                <td>5888000000000014</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="ultra"/>
                </td>
                <td id="testing.approvedTable.ultra">
                    {T.translate("testing.approvedTable.ultra")}</td>
                <td>6375040010000016</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="visa"/>
                </td>
                <td rowSpan="2" id="testing.approvedTable.visa">
                    {T.translate("testing.approvedTable.visa")}
                </td>
                <td>4507990000000010</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td>4111111111111111</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan="2" className="no-padding">
                    <div className="visa-debito"/>
                </td>
                <td rowSpan="2" id="testing.approvedTable.visaDebit">
                    {T.translate("testing.approvedTable.visaDebit")}
                </td>
                <td>4001020000000017</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            <tr>
                <td>4001020000000165</td>
                <td>123</td>
                <td>12/2023</td>
                <td>Using this card the response will take almost 16 seconds.</td>
            </tr>
            <tr>
                <td className="no-padding">
                    <div className="don-credito"/>
                </td>
                <td id="testing.approvedTable.donCredito">
                    {T.translate("testing.approvedTable.donCredito")}</td>
                <td>6087800000000014</td>
                <td>123</td>
                <td>12/2023</td>
                <td></td>
            </tr>
            </tbody>
        </table>
    );
};

export default Approved;