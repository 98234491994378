import React from 'react';
import T from 'i18react';
import {Tabs, Tab} from 'react-bootstrap-tabs';

import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import versions from '../../../../documentation/sdk_versions';


class Install extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            tabActive: 0
        };
    }

    render() {
        return (
            <div className="col-12">
                <h4 id="advancedCheckout.javascript.install.title">
                    Step 1 - {T.translate("advancedCheckout.javascript.install.title")}
                </h4>
                <Accordion id="advancedCheckout.javascript.install.general.title" additionalClass="left-tabs" open
                           title={"1.1 - " + T.translate("advancedCheckout.javascript.install.general.title")}>
                    <p id="advancedCheckout.javascript.install.general.text">
                        {T.translate("advancedCheckout.javascript.install.general.text")}
                    </p>
                        <Tabs selected={this.state.tabActive} onSelect={(index) => this.setState({ tabActive: index })}>
                            <Tab label="Sandbox">
                                <CodeHighlight language="HTML">
                                    {
                                        '<script type="text/javascript" src="https://sandbox.nps.com.ar/sdk/' + versions.js + '/NPS.js"></script>'
                                    }
                                </CodeHighlight>
                            </Tab>
                            <Tab label="Staging">
                                <CodeHighlight language="HTML">
                                    {
                                        '<script type="text/javascript" src="https://implementacion.nps.com.ar/sdk/' + versions.js + '/NPS.js"></script>'
                                    }
                                </CodeHighlight>
                            </Tab>
                            <Tab label="Production">
                                <CodeHighlight language="HTML">
                                    {
                                        '<script type="text/javascript" src="https://services2.nps.com.ar/sdk/' + versions.js + '/NPS.js"></script>'
                                    }
                                </CodeHighlight>
                            </Tab>
                        </Tabs>
                    <div id="advancedCheckout.javascript.install.general.information"
                         className="alert alert-info"
                         role="alert">
                        {T.translate("advancedCheckout.javascript.install.general.information")}
                    </div>
                    <div id="advancedCheckout.javascript.install.general.warning"
                         className="alert alert-warning"
                         role="alert">
                        {T.translate("advancedCheckout.javascript.install.general.warning")}
                    </div>
                </Accordion>
                <Accordion id="advancedCheckout.javascript.install.jquery.title" additionalClass="left-tabs" open
                           title={"1.2 - " + T.translate("advancedCheckout.javascript.install.jquery.title")}>
                    <p id="advancedCheckout.javascript.install.jquery.text">
                        {T.translate("advancedCheckout.javascript.install.jquery.text")}
                    </p>
                    <Tabs selected={this.state.tabActive}  onSelect={(index) => this.setState({ tabActive: index })}>
                        <Tab label="Sandbox">
                            <CodeHighlight language="HTML">
                                {
                                    '<script>\n' +
                                    'jQuery.getScript("https://sandbox.nps.com.ar/sdk/' + versions.js + '/NPS.js",function(){\n' +
                                    '  // Write NPS code in here...\n' +
                                    '}); \n' +
                                    '</script>'
                                }
                            </CodeHighlight>
                        </Tab>
                        <Tab label="Staging">
                            <CodeHighlight language="HTML">
                                {
                                    '<script>\n' +
                                    'jQuery.getScript("https://implementacion.nps.com.ar/sdk/' + versions.js + '/NPS.js",function(){\n' +
                                    '  // Write NPS code in here...\n' +
                                    '}); \n' +
                                    '</script>'
                                }
                            </CodeHighlight>
                        </Tab>
                        <Tab label="Production">
                            <CodeHighlight language="HTML">
                                {
                                    '<script>\n' +
                                    'jQuery.getScript("https://services2.nps.com.ar/sdk/' + versions.js + '/NPS.js",function(){\n' +
                                    '  // Write NPS code in here...\n' +
                                    '}); \n' +
                                    '</script>'
                                }
                            </CodeHighlight>
                        </Tab>
                    </Tabs>
                </Accordion>
                <hr/>
            </div>
        );
    }
}

export default Install;