import React from 'react';

import {cookieExpDays} from "../env";
import Cookies from '../helpers/Cookies';
import CodeHighlight from './CodeHighlight';
import {loadExamples} from '../actions/examplesActions';
import exampleFilesLocations from '../documentation/examples_location';

class ExampleBuilder extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            cookiesHandler: new Cookies(),
            openLangDropdown: false,
            openVarDropdown: false,
            disableVariation: false,
            loadedExample: false,
            language: 'Select',
            variation: '',
            rawVariation: '',
            code: '',
            loadedExamplesCount: '',
            examples: {}
        };
        this.variationExampleName = this.variationExampleName.bind(this);
        this.showExample = this.showExample.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.correctLangText = this.correctLangText.bind(this);
        this.checkCookie = this.checkCookie.bind(this);
        this.changeLangCodeForHighlight = this.changeLangCodeForHighlight.bind(this);
        this.createExamples = this.createExamples.bind(this);
    }

    componentDidMount(){

        let self = this;
        let resolvedFiles = this.state.examples;
        let otherMethodFiles = {};
        let storedExamples = sessionStorage.getItem('examples_'+this.props.endpoint);
        if(storedExamples){
            let parsed = JSON.parse(storedExamples);
            let examplesCodes = parsed[this.props.type.toLowerCase()];
            let newGlobalOrder = {}; //👁
            setTimeout(() => {
                if(self.props.clientExamples && !self.props.onlyServerExamples){
                    newGlobalOrder.javascript   = examplesCodes.javascript;
                    newGlobalOrder.android      = examplesCodes.android;
                    newGlobalOrder.objectivec   = examplesCodes.objectivec;
                    newGlobalOrder.swift        = examplesCodes.swift;
                    newGlobalOrder.dart        = examplesCodes.dart;
                }
                if(!self.props.onlyClientExamples){
                    newGlobalOrder.python   = examplesCodes.python;
                    newGlobalOrder.php      = examplesCodes.php;
                    newGlobalOrder.ruby     = examplesCodes.ruby;
                    newGlobalOrder.node     = examplesCodes.node;
                    newGlobalOrder.java     = examplesCodes.java;
                    newGlobalOrder.dotnet   = examplesCodes.dotnet;
                    newGlobalOrder.go       = examplesCodes.go;
                    newGlobalOrder.perl     = examplesCodes.perl;
                    newGlobalOrder.c        = examplesCodes.c;
                    newGlobalOrder.lua      = examplesCodes.lua;
                }

                self.setState({
                    examples: newGlobalOrder,
                    loadedExample: true
                });
                this.checkCookie();
            },5);
        }else{
            let clientLanguages = this.props.onlyClientExamples || this.props.clientExamples;
            let examplesByApi = loadExamples(exampleFilesLocations[this.props.endpoint],clientLanguages,this.props.endpoint);
            let waitForApi = setInterval(() =>{
                if(Object.keys(examplesByApi).length !== 0){
                    Object.keys(examplesByApi).forEach((language) =>{
                        Object.keys(examplesByApi[language]).forEach((method) => {
                            if (method.indexOf(this.props.endpoint + '__' + this.props.type) === 0) {
                                if (!resolvedFiles.hasOwnProperty(language)) {
                                    resolvedFiles[language] = {};
                                }
                                resolvedFiles[language][method] = examplesByApi[language][method];
                                self.setState({
                                    examples: resolvedFiles
                                })
                            } else {
                                if (!otherMethodFiles.hasOwnProperty(language)) {
                                    otherMethodFiles[language] = {};
                                }
                                otherMethodFiles[language][method] = examplesByApi[language][method];
                            }
                        });
                    });
                    clearInterval(waitForApi);
                    this.createExamples(otherMethodFiles);
                }else{
                    let loadedCountSession = sessionStorage.getItem('loadedExamplesCount');
                    let percentage = 0;
                    if(loadedCountSession){
                        loadedCountSession = loadedCountSession.split('/');
                        percentage = Math.round((loadedCountSession[0] * 100) / loadedCountSession[1]);
                    }

                    this.setState({
                        loadedExamplesCount: percentage
                    });
                }
            }, 200);
        }

        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchend', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps){
        if(this.props.updateExample !== nextProps.updateExample && nextProps.updateExample){
            this.checkCookie();
            this.props.event.sendMessage('updateExample', false);
        }
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchend', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.state.openLangDropdown && (this._exampleSelector && !this._exampleSelector.contains(event.target))) {
            this.setState({
                openLangDropdown: false
            });
        }
        if (this.state.openVarDropdown && (this._variationSelector && !this._variationSelector.contains(event.target))) {
            this.setState({
                openVarDropdown: false
            });
        }
    }

    createExamples(otherMethodFiles){
        let self = this;
        let correctExamples = false;

        let newGlobalOrder = {}; //👁
        setTimeout(() => {
            if(!self.props.onlyClientExamples &&
                (
                    self.state.examples.python &&
                    self.state.examples.php &&
                    self.state.examples.ruby &&
                    self.state.examples.node &&
                    self.state.examples.java &&
                    self.state.examples.dotnet &&
                    self.state.examples.go &&
                    self.state.examples.perl &&
                    self.state.examples.lua &&
                    self.state.examples.c
                )
            ){
                correctExamples = true;
            }else{
                if(self.props.onlyClientExamples){
                    correctExamples = true;
                }else{
                    this.createExamples(otherMethodFiles);
                }
            }

            if(correctExamples){
                if(self.props.clientExamples && !self.props.onlyServerExamples){
                    newGlobalOrder.javascript   = self.state.examples.javascript;
                    newGlobalOrder.android      = self.state.examples.android;
                    newGlobalOrder.objectivec   = self.state.examples.objectivec;
                    newGlobalOrder.swift        = self.state.examples.swift;
                    newGlobalOrder.dart         = self.state.examples.dart;
                }
                if(!self.props.onlyClientExamples) {
                    newGlobalOrder.python   = self.state.examples.python;
                    newGlobalOrder.php      = self.state.examples.php;
                    newGlobalOrder.ruby     = self.state.examples.ruby;
                    newGlobalOrder.node     = self.state.examples.node;
                    newGlobalOrder.java     = self.state.examples.java;
                    newGlobalOrder.dotnet   = self.state.examples.dotnet;
                    newGlobalOrder.go       = self.state.examples.go;
                    newGlobalOrder.perl     = self.state.examples.perl;
                    newGlobalOrder.c        = self.state.examples.c;
                    newGlobalOrder.lua      = self.state.examples.lua;
                }

                self.setState({
                    examples: newGlobalOrder,
                    loadedExample: true
                });

                if(this.props.type === 'Request'){
                    try {
                        if(sessionStorage.getItem('examples_'+this.props.endpoint)){
                            let currentData = JSON.parse(sessionStorage.getItem('examples_'+this.props.endpoint));

                            let request = Object.assign(currentData.request, newGlobalOrder);
                            let response = Object.assign(currentData.response, otherMethodFiles);

                            sessionStorage.setItem('examples_'+this.props.endpoint,JSON.stringify({request: request, response: response}));

                        }else{
                            sessionStorage.setItem('examples_'+this.props.endpoint,JSON.stringify({request: newGlobalOrder, response: otherMethodFiles}));
                        }
                    }
                    catch(error) {
                        sessionStorage.clear();
                        sessionStorage.setItem('examples_'+this.props.endpoint,JSON.stringify({request: newGlobalOrder, response: otherMethodFiles}));
                    }

                }else{
                    try {
                        if(sessionStorage.getItem('examples_'+this.props.endpoint)){
                            let currentData = JSON.parse(sessionStorage.getItem('examples_'+this.props.endpoint));

                            let request = Object.assign(currentData.request, otherMethodFiles);
                            let response = Object.assign(currentData.response, newGlobalOrder);

                            sessionStorage.setItem('examples_'+this.props.endpoint,JSON.stringify({request: request, response: response}));

                        }else{
                            sessionStorage.setItem('examples_'+this.props.endpoint,JSON.stringify({request: otherMethodFiles, response: newGlobalOrder}));
                        }
                    }
                    catch(error) {
                        sessionStorage.clear();
                        sessionStorage.setItem('examples_'+this.props.endpoint,JSON.stringify({request: otherMethodFiles, response: newGlobalOrder}));
                    }
                }
                this.checkCookie();
            }

        },2500);
    }

    correctLangText(lang){
        let correctLang = '';

        switch (lang){
            case 'objectivec':
                correctLang = 'Objective C';
                break;
            case 'dotnet':
                correctLang = '.Net';
                break;
            case 'node':
                correctLang = 'Node.js';
                break;
            default:
                correctLang = lang.charAt(0).toUpperCase() + lang.slice(1);
                break;
        }
        return correctLang;
    }

    variationExampleName(value){
        let name = value;
        if(exampleFilesLocations[this.props.endpoint]){
            if(exampleFilesLocations[this.props.endpoint][value]){
                name = exampleFilesLocations[this.props.endpoint][value];
            }
        }
        return  name;
    }

    checkCookie(){
        let rawCookie = this.state.cookiesHandler.getCookie('language');
        if(rawCookie && !this.props.onlyFiles){
            let cookie = JSON.parse(rawCookie);
            this.showExample(this.props.endpoint+'__'+this.props.type+'_'+cookie.variation,cookie.language, true);
        }
        if(rawCookie && this.props.onlyFiles){
            let cookie = JSON.parse(rawCookie);
            this.showExample(this.props.endpoint+'__'+this.props.type+'_with_'+this.props.onlyFiles,cookie.language, true);
        }
    }

    showExample(value, lang, fromCookie = false){
        let language = lang ? lang : this.state.language;
        let rawVariation = '';
        let tmp = [];
        let code = '';
        let disableVariation = false;

        if(lang){ language = lang }
        if(value){
            let valueArray = value.split('__')[1].split('_');
            if(typeof valueArray[1] !== 'undefined' && valueArray[1] !== ''){
                tmp = valueArray.splice(1,valueArray.length);
                rawVariation = this.props.endpoint+'__'+this.props.type+'_'+tmp.join('_');
            }else{
                rawVariation = this.props.endpoint+'__'+this.props.type;
            }
        }
        else {
            if(this.props.onlyFiles){
                if(Array.isArray(this.props.onlyFiles)){
                    rawVariation = this.props.endpoint+'__'+this.props.type+'_with_'+this.props.onlyFiles[0];
                    code = this.state.examples[language][rawVariation];
                }else{
                    rawVariation = this.props.endpoint+'__'+this.props.type+'_with_'+this.props.onlyFiles;
                    code = this.state.examples[language][rawVariation];
                }
            }
            if(this.state.rawVariation){
                rawVariation = this.state.rawVariation;
            }
        }
        if(this.state.examples[language] && !this.state.examples[language][rawVariation]){
            rawVariation = Object.keys(this.state.examples[language])[0];
        }

        if(this.state.examples[language]){
            code = this.state.examples[language][rawVariation];
        }else{
            language = 'Select';
            rawVariation = '';
        }

        this.setState({
            language: language,
            variation: this.variationExampleName(rawVariation),
            rawVariation: rawVariation,
            openLangDropdown: false,
            openVarDropdown: false,
            disableVariation: disableVariation,
            code: code
        });
        this.state.cookiesHandler.setCookie('language', JSON.stringify({variation: tmp.join('_'), language: language}), cookieExpDays);
        if(!fromCookie && this.props.event){
            this.props.event.sendMessage('updateExample', true);
        }
    }

    changeLangCodeForHighlight(lang){
        if(lang === 'node'){
            return 'javascript';
        }else{
            return lang
        }
    }

    render() {
        return (
            <div className="panel panel-default example-selector">
                {
                    !this.state.loadedExample &&
                    <div className="example-overlay">
                        <div className={`c100 p${this.state.loadedExamplesCount} small dark`}>
                            <span>{this.state.loadedExamplesCount}%</span>
                            <div className="slice">
                                <div className="bar"/>
                                <div className="fill"/>
                            </div>
                        </div>
                    </div>
                 }
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-12">
                            <small>Please use selector to change between programming languages</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className={this.state.openLangDropdown ? "dropdown show" : "dropdown"}
                                 ref={(ref) => this._exampleSelector = ref}>
                                <button className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        onClick={() => this.setState({ openLangDropdown: !this.state.openLangDropdown })}
                                        aria-expanded="false">
                                    SDK: {this.correctLangText(this.state.language)}
                                </button>
                                <div className={this.state.openLangDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                    {
                                        Object.getOwnPropertyNames(this.state.examples).map(((value,index) =>
                                            <span key={index} className={this.state.language === value ? "dropdown-item active" : "dropdown-item"} onClick={() => this.showExample(false,value)}>{this.correctLangText(value)}</span>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className={this.state.openVarDropdown ? "dropdown show" : "dropdown"}
                                 ref={(ref) => this._variationSelector = ref}>
                                <button className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        onClick={() => this.setState({ openVarDropdown: !this.state.openVarDropdown })}
                                        disabled={this.state.language === 'Select' || this.state.disableVariation}
                                        aria-expanded="false">
                                    Variation: {this.state.variation}
                                </button>
                                <div className={this.state.openVarDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                    {
                                        this.state.examples[this.state.language] &&
                                        Object.keys(this.state.examples[this.state.language]).sort().map(((value,index) => {
                                            if(this.props.onlyFiles){
                                                if(value.indexOf(this.props.onlyFiles) !== -1) {
                                                    return <span key={index}
                                                              className={this.state.rawVariation === value ? "dropdown-item active" : "dropdown-item"}
                                                              onClick={() => this.showExample(value)}>{this.variationExampleName(value)}</span>
                                                }
                                            }else{
                                                return <span key={index}
                                                          className={this.state.rawVariation === value ? "dropdown-item active" : "dropdown-item"}
                                                          onClick={() => this.showExample(value)}>{this.variationExampleName(value)}</span>
                                            }
                                            return '';
                                        }))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    {
                        this.state.code === '' &&
                        <CodeHighlight language='bash'>
                            {
                                'if (noLanguageSelected()){\n' +
                                '   echo "Please Select a language;"\n' +
                                '} else {\n' +
                                '   showAwesomeCodeExample();\n' +
                                '}'
                            }
                        </CodeHighlight>
                    }
                    {
                        this.state.code !== '' &&
                        <CodeHighlight language={this.state.language === 'node' ? 'javascript' : this.state.language}>
                            {this.state.code}
                        </CodeHighlight>
                    }
                </div>
            </div>
        );
    }
}

export default ExampleBuilder;