import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';

const Installments = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.swift.installments.title">
                (Optional) Step 6 - {T.translate("advancedCheckout.swift.installments.title")}
            </h4>
            <p id="advancedCheckout.swift.installments.text">
                {T.translate("advancedCheckout.swift.installments.text")}
            </p>
            <CodeHighlight language="Swift">
                {
                    'let nps = Nps(environment: NPSSANDBOX)!\n' +
                    'nps.merchantId = "__YOUR_NPS_MERCHANT_ID__"\n' +
                    'nps.clientSession = "__YOUR_NPS_CLIENT_SESSION__"\n' +
                    '\n' +
                    'nps.getInstallmentsOptions("100", \n' +
                    '                    product:@"14",\n' +
                    '                   currency:@"152",\n' +
                    '                    country:@"CHL",\n' +
                    '                numPayments:@"2",\n' +
                    '         paymentMethodToken:@"kkvKuOfD2bNKXCBYDkunIRqImvNFNxB3",\n' +
                    '              methodResponse: {( methodResponse: GetInstallmentsOptionsResponse?, error: Error?) -> Void in\n' +
                    '                    if error == nil{\n' +
                    '                         print(methodResponse?.responseCod as Any)\n' +
                    '                         for inst in methodResponse?.installments{\n' +
                    '                            print(inst.installment_amount)\n' +
                    '                            print(inst.interestRate)\n' +
                    '                            print(inst.num_payments)\n' +
                    '                         }\n' +
                    '                     }\n' +
                    '})'
                }
            </CodeHighlight>
        </div>
    );
};

export default Installments;