import React from 'react';
import T from 'i18react';

const Retail = () => {
    let images = {};
    let folder = require.context('../../../assets/img/cards/', false, /\.(png|jpe?g|svg)$/)
    folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

    return (
        <div className="row">
            <div className="col-12">
                <h3>
                    {T.translate("testing.retail.title")}
                </h3>
                <p>
                    {T.translate("testing.retail.text")}
                </p>
            </div>
            <div className="col-12">
                <h4>{T.translate("testing.retail.accept.title")}</h4>
                <p>{T.translate("testing.retail.accept.text")}</p>
                <table className="table table-documentation table-responsive image-testing-cards">
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                <strong>Product</strong>
                            </td>
                            <td>
                                <strong>PAN</strong>
                            </td>
                            <td>
                                <strong>Security Code</strong>
                            </td>
                            <td>
                                <strong>Exp. Date</strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="amex"/>
                            </td>
                            <td>American Express</td>
                            <td>370000101011106</td>
                            <td>1234</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="discover"/>
                            </td>
                            <td>Discover</td>
                            <td>6011000000000004</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="jcb"/>
                            </td>
                            <td>JCB</td>
                            <td>3528000000000007</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="mastercard"/>
                            </td>
                            <td>Mastercard</td>
                            <td>5100000000000016</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                               <div className="visa"/>
                            </td>
                            <td>Visa</td>
                            <td>4000021231111111</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <h4>{T.translate("testing.retail.review.title")}</h4>
                <p>{T.translate("testing.retail.review.text")}</p>
                <table className="table table-documentation table-responsive image-testing-cards">
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                <strong>Product</strong>
                            </td>
                            <td>
                                <strong>PAN</strong>
                            </td>
                            <td>
                                <strong>Security Code</strong>
                            </td>
                            <td>
                                <strong>Exp. Date</strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="amex"/>
                            </td>
                            <td>American Express</td>
                            <td>340000100010106</td>
                            <td>1234</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="discover"/>
                            </td>
                            <td>Discover</td>
                            <td>6011000000000012</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="jcb"/>
                            </td>
                            <td>JCB</td>
                            <td>3548000000000003</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="mastercard"/>
                            </td>
                            <td>Mastercard</td>
                            <td>5300000000000006</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="visa"/>
                            </td>
                            <td>Visa</td>
                            <td>4000111231110112</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <h4>{T.translate("testing.retail.deny.title")}</h4>
                <p>{T.translate("testing.retail.deny.text")}</p>
                <table className="table table-documentation table-responsive image-testing-cards">
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                <strong>Product</strong>
                            </td>
                            <td>
                                <strong>PAN</strong>
                            </td>
                            <td>
                                <strong>Security Code</strong>
                            </td>
                            <td>
                                <strong>Exp. Date</strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="amex"/>
                            </td>
                            <td>American Express</td>
                            <td>370000500010428</td>
                            <td>1234</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="discover"/>
                            </td>
                            <td>Discover</td>
                            <td>6011000000000111</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="jcb"/>
                            </td>
                            <td>JCB</td>
                            <td>3528000000000023</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="mastercard"/>
                            </td>
                            <td>Mastercard</td>
                            <td>5100000000000032</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                        <tr>
                            <td className="no-padding">
                                <div className="visa"/>
                            </td>
                            <td>Visa</td>
                            <td>4011201021110112</td>
                            <td>123</td>
                            <td>12/2023</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Retail;