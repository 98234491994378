import React from 'react';

class PaymentMethodsSelects extends React.Component {

    constructor(props, context) {
        super(props, context);

        let images = {};
        let folder = require.context('../assets/img/flags/flags-iso/flat/64/', false, /\.(png|jpe?g|svg)$/);
        folder.keys().map((item, index) => {
            images[item.replace('./', '')] = folder(item);
            return true;
        });

        this.state = {
            flags: images
        };

        this.translateCountry = this.translateCountry.bind(this);
        this.translateCardCode = this.translateCardCode.bind(this);
        this.translateCurrencyCode = this.translateCurrencyCode.bind(this);
        this.countryOptionRender = this.countryOptionRender.bind(this);
        this.countryValueRender = this.countryValueRender.bind(this);
        this.typeOptionRender = this.typeOptionRender.bind(this);
        this.typeValueRender = this.typeValueRender.bind(this);
        this.currencyOptionRender = this.currencyOptionRender.bind(this);
        this.currencyValueRender = this.currencyValueRender.bind(this);
    }

    translateCountry(country){
        let countryName = '';
        switch(country){
            case 'AR': countryName = 'Argentina'; break;
            case 'BR': countryName = 'Brazil'; break;
            case 'CL': countryName = 'Chile'; break;
            case 'CO': countryName = 'Colombia'; break;
            case 'CR': countryName = 'Costa Rica'; break;
            case 'GT': countryName = 'Guatemala'; break;
            case 'HN': countryName = 'Honduras'; break;
            case 'MX': countryName = 'Mexico'; break;
            case 'NI': countryName = 'Nicaragua'; break;
            case 'PA': countryName = 'Panama'; break;
            case 'PE': countryName = 'Peru'; break;
            case 'PY': countryName = 'Paraguay'; break;
            case 'SV': countryName = 'El Salvador'; break;
            case 'UY': countryName = 'Uruguay'; break;
            case 'VE': countryName = 'Venezuela'; break;
            default:  break;
        }
        return countryName;
    }
    translateCardCode(card){
        let cardClass = '';
        let cardId = card.product;

        switch(cardId){
            case 1: cardClass = 'amex'; break;
            case 2: cardClass = 'diners'; break;
            case 4: cardClass = 'jbc'; break;
            case 5: cardClass = 'mastercard'; break;
            case 8: cardClass = 'cabal'; break;
            case 9: cardClass = 'naranja'; break;
            case 10: cardClass = 'kadicard'; break;
            case 14: cardClass = 'visa'; break;
            case 15: cardClass = 'favacard'; break;
            case 17: cardClass = 'lider'; break;
            case 20: cardClass = 'credimas'; break;
            case 21: cardClass = 'nevada'; break;
            case 29: cardClass = 'naranja'; break;
            case 33: cardClass = 'patagonia-365'; break;
            case 34: cardClass = 'sol'; break;
            case 35: cardClass = 'cmr-falabella'; break;
            case 38: cardClass = 'nativa'; break;
            case 42: cardClass = 'shopping'; break;
            case 43: cardClass = 'italcred'; break;
            case 45: cardClass = 'club-la-nacion'; break;
            case 46: cardClass = 'club-personal'; break;
            case 47: cardClass = 'club-arnet'; break;
            case 48: cardClass = 'mas'; break;
            case 49: cardClass = 'default'; break;
            case 50: cardClass = 'pyme-nacion'; break;
            case 51: cardClass = 'clarin-365'; break;
            case 52: cardClass = 'club-speedo'; break;
            case 53: cardClass = 'argenta'; break;
            case 55: cardClass = 'visa-debito'; break;
            case 57: cardClass = 'mastercard'; break;
            case 58: cardClass = 'club-la-voz'; break;
            case 61: cardClass = 'nexo'; break;
            case 63: cardClass = 'nativa'; break;
            case 65: cardClass = 'argencard'; break;
            case 66: cardClass = 'maestro'; break;
            case 72: cardClass = 'consumax'; break;
            case 91: cardClass = 'credi-guia'; break;
            case 95: cardClass = 'coopeplus'; break;
            case 101: cardClass = 'discover'; break;
            case 102: cardClass = 'elo'; break;
            case 103: cardClass = 'magna'; break;
            case 104: cardClass = 'aura'; break;
            case 105: cardClass = 'hipercard'; break;
            case 106: cardClass = 'credencial'; break;
            case 107: cardClass = 'red-compra'; break;
            case 108: cardClass = 'default'; break;
            case 110: cardClass = 'bbps'; break;
            case 112: cardClass = 'ripley'; break;
            case 113: cardClass = 'oh'; break;
            case 114: cardClass = 'default'; break;
            case 115: cardClass = 'union-pay'; break;
            case 116: cardClass = 'hiper'; break;
            case 117: cardClass = 'carrefour'; break;
            case 118: cardClass = 'grupar'; break;
            case 119: cardClass = 'tuya'; break;
            case 120: cardClass = 'club-dia'; break;
            case 121: cardClass = 'mi-gift-card'; break;
            case 122: cardClass = 'quida'; break;
            case 123: cardClass = 'codensa'; break;
            case 124: cardClass = 'socios-bbva'; break;
            case 126: cardClass = 'uatp'; break;
            case 127: cardClass = 'credz'; break;
            case 128: cardClass = 'web-pay'; break;
            case 129: cardClass = 'comfama'; break;
            case 130: cardClass = 'carnet'; break;
            case 131: cardClass = 'carnet-debit'; break;
            case 132: cardClass = 'ultra'; break;
            case 300: cardClass = 'rapipago'; break;
            case 301: cardClass = 'pagofacil'; break;
            case 302: cardClass = 'bapro'; break;
            case 303: cardClass = 'ripsa'; break;
            case 304: cardClass = 'cobro-express'; break;
            case 305: cardClass = 'servi-pag'; break;
            case 306: cardClass = 'default'; break;
            case 307: cardClass = 'default'; break;
            case 308: cardClass = 'default'; break;
            case 309: cardClass = 'default'; break;
            case 310: cardClass = 'default'; break;
            case 311: cardClass = 'default'; break;
            case 312: cardClass = 'default'; break;
            case 313: cardClass = 'default'; break;
            case 314: cardClass = 'default'; break;
            case 315: cardClass = 'default'; break;
            case 320: cardClass = 'pago-mis-cuentas'; break;
            case 321: cardClass = 'safety-pay'; break;
            default: cardClass = 'default'; break;
        }
        return cardClass;
    }
    translateCurrencyCode(currency) {
        let code = '';

        switch(currency){
            case '32': code = 'Argentina - ARS'; break;
            case '986': code = 'Brazil - BRL'; break;
            case '152': code = 'Chile - CLP'; break;
            case '840': code = 'USA - USD'; break;
            case '170': code = 'Colombia - COP'; break;
            case '188': code = 'Costa Rica - CRC'; break;
            case '320': code = 'Guatemala - GTQ'; break;
            case '484': code = 'Mexico - MXN'; break;
            case '558': code = 'Nicaragua - NIO'; break;
            case '604': code = 'Peru - PEN'; break;
            case '600': code = 'Paraguay - PYG'; break;
            case '858': code = 'Uruguay - UYU'; break;
            case '340': code = 'Honduras - HNL'; break;
            case '937': code = 'Venezuela - VEF'; break;
            default: break;
        }
        return code;
    }

    countryOptionRender(opts){
        return <div className={opts.className}
                    onMouseDown={(event) => { opts.onSelect(opts.option,event); }}
                    onMouseEnter={(event) => { opts.onFocus(opts.option,event); }}
                    onMouseMove={(event) => { if(opts.isFocused) return; opts.onFocus(opts.option,event); }}
                    title={opts.option.title}>
            <img src={this.state.flags[opts.children + '.png']} alt={opts.children} className="country-flag"/>
            {this.translateCountry(opts.children)}
        </div>
    }

    countryValueRender(opts){
        return <div className="Select-value margin-left-0" title={opts.value.title}>
				<span className="Select-value-label">
                    {this.translateCountry(opts.label)}
				</span>
        </div>
    }

    typeOptionRender(opts){
        return <div className={opts.className}
                    onMouseDown={(event) => { opts.onSelect(opts.option,event); }}
                    onMouseEnter={(event) => { opts.onFocus(opts.option,event); }}
                    onMouseMove={(event) => { if(opts.isFocused) return; opts.onFocus(opts.option,event); }}>
            {opts.children}
        </div>
    }
    typeValueRender(opts){
        return <div className="Select-value margin-left-0">
				<span className="Select-value-label">
                    {opts.label}
				</span>
        </div>
    }
    currencyOptionRender(opts){
        return <div className={opts.className}
                    onMouseDown={(event) => { opts.onSelect(opts.option,event); }}
                    onMouseEnter={(event) => { opts.onFocus(opts.option,event); }}
                    onMouseMove={(event) => { if(opts.isFocused) return; opts.onFocus(opts.option,event); }}
                    title={opts.option.title}>
            {this.translateCurrencyCode(opts.children)}
        </div>
    }
    currencyValueRender(opts){
        return <div className="Select-value margin-left-0" title={opts.value.title}>
				<span className="Select-value-label">
                    {this.translateCurrencyCode(opts.label)}
				</span>
        </div>
    }
}

export default PaymentMethodsSelects;