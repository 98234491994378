import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';

import './assets/css/main.css';

import Wrapper from './bootstrap';

import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
        <Wrapper/>
    , document.getElementById('root'));
registerServiceWorker();
