import React from 'react';
import { withRouter } from 'react-router'
import { Link, NavLink } from 'react-router-dom';


import * as env from '../env';

import EN from '../assets/img/flags/flags-iso/flat/32/US.png';
import {apiReferenceURL} from "../env";
import {guideReferenceURL} from "../env";

class Sidebar extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            active: '',
            activeSub: '',
            offCanvasOpen: false,
            languageDrop: false,
            menu: '',
            link: '',
            lastPathMenu: '',
            window: { width: window.innerWidth, height: window.innerHeight }
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    handleClickOutside(event) {
        if (this.state.languageDrop && (this.languageRef && !this.languageRef.contains(event.target))) {
            this.setState({
                languageDrop: false
            });
        }
        if (this.state.offCanvasOpen && (this.sidebarRef && !this.sidebarRef.contains(event.target))) {
            if(event.target.classList.value !== 'navbar-toggler-icon'){
                this.setState({
                    offCanvasOpen: false
                });
                this.props.event.sendMessage('openSidebar',this.state.offCanvasOpen);
            }
        }
    }

    componentDidMount() {
        let pathArray = this.props.location.pathname.split('/');
        if(pathArray.length >= 4){
            this.setState({activeSub: pathArray[3]});
        }
        this.setState({
            active: pathArray[2],
            menu: pathArray[1],
            lastPathMenu: pathArray[pathArray.length - 1]
        });

        if(this.props.location.pathname === '/GuideReference' || this.props.location.pathname === '/GuideReference/'){
            this.setState({
                link: '/'
            })
        }else{
            this.setState({
                link: env.guideReferenceURL
            })
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchend', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState){
        let pathArray = this.props.location.pathname.split('/');
        let iconsAbsolute = true;

        if(prevState.menu  !== pathArray[1]){
            this.setState({
                menu: pathArray[1],
                lastPathMenu: pathArray[pathArray.length - 1]
            });
        }
        this.sidebarRef.childNodes[0].childNodes.forEach((elem) => {
            if(elem.className === 'nav'){
                if(elem.offsetHeight > 600){
                    iconsAbsolute = false;
                }
            }
            if(elem.className === 'nav nav-icons'){
                if(iconsAbsolute)
                    elem.childNodes[0].style.position = 'absolute';
                else
                    elem.childNodes[0].style.position = 'relative';
            }
        });
        if(prevProps.location.pathname !== this.props.location.pathname){
            if(this.props.location.pathname === '/GuideReference'){
                this.setState({
                    link: '/'
                })
            }else{
                this.setState({
                    link: env.guideReferenceURL
                })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        let path = this.props.location.pathname;
        let newPath = nextProps.location.pathname;
        let pathArray = nextProps.location.pathname.split('/');
        let active = pathArray[2];

        this.setState({
            offCanvasOpen: nextProps.open
        });
        if(path !== newPath){
            this.setState({
                active: active,
                lastPathMenu: pathArray[pathArray.length - 1]
            });
            if(pathArray.length >= 4){
                this.setState({activeSub: pathArray[3]});
            }else{
                this.setState({activeSub: ''});
            }
            this.props.scrollToTop();
        }
    }


    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchend', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ window: { width: window.innerWidth, height: window.innerHeight} });
    }

    render() {
        return (
            <div className={this.state.offCanvasOpen ? 'sidebar off-canvas-sidebar open' : 'sidebar off-canvas-sidebar'}
                 data-background-color="white"
                 data-active-color="danger"
                 ref={(ref) => this.sidebarRef = ref}
            >
                <div className='sidebar-wrapper'>
                    <div className="logo">
                        <Link to={this.state.link} className="simple-text">
                            {
                                (this.state.window.width < 1024) ?
                                <img src={env.logo} className="img-fluid logo-ingenico" alt="logo" style={{margin: '4px 0'}}/> :
                                <img src={env.logo} className="img-fluid logo-ingenico" alt="logo"/>

                            }
                        </Link>
                    </div>

                    {/*NAV Guide Reference*/}

                    <ul className={this.state.menu === env.guideReferenceURL.substr(1) || this.state.menu === 'search' || this.state.menu === '' ? 'nav' : 'hide'}>
                        <li>
                            <NavLink to={`${env.guideReferenceURL}/`}
                                     exact
                                     activeClassName="active">
                                <p>Getting Started</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${env.guideReferenceURL}/reference`}
                                     activeClassName="active">
                                <p>Guide Reference</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'reference' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/reference`}
                                          exact
                                          activeClassName="active-sub">
                                        Overview
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/reference/simple`}
                                             exact
                                             activeClassName="active-sub">
                                        Simple Checkout
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'simple' ? {display:'block'} : {}}>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/reference/simple/card`}
                                                  exact
                                                  activeClassName="active-sub">
                                                Card Payments
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/reference/simple/split`}
                                                  exact
                                                  activeClassName="active-sub">
                                                Split Card Payments
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/reference/simple/voucher`}
                                                  exact
                                                  activeClassName="active-sub">
                                                Voucher Payments
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/reference/simple/bank`}
                                                  exact
                                                  activeClassName="active-sub">
                                                Bank Payments
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/reference/simple/merchantPackage`}
                                                     exact
                                                     activeClassName="active-sub">
                                                Customize your form
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/reference/advanced`}
                                             exact
                                             activeClassName="active-sub">
                                        Advanced Checkout
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${guideReferenceURL}/reference/directPayment`}
                                             exact
                                             activeClassName="active-sub">
                                        Direct Payment
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${guideReferenceURL}/reference/commonInterface`}
                                             style={{lineHeight: '20px'}}
                                             exact
                                             activeClassName="active-sub">
                                       Common Interface Specification
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${guideReferenceURL}/services`}
                                     activeClassName="active">
                                <p>Services</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'services' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/risk`}
                                             exact
                                             activeClassName="active-sub">
                                        Risk Assessment
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/3DSecure`}
                                             exact
                                             activeClassName="active-sub">
                                        3D Secure
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/verificationServices`}
                                             exact
                                             activeClassName="active-sub">
                                        Verification Services
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'verificationServices' ? {display:'block'} : {}}>
                                        <li>
                                            <span onClick={() => sessionStorage.setItem('hashGuideVerification','guideReference.services.verification.securityCode.title')}>
                                                Security Code
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={() => sessionStorage.setItem('hashGuideVerification','guideReference.services.verification.avs.title')}>
                                                AVS
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={() => sessionStorage.setItem('hashGuideVerification','guideReference.services.verification.extended.title')}>
                                                Extended Services
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${guideReferenceURL}/services/recurring`}
                                             exact
                                             activeClassName="active-sub">
                                        Recurring Payments
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/installments`}
                                             exact
                                             activeClassName="active-sub">
                                        Installment Plans
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/taxes`}
                                             exact
                                             activeClassName="active-sub">
                                        Taxes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/promotions`}
                                             exact
                                             activeClassName="active-sub">
                                        Promotions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${guideReferenceURL}/services/wallets`}
                                             activeClassName="active-sub">
                                        Wallets
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'wallets' ? {display:'block'} : {}}>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/services/wallets/masterpass`}
                                                     activeClassName="active-sub">
                                                Masterpass
                                            </NavLink>
                                            <ul className="sub-menu" style={this.state.activeSub === 'wallets' ? {display:'block'} : {}}>
                                                <li>
                                                    <NavLink to={`${guideReferenceURL}/services/wallets/masterpass/pci`}
                                                             exact
                                                             activeClassName="active-sub">
                                                        PCI onboarding by merchant
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${guideReferenceURL}/services/wallets/masterpass/nonPci`}
                                                             exact
                                                             activeClassName="active-sub">
                                                        Non PCI onboarding by merchant
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${guideReferenceURL}/services/wallets/masterpass/full`}
                                                             exact
                                                             activeClassName="active-sub">
                                                        Non PCI onboarding by Worldline
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <NavLink to={`${guideReferenceURL}/services/wallets/visacheckout`}
                                                     exact
                                                     activeClassName="active-sub">
                                                VisaCheckout
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/tokenization`}
                                             exact
                                             activeClassName="active-sub">
                                        Tokenization
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/services/aggregators`}
                                             exact
                                             activeClassName="active-sub">
                                        Aggregators
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/*<li>*/}
                            {/*<NavLink to={`${env.guideReferenceURL}/`}>*/}
                                {/*<p>Payment Methods</p>*/}
                            {/*</NavLink>*/}
                        {/*</li>*/}
                        <li className={this.state.active === 'libraries' ? 'active' : ''}>
                            <NavLink to={`${env.guideReferenceURL}/libraries`}
                                     activeClassName="active">
                                <p>Libraries</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'libraries' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/libraries`}
                                             exact
                                             activeClassName="active-sub">
                                        Server SDKs
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/libraries/client`}
                                             exact
                                             activeClassName="active-sub">
                                        Client SDKs
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${env.guideReferenceURL}/libraries/plugins`}
                                             exact
                                             activeClassName="active-sub">
                                        Plugins
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/*<li className={this.state.active === 'thirdParty' ? 'active' : ''}>*/}
                            {/*<Link to={`${env.guideReferenceURL}/thirdParty`}>*/}
                                {/*<p>Third-Party Integrations</p>*/}
                            {/*</Link>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink to={`${env.guideReferenceURL}/testing`}
                                     exact
                                     activeClassName="active">
                                <p>Testing</p>
                            </NavLink>
                        </li>
                        <li className="side-divider">
                            <hr/>
                        </li>
                        <li>
                            <Link to={`${env.apiReferenceURL}/`}>
                                <p>Api Reference</p>
                            </Link>
                        </li>
                    </ul>

                    {/*NAV API Reference*/}

                    <ul className={this.state.menu === env.apiReferenceURL.substr(1) ? 'nav' : 'hide'}>
                        <li  className={this.state.active === '' ? 'active' : ''}>
                            <NavLink to={`${apiReferenceURL}/`}
                                     exact
                                     activeClassName="active">
                                <p>Index</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/overview`}
                                     activeClassName="active">
                                <p>Overview</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'overview' ? {display:'block'} : {}}>
                                <li>
                                    <span onClick={() => sessionStorage.setItem('hashApiOverview','apiOverviewEnvironments')}>
                                        Environments
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => sessionStorage.setItem('hashApiOverview','apiOverviewTimeout')}>
                                        Timeout
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => sessionStorage.setItem('hashApiOverview','apiOverviewContentType')}>
                                        Content-Type
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => sessionStorage.setItem('hashApiOverview','apiOverviewSecureHash')}>
                                        Secure Hash
                                    </span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/simpleCheckout`}
                                     activeClassName="active">
                                <p>Simple Checkout</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'simpleCheckout' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/simpleCheckout/CardPayment`}
                                             activeClassName="active-sub">
                                        Card Payment
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'CardPayment' ? {display:'block'} : {}}>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/simpleCheckout/CardPayment/Sale`}
                                                exact
                                                activeClassName="active-sub">
                                                Sale
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/simpleCheckout/CardPayment/Authorization`}
                                                exact
                                                activeClassName="active-sub">
                                                Authorization
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment`}
                                             activeClassName="active-sub">
                                        Card Split Payment
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'CardSplitPayment' ? {display:'block'} : {}}>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Sale`}
                                                exact
                                                activeClassName="active-sub">
                                                Sale
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Authorization`}
                                                exact
                                                activeClassName="active-sub">
                                                Authorization
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/simpleCheckout/CashPayment`}
                                             exact
                                             activeClassName="active-sub">
                                        Cash Payment
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/simpleCheckout/BankPayment`}
                                             exact
                                             activeClassName="active-sub">
                                        Bank Payment
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/payments`}
                                     activeClassName="active">
                                <p>Payments</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'payments' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/payments/CardPayment`}
                                             activeClassName="active-sub">
                                        Card Payment
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'CardPayment' ? {display:'block'} : {}}>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/payments/CardPayment/Sale`}
                                                exact
                                                activeClassName="active-sub">
                                                Sale
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/payments/CardPayment/Authorization`}
                                                exact
                                                activeClassName="active-sub">
                                                Authorization
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/payments/Capture`}
                                             exact
                                             activeClassName="active-sub">
                                        Capture
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/payments/Refund`}
                                             exact
                                             activeClassName="active-sub">
                                        Refund
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/payments/RetrieveTransactions`}
                                             activeClassName="active-sub">
                                        Retrieve Transactions
                                    </NavLink>
                                    <ul className="sub-menu" style={this.state.activeSub === 'RetrieveTransactions' ? {display:'block'} : {}}>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/payments/RetrieveTransactions/SimpleQueryTx`}
                                                exact
                                                activeClassName="active-sub">
                                                SimpleQueryTx
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={`${apiReferenceURL}/payments/RetrieveTransactions/QueryTxs`}
                                                exact
                                                activeClassName="active-sub">
                                                QueryTxs
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/payments/RetrieveInstallments`}
                                             exact
                                             activeClassName="active-sub">
                                        Retrieve Installments
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/services`}
                                     activeClassName="active">
                                <p>Services</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'services' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/services/CardNumber`}
                                             exact
                                             activeClassName="active-sub">
                                        Get Card Number
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/services/IINLookup`}
                                             exact
                                             activeClassName="active-sub">
                                        IIN Lookup
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/services/ClientSession`}
                                             exact
                                             activeClassName="active-sub">
                                        Client Session
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/services/ChangeSecretKey`}
                                             exact
                                             activeClassName="active-sub">
                                        Change Secret Key
                                    </NavLink>
                                </li>
                                {/*<li className={(this.state.activeSub === 'QueryCardDetails') ? 'active' : ''}>*/}
                                    {/*<Link to={`${apiReferenceURL}/services/QueryCardDetails`}>*/}
                                        {/*Query Card Details*/}
                                    {/*</Link>*/}
                                {/*</li>*/}
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/paymentMethodToken`}
                                     activeClassName="active">
                                <p className="line-height-16">Payment Method Token</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'paymentMethodToken' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethodToken/Create`}
                                             exact
                                             activeClassName="active-sub">
                                        Create
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethodToken/Recache`}
                                             exact
                                             activeClassName="active-sub">
                                        Recache
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethodToken/Retrieve`}
                                             exact
                                             activeClassName="active-sub">
                                        Retrieve
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/paymentMethod`}
                                     activeClassName="active">
                                <p>Payment Method</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'paymentMethod' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethod/Create`}
                                             exact
                                             activeClassName="active-sub">
                                        Create
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethod/CreateFromPayment`}
                                             exact
                                             activeClassName="active-sub">
                                        Create from Payment
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethod/Retrieve`}
                                             exact
                                             activeClassName="active-sub">
                                        Retrieve
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethod/Update`}
                                             exact
                                             activeClassName="active-sub">
                                        Update
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/paymentMethod/Delete`}
                                             exact
                                             activeClassName="active-sub">
                                        Delete
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/customer`}
                                     activeClassName="active">
                                <p>Customer</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'customer' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/customer/Create`}
                                             exact
                                             activeClassName="active-sub">
                                        Create
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/customer/Retrieve`}
                                             exact
                                             activeClassName="active-sub">
                                        Retrieve
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/customer/Update`}
                                             exact
                                             activeClassName="active-sub">
                                        Update
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/customer/Delete`}
                                             exact
                                             activeClassName="active-sub">
                                        Delete
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/fraud`}
                                     activeClassName="active">
                                <p>Fraud Prevention</p>
                            </NavLink>
                            <ul className="sub-menu" style={this.state.active === 'fraud' ? {display:'block'} : {}}>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/fraud/Screening`}
                                             exact
                                             activeClassName="active-sub">
                                        Fraud Screening
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${apiReferenceURL}/fraud/Manual`}
                                             exact
                                             activeClassName="active-sub">
                                        Notify Manual Review
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/complex`}
                                     exact
                                     activeClassName="active">
                                <p>Complex Types</p>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${apiReferenceURL}/reference`}
                                     exact
                                     activeClassName="active">
                                <p>Reference Tables</p>
                            </NavLink>
                        </li>
                        <li className="side-divider">
                            <hr/>
                        </li>
                        <li>
                            <Link to={`${env.guideReferenceURL}/`}>
                                <p>Guide Reference</p>
                            </Link>
                        </li>
                    </ul>

                    {/* Foot icons */}

                    <ul className="nav nav-icons">
                        <li className="icons">
                            <a href="https://github.com/Ingenico-NPS-Latam" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-github"></i>
                            </a>
                            <div ref={(node) => this.languageRef = node}
                                 className={this.state.languageDrop ? "btn-group dropup show" : "btn-group dropup"}>
                                <button type="button"
                                        className="btn btn-link dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        onClick={() => this.setState({ languageDrop: !this.state.languageDrop })}
                                        aria-expanded="true">
                                    <i className="fa fa-globe" aria-hidden="true"/>
                                </button>
                                <div className={this.state.languageDrop ? "dropdown-menu show" : "dropdown-menu"} x-placement="top-start">
                                    <a className="dropdown-item"
                                       onClick={() => this.setState({ languageDrop: !this.state.languageDrop })}>
                                        <img src={EN} alt="EN" className="flag en" onClick={() => this.props.changeLanguage('en')}/>
                                    </a>
                                    {/*<a className="dropdown-item"*/}
                                       {/*onClick={() => this.setState({ languageDrop: !this.state.languageDrop })}>*/}
                                        {/*<img src={ES} alt="ES" className="flag es" onClick={() => this.props.changeLanguage('es')}/>*/}
                                    {/*</a>*/}
                                    {/*<a className="dropdown-item"*/}
                                       {/*onClick={() => this.setState({ languageDrop: !this.state.languageDrop })}>*/}
                                        {/*<img src={PT} alt="PT" className="flag pt" onClick={() => this.props.changeLanguage('pt')}/>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                            <i className="fa fa-envelope contact-icon"
                               onClick={() => this.props.openContactForm() }
                               aria-hidden="true"/>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(Sidebar);