import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";

class PaymentMethodToken extends React.Component {

    render() {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Payment Method Token</h3>
                    </div>
                    <div className="col-12">
                        <p>
                            In this section you will see methods for one time used as a part of an advanced integration.
                        </p>
                        <div className="alert alert-info">
                            We use the word Token to identify an object or method as <b>One time Used</b>
                        </div>
                        <hr/>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 25}}>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethodToken/Create`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Create
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethodToken/Recache`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Recache
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/paymentMethodToken/Retrieve`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Retrieve
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentMethodToken;