import React from 'react';

class Login extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            loginDrop: false,
            cpDrop: false,
            loginModal: false,
            showPassword: false,
            loginEnv: '',
            username: {
                active: false,
                text: ''
            },
            password: {
                active: false,
                text: ''
            }
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateText = this.updateText.bind(this);
        this.environment = this.environment.bind(this);
        this.showPassword = this.showPassword.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchend', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.state.loginDrop && (this.loginRef && !this.loginRef.contains(event.target))) {
            this.setState({
                loginDrop: false
            });
        }

        if (this.state.loginModal && (this.loginModalRef && !this.loginModalRef.contains(event.target))) {
            this.setState({
                loginModal: false
            });
        }
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchend', this.handleClickOutside);
    }

    environment(event){
        this.setState({
            loginEnv: event.target.value
        });
    }

    updateText(event){
        if(event.target.id === 'username'){
            this.setState({
                username:{
                    active: event.target.value ? true : false,
                    text: event.target.value
                }
            });
        }else if(event.target.id === 'password') {
            this.setState({
                password:{
                    active: event.target.value ? true : false,
                    text: event.target.value
                }
            });
        }
    }

    showPassword(){
        let icon = document.getElementById('showPassword');

        if(!this.state.showPassword){
            document.getElementById('password').setAttribute('type','text');
            icon.classList.add('fa-eye');
            icon.classList.remove('fa-eye-slash');
        }else{
            document.getElementById('password').setAttribute('type','password');
            icon.classList.add('fa-eye-slash');
            icon.classList.remove('fa-eye');
        }

        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    render() {
        return (
            <div className="login">
                <div ref={(node) => this.loginRef = node}
                    className={this.state.loginDrop ? "dropdown show" : "dropdown"}>
                    <button className="btn btn-link dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            onClick={() => this.setState({ loginDrop: !this.state.loginDrop })}
                            aria-haspopup="true"
                            aria-expanded="false">
                        <i className="fa fa-user-circle" aria-hidden="true"/>
                    </button>
                    <div className={this.state.loginDrop ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                        {/*<a id="loginClickOpen" className="dropdown-item" onClick={() => this.setState({loginModal: true})}>*/}
                            {/*Login*/}
                        {/*</a>*/}
                        <div className="dropdown-item"
                           onMouseOver={() => this.setState({cpDrop: true})}
                           onMouseLeave={() => { setTimeout( () => { this.setState({cpDrop: false}) },300) }}
                        >
                            Control Panel
                            <div className={this.state.cpDrop ? "dropdown-menu dropdown-left show" : "dropdown-menu dropdown-left"}>
                                <a className="dropdown-item"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   href="https://panel.nps.com.ar">
                                    Production
                                </a>
                                <a className="dropdown-item"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   href="https://boimplementacion.nps.com.ar">
                                    Staging
                                </a>
                                <a className="dropdown-item"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   href="https://bosandbox.nps.com.ar">
                                    Sandbox
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.loginModal ? 'modal fade show' : 'modal fade'} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content"
                             ref={(node) => this.loginModalRef = node}>
                            <div className="modal-header">
                                <h5 className="modal-title">Login</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({loginModal: false})}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="help-environment">
                                        <small>Select the environment where you want to login</small>
                                    </div>
                                    <div className="switch-toggle switch-holo">
                                        <input id="sandbox"
                                               name="env"
                                               value="sandbox"
                                               type="radio"
                                               onChange={this.environment}
                                               checked={this.state.loginEnv === 'sandbox'}/>
                                        <label htmlFor="sandbox">Sandbox</label>

                                        <input id="implementation"
                                               name="env"
                                               value="implementation"
                                               type="radio"
                                               onChange={this.environment}
                                               checked={this.state.loginEnv === 'implementation'} />
                                        <label htmlFor="implementation">Implementation</label>

                                        <input id="production"
                                               name="env"
                                               value="production"
                                               type="radio"
                                               onChange={this.environment}
                                               checked={this.state.loginEnv === 'production'}/>
                                        <label htmlFor="production">Production</label>
                                        {/*<a>&nbsp;</a>*/}
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="username"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="username"
                                               className={this.state.username.active || this.state.username.text ? 'active': ''}>
                                            User
                                        </label>
                                    </div>

                                    <div className="md-form">
                                        <input type="password" id="password"
                                               className="form-control"
                                               onChange={this.updateText}/>
                                        <label htmlFor="password"
                                               className={this.state.password.active || this.state.password.text ? 'active': ''}>
                                            Password
                                        </label>
                                        <i className="fa fa-eye-slash prefix" id="showPassword" onClick={() => this.showPassword()}/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-block btn-primary">Login</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.loginModal ? 'modal-backdrop fade show' : ''}/>
            </div>
        );
    }
}

export default Login;