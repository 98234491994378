import ReactDOM from "react-dom";


/*ENUMS*/
import enumVersion from '../documentation/enums/_Version';
import enumSource from '../documentation/enums/_Source';
import enumLanguage from '../documentation/enums/_Languages';
import enumCurrency from '../documentation/enums/_Currencies';
import enumCountry from '../documentation/enums/_Countries';
import enumPlan from '../documentation/enums/_Plans';
import enumIdType from '../documentation/enums/_Person_Identifications';
import enumShipMethod from '../documentation/enums/_Shipping_Methods';
import enumShipCarrier from '../documentation/enums/_Shipping_Carriers';
import enumCriteria from '../documentation/enums/_Criteria';
import enumQueryCriteria from '../documentation/enums/_QueryCriteria';
import enumReviewResult from '../documentation/enums/_ReviewResult';
import enum3DsecureProtocol from '../documentation/enums/_3DSecureProtocol';

export default class  DocumentationTable {

    constructor(props, context) {
        this.iterateThroughItems = this.iterateThroughItems.bind(this);
        this.iterateInner = this.iterateInner.bind(this);
        this.buildRows = this.buildRows.bind(this);
        this.showChildren = this.showChildren.bind(this);
        this.findClassElement = this.findClassElement.bind(this);
        this.classDifferences = this.classDifferences.bind(this);
        this.adjustParagraphs = this.adjustParagraphs.bind(this);
    }

    adjustParagraphs(text){
        return text.replace(/\\/g,'. <br/>');
    }

    showChildren(ref,event){
        let self = this;
        let elemClass = event.target.parentElement.classList;
        let filtered = this.findClassElement(elemClass,'children');
        if(filtered){
            let code = filtered.split('-')[1];
            let parents = ReactDOM.findDOMNode(ref).getElementsByClassName('sub-row');

            let childrens = Array.from(parents).filter(parent => {
                let tmp = parent.className.split(' ');
                let response = self.findClassElement(tmp,'child-'+code);
                if(response){
                    return response;
                }else{
                    return false
                }
            });

            let classNameChild = Array.from(childrens[0].classList).filter(childClass => {
                if(childClass.indexOf('child-') !== -1){
                    return childClass;
                }
                return false;
            });

            let finalElements = ReactDOM.findDOMNode(ref).getElementsByClassName(classNameChild[0]);

            Array.from(finalElements).map(function(element){
                let alreadyHaveClass = self.findClassElement(element.classList, 'showSubRow');
                if(alreadyHaveClass){
                    let difference = self.classDifferences(element.classList,alreadyHaveClass);
                    element.className = difference.join(' ');


                    let differenceParent = Array.from(event.target.parentElement.classList).filter(function(x){
                        return x !== 'subRowShowed';
                    });
                    event.target.parentElement.className = differenceParent.join(' ');


                    let haveChilds = self.findClassElement(element.classList,'have-sub-row');
                    if(haveChilds){
                        let childClasses = self.findClassElement(element.classList,'children');
                        let childCode = childClasses.split('-')[1];
                        let childElements = ReactDOM.findDOMNode(ref).getElementsByClassName('child-'+childCode);
                        Array.from(childElements).map(function(element){
                            let alreadyHaveClass = self.findClassElement(element.classList, 'showSubRow');
                            if(alreadyHaveClass){
                                let difference = self.classDifferences(element.classList,alreadyHaveClass);
                                element.className = difference.join(' ');
                            }
                            return false;
                        });

                        let differenceParent = Array.from(element.classList).filter(function(x){
                            return x !== 'subRowShowed';
                        });
                        element.className = differenceParent.join(' ');
                    }
                }else{
                    element.className += ' showSubRow';
                    event.target.parentElement.className += ' subRowShowed'
                }
                return false;
            });
        }
    }

    findClassElement(elemClass, classSearch){
        return Array.from(elemClass).find(function(value){
            return value.indexOf(classSearch) !== -1;
        });
    }

    classDifferences(elemClass, classSearch){
        return Array.from(elemClass).filter(function(x){
            return x !== classSearch;
        });
    }

    iterateThroughItems(data, idTranslate){
        let tableRows = '';
        let self = this;
        Object.keys(data).map((indexKey) => {
                if(typeof data[indexKey].struct === 'object'){
                    let code = btoa(Math.random());
                    let styles= ['have-sub-row', 'children-'+code];
                    tableRows += self.buildRows(data[indexKey], indexKey,styles, idTranslate+'.'+indexKey);
                    tableRows += self.iterateInner(data[indexKey].struct, code,1)
                }else{
                    tableRows += self.buildRows(data[indexKey], indexKey,[], idTranslate+'.'+indexKey);
                }
                return false;
            }
        );
        return {__html:tableRows};
    }

    iterateInner(data,code,depth,innerRow){
        let tableRows = '';
        let self = this;
        Object.keys(data).map((indexKey) => {
                if(typeof data[indexKey].struct === 'object'){
                    let codeSub = btoa(Math.random());
                    let styles = ['sub-row', 'have-sub-row', 'child-'+code,'children-'+codeSub, 'padding-depth-'+depth];
                    tableRows += self.buildRows(data[indexKey], indexKey,styles);
                    tableRows += self.iterateInner(data[indexKey].struct, codeSub, depth + 1, true)
                }else{
                    let styles = ['sub-row', 'child-'+code, 'padding-depth-'+depth];
                    if(innerRow){
                        styles.push('padding-inner-sub-row');
                    }
                    tableRows += self.buildRows(data[indexKey], indexKey,styles);
                }
                return false;
            }
        );
        return tableRows;
    }

    buildRows(data,indexKey,styles, idTranslate = ''){
        let example = '';
        let minMax = '';
        let validator = '<small>';

        if((data.example && data.type !== 'enum') || (indexKey === 'TypeId' || indexKey === 'Type' || indexKey === 'Risk')){
            example = '<br/><br/><i>Example:</i>&nbsp;&nbsp;&nbsp;'+data.example;
        }else{
            if(data.type === 'enum') {
                example = '<br/><br/><i>Example:</i>&nbsp;&nbsp;&nbsp;' +
                    '<select>';

                switch (indexKey) {
                    case 'psp_Version':
                        enumVersion.map(val =>
                            example += '<option>' + val.description + '</option>'
                        );
                        break;
                    case 'psp_TxSource':
                        enumSource.map(val =>
                            example += '<option>' + val.code + '</option>'
                        );
                        break;
                    case 'psp_3dSecure_ProtocolVersion':
                        enum3DsecureProtocol.map(val => 
                            example += '<option>' + val.code + '</option>'
                          );
                        break;
                    case 'psp_FrmLanguage':
                        enumLanguage.map(val =>
                            example += '<option>' + val.code + '</option>'
                        );
                        break;
                    case 'InvoiceCurrency':
                    case 'BaseFareCurrency':
                    case 'psp_Currency':
                        enumCurrency.map(val =>
                            example += '<option>' + val.description + ' (' + val.code + ')</option>'
                        );
                        break;
                    case 'psp_Country':
                        enumCountry.map(val =>
                            example += '<option>' + val.description + ' (' + val.code + ')</option>'
                        );
                        break;
                    case 'psp_Plan':
                        enumPlan.map(val =>
                            example += '<option>' + val.description + ' (' + val.code + ')</option>'
                        );
                        break;
                    case 'IDType':
                        enumIdType.map(val =>
                            example += '<option>' + val.code + ' (' + val.description + ')</option>'
                        );
                        break;
                    case 'Method':
                        enumShipMethod.map(val =>
                            example += '<option>' + val.code + '(' + val.description + ')</option>'
                        );
                        break;
                    case 'Carrier':
                        enumShipCarrier.map(val =>
                            example += '<option>' + val.code + ' (' + val.description + ')</option>'
                        );
                        break;
                    case 'psp_Criteria':
                        enumCriteria.map(val =>
                            example += '<option>' + val.code + ' (' + val.description + ')</option>'
                        );
                        break;
                    case 'psp_QueryCriteria':
                        enumQueryCriteria.map(val =>{
                            if(idTranslate.indexOf('SimpleQueryTx') !== -1 && (val.code === 'T' || val.code === 'M')){
                                example += '<option>' + val.code + ' (' + val.description + ')</option>'
                            }
                            if(idTranslate.indexOf('QueryTxs') !== -1 && val.code === 'O'){
                                example += '<option>' + val.code + ' (' + val.description + ')</option>'
                            }
                            return false;
                        });
                        break;
                    case 'psp_ReviewResult':
                        enumReviewResult.map(val =>
                            example += '<option>' + val.code + ' (' + val.description + ')</option>'
                        );
                        break;
                    case 'Gender':
                        example += '<option>M</option>' +
                            '<option>F</option>';
                        break;
                    default:
                        break;
                }
                example += '</select>';
            }
        }
        if(data.format){
            switch (data.format){
                case 'VALIDATE_ALPHA_NUM_SPACE':
                    validator += 'Alphabetic characters and any number with spaces';
                    break;
                case 'VALIDATE_ALPHA_NUM':
                    validator += 'Alphabetic characters and any number';
                    break;
                case 'VALIDATE_ALPHA':
                    validator += 'Alphabetic characters';
                    break;
                case 'VALIDATE_NAME':
                    validator += 'Alphabetic characters, can have accents, spaces or apostrophe';
                    break;
                case 'VALIDATE_GENDER':
                case 'VALIDATE_ORDER_ITEM_RISK':
                case 'VALIDATE_PASSENGER_TYPE':
                case 'VALIDATE_COUNTRY':
                case 'VALIDATE_CURRENCY':
                case 'VALIDATE_TXSOURCE':
                case 'VALIDATE_LANGUAGE':
                case 'VALIDATE_VERSION':
                case 'VALIDATE_BANKID':
                case 'VALIDATE_SHIPPING_METHOD':
                case 'VALIDATE_SHIPPING_CARRIER':
                case 'VALIDATE_ID_TYPE':
                case 'VALIDATE_TAX_TYPE_ID':
                case 'VALIDATE_MERCHANT_TYPE':
                case 'VALIDATE_PLAN':
                case 'VALIDATE_3DS_PROTOCOL':
                    validator += 'Enum value only';
                    break;
                case 'VALIDATE_UTF8_32_126':
                    validator += 'Alphabetic characters, numbers, spaces and symbols. Any UTF-8 character between 32 and 126 (Decimal Base)';
                    break;
                case 'VALIDATE_MERCHANTID':
                    validator += 'Alphabetic characters, any number and underscores';
                    break;
                case 'VALIDATE_ORDER':
                    validator += 'Alphabetic characters, any number, underscore, dash and dots';
                    break;

                case 'VALIDATE_QUERYCRITERIA':
                    validator += '';
                    break;
                case 'VALIDATE_ANY_VALUE':
                    validator += 'Any UTF8 character';
                    break;
                case 'VALIDATE_IP_ADDRESS':
                    validator += 'IP address in v4 or v6 format';
                    break;
                case 'VALIDATE_BASE64':
                    validator += 'Alphabetic characters, numbers, and symbols (+, =, /)';
                    break;
                case 'VALIDATE_BASE64_UUID':
                  validator += 'UUID value';
                  break;
                default:
                    break;
            }
        }
        validator += '</small>';


        if(data.type === 'string' || data.type === 'enum'){
            minMax = '<small>Min length: '+ data.min +' / Max length: '+ data.max +'</small>';
        }else if(data.type === 'number'){
            minMax = '<small>Min: '+ data.min +' / Max: '+ data.max +'</small>';
        }else if(data.type === 'uri'){
            let min = data.min ? data.min : '-';
            minMax = '<small>Min length: '+ min +' / Max length: '+ data.max +'</small>';
        }


        let required = data.mandatory ? '<i class="fa fa-check-circle" aria-hidden="true"></i>' : '';

        return '<tr class="'+styles.join(' ')+'" id="'+idTranslate+'">\n' +
            '                    <td>'+indexKey+'</td>\n' +
            '                    <td class="text-center">' + required +
            '</td>\n' +
            '                    <td>'+data.type+'</td>\n' +
            '                    <td class="table-description">'+this.adjustParagraphs(data.description)+'<br/>' + minMax + '<br/>' + validator + example +
            '                </tr>';
    }
}

