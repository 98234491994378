import React from 'react';
import T from 'i18react';

import Accordion from '../../../../components/Accordion';
import CodeHighlight from '../../../../components/CodeHighlight';

const PHP = () => {
    return (
        <div className="col-12">
            <div className="php-library"/>
            <div className="row">
                <div className="col-12 col-md-2 download text-center">
                    <a href="https://github.com/Ingenico-NPS-Latam/nps-sdk-php" className="get-started-in" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" />
                        Download
                    </a>
                    <br/>
                    <br/>
                    <span className="badge badge-success">
                        5.3 or above
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                    <h4 id="libraries.server.php.install">
                        {T.translate("libraries.server.php.install")}
                    </h4>
                    <Accordion id="libraries.server.php.install.composer.title" open
                        title={T.translate("libraries.server.php.install.composer.title")}>
                        <p id="libraries.server.php.install.composer.text">
                            {T.translate("libraries.server.php.install.composer.text")}
                        </p>
                        <CodeHighlight languague="JSON">
                            {
                                '{\n' +
                                '    "require": \n' +
                                '        {\n' +
                                '            "nps/php-sdk": "1.2.19"\n' +
                                '        }\n' +
                                '}'
                            }
                        </CodeHighlight>
                        <p id="libraries.server.php.install.composer.command">
                            {T.translate("libraries.server.php.install.composer.command")}
                        </p>
                        <CodeHighlight languague="BASH">
                            {
                                'composer require nps/php-sdk'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.php.install.manual.title" open
                        title={T.translate("libraries.server.php.install.manual.title")}>
                        <p id="libraries.server.php.install.manual.text">
                            {T.translate("libraries.server.php.install.manual.text")}
                        </p>
                        <CodeHighlight languague="PHP">
                            {
                               'require_once(__DIR__ . \'/nps-sdk-php/init.php\');'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.php.configuration.title">
                        {T.translate("libraries.server.php.configuration.title")}
                    </h4>
                    <Accordion id="libraries.server.php.configuration.basic" open
                        title={T.translate("libraries.server.php.configuration.basic")}>
                        <CodeHighlight languague="PHP">
                            {
                                'require_once \'./vendor/autoload.php\';\n' +
                                'use NpsSDK\\Configuration;\n' +
                                'use NpsSDK\\Constants;\n' +
                                'Configuration::environment(Constants::SANDBOX_ENV);\n' +
                                'Configuration::secretKey("_YOUR_SECRET_KEY_");'
                            }
                        </CodeHighlight>
                    </Accordion>
                    <Accordion id="libraries.server.php.configuration.advanced.title" open
                        title={T.translate("libraries.server.php.configuration.advanced.title")}>
                        <div className="row">
                            <div className="col-12">
                                <hr/>
                                <h4 id="libraries.server.php.configuration.advanced.log.title">
                                    {T.translate("libraries.server.php.configuration.advanced.log.title")}
                                </h4>
                                <p id="libraries.server.php.configuration.advanced.log.text">
                                    {T.translate("libraries.server.php.configuration.advanced.log.text")}
                                </p>
                                <div id="libraries.server.php.configuration.advanced.log.information"
                                    className="alert alert-info">
                                    {T.translate("libraries.server.php.configuration.advanced.log.information")}
                                </div>
                                <CodeHighlight languague="PHP">
                                    {
                                        'use Monolog\\Logger;\n' +
                                        '$logger = new Logger("NpsSdk");\n' +
                                        '\n' +
                                        'use NpsSDK\\Configuration;\n' +
                                        '\n' +
                                        'Configuration::secretKey("_YOUR_SECRET_KEY_");\n' +
                                        'Configuration::logger($logger);'
                                    }
                                </CodeHighlight>
                                <p id="libraries.server.php.configuration.advanced.log.level">
                                    {T.translate("libraries.server.php.configuration.advanced.log.level")}
                                </p>
                                <CodeHighlight languague="PHP">
                                    {
                                        'use NpsSDK\\Configuration;\n' +
                                        '\n' +
                                        'Configuration::secretKey("_YOUR_SECRET_KEY_");\n' +
                                        'Configuration::loglevel("DEBUG");'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.php.configuration.advanced.sanitize.title">
                                    {T.translate("libraries.server.php.configuration.advanced.sanitize.title")}
                                </h4>
                                <p id="libraries.server.php.configuration.advanced.sanitize.text">
                                    {T.translate("libraries.server.php.configuration.advanced.sanitize.text")}
                                </p>
                                <CodeHighlight languague="PHP">
                                    {
                                        'use NpsSDK\\Configuration;\n' +
                                        '\n' +
                                        'Configuration::secretKey("_YOUR_SECRET_KEY_");\n' +
                                        'Configuration::sanitize(true);'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.php.configuration.advanced.timeout.title">
                                    {T.translate("libraries.server.php.configuration.advanced.timeout.title")}
                                </h4>
                                <p id="libraries.server.php.configuration.advanced.timeout.execution">
                                    {T.translate("libraries.server.php.configuration.advanced.timeout.execution")}
                                </p>
                                <p id="libraries.server.php.configuration.advanced.timeout.connection">
                                    {T.translate("libraries.server.php.configuration.advanced.timeout.connection")}
                                </p>
                                <CodeHighlight languague="PHP">
                                    {
                                        'use NpsSDK\\Configuration;\n' +
                                        '\n' +
                                        'Configuration::secretKey("_YOUR_SECRET_KEY_");\n' +
                                        'Configuration::executionTimeout(60);\n' +
                                        'Configuration::connectionTimeout(10);'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.php.configuration.advanced.proxy.title">
                                    {T.translate("libraries.server.php.configuration.advanced.proxy.title")}
                                </h4>
                                <p id="libraries.server.php.configuration.advanced.proxy.text">
                                    {T.translate("libraries.server.php.configuration.advanced.proxy.text")}
                                </p>
                                <CodeHighlight languague="PHP">
                                    {
                                        'use NpsSDK\\Configuration;\n' +
                                        '\n' +
                                        'Configuration::secretKey("_YOUR_SECRET_KEY_");\n' +
                                        'Configuration::proxyUrl("http://yourproxy");\n' +
                                        'Configuration::proxyPort(6854);\n' +
                                        'Configuration::proxyUser("proxyUsername");\n' +
                                        'Configuration::proxyPass("proxyPassword");'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <h4 id="libraries.server.php.configuration.advanced.cache.title">
                                    {T.translate("libraries.server.php.configuration.advanced.cache.title")}
                                </h4>
                                <p id="libraries.server.php.configuration.advanced.cache.text">
                                    {T.translate("libraries.server.php.configuration.advanced.cache.text")}
                                </p>
                                <CodeHighlight languague="PHP">
                                    {
                                        'use NpsSDK\\Configuration;\n' +
                                        '\n' +
                                        'Configuration::secretKey(“_YOUR_SECRET_KEY_”);\n' +
                                        'Configuration::useCache(True);\n' +
                                        'Configuration::cacheTTL(86400);\n' +
                                        'Configuration::cacheLocation("/tmp");'
                                    }
                                </CodeHighlight>
                                <hr/>
                            </div>
                        </div>
                    </Accordion>
                    <hr/>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.php.environments.title">
                        {T.translate("libraries.server.php.environments.title")}
                    </h4>
                    <p id="libraries.server.php.environments.text">
                        {T.translate("libraries.server.php.environments.text")}
                    </p>
                    <CodeHighlight languague="PHP">
                        {
                            'require_once \'./vendor/autoload.php\';\n' +
                            '\n' +
                            'use NpsSDK\\Configuration;\n' +
                            'use NpsSDK\\Constants;\n' +
                            'Configuration::environment(Constants::STAGING_ENV);\n' +
                            'Configuration::environment(Constants::SANDBOX_ENV);\n' +
                            'Configuration::environment(Constants::PRODUCTION_ENV);'
                        }
                    </CodeHighlight>
                </div>
                <div id="libraries.server.php.error.title"
                    className="col-12">
                    <h4>
                        {T.translate("libraries.server.php.error.title")}
                    </h4>
                    <p id="libraries.server.php.error.text">
                        {T.translate("libraries.server.php.error.text")}
                    </p>
                    <div id="libraries.server.php.error.information"
                        className="alert alert-info"
                         role="alert">
                        {T.translate("libraries.server.php.error.information")}
                    </div>
                    <CodeHighlight languague="PHP">
                        {
                            'require_once \'./vendor/autoload.php\';\n' +
                            '\n' +
                            'use NpsSDK\\ApiException;\n' +
                            '\n' +
                            '//Code\n' +
                            'try{\n' +
                            '    //code or sdk call\n' +
                            '}catch(ApiException $e){\n' +
                            '    //Code to handle error\n' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
                <div className="col-12">
                    <h4 id="libraries.server.php.example.title">
                        {T.translate("libraries.server.php.example.title")}
                    </h4>
                    <p id="libraries.server.php.example.text">
                        {T.translate("libraries.server.php.example.text")}
                    </p>
                    <CodeHighlight languague="PHP">
                        {
                            'require_once \'./vendor/autoload.php\';\n' +
                            '\n' +
                            'use NpsSDK\\Sdk;\n' +
                            'use NpsSDK\\Configuration;\n' +
                            'use NpsSDK\\Constants;\n' +
                            'use NpsSDK\\ApiException;\n' +
                            '\n' +
                            'Configuration::environment(Constants::SANDBOX_ENV);\n' +
                            'Configuration::secretKey("YourKeyhere");\n' +
                            '\n' +
                            '$sdk = new Sdk();\n' +
                            '\n' +
                            '$params = array(\n' +
                            '    \'psp_Version\'          => \'2.2\',\n' +
                            '    \'psp_MerchantId\'       => \'psp_test\',\n' +
                            '    \'psp_TxSource\'         => \'WEB\',\n' +
                            '    \'psp_MerchTxRef\'       => \'ORDER56666-3\',\n' +
                            '    \'psp_MerchOrderId\'     => \'ORDER56666\',\n' +
                            '    \'psp_Amount\'           => \'1000\',\n' +
                            '    \'psp_NumPayments\'      => \'1\',\n' +
                            '    \'psp_Currency\'         => \'032\', \n' +
                            '    \'psp_Country\'          => \'ARG\', \n' +
                            '    \'psp_Product\'          => \'14\',\n' +
                            '    \'psp_CustomerMail\'     => \'john.doe@example.com\',\n' +
                            '    \'psp_CardNumber\'       => \'4507990000000010\', \n' +
                            '    \'psp_CardExpDate\'      => \'1903\', \n' +
                            '    \'psp_CardSecurityCode\' => \'306\',\n' +
                            '    \'psp_SoftDescriptor\'   => \'Sol Tropical E\',\n' +
                            '    \'psp_PosDateTime\'      => \'2016-12-01 12:00:00\'\n' +
                            '    \n' +
                            ');\n' +
                            'try{\n' +
                            '    $resp = $sdk->payOnline2p($params);\n' +
                            '}catch(ApiException $e){\n' +
                            '    echo \'Code to handle error\';\n' +
                            '}'
                        }
                    </CodeHighlight>
                </div>
            </div>
        </div>
    );
};
export default PHP;