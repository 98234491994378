import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";

class Payments extends React.Component
{
    render()
    {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Payments</h3>
                    </div>
                    <div className="col-12">
                        <p>
                            This section describes the methods that can modify the balance in the customer’s account,
                            either by a credit (refund) or a debit (sale) in the bank account <br/><br/>
                            This method also know as 2-party method
                        </p>
                        <hr/>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 25}}>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/payments/CardPayment`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Card Payment
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/payments/Capture`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Capture
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/payments/Refund`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Refund
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <Link to={`${apiReferenceURL}/payments/RetrieveTransactions`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Retrieve Transactions
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/payments/RetrieveInstallments`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Retrieve Installment
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        );
    }
}

export default Payments;