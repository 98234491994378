import React from 'react';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

class TypistContainer extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            typing: true
        };
        this.done = this.done.bind(this);
    }

    done(){
        this.setState({ typing: false }, () => {
            setTimeout(() => this.setState({ typing: true }), this.props.timeout || 200);
        });
    }

    render() {
        const {children, timeout, ...props} = this.props;

        return this.state.typing ?
            <Typist {...props} onTypingDone={this.done}>{children}</Typist>
            : <div className="Typist"><span>&nbsp;</span><span className="Cursor Cursor--blinking">|</span></div>;
    }
}

export default TypistContainer;