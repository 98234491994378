import React from 'react';
import T from 'i18react';
import ReactSVG from 'react-svg'

import Sale from '../../ApiReference/SimpleCheckout/CardSplitPayment/Sale';
import image from '../../../assets/img/simpleCheckout/split.png';
import apiIcon from '../../../assets/img/api.svg';
import diagram from '../../../assets/img/SplitTransactionDiagram.svg';
import {apiReferenceURL} from "../../../env";
import * as env from "../../../env";

class Split extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            bigImg: false,
        };
        this.loadImagesAndPick = this.loadImagesAndPick.bind(this);
    }

    loadImagesAndPick(){
        let images = {};
        let folder = require.context('../../../assets/img/users/', false, /\.(png|jpe?g|svg)$/)
        folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });

        let result;
        let count = 0;
        for (let prop in images)
            if (Math.random() < 1/++count)
                result = prop;

        return images[result];
    }
    render() {
        return (
            <div className="container-fluid simple-checkout ">
                <div className="row">
                    <div className="col-12">
                        <h3 id="simpleCheckout.split.title">
                            {T.translate("simpleCheckout.split.title")}</h3>
                        <div id="simpleCheckout.split.text">
                            {T.translate("simpleCheckout.split.text")}
                        </div>
                    </div>
                    <div className="col-12">
                        <h3 id="simpleCheckout.split.diagram.title">
                            {T.translate("simpleCheckout.split.diagram.title")}
                        </h3>
                        <br/><br/>
                        <div className="col-12 checkout-container">
                            <ReactSVG src={diagram}
                                 alt="bank diagram"
                                 className="checkout-image"/>
                            <img src={this.loadImagesAndPick()} alt="1" className="img-fluid checkout-user"/>
                            <img src={env.logo} alt="1" className="img-fluid checkout-logo"/>
                        </div>
                        <div className="col-12">
                            <hr/>
                            <ol>
                                <li id="simpleCheckout.split.diagram.list.1">
                                    {T.translate("simpleCheckout.split.diagram.list.1")}</li>
                                <li id="simpleCheckout.diagram.split.list.2">
                                    {T.translate("simpleCheckout.split.diagram.list.2")}</li>
                                <li id="simpleCheckout.diagram.split.list.3">
                                    {T.translate("simpleCheckout.split.diagram.list.3")}</li>
                                <li id="simpleCheckout.diagram.split.list.4">
                                    {T.translate("simpleCheckout.split.diagram.list.4")}</li>
                                <li id="simpleCheckout.diagram.split.list.5">
                                    {T.translate("simpleCheckout.split.diagram.list.5")}</li>
                                <li id="simpleCheckout.diagram.split.list.6">
                                    {T.translate("simpleCheckout.split.diagram.list.6")}</li>
                                <li id="simpleCheckout.diagram.split.list.7">
                                    {T.translate("simpleCheckout.split.diagram.list.7")}</li>
                                <li id="simpleCheckout.diagram.split.list.8">
                                    {T.translate("simpleCheckout.split.diagram.list.8")}</li>
                                <li id="simpleCheckout.diagram.split.list.9">
                                    {T.translate("simpleCheckout.split.diagram.list.9")}</li>
                                <li id="simpleCheckout.diagram.split.list.10">
                                    {T.translate("simpleCheckout.split.diagram.list.10")}</li>
                                <li id="simpleCheckout.diagram.split.list.11">
                                    {T.translate("simpleCheckout.split.diagram.list.11")}</li>
                                <li id="simpleCheckout.diagram.split.list.12">
                                    {T.translate("simpleCheckout.split.diagram.list.12")}</li>
                                <li id="simpleCheckout.diagram.split.list.13">
                                    {T.translate("simpleCheckout.diagram.split.list.13")}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="simpleCheckout.split.byMerchant.title">
                            1.- {T.translate("simpleCheckout.split.byMerchant.title")}</h4>
                        <div id="simpleCheckout.split.byMerchant.text">
                            {T.translate("simpleCheckout.split.byMerchant.text")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="simpleCheckout.split.byInstallments.title">
                            2.- {T.translate("simpleCheckout.split.byInstallments.title")}</h4>
                        <div id="simpleCheckout.split.byInstallments.text">
                            {T.translate("simpleCheckout.split.byInstallments.text")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="simpleCheckout.split.byCard.title">
                            3.- {T.translate("simpleCheckout.split.byCard.title")}</h4>
                        <div id="simpleCheckout.split.byCard.text">
                            {T.translate("simpleCheckout.split.byCard.text")}
                        </div>
                        <img src={image} alt="Split Payment"
                             className={this.state.bigImg ? 'img-fluid cursor-pointer shadow': 'img-small cursor-pointer shadow'}
                             onClick={() => this.setState({bigImg: !this.state.bigImg})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 id="simpleCheckout.split.combine.title">
                            {T.translate("simpleCheckout.split.combine.title")}</h4>
                        <div id="simpleCheckout.split.combine.text">
                            {T.translate("simpleCheckout.split.combine.text")}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-4 col-md-2 flex-v-center">
                                    <ReactSVG src={apiIcon} alt="Locked" className="api-icon-svg"/>
                                </div>
                                <div className="col-8 col-md-10 flex-v-center">
                                    <p>
                                        {T.translate("guideReference.apiHelp.split.detail",
                                            {
                                                "auth": <T.a href={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Authorization` } text="Authorization"/>,
                                                "sale": <T.a href={`${apiReferenceURL}/simpleCheckout/CardSplitPayment/Sale` } text="Sale"/>
                                            }
                                        )}
                                        <br/>
                                        {T.translate("guideReference.apiHelp.all",
                                            {
                                                "api": <T.a href={`${apiReferenceURL}/`} text="Api Reference"/>
                                            }
                                        )}
                                        <br/><br/>
                                        {T.translate("guideReference.apiHelp.example")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <Sale showDocumentation={false}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Split;