import React from 'react';

/*Since it's the same documentation available in advanced checkout, it's imported here*/
import AdvancedCheckout from '../../AdvancedCheckout/Swift';

const Swift = () => {
    return (
        <div className="col-12">
            <AdvancedCheckout/>
        </div>
    );
};

export default Swift;