import React from 'react';
import { Link } from 'react-router-dom';
import {apiReferenceURL} from "../../env";

class Services extends React.Component {

    render() {
        return (
            <div className="container-fluid api-resume-method">
                <div className="row">
                    <div className="col-12">
                        <h3>Services</h3>
                    </div>
                    <div className="col-12">
                        <p>
                            This section shows you a set of methods that are not payment methods.
                        </p>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Link to={`${apiReferenceURL}/services/CardNumber`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Get Card Number
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/services/IINLookup`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                IIN Lookup
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/services/ClientSession`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Client Session
                            </button>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to={`${apiReferenceURL}/services/ChangeSecretKey`}>
                            <button type="button" className="btn btn-light btn-block margin-25-bottom">
                                Change Secret Key
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        );
    }
}

export default Services;