import React from 'react';

import Accordion from '../../components/Accordion'

import Version from '../../documentation/enums/_Version';
import Countries from '../../documentation/enums/_Countries';
import Currencies from '../../documentation/enums/_Currencies';
import Source from '../../documentation/enums/_Source';
import PersonIdentifications from '../../documentation/enums/_Person_Identifications';
import Products from '../../documentation/enums/_Products';
import SecureAction from '../../documentation/enums/_3DSecureAction';
import Banks from '../../documentation/enums/_Banks';
import DocumentTypes from '../../documentation/enums/_DocumentTypes';
import Languages from '../../documentation/enums/_Languages';
import Plans from '../../documentation/enums/_Plans';
import ResponseCodes from '../../documentation/enums/_ResponseCodes';
import ResponseExtended from '../../documentation/enums/_ResponseExtended';
import ShippingCarries from '../../documentation/enums/_Shipping_Carriers';
import ShippingMethods from '../../documentation/enums/_Shipping_Methods';
import CardSecurityCode from '../../documentation/enums/_CardSecurityCodes';

class Reference extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
          tabOpen: ''
        };

        this.createTableRow = this.createTableRow.bind(this);
        this.loadImagesAndPick = this.loadImagesAndPick.bind(this);
    }

    checkOpen(tab){
        if(sessionStorage.getItem('scrollTo')){
            return sessionStorage.getItem('scrollTo').split('.')[3].toLowerCase() === tab.toLowerCase();
        }else{
            return false;
        }
    }

    componentDidUpdate(){
        window.location.reload();
    }

    createTableRow(data,index,cards){
        let dscrpt = { __html: '' };

        if(data.description){
            if(data.description.indexOf('%%') !== -1){
                let parts = data.description.split('%%');
                return <tr key={index}>
                    <td>{data.code}</td>
                    <td>{parts[0]}</td>
                    <td>{parts[1]}</td>
                </tr>
            }else{
                dscrpt = { __html: data.description};
            }
        }

        if(cards){
            return <tr key={index}>
                <td>
                    <div className={`card-${data.code}`}/>
                </td>
                <td>{data.type}</td>
                <td>{data.code}</td>
                <td dangerouslySetInnerHTML={dscrpt}/>
            </tr>
        }else{
            return <tr key={index}>
                <td><b>{data.code}</b></td>
                <td dangerouslySetInnerHTML={dscrpt}/>
            </tr>
        }
    }

    loadImagesAndPick(id){
        let imageId = id.trim() + ".png";
        let images = {};
        let folder = require.context('../../assets/img/cards/', false, /\.(png|jpe?g|svg)$/)
        folder.keys().map((item, index) => { images[item.replace('./', '')] = folder(item); return true; });
        return images[imageId];
    }

    render() {
        return (
            <div>
                <Accordion title="Version">
                    <table className="table">
                        <tbody>
                            { Version.map((data,index) =>
                                this.createTableRow(data,index)
                            )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Countries" open={ this.checkOpen('Countries') }>
                    <table className="table">
                        <tbody>
                            { Countries.map((data,index) =>
                                this.createTableRow(data,index)
                            )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Currencies" open={ this.checkOpen('Currencies') }>
                    <table className="table">
                        <tbody>
                            { Currencies.map((data,index) =>
                                this.createTableRow(data,index)
                            )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Source" open={ this.checkOpen('Source') }>
                    <table className="table">
                        <tbody>
                            { Source.map((data,index) =>
                                this.createTableRow(data,index)
                            )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Person Identifications" open={ this.checkOpen('Person Identifications') }>
                    <table className="table">
                        <tbody>
                            { PersonIdentifications.map((data,index) =>
                                this.createTableRow(data,index)
                            )}
                        </tbody>
                    </table>
                </Accordion>

                <Accordion title="Products" open={ this.checkOpen('Products') }>
                    <table className="table image-testing-cards">
                        <tbody>
                        { Products.map((data,index) =>
                            this.createTableRow(data,index,true)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="3D Secure Action" open={ this.checkOpen('3D Secure Action') }>
                    <table className="table">
                        <tbody>
                        { SecureAction.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Banks" open={ this.checkOpen('Banks') }>
                    <table className="table">
                        <tbody>
                        { Banks.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Document Types" open={ this.checkOpen('Document Types') }>
                    <table className="table">
                        <tbody>
                        { DocumentTypes.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Languages" open={ this.checkOpen('Languages') }>
                    <table className="table">
                        <tbody>
                        { Languages.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Plans" open={ this.checkOpen('Plans') }>
                    <table className="table">
                        <tbody>
                        { Plans.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Response Codes" open={ this.checkOpen('Response Codes') }>
                    <table className="table">
                        <tbody>
                        { ResponseCodes.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Response Extended" open={ this.checkOpen('Response Extended') }>
                    <table className="table">
                        <tbody>
                        { ResponseExtended.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Card Security Codes" open={ this.checkOpen('Card Security Codes') }>
                    <table className="table">
                        <tbody>
                        { CardSecurityCode.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Shipping Carries" open={ this.checkOpen('Shipping Carries') }>
                    <table className="table">
                        <tbody>
                        { ShippingCarries.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
                <Accordion title="Shipping Methods" open={ this.checkOpen('Shipping Methods') }>
                    <table className="table">
                        <tbody>
                        { ShippingMethods.map((data,index) =>
                            this.createTableRow(data,index)
                        )}
                        </tbody>
                    </table>
                </Accordion>
            </div>

        );
    }
}

export default Reference;