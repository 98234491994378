import React from 'react';
import T from "i18react/dist/i18react";
import {apiReferenceURL, merchantName} from "../../../env";

class Tokenization extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: "Tokenization Component"
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 id="guideReference.services.tokenization.title">
                            { T.translate("guideReference.services.tokenization.title") }</h3>
                        <div id="guideReference.services.tokenization.text">
                            { T.translate("guideReference.services.tokenization.text") }
                        </div>
                        <br/>
                        <p>{ T.translate("guideReference.services.tokenization.methods.text") }</p>
                        <ul>
                            <li>{T.translate("guideReference.services.tokenization.methods.1")}</li>
                            <li>{T.translate("guideReference.services.tokenization.methods.2")}</li>
                            <li>{T.translate("guideReference.services.tokenization.methods.3")}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>{T.translate("guideReference.services.tokenization.types.text")}</p>
                        <br/>
                        <h4 id="guideReference.services.tokenization.types.oneTimeUse.title">
                            { T.translate("guideReference.services.tokenization.types.oneTimeUse.title") }
                        </h4>
                        <div id="guideReference.services.tokenization.types.oneTimeUse..text">
                            { T.translate("guideReference.services.tokenization.types.oneTimeUse.text",
                                {
                                    "link": <T.a href={`${apiReferenceURL}/`} text="Api Reference"/>
                                }
                            )}
                        </div>
                        <h4 id="guideReference.services.tokenization.types.persistent.title">
                            { T.translate("guideReference.services.tokenization.types.persistent.title") }
                        </h4>
                        <div id="guideReference.services.tokenization.types.persistent..text">
                            { T.translate("guideReference.services.tokenization.types.persistent.text") }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <br/>
                        <div className="alert alert-info">
                            <div className="row">
                                <div className="col-12">
                                    <p id="guideReference.services.tokenization.types.tips">
                                        {T.translate("guideReference.services.tokenization.types.tip")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="alert alert-warning">
                            <div className="row">
                                <div className="col-12">
                                    <p id="guideReference.services.tokenization.types.customer.text">
                                        <strong>
                                            {T.translate("guideReference.services.tokenization.types.customer.title")}
                                        </strong>
                                        <br/>
                                        {T.translate("guideReference.services.tokenization.types.customer.text",{"whiteLabel": <T.text text={merchantName}/>})}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tokenization;