import React from 'react';
import T from 'i18react';
import CodeHighlight from '../../../../components/CodeHighlight';
import Accordion from '../../../../components/Accordion';
import versions from '../../../../documentation/sdk_versions';

const Install = () => {
    return (
        <div className="col-12">
            <h4 id="advancedCheckout.objectiveC.install.title">
                Step 1 - {T.translate("advancedCheckout.objectiveC.install.title")}
            </h4>
            <p id="advancedCheckout.objectiveC.install.text">
                {T.translate("advancedCheckout.objectiveC.install.text")}
            </p>
            <Accordion id="advancedCheckout.objectiveC.install.cocoaPod.title" open
                title={ "1.1 - " + T.translate("advancedCheckout.objectiveC.install.cocoaPod.title")}>
               <ul>
                   <li id="advancedCheckout.objectiveC.install.cocoaPod.list.1">
                       {T.translate("advancedCheckout.objectiveC.install.cocoaPod.list.1")}
                       <CodeHighlight language="Objective-C">
                           {
                               'pod \'npsSdk\', \''+versions.ios+'\''
                           }
                       </CodeHighlight>
                   </li>
                   <li id="advancedCheckout.objectiveC.install.cocoaPod.list.2">
                       {T.translate("advancedCheckout.objectiveC.install.cocoaPod.list.2")}
                       <CodeHighlight language="Objective-C">
                           {
                              'pod repo update\n' +
                              'pod install'
                           }
                       </CodeHighlight>
                   </li>
               </ul>
            </Accordion>
            <hr/>
            <h4 id="advancedCheckout.objectiveC.install.integration.title">
                Step 2 - {T.translate("advancedCheckout.objectiveC.install.integration.title")}
            </h4>
            <Accordion id="advancedCheckout.objectiveC.install.integration.import.title" open
                title={ "2.1 - " + T.translate("advancedCheckout.objectiveC.install.integration.import.title")}>
                <p id="advancedCheckout.objectiveC.install.integration.import.text">
                    {T.translate("advancedCheckout.objectiveC.install.integration.import.text")}
                </p>
                <CodeHighlight language="Objective-C">
                    {
                        '#import "Nps.h"'
                    }
                </CodeHighlight>
            </Accordion>
            <hr/>
        </div>
    );
};

export default Install;