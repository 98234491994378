import React from 'react';
import { withRouter } from 'react-router'
import T from 'i18react';
import ReactGA from 'react-ga';
import ReactSVG from 'react-svg';

import Search from './Search';
import Login from './Login';
import {googleAnalitics} from "../env";

import darkModeIcon from '../assets/img/dark.svg';
import lightModeIcon from '../assets/img/light.svg';
import preloaderDarkMode from '../assets/img/spin.svg';

class Navigation extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            darkModeEnabled: this.props.darkMode,
            darkModeLoader: false,
            darkModeImg: (this.props.darkMode) ? lightModeIcon : darkModeIcon ,
            title: '',
            openSidebar: false,
            showSearch: true
        };
        this.setTitle = this.setTitle.bind(this);
        this.openSidebar = this.openSidebar.bind(this);
    }

    componentDidMount() {
        if(googleAnalitics.active){
            ReactGA.initialize(googleAnalitics.code);
        }
        let path = this.props.location.pathname.split('/')[1];

        if(this.props.location.pathname.split('/')[1] === 'libraries'){
            this.setTitle(path);
        }else{
            if(this.props.location.pathname.split('/')[1] === 'GuideReference'){
                if(this.props.location.pathname.split('/').length >= 4){
                    this.setTitle(this.props.location.pathname.split('/')[3]);
                }else{
                    if(this.props.location.pathname.split('/').length >= 3){
                        this.setTitle(this.props.location.pathname.split('/')[2]);
                    }else{
                        this.setTitle(path);
                    }
                }
            }else{
                this.setState({ title: 'API Reference' });
            }
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.location.pathname !== prevProps.location.pathname){
            if(googleAnalitics.active){
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
            this.setState({
                showSearch: !(this.props.location.pathname.split('/')[1] === 'search')
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let path = this.props.location.pathname;
        let newPath = nextProps.location.pathname;

        if(path !== newPath || this.props.language !== nextProps.language){
            if(nextProps.location.pathname.split('/')[1] === 'GuideReference'){
                if(nextProps.location.pathname.split('/').length >= 4){
                    this.setTitle(nextProps.location.pathname.split('/')[3]);
                }else{
                    if(nextProps.location.pathname.split('/').length >= 3){
                        this.setTitle(nextProps.location.pathname.split('/')[2]);
                    }else{
                        this.setTitle(path);
                    }
                }
            }else{
                this.setState({ title: 'API Reference' });
            }
        }

        if(this.props.sidebarState !== nextProps.sidebarState){
            this.setState({
                openSidebar: nextProps.sidebarState
            });
        }
    }

    setTitle(path){
        switch (path){
            case '':
                this.setState({ title: T.translate("gettingStarted.title") });
                break;
            case 'reference':
                this.setState({ title: T.translate("guideReference.title") });
                break;
            case 'simple':
                this.setState({ title: T.translate("guideReference.title") });
                break;
            case 'directPayment':
                this.setState({ title: 'Direct Payment' });
                break;
            case 'advanced':
                this.setState({ title: T.translate("advancedCheckout.title") });
                break;
            case 'libraries':
                this.setState({ title: T.translate("libraries.title") });
                break;
            case 'commonInterface':
                this.setState({ title: T.translate("commonInterface.title") });
                break;
            case 'services':
            case 'verificationServices':
            case 'wallets':
            case 'risk':
            case '3DSecure':
            case 'recurring':
            case 'installments':
            case 'taxes':
            case 'promotions':
            case 'tokenization':
            case 'agregators':
                this.setState({ title: 'Services' });
                break;
            case 'thirdParty':
                this.setState({ title: 'Third-Party Integration' });
                break;
            case 'testing':
                this.setState({ title: T.translate("testing.title") });
                break;
            default:
                this.setState({ title: '' });
                break;
        }
    }

    openSidebar(){
        this.props.event.sendMessage('openSidebar',!this.state.openSidebar);
        this.setState({
            openSidebar: !this.state.openSidebar
        });
    }

    enableDarkMode(event){
      localStorage.setItem('darkModeEnabled',event);
      (event) ? document.body.classList.add("dark-mode") : document.body.classList.remove("dark-mode");
      this.setState({ darkModeEnabled: event, darkModeLoader: true, darkModeImg: (event) ? lightModeIcon : darkModeIcon, loginDrop: false });
      setTimeout(() => {
        this.setState({ darkModeLoader: false });
      },500);
    }

    render() {
        return (
          <>
            {/* Si esta cargando, muestra el loader */}
            { this.state.darkModeLoader && <div className="preloaderDarkMode"><ReactSVG src={preloaderDarkMode}/></div> }

            <nav className="navbar navbar-expand-lg navbar-default fixed-top">
                <span className="navbar-brand">
                    {this.state.title}
                    &nbsp;
                </span>
                <button className="navbar-toggler"
                        type="button"
                        onClick={() => this.openSidebar()}
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {
                        this.state.showSearch &&
                        <Search/>
                    }
                    <div className="dark-mode-container">
                        {
                          <a id="darkLightToggle" onClick={() => this.enableDarkMode(!this.state.darkModeEnabled)} className="dark-light-toggle">
                            <img src={this.state.darkModeImg} alt=""/>
                          </a>
                        }
                    </div>
                    <Login/>
                </div>
            </nav>
        </>
        );
    }
}

export default withRouter(Navigation);